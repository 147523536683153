import React, { useState } from 'react';
import { ListItem, ListItemText, ListItemIcon, Dialog, DialogContent, Box, List, Card, Grid } from '@mui/material';
// icon
import { Icon } from '@iconify/react';
import archive from '@iconify/icons-eva/archive-fill';
//
import PropTypes from 'prop-types';

ButtonDemo.propTypes = {
  modulo: PropTypes.string,
  altName: PropTypes.string
};

export default function ButtonDemo({ modulo, altName }) {
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
        <ListItemIcon>
          <Icon icon={archive} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Solicitar Demo</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <List>
                <ListItem sx={{ color: '#637381' }}>
                  <a
                    href={`https://wa.me/+523322638033?text=Hola, quisiera una demo del modulo ${altName}`}
                    style={{ textDecoration: 'none' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ListItemIcon>
                      <Icon icon={archive} width={23} height={23} />
                    </ListItemIcon>
                    <ListItemText>Solicitar Demo</ListItemText>
                  </a>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Box />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
