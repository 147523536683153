import React, { useState } from 'react';
import usePlan, { isExpired, isContrated, getExpireDate, isAuthorized, hasContrated } from 'src/hooks/usePlan';
import { ListItem, ListItemText, Dialog, DialogContent, Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';

const moment = require('moment');

ModInfo.propTypes = {
  modulo: PropTypes.string
};

export default function ModInfo({ modulo }) {
  const [open, setOpen] = useState(false);
  const { plan } = usePlan();

  const expireDate = getExpireDate(modulo, plan);
  const expired = isExpired(modulo, plan);
  const contrated = isContrated(modulo, plan);
  const hContrated = hasContrated(modulo, plan);
  const authorized = isAuthorized(modulo, plan);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
        <ListItemText>Informacion</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose}>
        <DialogContent>
          <Box>
            <Typography>expiración: {moment(expireDate).format('YYYY/MM/DD hh:mm a')}</Typography>
            <Typography>expirado: {expired ? 'si' : 'no'}</Typography>
            <Typography>contratado: {contrated ? 'si' : 'no'}</Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
