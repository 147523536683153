import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogContent,
  Typography,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { changeCTAState } from 'src/hooks/usePlan';
import PropTypes from 'prop-types';

StateMod.propTypes = {
  userid: PropTypes.string,
  altName: PropTypes.string,
  modulo: PropTypes.string,
  crm: PropTypes.object
};

export default function StateMod({ userid, crm, modulo, altName }) {
  const [openT, setOpenT] = useState(false);
  const { state, tags } = crm[modulo];

  const clickOpenT = () => {
    setOpenT(true);
  };

  const clickCloseT = () => {
    setOpenT(false);
  };

  return (
    <TableRow>
      <TableCell>{altName}</TableCell>
      <TableCell>{state}</TableCell>
      <TableCell>
        <Button variant="contained" onClick={clickOpenT}>
          Mostrar Etiquetas
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            changeCTAState(modulo, crm, userid);
          }}
        >
          Cambiar Estado
        </Button>
        <Dialog open={openT} onClose={clickCloseT}>
          <DialogContent>
            <Typography variant="h4">Etiquetas</Typography>
            <List>
              {tags?.map((t) => (
                <ListItem key={t}>
                  <ListItemText>{t}</ListItemText>
                </ListItem>
              ))}
            </List>
          </DialogContent>
        </Dialog>
      </TableCell>
    </TableRow>
  );
}
