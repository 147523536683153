import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Avatar,
  IconButton,
  Box,
  Button,
  AppBar,
  Toolbar,
  DialogActions,
  Tooltip
} from '@mui/material';
import { Icon } from '@iconify/react';
import closefill from '@iconify/icons-eva/close-fill';
import { Close } from '@mui/icons-material';
import UserCard from 'src/components/_dashboard/user/cards/UserCard';
import { alpha } from '@mui/material/styles';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

KanbanUser.propTypes = {
  userid: PropTypes.string.isRequired,
  removeUser: PropTypes.func,
  disabled: PropTypes.bool
};

function KanbanUser({ userid, removeUser, disabled = false }) {
  const [open, setOpen] = useState(false);
  const [openDC, setOpenDC] = useState(false);
  const [userI, setUserI] = useState({});

  useEffect(() => {
    async function getUI() {
      await firestore
        .collection('users')
        .doc(userid)
        .onSnapshot((doc) => {
          setUserI({ id: doc.id, ...doc.data() });
        });
    }
    getUI();
  }, [userid]);

  // metodos
  const clickOpen = () => {
    setOpen(true);
  };
  const clickClose = () => {
    setOpen(false);
  };
  const clickOpenDC = () => {
    setOpenDC(true);
  };
  const clickCloseDC = () => {
    setOpenDC(false);
  };

  return (
    <>
      <Tooltip title={userI?.displayName}>
        <IconButton onClick={clickOpen} key={userI.id}>
          <Avatar alt={userI?.displayName} src={userI?.photoURL} sx={{ m: 0.5, width: 36, height: 36 }} />
        </IconButton>
      </Tooltip>
      {removeUser && (
        <IconButton
          size="small"
          onClick={clickOpenDC}
          disabled={disabled}
          sx={{
            p: '2px',
            top: '5px',
            right: '28px',
            color: 'common.white',
            position: 'relative',
            width: '20px',
            height: '20px',
            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48)
            }
          }}
        >
          <Icon icon={closefill} />
        </IconButton>
      )}
      <Dialog open={open} onClose={clickClose}>
        <DialogContent>
          <UserCard user={userI} />
        </DialogContent>
      </Dialog>
      <Dialog open={openDC} onClose={clickCloseDC}>
        <AppBar position="relative">
          <Toolbar>
            <Button onClick={clickCloseDC}>
              <Close />
            </Button>
            <Box>
              <h3>Remover usuário</h3>
            </Box>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box>
            <p>¿Esta seguro que desea Remover a este usuario?</p>
          </Box>
          <DialogActions>
            <Button onClick={clickCloseDC}>Cancelar</Button>
            <Button onClick={() => removeUser(userid)}>Eliminar</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default KanbanUser;
