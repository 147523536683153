import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  Tooltip,
  Stack,
  Dialog,
  DialogContent,
  MenuItem,
  List,
  FormControlLabel,
  Checkbox,
  TextField,
  Box
} from '@mui/material';
import { MobileDateRangePicker } from '@mui/lab';
import { MIconButton } from '../../../@material-extend';
import { styled } from '@mui/styles';
import UserAvatar from 'src/components/_dashboard/general/UserAvatar';
import UserElement from 'src/components/_dashboard/general/UserElement';
import NewSprintForm from '../NewSprintForm';
import { firestore } from 'src/contexts/FirebaseContext';
import {
  ObjsCompletados,
  ObjsEnProceso,
  ObjsSinEjecutar,
  ObjsTotal,
  ObjsXPE,
  ObjsXStatus
} from 'src/components/_dashboard/kanbansprint/charts';
// icon
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import heartoutline from '@iconify/icons-eva/heart-outline';
import heartfill from '@iconify/icons-eva/heart-fill';
//
import { useDatePicker, DisplayTime } from '../../kobjs/KanbanTaskAdd';
import useAuth from 'src/hooks/useAuth';
import usePermissions, { tienePermiso } from 'src/hooks/usePermissions';
import PropTypes from 'prop-types';

const PRIORITIZES = ['creado', 'en proceso', 'completado', 'detenido', 'archivado'];

// --------------------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary
}));

const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'left'
});

// --------------------------------------------------------------------------------

SprintCard.propTypes = {
  info: PropTypes.object,
  idEmpresa: PropTypes.string
};

export default function SprintCard({ info, idEmpresa }) {
  const { user } = useAuth();
  const { permisos } = usePermissions();
  const [creador, setCreador] = useState('');
  const { id, nombre, objetivo, fechaE, fechaF } = info;
  const [objs, setObjs] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [due, setDue] = useState([fechaE, fechaF]);
  const [openE, setOpenE] = useState(false);
  const [scrumMaster, setScrumMaster] = useState(info.scrumMaster || []);
  const [openMenuSM, setOpenMenuSM] = useState(false);
  const [users, setUsers] = useState([]);
  const [likes, setLikes] = useState(info.likes || []);
  const [status, setStatus] = useState(info.status || 'creado');

  // permisos

  const canEditSprint = tienePermiso('editar sprints', user.id, permisos);

  const allowEdit = canEditSprint || user.id === creador;

  useEffect(() => {
    async function getUsers() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setUsers(doc.data().membersinfo);
          }
        });
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .onSnapshot((doc) => {
          const { creator } = doc.data();
          setCreador(creator);
        });
    }
    getUsers();
  }, [idEmpresa]);

  useEffect(() => {
    async function getCols() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .doc(id)
        .collection('kanbanColunms')
        .onSnapshot((queryS) => {
          const cols = [];
          queryS.forEach((doc) => {
            cols.push({ ...doc.data() });
          });
          setStatuses(cols);
        });
    }
    getCols();
  }, [idEmpresa, id]);

  useEffect(() => {
    async function getObjs() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .doc(id)
        .get()
        .then((doc) => {
          const { objetivos } = doc.data();
          const objss = [];
          objetivos.forEach(async (idK) => {
            await firestore
              .collection('kobjsCards')
              .doc(idK)
              .get()
              .then((docc) => {
                if (docc.exists) {
                  const { name } = docc.data();
                  objss.push({ name, status: statuses.find((f) => f.cardIds.includes(idK))?.name || 'Programado' });
                  setObjs([...objss]);
                }
              });
          });
          // setObjs([...objss]);
        });
    }
    getObjs();
  }, [idEmpresa, id, statuses]);

  // scrum master

  const addScrumMaster = async (userr) => {
    const newArr = [...scrumMaster, userr];
    setScrumMaster(newArr);
    await firestore.collection('empresas').doc(idEmpresa).collection('sprints').doc(id).update({ scrumMaster: newArr });
  };

  const removeScrumMaster = async (userr) => {
    const newArr = [...scrumMaster.filter((as) => as !== userr)];
    setScrumMaster(newArr);
    await firestore.collection('empresas').doc(idEmpresa).collection('sprints').doc(id).update({ scrumMaster: newArr });
  };

  const setFechasFirestore = async (dates) => {
    const [fE, fF] = dates;
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('sprints')
      .doc(id)
      .update({ fechaE: fE, fechaF: fF });
  };

  const {
    dueDate,
    startTime,
    endTime,
    isSameDays,
    isSameMonths,
    onChangeDueDate,
    openPicker,
    onOpenPicker,
    onClosePicker
  } = useDatePicker({
    date: due
  });

  const setStatusF = async (newStatus) => {
    setStatus(newStatus);
    await firestore.collection('empresas').doc(idEmpresa).collection('sprints').doc(id).update({ status: newStatus });
  };

  const like = async () => {
    const newArr = [...likes];
    if (newArr.includes(user.id)) {
      setLikes(newArr.filter((d) => d !== user.id));
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .doc(id)
        .update({ likes: newArr.filter((d) => d !== user.id) });
    } else {
      newArr.push(user.id);
      setLikes(newArr);
      await firestore.collection('empresas').doc(idEmpresa).collection('sprints').doc(id).update({ likes: newArr });
    }
  };

  return (
    <Card>
      <Card>
        <CardContent>
          <CardHeader
            title={
              <>
                <Typography variant="h3">{nombre}</Typography>
                <Typography variant="h4">{objetivo}</Typography>
                <RowStyle>
                  <LabelStyle>Rango de Fecha</LabelStyle>
                  <>
                    {startTime && endTime ? (
                      <DisplayTime
                        startTime={startTime}
                        endTime={endTime}
                        isSameDays={isSameDays}
                        isSameMonths={isSameMonths}
                        onOpenPicker={onOpenPicker}
                        sx={{ typography: 'body2' }}
                      />
                    ) : (
                      <Tooltip title="Agregar persona">
                        <MIconButton
                          disabled={!allowEdit}
                          onClick={onOpenPicker}
                          sx={{
                            p: 1,
                            ml: 0.5,
                            border: (theme) => `dashed 1px ${theme.palette.divider}`
                          }}
                        >
                          <Icon icon={plusFill} width={20} height={20} />
                        </MIconButton>
                      </Tooltip>
                    )}
                  </>
                  <MobileDateRangePicker
                    open={openPicker}
                    onClose={onClosePicker}
                    onOpen={onOpenPicker}
                    value={dueDate}
                    onChange={(value) => {
                      if (!allowEdit) {
                        onChangeDueDate(value);
                        setDue(value);
                        setFechasFirestore(value);
                      }
                    }}
                    renderInput={() => {}}
                    disabled={!allowEdit}
                  />
                </RowStyle>
                <RowStyle>
                  <Stack direction="row">
                    <LabelStyle sx={{ mt: 1.5 }}>Scrum Master</LabelStyle>
                    <Stack direction="row" flexWrap="wrap" alignItems="center">
                      {scrumMaster.map((userid) => (
                        <UserAvatar key={userid} userid={userid} removeUser={removeScrumMaster} />
                      ))}
                      <Tooltip title="Agregar ScrumMaster">
                        <MIconButton
                          disabled={scrumMaster.length !== 0}
                          sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                          onClick={() => setOpenMenuSM(true)}
                        >
                          <Icon icon={plusFill} width={20} height={20} />
                        </MIconButton>
                      </Tooltip>
                      <Dialog open={openMenuSM} onClose={() => setOpenMenuSM(false)}>
                        <DialogContent>
                          <List>
                            {users
                              .filter((u) => !Boolean(scrumMaster.find((d) => d === u)))
                              .map((u) => {
                                return (
                                  <MenuItem
                                    key={'userr' + u.id}
                                    onClick={() => {
                                      addScrumMaster(u);
                                      setOpenMenuSM(false);
                                    }}
                                  >
                                    <UserElement userid={u} />
                                  </MenuItem>
                                );
                              })}
                          </List>
                        </DialogContent>
                      </Dialog>
                    </Stack>
                  </Stack>
                </RowStyle>
                <RowStyle>
                  <LabelStyle>Status</LabelStyle>
                  <TextField
                    disabled={!allowEdit}
                    fullWidth
                    select
                    size="small"
                    value={status}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val === 'archivado') {
                        if (window.confirm('¿desea archivar este sprint?')) {
                          setStatusF(val);
                        }
                      } else {
                        setStatusF(val);
                      }
                    }}
                    sx={{
                      '& svg': { display: 'none' },
                      '& fieldset': { display: 'none' },
                      '& .MuiSelect-select': { p: 0, display: 'flex', alignItems: 'center' }
                    }}
                  >
                    {PRIORITIZES.map((option) => (
                      <MenuItem key={option} value={option}>
                        <Box
                          sx={{
                            mr: 1,
                            width: 14,
                            height: 14,
                            borderRadius: 0.5,
                            bgcolor: 'error.main',
                            ...(option === 'creado' && { bgcolor: 'info.main' }),
                            ...(option === 'en proceso' && { bgcolor: 'warning.main' }),
                            ...(option === 'completado' && { bgcolor: 'success.main' }),
                            ...(option === 'archivado' && { bgcolor: 'info.dark' })
                          }}
                        />
                        <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                          {option}
                        </Typography>
                      </MenuItem>
                    ))}
                  </TextField>
                </RowStyle>
              </>
            }
            action={
              <>
                <Tooltip title="Like">
                  <FormControlLabel
                    sx={{ position: 'relative' }}
                    value={likes.includes(user.id)}
                    checked={likes.includes(user.id)}
                    control={
                      <Checkbox
                        disableRipple
                        value={likes.includes(user.id)}
                        checked={likes.includes(user.id)}
                        icon={<Icon icon={heartoutline} width={20} height={20} />}
                        checkedIcon={<Icon icon={heartfill} width={20} height={20} />}
                        onChange={like}
                        sx={{ position: 'relative' }}
                      />
                    }
                    label=""
                  />
                </Tooltip>
                <NewSprintForm edit showB iconB idEmpresa={idEmpresa} spid={id} open={openE} setOpen={setOpenE} />
              </>
            }
          />
        </CardContent>
      </Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <ObjsSinEjecutar data={objs} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ObjsEnProceso data={objs} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ObjsTotal data={objs} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ObjsCompletados data={objs} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <ObjsXPE data={objs} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ObjsXStatus data={objs} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
