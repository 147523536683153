import React from 'react';
import { motion } from 'framer-motion';
// material
import { styled } from '@mui/material/styles';
import { Container, Typography, CardHeader, IconButton } from '@mui/material';
// icon
import { Icon } from '@iconify/react';
import settings from '@iconify/icons-ant-design/setting-fill';
import starfill from '@iconify/icons-eva/star-fill';
import staroutline from '@iconify/icons-eva/star-outline';
//
import { varWrapEnter, varFadeInRight, TextAnimate } from '../../animate';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

const RootStyle = styled(motion.div)(({ theme }) => ({
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('md')]: {
    height: 400,
    padding: 0
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    position: 'absolute',
    bottom: theme.spacing(10)
  }
}));

// ----------------------------------------------------------------------

PHero.propTypes = {
  nameP: PropTypes.string,
  vision: PropTypes.string,
  img: PropTypes.string,
  likes: PropTypes.array,
  like: PropTypes.func,
  onOpenEdit: PropTypes.func
};

export default function PHero({ nameP, vision, img, likes = [], like, onOpenEdit }) {
  const { user } = useAuth();
  return (
    <RootStyle
      initial="initial"
      animate="animate"
      variants={varWrapEnter}
      sx={{ backgroundImage: `url(/static/overlay.svg), url(${img})` }}
    >
      <Container maxWidth="lg" sx={{ position: 'relative', height: '100%' }}>
        <CardHeader
          action={
            <>
              <IconButton button onClick={like} sx={{ color: '#FAAF00' }}>
                <Icon icon={likes.includes(user.id) ? starfill : staroutline} />
              </IconButton>
              <IconButton onClick={onOpenEdit} sx={{ color: 'primary.main' }}>
                <Icon icon={settings} width={25} height={25} />
              </IconButton>
            </>
          }
        />
        <ContentStyle>
          <TextAnimate text={nameP} sx={{ color: 'primary.main' }} variants={varFadeInRight} />
          <br />
          {/* <Box sx={{ display: 'inline-flex', color: 'common.white' }}>
            <TextAnimate text={vision} sx={{ mr: 2 }} />
          </Box> */}

          <motion.div variants={varFadeInRight}>
            <Typography
              variant="h4"
              sx={{
                mt: 5,
                color: 'common.white',
                fontWeight: 'fontWeightMedium'
              }}
            >
              {vision}
            </Typography>
          </motion.div>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
