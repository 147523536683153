import React, { useState, useEffect, createContext, useContext } from 'react';
// material
import { Container, Grid } from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import {
  EcommerceProductSold,
  EcommerceSalesProfit,
  EcommerceYearlySales,
  EcommerceBestSalesman,
  EcommerceTotalBalance,
  EcommerceSaleByGender,
  EcommerceSalesOverview,
  EcommerceLatestProducts,
  EcommerceCurrentBalance,
  ListP
} from '../../components/_dashboard/general-ecommerce';
import { firestore } from 'src/contexts/FirebaseContext';

// ----------------------------------------------------------------------

const PaysC = createContext({ payments: [] });

export const useEcommerceD = () => useContext(PaysC);

export default function GeneralEcommerce() {
  const { themeStretch } = useSettings();
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    async function getPayments() {
      await firestore.collection('payments').onSnapshot((queryS) => {
        const dat = [];
        queryS.forEach((doc) => {
          dat.push({ id: doc.id, ...doc.data() });
        });
        setPayments(dat);
      });
    }
    getPayments();
  }, []);

  return (
    <Page title="General: E-commerce | Bunka">
      <PaysC.Provider value={{ payments }}>
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} md={8}>
            <EcommerceWelcome />
          </Grid>

          <Grid item xs={12} md={4}>
            <EcommerceNewProducts />
          </Grid> */}
            <Grid item xs={12}>
              <ListP />
            </Grid>
            <Grid item xs={12} md={4}>
              <EcommerceProductSold />
            </Grid>
            <Grid item xs={12} md={4}>
              <EcommerceTotalBalance />
            </Grid>
            <Grid item xs={12} md={4}>
              <EcommerceSalesProfit />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <EcommerceSaleByGender />
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
              <EcommerceYearlySales />
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
              <EcommerceSalesOverview />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <EcommerceCurrentBalance />
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
              <EcommerceBestSalesman />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <EcommerceLatestProducts />
            </Grid>
          </Grid>
        </Container>
      </PaysC.Provider>
    </Page>
  );
}
