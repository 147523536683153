import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { findIndex, isString } from 'lodash';
import { useDropzone } from 'react-dropzone';
import { useState, useCallback, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import closeFill from '@iconify/icons-eva/close-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Paper } from '@mui/material';
// utils
import { MIconButton } from '../../@material-extend';
import { useDispatch } from 'src/redux/store';
import { getBoard } from 'src/redux/slices/kanban';
import { varFadeInRight } from '../../animate';
import LightboxModal from '../../LightboxModal';
import { firestore, storage as st } from 'src/contexts/FirebaseContext';

const geolocation = require('geolocation');

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  width: 64,
  height: 64,
  fontSize: 24,
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  border: `dashed 1px ${theme.palette.divider}`,
  '&:hover': { opacity: 0.72 }
}));

// ----------------------------------------------------------------------

UploadFile.propTypes = {
  cardId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  idEmpresa: PropTypes.string
};

function UploadFile({ cardId, disabled, idEmpresa }) {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [location, setLocation] = useState(null);

  useEffect(() => {
    geolocation.getCurrentPosition((err, pos) => {
      if (err) {
        console.error(err);
      } else {
        setLocation(pos);
      }
    });
  }, []);

  const handleRemove = (file) => {
    const filteredItems = files.filter((_file) => _file !== file);
    setFiles(filteredItems);
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) => {
          const refF = st.ref().child(`${idEmpresa}/${cardId}/${file.name}`);
          refF.put(file).then(() => {
            refF.getDownloadURL().then((url) => {
              refF.updateMetadata({ contentType: 'image', customMetadata: { fecha: new Date(), location } });
              firestore
                .collection('kobjsCards')
                .doc(cardId)
                .get()
                .then((doc) => {
                  const { attachments } = doc.data();
                  firestore
                    .collection('kobjsCards')
                    .doc(cardId)
                    .update({
                      attachments: [...attachments, url]
                    })
                    .then(() => {
                      dispatch(getBoard());
                    });
                });
            });
          });
          return Object.assign(file, {
            preview: URL.createObjectURL(file)
          });
        })
      );
    },
    [setFiles, cardId, dispatch, idEmpresa, location]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop
  });

  return (
    <>
      {files.map((file) => {
        const { name, preview } = file;
        const key = isString(file) ? file : name;

        return (
          <Box
            key={key}
            {...varFadeInRight}
            sx={{
              p: 0,
              m: 0.5,
              width: 64,
              height: 64,
              borderRadius: 1,
              overflow: 'hidden',
              position: 'relative'
            }}
          >
            <Paper
              variant="outlined"
              component="img"
              src={isString(file) ? file : preview}
              sx={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute' }}
            />
            <Box sx={{ top: 6, right: 6, position: 'absolute' }}>
              <MIconButton
                disabled={disabled}
                size="small"
                onClick={() => handleRemove(file)}
                sx={{
                  p: '2px',
                  color: 'common.white',
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                  '&:hover': {
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48)
                  }
                }}
              >
                <Icon icon={closeFill} />
              </MIconButton>
            </Box>
          </Box>
        );
      })}

      {!disabled && (
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 })
          }}
        >
          <input {...getInputProps()} />

          <Box component={Icon} icon={plusFill} sx={{ color: 'text.secondary' }} />
        </DropZoneStyle>
      )}
    </>
  );
}

KanbanTaskAttachments.propTypes = {
  attachments: PropTypes.array.isRequired,
  cardId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  idEmpresa: PropTypes.string
};

export default function KanbanTaskAttachments({ attachments, cardId, disabled = false, idEmpresa }) {
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const imagesLightbox = attachments;

  const handleOpenLightbox = (url) => {
    const selectedImage = findIndex(imagesLightbox, (index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  const handleCloseLightbox = () => {
    setOpenLightbox(false);
  };

  return (
    <>
      {attachments.map((attachment) => (
        <Box
          component="img"
          key={attachment}
          src={attachment}
          onClick={() => handleOpenLightbox(attachment)}
          sx={{ width: 64, height: 64, objectFit: 'cover', cursor: 'pointer', borderRadius: 1, m: 0.5 }}
        />
      ))}

      <UploadFile cardId={cardId} disabled={disabled} idEmpresa={idEmpresa} />

      <LightboxModal
        images={imagesLightbox}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onClose={handleCloseLightbox}
      />
    </>
  );
}
