import { v4 as uuidv4 } from 'uuid';
import { trim } from 'lodash';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import peopleFill from '@iconify/icons-eva/people-fill';
import { format, isSameDay, isSameMonth } from 'date-fns';
import calendarFill from '@iconify/icons-eva/calendar-fill';
import radioButtonOffOutline from '@iconify/icons-eva/radio-button-off-outline';
import checkmarkCircle2Outline from '@iconify/icons-eva/checkmark-circle-2-outline';
// material
import {
  Paper,
  Stack,
  Tooltip,
  Checkbox,
  Box,
  OutlinedInput,
  ClickAwayListener,
  List,
  MenuItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Dialog,
  DialogContent
} from '@mui/material';
import { MobileDateRangePicker } from '@mui/lab';
import { parseDate } from 'src/utils/formatTime';
//
import { MIconButton } from '../../@material-extend';
import { firestore } from 'src/contexts/FirebaseContext';
import useAuth from 'src/hooks/useAuth';
import { captureEventC } from 'src/utils/events';

// ----------------------------------------------------------------------

const defaultTask = {
  attachments: [],
  comments: [],
  description: '',
  due: [null, null],
  assignee: [],
  responsable: [],
  informado: [],
  lider: [],
  apoyo: [],
  consultado: [],
  soporte: [],
  likes: [],
  krs: [],
  prioritize: 'no definido',
  proyectosA: []
};

export function useDatePicker({ date }) {
  const [dueDate, setDueDate] = useState([date[0], date[1]]);
  const [openPicker, setOpenPicker] = useState(false);

  const startTime = dueDate[0];
  const endTime = dueDate[1];

  const isSameDays = isSameDay(new Date(startTime), new Date(endTime));
  const isSameMonths = isSameMonth(new Date(startTime), new Date(endTime));

  const handleChangeDueDate = (newValue) => {
    setDueDate(newValue);
  };

  const handleOpenPicker = () => {
    setOpenPicker(true);
  };

  const handleClosePicker = () => {
    setOpenPicker(false);
  };

  return {
    dueDate,
    startTime,
    endTime,
    isSameDays,
    isSameMonths,
    onChangeDueDate: handleChangeDueDate,
    openPicker,
    onOpenPicker: handleOpenPicker,
    onClosePicker: handleClosePicker
  };
}

DisplayTime.propTypes = {
  isSameDays: PropTypes.bool,
  isSameMonths: PropTypes.bool,
  onOpenPicker: PropTypes.func,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  sx: PropTypes.object
};

export function DisplayTime({ startTime, endTime, isSameDays, isSameMonths, onOpenPicker, sx }) {
  const style = {
    typography: 'caption',
    cursor: 'pointer',
    '&:hover': { opacity: 0.72 }
  };

  if (isSameMonths) {
    return (
      <Box onClick={onOpenPicker} sx={{ ...style, ...sx }}>
        {isSameDays
          ? format(new Date(endTime), 'dd MMM')
          : `${format(new Date(parseDate(startTime)), 'dd')} - ${format(new Date(parseDate(endTime)), 'dd MMM')}`}
      </Box>
    );
  }
  return (
    <Box onClick={onOpenPicker} sx={{ ...style, ...sx }}>
      {format(new Date(parseDate(startTime)), 'dd MMM')} - {format(new Date(parseDate(endTime)), 'dd MMM')}
    </Box>
  );
}

KanbanTaskAdd.propTypes = {
  onAddTask: PropTypes.func,
  onCloseAddTask: PropTypes.func,
  idEmpresa: PropTypes.string
};

export default function KanbanTaskAdd({ onAddTask, onCloseAddTask, idEmpresa }) {
  const { user } = useAuth();
  const [name, setName] = useState('');
  const [completed, setCompleted] = useState(false);
  const [users, setUsers] = useState([]);
  const [openDR, setOpenDR] = useState(false);
  const [responsable, setResponsable] = useState([]);
  const {
    dueDate,
    startTime,
    endTime,
    isSameDays,
    isSameMonths,
    onChangeDueDate,
    openPicker,
    onOpenPicker,
    onClosePicker
  } = useDatePicker({
    date: [null, null]
  });

  useEffect(() => {
    async function getUsers() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setUsers(doc.data().membersinfo);
          }
        });
    }
    getUsers();
  }, [idEmpresa]);

  const handleKeyUpAddTask = (event) => {
    if (event.key === 'Enter') {
      if (trim(name) !== '') {
        captureEventC('add-objetive', 'empresa', user.id, idEmpresa);
        onAddTask({ ...defaultTask, id: uuidv4(), name, due: dueDate, completed, responsable });
      }
    }
  };

  const handleClickAddTask = () => {
    if (name) {
      onAddTask({ ...defaultTask, id: uuidv4(), name, due: dueDate, completed, responsable });
    }
    onCloseAddTask();
  };

  const handleChangeCompleted = (event) => {
    captureEventC('complete-objetive', 'empresa', user.id, idEmpresa);
    setCompleted(event.target.checked);
  };

  const clickOpenDR = () => {
    setOpenDR(true);
  };

  const clickCloseDR = () => {
    setOpenDR(false);
  };

  const addResponsable = (us) => {
    setResponsable([...responsable, us]);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAddTask}>
        <Paper variant="outlined" sx={{ p: 2 }}>
          <OutlinedInput
            multiline
            size="small"
            placeholder="Nombre de la tarea"
            value={name}
            onChange={(event) => setName(event.target.value)}
            onKeyUp={handleKeyUpAddTask}
            sx={{
              '& input': { p: 0 },
              '& fieldset': { borderColor: 'transparent !important' }
            }}
          />

          <Stack direction="row" justifyContent="space-between">
            <Tooltip title="Marcar como completado">
              <Checkbox
                disableRipple
                checked={completed}
                onChange={handleChangeCompleted}
                icon={<Icon icon={radioButtonOffOutline} />}
                checkedIcon={<Icon icon={checkmarkCircle2Outline} />}
              />
            </Tooltip>

            <Stack direction="row" spacing={1.5} alignItems="center">
              <Tooltip title="Asignar responsable" onClick={clickOpenDR}>
                <MIconButton size="small">
                  <Icon icon={peopleFill} width={20} height={20} />
                </MIconButton>
              </Tooltip>
              <Dialog open={openDR} onClose={clickCloseDR}>
                <DialogContent>
                  <List>
                    {users
                      .filter((u) => !Boolean(responsable.find((d) => d.id === u.id)))
                      .map((u) => {
                        return (
                          <MenuItem
                            key={'userr' + u.id}
                            onClick={() => {
                              addResponsable(u);
                              clickCloseDR();
                            }}
                            disabled={responsable.length >= 1}
                          >
                            <ListItemAvatar>
                              <Avatar src={u.avatar} />
                            </ListItemAvatar>
                            <ListItemText>{u.nombre}</ListItemText>
                          </MenuItem>
                        );
                      })}
                  </List>
                </DialogContent>
              </Dialog>

              {startTime && endTime ? (
                <DisplayTime
                  startTime={startTime}
                  endTime={endTime}
                  isSameDays={isSameDays}
                  isSameMonths={isSameMonths}
                  onOpenPicker={onOpenPicker}
                />
              ) : (
                <Tooltip title="agregar fecha de entrega">
                  <MIconButton size="small" onClick={onOpenPicker}>
                    <Icon icon={calendarFill} width={20} height={20} />
                  </MIconButton>
                </Tooltip>
              )}

              <MobileDateRangePicker
                open={openPicker}
                onClose={onClosePicker}
                onOpen={onOpenPicker}
                value={dueDate}
                onChange={onChangeDueDate}
                renderInput={() => {}}
              />
            </Stack>
          </Stack>
        </Paper>
      </ClickAwayListener>
    </>
  );
}
