import React, { useState, useEffect, useCallback } from 'react';
// material-ui
import {
  Button,
  ListItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogContent,
  FormControlLabel,
  Stack,
  Card,
  FormHelperText,
  Typography,
  Switch,
  Box,
  TextField,
  Grid,
  Autocomplete,
  MenuItem,
  Chip
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { UploadAvatar } from '../../upload';
import { fData } from 'src/utils/formatNumber';
import UserElement from '../general/UserElement';
// formik and Yup
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
// icon
import { Icon } from '@iconify/react';
import editI from '@iconify/icons-ant-design/edit-fill';
import addTeamI from '@iconify/icons-ant-design/usergroup-add';
//
import { firestore, storage as st } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

NewTeamForm.propTypes = {
  idEmpresa: PropTypes.string,
  idDoc: PropTypes.string,
  edit: PropTypes.bool,
  normalB: PropTypes.bool,
  listB: PropTypes.bool
};

export default function NewTeamForm({ idEmpresa, idDoc, edit = false, normalB = false, listB = false }) {
  const [open, setOpen] = useState(false);
  const [empMembers, setEmpMembers] = useState([]);

  const [fileS, setFile] = useState(null);

  useEffect(() => {
    async function getMembers() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .onSnapshot((doc) => {
          const { membersids } = doc.data();
          setEmpMembers(membersids);
        });
    }
    getMembers();
  }, [idEmpresa]);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  const validation = Yup.object().shape({
    nombre: Yup.string().required('Obligarorio')
  });

  const formik = useFormik({
    initialValues: {
      nombre: '',
      rol: '',
      quehace: '',
      proposito: '',
      miembros: []
    },
    validationSchema: validation,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        if (edit) {
          if (fileS !== null) {
            const imgRef = `empresas/${idEmpresa}/equipos/${idDoc}`;
            const refF = st.ref().child(imgRef);
            await refF.put(fileS.file);
          }
          await firestore
            .collection('empresas')
            .doc(idEmpresa)
            .collection('equipos')
            .doc(idDoc)
            .update({ ...values });
        } else {
          const id = uuidv4();
          if (fileS !== null) {
            const imgRef = `empresas/${idEmpresa}/equipos/${id}`;
            const refF = st.ref().child(imgRef);
            await refF.put(fileS.file);
          }
          await firestore
            .collection('empresas')
            .doc(idEmpresa)
            .collection('equipos')
            .doc(id)
            .set({ ...values })
            .then(() => {
              resetForm();
            });
        }
        setSubmitting(false);
      } catch (err) {
        console.log(err);
        setSubmitting(false);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps, setValues } = formik;

  useEffect(() => {
    async function getDoc() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('equipos')
        .doc(idDoc)
        .get()
        .then((doc) => {
          const data = doc.data();
          setValues({ ...data });
        });
    }
    if (edit) {
      getDoc();
    }
    // eslint-disable-next-line
  }, [edit, idDoc, idEmpresa]);

  const handleDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setFile({ file, preview: URL.createObjectURL(file) });
    }
  }, []);

  return (
    <>
      {listB && (
        <ListItem button onClick={clickOpen}>
          <ListItemIcon>
            <Icon icon={edit ? editI : addTeamI} width={23} height={23} />
          </ListItemIcon>
          <ListItemText>{edit ? <>editar</> : <>nuevo equipo</>}</ListItemText>
        </ListItem>
      )}
      {normalB && (
        <Button variant="contained" onClick={clickOpen}>
          {edit ? <>editar</> : <>nuevo equipo</>}
        </Button>
      )}
      <Dialog open={open} onClose={clickClose}>
        <DialogContent>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
                    <UploadAvatar
                      accept="image/*"
                      file={fileS}
                      maxSize={3145728}
                      onDrop={handleDrop}
                      error={Boolean(touched.photoURL && errors.photoURL)}
                      caption={
                        <Typography
                          variant="caption"
                          sx={{
                            mt: 2,
                            mx: 'auto',
                            display: 'block',
                            textAlign: 'center',
                            color: 'text.secondary'
                          }}
                        >
                          Permitido *.jpeg, *.jpg, *.png, *.gif
                          <br /> max size of {fData(3145728)}
                        </Typography>
                      }
                    />

                    <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                      {touched.photoURL && errors.photoURL}
                    </FormHelperText>

                    <FormControlLabel
                      control={<Switch {...getFieldProps('cubierto')} color="primary" />}
                      labelPlacement="start"
                      label="Cubierto"
                      sx={{ mt: 5 }}
                    />
                  </Card>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={{ xs: 2, md: 3 }}>
                      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                        <TextField
                          fullWidth
                          label="Nombre"
                          {...getFieldProps('nombre')}
                          error={Boolean(errors.nombre) && Boolean(touched.nombre)}
                          helperText={Boolean(touched.nombre) && errors.nombre}
                        />
                        <TextField
                          fullWidth
                          label="Descripcion"
                          {...getFieldProps('descripcion')}
                          error={Boolean(errors.descripcion) && Boolean(touched.descripcion)}
                          helperText={Boolean(touched.descripcion) && errors.descripcion}
                        />
                      </Stack>

                      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                        <TextField fullWidth label="Numero de Telefono" {...getFieldProps('phoneNumber')} />
                        <TextField fullWidth label="Dirección" {...getFieldProps('address')} />
                      </Stack>

                      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                        <TextField
                          select
                          fullWidth
                          label="País"
                          placeholder="País"
                          {...getFieldProps('country')}
                          SelectProps={{ native: true }}
                          error={Boolean(touched.country && errors.country)}
                          helperText={touched.country && errors.country}
                        >
                          <option value="" />
                          {['a', 'b'].map((option) => (
                            <option key={option.code} value={option.label}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                        <TextField fullWidth label="Estado/Región" {...getFieldProps('state')} />
                      </Stack>

                      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                        <TextField fullWidth label="Ciudad" {...getFieldProps('city')} />
                        <TextField fullWidth label="Codigo Postal" {...getFieldProps('zipCode')} />
                      </Stack>
                      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                        <TextField fullWidth label="Posición" {...getFieldProps('position')} />
                        <TextField fullWidth label="Empresa" {...getFieldProps('company')} />
                      </Stack>
                      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                        <TextField fullWidth label="Escuela" {...getFieldProps('school')} />
                      </Stack>
                      <TextField
                        {...getFieldProps('about')}
                        fullWidth
                        multiline
                        minRows={4}
                        maxRows={4}
                        label="Sobre ti"
                      />
                      <Autocomplete
                        multiple
                        freeSolo
                        value={values.miembros}
                        onChange={(event, newValue) => {
                          setFieldValue('miembros', newValue);
                        }}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              {...getTagProps({ index })}
                              key={option}
                              size="small"
                              label={<UserElement userid={option} />}
                            />
                          ))
                        }
                        renderOption={(params, value) => (
                          <MenuItem {...params}>
                            <UserElement userid={value} />
                          </MenuItem>
                        )}
                        options={empMembers.map((option) => option)}
                        renderInput={(params) => <TextField {...params} label="Miembros" />}
                      />
                    </Stack>

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        Guardar Cambios
                      </LoadingButton>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Dialog>
    </>
  );
}
