import React from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  Avatar,
  Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

Integrantes.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  empresa: PropTypes.object,
  idEmpresa: PropTypes.string.isRequired
};

export default function Integrantes({ empresa, idEmpresa, open, setOpen }) {
  const { membersinfo, creator, nombre } = empresa;

  const clickClose = () => {
    setOpen(false);
  };

  const removerintegrante = async (u) => {
    if (window.confirm('desea eliminar a ' + u.nombre + 'de la empresa')) {
      await firestore
        .collection('proyectosempresa')
        .where('idEmpresa', '==', idEmpresa)
        .get()
        .then((queryS) => {
          queryS.forEach((doc) => {
            const dt = doc.data();
            if (dt.creator !== u.id) {
              firestore
                .collection('proyectosempresa')
                .doc(doc.id)
                .update({
                  membersinfo: dt.membersinfo.filter((d) => d.id !== u.id),
                  membersids: dt.membersids.filter((d) => d !== u.id)
                });
            }
          });
        });
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .get()
        .then((doc) => {
          const dt = doc.data();
          firestore
            .collection('empresas')
            .doc(idEmpresa)
            .update({
              membersinfo: dt.membersinfo.filter((d) => d.id !== u.id),
              membersids: dt.membersids.filter((d) => d !== u.id)
            });
        });
    }
  };

  return (
    <>
      <Dialog open={open} onClose={clickClose}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton onClick={clickClose}>
              <Close />
            </IconButton>
            <Typography variant="subtitle1">integrantes de {nombre}</Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box>
            <List>
              {membersinfo
                .filter((d) => d.id !== creator)
                .map((member) => (
                  <ListItem key={member.id}>
                    <ListItemAvatar>
                      <Avatar src={member.avatar} />
                    </ListItemAvatar>
                    <ListItemText>{member.nombre}</ListItemText>
                    <ListItemButton onClick={() => removerintegrante(member)}>Remover</ListItemButton>
                  </ListItem>
                ))}
            </List>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
