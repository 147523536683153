import React, { useState, useEffect } from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import EquipoItem from './EquipoItem';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

EquiposCard.propTypes = {
  idEmpresa: PropTypes.string
};

export default function EquiposCard({ idEmpresa }) {
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    async function getEquipos() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('equipos')
        .onSnapshot((queryS) => {
          const teamsc = [];
          queryS.forEach((doc) => {
            teamsc.push({ id: doc.id, ...doc.data() });
          });
          setTeams(teamsc);
        });
    }
    getEquipos();
  }, [idEmpresa]);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          {teams.map((team) => (
            <EquipoItem key={team.id} equipo={team} idEmpresa={idEmpresa} />
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
