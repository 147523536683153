import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
// material
import { Container, Stack, Skeleton, Grid, Button } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getBoard, persistColumn, persistCard } from '../../redux/slices/kanbanentregables';
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { KanbanColumn, KanbanColumnAdd } from '../../components/_dashboard/kanbanentregables';
import { firestore } from 'src/contexts/FirebaseContext';
// icon
import { Icon } from '@iconify/react';
import refreshI from '@iconify/icons-eva/refresh-fill';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

const SkeletonLoad = (
  <>
    {[...Array(3)].map((_, index) => (
      <Grid item xs={12} md={3} key={index}>
        <Skeleton variant="rectangular" width="100%" sx={{ paddingTop: '115%', borderRadius: 2 }} />
      </Grid>
    ))}
  </>
);

KanbanEntregables.propTypes = {
  idEmpresa: PropTypes.string.isRequired
};

export default function KanbanEntregables({ idEmpresa }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { board } = useSelector((state) => state.kanbanentregables);

  useEffect(() => {
    dispatch(getBoard(idEmpresa));
  }, [dispatch, idEmpresa]);

  const onDragEnd = (result) => {
    // Reorder card
    const { destination, source, draggableId, type } = result;
    console.log(source, destination, draggableId, type);

    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    if (type === 'column') {
      const newColumnOrder = Array.from(board.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      dispatch(persistColumn(newColumnOrder, idEmpresa));
      enqueueSnackbar('Actializacion exitosa', { variant: 'success' });
      return;
    }

    const start = board.columns[source.droppableId];
    const finish = board.columns[destination.droppableId];

    if (start.id === finish.id) {
      const updatedCardIds = [...start.cardIds];
      updatedCardIds.splice(source.index, 1);
      updatedCardIds.splice(destination.index, 0, draggableId);

      const updatedColumn = {
        ...start,
        cardIds: updatedCardIds
      };

      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanColunmsentregables')
        .doc(updatedColumn.id)
        .update({ ...updatedColumn });

      dispatch(
        persistCard({
          columns: {
            ...board.columns,
            [updatedColumn.id]: updatedColumn
          }
        })
      );

      return;
    }

    const startCardIds = [...start.cardIds];
    startCardIds.splice(source.index, 1);
    const updatedStart = {
      ...start,
      cardIds: startCardIds
    };

    const finishCardIds = [...finish.cardIds];
    finishCardIds.splice(destination.index, 0, draggableId);
    const updatedFinish = {
      ...finish,
      cardIds: finishCardIds
    };

    firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('kanbanColunmsentregables')
      .doc(updatedStart.id)
      .update({ ...updatedStart });
    firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('kanbanColunmsentregables')
      .doc(updatedFinish.id)
      .update({ ...updatedFinish });

    dispatch(
      persistCard({
        columns: {
          ...board.columns,
          [updatedStart.id]: updatedStart,
          [updatedFinish.id]: updatedFinish
        }
      })
    );
    enqueueSnackbar('Actualizacion exitosa', { variant: 'success' });
  };

  return (
    <Container maxWidth={false} sx={{ height: '100%', marginBottom: '5%' }}>
      <Button
        onClick={() => {
          dispatch(getBoard(idEmpresa));
        }}
        sx={{ color: '#637381' }}
      >
        <Icon icon={refreshI} width={25} height={25} />
        Actualizar
      </Button>
      <HeaderBreadcrumbs heading="Entregables" links={[{ name: '' }]} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="all-columns" direction="horizontal" type="column">
          {(provided) => (
            <Stack
              {...provided.droppableProps}
              ref={provided.innerRef}
              direction="row"
              alignItems="flex-start"
              spacing={3}
              sx={{ height: 'calc(100% - 32px)', overflowY: 'hidden' }}
            >
              {board?.columnOrder?.map((columnId, index) => {
                const column = board.columns[columnId];
                return <KanbanColumn index={index} key={columnId} column={column} idEmpresa={idEmpresa} />;
              })}

              {!board?.columnOrder.length && SkeletonLoad}

              {provided.placeholder}
              <KanbanColumnAdd idEmpresa={idEmpresa} />
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
}
