import React, { useState, useEffect } from 'react';
import { ListItem, ListItemText, ListItemIcon, Dialog, DialogContent, Stack, Typography, Box } from '@mui/material';
import { CTAShow } from 'src/components/_dashboard/ctas';
import { EmpresaItem } from 'src/components/_dashboard/general-app/EmpresasFav';
// icon
import { Icon } from '@iconify/react';
import list from '@iconify/icons-ant-design/unordered-list';
// hooks
import useAuth from 'src/hooks/useAuth';
import { isPremium } from 'src/hooks/usePlan';
import { firestore } from 'src/contexts/FirebaseContext';

export default function ListaEmpresa() {
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const modulo = 'empresa';
  const [empresas, setEmpresa] = useState([]);

  const etapaCTA = empresas.filter((e) => e.creator === user.id).length === 0 ? 'atraccion' : 'engage';

  useEffect(() => {
    async function getEmpresas() {
      await firestore.collection('empresas').onSnapshot((queryS) => {
        const emps = [];
        queryS.forEach((doc) => {
          const { membersids, premium, expiresP } = doc.data();
          const memb = isPremium(premium, expiresP) ? membersids : membersids.slice(0, 2);
          const newEmp = { id: doc.id, ...doc.data() };
          newEmp.membersids = memb;
          emps.push(newEmp);
        });
        setEmpresa(emps.filter((em) => em.creator === user.id || Boolean(em.membersids.includes(user.id))));
      });
    }
    getEmpresas();
  }, [user]);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
        <ListItemIcon>
          <Icon icon={list} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Lista de empresas</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <Box sx={{ width: '50vw' }} />
        <DialogContent>
          <CTAShow etapaCTA={etapaCTA} modulo={modulo} />
          <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
            {empresas.map((emp) => (
              <EmpresaItem key={emp.id} empresa={emp} allowInteraction />
            ))}
            {empresas.length === 0 && <Typography>No tienes empresas o no perteneces a una</Typography>}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
