import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import { Stack, Tooltip, Typography, Dialog, DialogContent, CardHeader, List, MenuItem } from '@mui/material';
import { styled } from '@mui/styles';
import UserElement from 'src/components/_dashboard/general/UserElement';
import UserAvatar from 'src/components/_dashboard/general/UserAvatar';
//
import { useDispatch } from 'src/redux/store';
import { getBoard } from 'src/redux/slices/kobjs';
import { getBoard as getBoardS } from 'src/redux/slices/kanbanempresa';
import { MIconButton } from 'src/components/@material-extend';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { createNotification, generateNotification } from 'src/utils/notifications';
import { firestore } from 'src/contexts/FirebaseContext';
import useAuth from 'src/hooks/useAuth';
import { captureEventC } from 'src/utils/events';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary
}));

const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
});

// ----------------------------------------------------------------------

Involucrados.propTypes = {
  card: PropTypes.object.isRequired,
  cardId: PropTypes.string,
  projectId: PropTypes.string,
  idEmpresa: PropTypes.string
};

export default function Involucrados({ card, cardId, projectId, idEmpresa }) {
  const { name } = card;
  const { user } = useAuth();
  const { empid, spid } = useParams();
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [apoyo, setApoyo] = useState(card.apoyo || []);
  const [responsable, setResponsable] = useState(card.responsable || []);
  const [lider, setLider] = useState(card.lider || []);
  const [soporte, setSoporte] = useState(card.soporte || []);
  const [consultado, setConsultado] = useState(card.consultado || []);
  const [informado, setInformado] = useState(card.informado || []);

  const [openMenua, setOpenMenua] = useState(false);
  const [openMenur, setOpenMenur] = useState(false);
  const [openMenul, setOpenMenul] = useState(false);
  const [openMenus, setOpenMenus] = useState(false);
  const [openMenuc, setOpenMenuc] = useState(false);
  const [openMenui, setOpenMenui] = useState(false);

  useEffect(() => {
    setApoyo(card.apoyo || []);
    setResponsable(card.responsable || []);
    setLider(card.lider || []);
    setSoporte(card.soporte || []);
    setConsultado(card.consultado || []);
    setInformado(card.informado || []);
  }, [card]);

  useEffect(() => {
    async function getUsers() {
      await firestore
        .collection('proyectosempresa')
        .doc(card.pid || projectId)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const membersids = doc.data().membersids || [];
            const dat = [];
            membersids.forEach((mem) => dat.push({ id: mem }));
            setUsers(dat);
          }
        });
    }
    getUsers();
  }, [projectId, card]);

  const addApoyoa = async (u) => {
    const newArr = [...apoyo, u];
    captureEventC('add-apoyo-objetive', 'proyecto', user.id, idEmpresa, projectId);
    setApoyo(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ apoyo: newArr })
      .then(() => {
        if (spid) {
          dispatch(getBoardS(empid, spid));
        } else {
          dispatch(getBoard(projectId));
        }
      });
    const projectID = card.pid || projectId;
    const noti = generateNotification(
      'se te ha asigando un objetivo en apoyo',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + empid + '/proyecto/' + projectID
    );
    createNotification(noti);
  };

  const removeApoyoa = async (u) => {
    const newArr = [...apoyo.filter((as) => as.id !== u.id)];
    captureEventC('remove-apoyo-objetive', 'proyecto', user.id, idEmpresa, projectId);
    setApoyo(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ apoyo: newArr })
      .then(() => {
        if (spid) {
          dispatch(getBoardS(empid, spid));
        } else {
          dispatch(getBoard(projectId));
        }
      });
    const projectID = card.pid || projectId;
    const noti = generateNotification(
      'se te han quitado en un objetivo en apoyo',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + empid + '/proyecto/' + projectID
    );
    createNotification(noti);
  };

  const addResponsable = async (u) => {
    const newArr = [...responsable, u];
    captureEventC('add-responsable-objetive', 'proyecto', user.id, idEmpresa, projectId);
    setResponsable(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ responsable: newArr })
      .then(() => {
        if (spid) {
          dispatch(getBoardS(empid, spid));
        } else {
          dispatch(getBoard(projectId));
        }
      });
    const projectID = card.pid || projectId;
    const noti = generateNotification(
      'se te ha asigando un objetivo en Responsable',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + empid + '/proyecto/' + projectID
    );
    createNotification(noti);
  };

  const removeResponsable = async (u) => {
    const newArr = [...responsable.filter((as) => as.id !== u.id)];
    captureEventC('remove-responsable-objetive', 'proyecto', user.id, idEmpresa, projectId);
    setResponsable(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ responsable: newArr })
      .then(() => {
        if (spid) {
          dispatch(getBoardS(empid, spid));
        } else {
          dispatch(getBoard(projectId));
        }
      });
    const projectID = card.pid || projectId;
    const noti = generateNotification(
      'se te han quitado en un objetivo en responsable',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + empid + '/proyecto/' + projectID
    );
    createNotification(noti);
  };
  const addLider = async (u) => {
    const newArr = [...lider, u];
    captureEventC('add-lider-objetive', 'proyecto', user.id, idEmpresa, projectId);
    setLider(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ lider: newArr })
      .then(() => {
        if (spid) {
          dispatch(getBoardS(empid, spid));
        } else {
          dispatch(getBoard(projectId));
        }
      });
    const projectID = card.pid || projectId;
    const noti = generateNotification(
      'se te ha asigando un objetivo en Lider',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + empid + '/proyecto/' + projectID
    );
    createNotification(noti);
  };

  const removeLider = async (u) => {
    const newArr = [...lider.filter((as) => as.id !== u.id)];
    captureEventC('remove-lider-objetive', 'proyecto', user.id, idEmpresa, projectId);
    setLider(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ lider: newArr })
      .then(() => {
        if (spid) {
          dispatch(getBoardS(empid, spid));
        } else {
          dispatch(getBoard(projectId));
        }
      });
    const projectID = card.pid || projectId;
    const noti = generateNotification(
      'se te han quitado en un objetivo en lider',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + empid + '/proyecto/' + projectID
    );
    createNotification(noti);
  };
  const addSoporte = async (u) => {
    const newArr = [...soporte, u];
    captureEventC('add-soporte-objetive', 'proyecto', user.id, idEmpresa, projectId);
    setSoporte(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ soporte: newArr })
      .then(() => {
        if (spid) {
          dispatch(getBoardS(empid, spid));
        } else {
          dispatch(getBoard(projectId));
        }
      });
    const projectID = card.pid || projectId;
    const noti = generateNotification(
      'se te ha asigando un objetivo en Soporte',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + empid + '/proyecto/' + projectID
    );
    createNotification(noti);
  };

  const removeSoporte = async (u) => {
    const newArr = [...soporte.filter((as) => as.id !== u.id)];
    captureEventC('remove-soporte-objetive', 'proyecto', user.id, idEmpresa, projectId);
    setSoporte(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ soporte: newArr })
      .then(() => {
        if (spid) {
          dispatch(getBoardS(empid, spid));
        } else {
          dispatch(getBoard(projectId));
        }
      });
    const projectID = card.pid || projectId;
    const noti = generateNotification(
      'se te han quitado en un objetivo en soporte',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + empid + '/proyecto/' + projectID
    );
    createNotification(noti);
  };

  const addConsultado = async (u) => {
    const newArr = [...consultado, u];
    captureEventC('add-consultado-objetive', 'proyecto', user.id, idEmpresa, projectId);
    setConsultado(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ consultado: newArr })
      .then(() => {
        if (spid) {
          dispatch(getBoardS(empid, spid));
        } else {
          dispatch(getBoard(projectId));
        }
      });
    const projectID = card.pid || projectId;
    const noti = generateNotification(
      'se te ha asigando un objetivo en Consultado',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + empid + '/proyecto/' + projectID
    );
    createNotification(noti);
  };

  const removeConsultado = async (u) => {
    const newArr = [...consultado.filter((as) => as.id !== u.id)];
    captureEventC('remove-consultado-objetive', 'proyecto', user.id, idEmpresa, projectId);
    setConsultado(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ consultado: newArr })
      .then(() => {
        if (spid) {
          dispatch(getBoardS(empid, spid));
        } else {
          dispatch(getBoard(projectId));
        }
      });
    const projectID = card.pid || projectId;
    const noti = generateNotification(
      'se te han quitado en un objetivo en consultado',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + empid + '/proyecto/' + projectID
    );
    createNotification(noti);
  };

  const addInformado = async (u) => {
    const newArr = [...informado, u];
    captureEventC('add-informado-objetive', 'proyecto', user.id, idEmpresa, projectId);
    setInformado(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ informado: newArr })
      .then(() => {
        if (spid) {
          dispatch(getBoardS(empid, spid));
        } else {
          dispatch(getBoard(projectId));
        }
      });
    const projectID = card.pid || projectId;
    const noti = generateNotification(
      'se te ha asigando un objetivo en Informado',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + empid + '/proyecto/' + projectID
    );
    createNotification(noti);
  };

  const removeInformado = async (u) => {
    const newArr = [...informado.filter((as) => as.id !== u.id)];
    captureEventC('remove-informado-objetive', 'proyecto', user.id, idEmpresa, projectId);
    setInformado(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ informado: newArr })
      .then(() => {
        if (spid) {
          dispatch(getBoardS(empid, spid));
        } else {
          dispatch(getBoard(projectId));
        }
      });
    const projectID = card.pid || projectId;
    const noti = generateNotification(
      'se te han quitado en un objetivo en informado',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + empid + '/proyecto/' + projectID
    );
    createNotification(noti);
  };

  return (
    <>
      <CardHeader title="Involucrados" subheader="en el objetivo" />
      <Stack>
        <RowStyle>
          <Stack direction="row">
            <LabelStyle sx={{ mt: 1.5 }}>Responsable</LabelStyle>
            <Stack direction="row" flexWrap="wrap" alignItems="center">
              {responsable.map((u) => (
                <UserAvatar key={u.id} userid={u.id} removeUser={removeResponsable} />
              ))}
              <Tooltip title="Agregar responsable">
                <MIconButton
                  disabled={responsable.length !== 0}
                  sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                  onClick={() => setOpenMenur(true)}
                >
                  <Icon icon={plusFill} width={20} height={20} />
                </MIconButton>
              </Tooltip>
              <Dialog open={openMenur} onClose={() => setOpenMenur(false)}>
                <DialogContent>
                  <List>
                    {users
                      .filter((u) => !Boolean(responsable.find((d) => d.id === u.id)))
                      .map((u) => {
                        return (
                          <MenuItem
                            key={'userr' + u.id}
                            onClick={() => {
                              addResponsable(u);
                              setOpenMenur(false);
                            }}
                          >
                            <UserElement userid={u.id} />
                          </MenuItem>
                        );
                      })}
                  </List>
                </DialogContent>
              </Dialog>
            </Stack>
          </Stack>
        </RowStyle>
        <RowStyle>
          <Stack direction="row">
            <LabelStyle sx={{ mt: 1.5 }}>Apoyo</LabelStyle>
            <Stack direction="row" flexWrap="wrap" alignItems="center">
              {apoyo.map((u) => (
                <UserAvatar key={u.id} userid={u.id} removeUser={removeApoyoa} />
              ))}
              <Tooltip title="Agregar apoyo">
                <MIconButton
                  sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                  onClick={() => setOpenMenua(true)}
                >
                  <Icon icon={plusFill} width={20} height={20} />
                </MIconButton>
              </Tooltip>
              <Dialog open={openMenua} onClose={() => setOpenMenua(false)}>
                <DialogContent>
                  <List>
                    {users
                      .filter((u) => !Boolean(apoyo.find((d) => d.id === u.id)))
                      .map((u) => {
                        return (
                          <MenuItem
                            key={'usera' + u.id}
                            onClick={() => {
                              addApoyoa(u);
                            }}
                          >
                            <UserElement userid={u.id} />
                          </MenuItem>
                        );
                      })}
                  </List>
                </DialogContent>
              </Dialog>
            </Stack>
          </Stack>
        </RowStyle>
        <RowStyle>
          <Stack direction="row">
            <LabelStyle sx={{ mt: 1.5 }}>Lider</LabelStyle>
            <Stack direction="row" flexWrap="wrap" alignItems="center">
              {lider.map((u) => (
                <UserAvatar key={u.id} userid={u.id} removeUser={removeLider} />
              ))}
              <Tooltip title="Agregar lider">
                <MIconButton
                  sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                  onClick={() => setOpenMenul(true)}
                >
                  <Icon icon={plusFill} width={20} height={20} />
                </MIconButton>
              </Tooltip>
              <Dialog open={openMenul} onClose={() => setOpenMenul(false)}>
                <DialogContent>
                  <List>
                    {users
                      .filter((u) => !Boolean(lider.find((d) => d.id === u.id)))
                      .map((u) => {
                        return (
                          <MenuItem
                            key={'userl' + u.id}
                            onClick={() => {
                              addLider(u);
                            }}
                          >
                            <UserElement userid={u.id} />
                          </MenuItem>
                        );
                      })}
                  </List>
                </DialogContent>
              </Dialog>
            </Stack>
          </Stack>
        </RowStyle>
        <RowStyle>
          <Stack direction="row">
            <LabelStyle sx={{ mt: 1.5 }}>Soporte</LabelStyle>
            <Stack direction="row" flexWrap="wrap" alignItems="center">
              {soporte.map((u) => (
                <UserAvatar key={u.id} userid={u.id} removeUser={removeSoporte} />
              ))}
              <Tooltip title="Agregar soporte">
                <MIconButton
                  sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                  onClick={() => setOpenMenus(true)}
                >
                  <Icon icon={plusFill} width={20} height={20} />
                </MIconButton>
              </Tooltip>
              <Dialog open={openMenus} onClose={() => setOpenMenus(false)}>
                <DialogContent>
                  <List>
                    {users
                      .filter((u) => !Boolean(soporte.find((d) => d.id === u.id)))
                      .map((u) => {
                        return (
                          <MenuItem
                            key={'users' + u.id}
                            onClick={() => {
                              addSoporte(u);
                            }}
                          >
                            <UserElement userid={u.id} />
                          </MenuItem>
                        );
                      })}
                  </List>
                </DialogContent>
              </Dialog>
            </Stack>
          </Stack>
        </RowStyle>
        <RowStyle>
          <Stack direction="row">
            <LabelStyle sx={{ mt: 1.5 }}>Consultado</LabelStyle>
            <Stack direction="row" flexWrap="wrap" alignItems="center">
              {consultado.map((u) => (
                <UserAvatar key={u.id} userid={u.id} removeUser={removeConsultado} />
              ))}
              <Tooltip title="Agregar consultado">
                <MIconButton
                  sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                  onClick={() => setOpenMenuc(true)}
                >
                  <Icon icon={plusFill} width={20} height={20} />
                </MIconButton>
              </Tooltip>
              <Dialog open={openMenuc} onClose={() => setOpenMenuc(false)}>
                <DialogContent>
                  <List>
                    {users
                      .filter((u) => !Boolean(consultado.find((d) => d.id === u.id)))
                      .map((u) => {
                        return (
                          <MenuItem
                            key={'userc' + u.id}
                            onClick={() => {
                              addConsultado(u);
                            }}
                          >
                            <UserElement userid={u.id} />
                          </MenuItem>
                        );
                      })}
                  </List>
                </DialogContent>
              </Dialog>
            </Stack>
          </Stack>
        </RowStyle>
        <RowStyle>
          <Stack direction="row">
            <LabelStyle sx={{ mt: 1.5 }}>Informado</LabelStyle>
            <Stack direction="row" flexWrap="wrap" alignItems="center">
              {informado.map((u) => (
                <UserAvatar key={u.id} userid={u.id} removeUser={removeInformado} />
              ))}
              <Tooltip title="Agregar informado">
                <MIconButton
                  sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                  onClick={() => setOpenMenui(true)}
                >
                  <Icon icon={plusFill} width={20} height={20} />
                </MIconButton>
              </Tooltip>
              <Dialog open={openMenui} onClose={() => setOpenMenui(false)}>
                <DialogContent>
                  <List>
                    {users
                      .filter((u) => !Boolean(informado.find((d) => d.id === u.id)))
                      .map((u) => {
                        return (
                          <MenuItem
                            key={'useri' + u.id}
                            onClick={() => {
                              addInformado(u);
                            }}
                          >
                            <UserElement userid={u.id} />
                          </MenuItem>
                        );
                      })}
                  </List>
                </DialogContent>
              </Dialog>
            </Stack>
          </Stack>
        </RowStyle>
      </Stack>
    </>
  );
}
