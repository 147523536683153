import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Dialog,
  DialogContent,
  ListItem,
  ListItemText
} from '@mui/material';
import ModControl from './ModControl';
// firebase
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

ModsControlPanelD.propTypes = {
  userid: PropTypes.string
};

export default function ModsControlPanelD({ userid }) {
  const [open, setOpen] = useState(false);
  const [plan, setPlan] = useState(undefined);

  useEffect(() => {
    async function getPlan() {
      await firestore
        .collection('plans')
        .doc(userid)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setPlan({ ...doc.data() });
          }
        });
    }
    getPlan();
  }, [userid]);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#506070' }}>
        <ListItemText>modulos</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose}>
        <DialogContent>
          <Typography variant="h4">Contrataciones</Typography>
          <Box>
            {plan && (
              <>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Modulo</TableCell>
                      <TableCell>Fecha de Expiracion</TableCell>
                      <TableCell>Contratado</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <ModControl userid={userid} plan={plan} modulo="empresa" altName="Empresa" limitM />
                    <ModControl userid={userid} plan={plan} modulo="organizacion" altName="Organizacion" limitM />
                    <ModControl
                      userid={userid}
                      plan={plan}
                      modulo="finanzasP"
                      altName="Finanzas Personales"
                      dateApply
                    />
                    <ModControl
                      userid={userid}
                      plan={plan}
                      modulo="desarrolloP"
                      altName="Desarrollo Profesional"
                      dateApply
                    />
                    <ModControl userid={userid} plan={plan} modulo="ML" altName="Machine Learning" dateApply />
                  </TableBody>
                </Table>
              </>
            )}
            {!plan && <Typography variant="h4">Datos No Disponibles</Typography>}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
