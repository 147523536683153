import React, { useState } from 'react';
import { ListItem, ListItemText, ListItemIcon, Dialog, DialogContent, Grid, Box } from '@mui/material';
import MenuRH from './MenuRH';
import CardRH from './CardRH';
// icon
import { Icon } from '@iconify/react';
import rhI from '@iconify/icons-ant-design/idcard-fill';
//
import PropTypes from 'prop-types';

DialogRH.propTypes = {
  idEmpresa: PropTypes.string
};

export default function DialogRH({ idEmpresa }) {
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
        <ListItemIcon>
          <Icon icon={rhI} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Recursos Humanos</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <Box sx={{ width: '70vw' }} />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <MenuRH idEmpresa={idEmpresa} />
            </Grid>
            <Grid item xs={12} lg={8}>
              <CardRH idEmpresa={idEmpresa} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
