import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, AppBar, Toolbar, Button, IconButton } from '@mui/material';
import { TreeView, TreeItem } from '@mui/lab';
import { Close, Add } from '@mui/icons-material';
// icon
import { Icon } from '@iconify/react';
import fileI from '@iconify/icons-eva/file-text-fill';
//
import PropTypes from 'prop-types';
import { firestore } from 'src/contexts/FirebaseContext';

ObjItem.propTypes = {
  idEmpresa: PropTypes.string,
  idobj: PropTypes.string,
  cardId: PropTypes.string
};

function ObjItem({ idEmpresa, idobj, cardId }) {
  const [nombre, setNombre] = useState('');
  const [objsA, setobjsA] = useState([]);

  useEffect(() => {
    async function getProyects() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanCards')
        .doc(idobj)
        .onSnapshot((doc) => {
          const { name } = doc.data();
          setNombre(name);
          setobjsA(doc.data().objsA || []);
        });
    }
    getProyects();
  }, [idEmpresa, idobj]);

  const addProyect = async (cardid, idP) => {
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('kanbanCards')
      .doc(cardid)
      .get()
      .then((doc) => {
        const objs = doc.data().objs || [];
        firestore
          .collection('empresas')
          .doc(idEmpresa)
          .collection('kanbanCards')
          .doc(cardid)
          .update({ objsA: [...objs, idP] });
      });
  };

  const removeProyect = async (cardid, idP) => {
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('kanbanCards')
      .doc(cardid)
      .get()
      .then((doc) => {
        const objs = doc.data().objs || [];
        firestore
          .collection('empresas')
          .doc(idEmpresa)
          .collection('kanbanCards')
          .doc(cardid)
          .update({ objsA: objs.filter((p) => p !== idP) });
      });
  };

  return (
    <TreeItem
      icon={<Icon icon={fileI} width={20} height={20} />}
      label={
        <>
          {nombre}
          {objsA.includes(cardId) ? (
            <>
              <IconButton onClick={() => removeProyect(idobj, cardId)}>
                <Close />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton onClick={() => addProyect(idobj, cardId)}>
                <Add />
              </IconButton>
            </>
          )}
        </>
      }
    />
  );
}

AddProyecto.propTypes = {
  idEmpresa: PropTypes.string,
  cardId: PropTypes.string
};

export default function AddProyecto({ cardId, idEmpresa }) {
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const [pes, setPEs] = useState([]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  useEffect(() => {
    async function getProyects() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanColunms')
        .onSnapshot((queryS) => {
          const datas = [];
          queryS.forEach((doc) => {
            datas.push({ id: doc.id, ...doc.data() });
          });
          setPEs(datas);
        });
    }
    getProyects();
  }, [idEmpresa]);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" onClick={clickOpen} sx={{ margin: '2px', color: '#637381' }}>
        Alinear Objetivos
      </Button>
      <Dialog open={open} onClose={clickClose}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton onClick={clickClose}>
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <TreeView expanded={expanded} onNodeToggle={handleToggle}>
            {pes.map((p, i) => (
              <TreeItem key={p.id} label={p.name} nodeId={i}>
                {p.cardIds.map((id) => (
                  <ObjItem key={id} idobj={id} idEmpresa={idEmpresa} cardId={cardId} />
                ))}
              </TreeItem>
            ))}
          </TreeView>
        </DialogContent>
      </Dialog>
    </>
  );
}
