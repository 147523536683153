import React from 'react';
import { Card, List } from '@mui/material';
import AltaDepartamento from './AltaDepartamento';
import { DialogPerfiles } from '../perfilespuesto';
import { DialogRH } from '../rh';
import PropTypes from 'prop-types';

MenuPerf.propTypes = {
  idEmpresa: PropTypes.string
};

export default function MenuPerf({ idEmpresa }) {
  return (
    <Card sx={{ margin: '3%' }}>
      <List>
        <AltaDepartamento idEmpresa={idEmpresa} listB />
        <DialogPerfiles idEmpresa={idEmpresa} />
        <DialogRH idEmpresa={idEmpresa} />
      </List>
    </Card>
  );
}
