import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  TextField,
  MenuItem,
  Grid
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

EditKR.propTypes = {
  kr: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  cardId: PropTypes.string,
  idEmpresa: PropTypes.string
};

// eslint-disable-next-line
export default function EditKR({ kr, open, onClose, onSave, cardId, idEmpresa }) {
  const [newKRText, setNewKRText] = useState(kr.text || '');
  const [newKRStatus, setNewKRStatus] = useState(kr.status || 'en_progreso');
  const [responsable] = useState(kr.responsable || []);
  const [KRType, setKRType] = useState('%');
  const [initialValue, setInitialValue] = useState(kr.min || 0);
  const [metaValue, setMetaValue] = useState(kr.meta || 100);
  const [maxValue, setMaxValue] = useState(kr.max || 100);

  const KRTypes = [
    { type: '%', name: '%' },
    { type: '$', name: '$' },
    { type: '€', name: '€' },
    { type: 'personas', name: 'personas' },
    { type: 'ventas', name: 'ventas' },
    { type: 'clientes', name: 'clientes' },
    { type: 'articulos', name: 'articulos' },
    { type: 'gente', name: 'gente' },
    { type: 'inscripciones', name: 'inscripciones' },
    { type: 'items', name: 'items' },
    { type: 'nps', name: 'nps' },
    { type: 'personas', name: 'personas' },
    { type: 'projectos', name: 'projectos' },
    { type: 'pruebas', name: 'pruebas' },
    { type: 'publicaciones', name: 'publicaciones' },
    { type: 'visitantes', name: 'visitantes' }
  ];

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField fullWidth label="New KR" value={newKRText} onChange={(e) => setNewKRText(e.target.value)} />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  select
                  label="Estado"
                  value={newKRStatus}
                  onChange={(e) => setNewKRStatus(e.target.value)}
                >
                  <MenuItem value="en_progreso">En progreso</MenuItem>
                  <MenuItem value="completado">Completado</MenuItem>
                  <MenuItem value="detenido">Detenido</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="tipo de KPI"
                  select
                  value={KRType}
                  onChange={(e) => setKRType(e.target.value)}
                >
                  {KRTypes.map((krr, index) => (
                    <MenuItem key={index} value={krr.type}>
                      {krr.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="valor inicial"
                  type="number"
                  value={initialValue}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value > maxValue) {
                      setMaxValue(value);
                    }
                    if (value > metaValue) {
                      setMetaValue(value);
                    }
                    setInitialValue(Number(value));
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="valor Meta"
                  type="number"
                  value={metaValue}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value > maxValue) {
                      setMaxValue(value);
                    }
                    setMetaValue(Number(value));
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="valor Maximo"
                  type="number"
                  value={maxValue}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value < metaValue) {
                      setMetaValue(value);
                    }
                    setMaxValue(Number(value));
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                onSave({
                  text: newKRText,
                  status: newKRStatus,
                  id: kr.id,
                  responsable,
                  min: initialValue,
                  avance: kr.avance || 0,
                  meta: metaValue,
                  max: maxValue,
                  type: KRType
                });
                const kravance = kr.avance < initialValue ? initialValue : kr.avance;
                firestore
                  .collection('empresas')
                  .doc(idEmpresa)
                  .collection('kanbanCards')
                  .doc(cardId)
                  .collection('krtime')
                  .add({
                    krid: kr.id,
                    krtext: newKRText,
                    date: new Date(),
                    min: initialValue,
                    avance: kravance,
                    meta: metaValue,
                    max: maxValue
                  });
                onClose();
              }}
            >
              Guardar
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
