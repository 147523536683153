import React from 'react';
import { Dialog, Box, Grid, DialogContent, Card, List } from '@mui/material';
import { CTANav, CTAShow } from 'src/components/_dashboard/ctas';
import { ChooseCTA } from 'src/components/_dashboard/asistentemain/other';
import { isPremium } from 'src/hooks/usePlan';
import MNavigator from './MNavigator';
import CardEmpresa from './CardEmpresa';
import useAuth from 'src/hooks/useAuth';
import PropTypes from 'prop-types';

EmpNav.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  empresa: PropTypes.func
};

export default function EmpNav({ open = false, setOpen, empresa }) {
  const { user } = useAuth();
  const { premium, expiresP } = empresa;
  const modulo = 'empresa';
  const clickClose = () => {
    setOpen(false);
  };

  const ctaS = isPremium(premium, expiresP) ? 'engage' : 'atraccion';

  return (
    <Dialog open={open} onClose={clickClose} maxWidth={1200}>
      <Box sx={{ width: '85vw' }} />
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <MNavigator empresa={empresa} />
            {(user.email === 'kintaroponce.uni@gmail.com' || user.email === 'ocompudoc@gmail.com') && (
              <Grid item xs={12}>
                <Card>
                  <List>
                    <CTANav />
                    <ChooseCTA modulo={modulo} />
                  </List>
                </Card>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} lg={9}>
            <CTAShow etapaCTA={ctaS} modulo={modulo} />
            <CardEmpresa empresa={empresa} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
