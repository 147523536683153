import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, MenuItem, IconButton, Tooltip } from '@mui/material';
import { Add } from '@mui/icons-material';
import { v1 as uuidv1 } from 'uuid';
import { useDispatch } from 'src/redux/store';
import { getBoard } from 'src/redux/slices/kanbanempresa';
import { firestore } from 'src/contexts/FirebaseContext';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

KRsTask.propTypes = {
  card: PropTypes.object.isRequired,
  cardId: PropTypes.string.isRequired,
  idEmpresa: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default function KRsTask({ card, cardId, idEmpresa, disabled = false }) {
  const dispatch = useDispatch();
  const [KRs, setKRs] = useState(card.krs || []);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setKRs(card.krs || []);
  }, [card]);

  const [newKR, setNewKR] = useState(false);
  const [newKRText, setNewKRText] = useState('');
  const [newKRStatus] = useState('creado');
  const [KRType, setKRType] = useState('%');
  const [initialValue, setInitialValue] = useState(0);
  const [metaValue, setMetaValue] = useState(100);
  const [maxValue, setMaxValue] = useState(100);
  const [responsable] = useState([]);

  const actualizar = async (arr) => {
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('kanbanCards')
      .doc(cardId)
      .update({
        krs: arr
      })
      .then(() => {
        enqueueSnackbar('Actializacion exitosa', { variant: 'success' });
        dispatch(getBoard(idEmpresa));
      });
  };

  const KRTypes = [
    { type: '%', name: '%' },
    { type: '$', name: '$' },
    { type: '€', name: '€' },
    { type: 'personas', name: 'personas' },
    { type: 'ventas', name: 'ventas' },
    { type: 'clientes', name: 'clientes' },
    { type: 'articulos', name: 'articulos' },
    { type: 'gente', name: 'gente' },
    { type: 'inscripciones', name: 'inscripciones' },
    { type: 'items', name: 'items' },
    { type: 'npts', name: 'npts' },
    { type: 'personas', name: 'personas' },
    { type: 'projectos', name: 'projectos' },
    { type: 'pruebas', name: 'pruebas' },
    { type: 'publicaciones', name: 'publicaciones' },
    { type: 'visitantes', name: 'visitantes' }
  ];

  return (
    <Box component="div">
      {newKR && (
        <>
          <TextField
            disabled={disabled}
            label="New KR"
            value={newKRText}
            onChange={(e) => setNewKRText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                const id = uuidv1();
                setKRs([
                  ...KRs,
                  {
                    id,
                    text: newKRText,
                    status: newKRStatus,
                    responsable,
                    min: initialValue,
                    avance: initialValue,
                    meta: metaValue,
                    max: maxValue,
                    type: KRType
                  }
                ]);
                actualizar([
                  ...KRs,
                  {
                    id,
                    text: newKRText,
                    status: newKRStatus,
                    responsable,
                    min: initialValue,
                    meta: metaValue,
                    avance: initialValue,
                    max: maxValue,
                    type: KRType
                  }
                ]);
                setNewKRText('');
                setInitialValue(0);
                setMaxValue(100);
                setKRType('%');
                firestore
                  .collection('empresas')
                  .doc(idEmpresa)
                  .collection('kanbanCards')
                  .doc(cardId)
                  .collection('krtime')
                  .add({
                    krid: id,
                    krtext: newKRText,
                    date: new Date(),
                    avance: initialValue,
                    meta: metaValue,
                    max: maxValue,
                    min: initialValue
                  });
              }
            }}
          />
          <TextField label="tipo de KPI" select value={KRType} onChange={(e) => setKRType(e.target.value)}>
            {KRTypes.map((krr, index) => (
              <MenuItem key={index} value={krr.type}>
                {krr.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            disabled={disabled}
            label="valor inicial"
            type="number"
            value={initialValue}
            onChange={(e) => setInitialValue(Number(e.target.value))}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                const id = uuidv1();
                setKRs([
                  ...KRs,
                  {
                    id,
                    text: newKRText,
                    status: newKRStatus,
                    responsable,
                    min: initialValue,
                    avance: initialValue,
                    meta: metaValue,
                    max: maxValue,
                    type: KRType
                  }
                ]);
                actualizar([
                  ...KRs,
                  {
                    id,
                    text: newKRText,
                    status: newKRStatus,
                    responsable,
                    min: initialValue,
                    avance: initialValue,
                    meta: metaValue,
                    max: maxValue,
                    type: KRType
                  }
                ]);
                setNewKRText('');
                setInitialValue(0);
                setMaxValue(100);
                setKRType('%');
                firestore
                  .collection('empresas')
                  .doc(idEmpresa)
                  .collection('kanbanCards')
                  .doc(cardId)
                  .collection('krtime')
                  .add({
                    krid: id,
                    krtext: newKRText,
                    date: new Date(),
                    avance: initialValue,
                    meta: metaValue,
                    max: maxValue,
                    min: initialValue
                  });
              }
            }}
          />
          <TextField
            disabled={disabled}
            label="valor Meta"
            type="number"
            value={metaValue}
            onChange={(e) => setMetaValue(Number(e.target.value))}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                const id = uuidv1();
                setKRs([
                  ...KRs,
                  {
                    id,
                    text: newKRText,
                    status: newKRStatus,
                    responsable,
                    min: initialValue,
                    avance: initialValue,
                    meta: metaValue,
                    max: maxValue,
                    type: KRType
                  }
                ]);
                actualizar([
                  ...KRs,
                  {
                    id,
                    text: newKRText,
                    status: newKRStatus,
                    responsable,
                    min: initialValue,
                    meta: metaValue,
                    avance: initialValue,
                    max: maxValue,
                    type: KRType
                  }
                ]);
                setNewKRText('');
                setInitialValue(0);
                setMaxValue(100);
                setKRType('%');
                firestore
                  .collection('empresas')
                  .doc(idEmpresa)
                  .collection('kanbanCards')
                  .doc(cardId)
                  .collection('krtime')
                  .add({
                    krid: id,
                    krtext: newKRText,
                    date: new Date(),
                    avance: initialValue,
                    max: maxValue,
                    meta: metaValue,
                    min: initialValue
                  });
              }
            }}
          />
          <TextField
            disabled={disabled}
            label="valor Maximo"
            type="number"
            value={maxValue}
            onChange={(e) => {
              const value = e.target.value;
              if (value < metaValue) {
                setMetaValue(value);
              }
              setMaxValue(Number(value));
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                const id = uuidv1();
                setKRs([
                  ...KRs,
                  {
                    id,
                    text: newKRText,
                    status: newKRStatus,
                    responsable,
                    min: initialValue,
                    avance: initialValue,
                    meta: metaValue,
                    max: maxValue,
                    type: KRType
                  }
                ]);
                actualizar([
                  ...KRs,
                  {
                    id,
                    text: newKRText,
                    status: newKRStatus,
                    responsable,
                    min: initialValue,
                    avance: initialValue,
                    meta: metaValue,
                    max: maxValue,
                    type: KRType
                  }
                ]);
                setNewKRText('');
                setInitialValue(0);
                setMaxValue(100);
                setKRType('%');
                firestore
                  .collection('empresas')
                  .doc(idEmpresa)
                  .collection('kanbanCards')
                  .doc(cardId)
                  .collection('krtime')
                  .add({
                    krid: id,
                    krtext: newKRText,
                    date: new Date(),
                    avance: initialValue,
                    max: maxValue,
                    meta: metaValue,
                    min: initialValue
                  });
              }
            }}
          />
          <Button
            disabled={disabled}
            variant="contained"
            onClick={() => {
              const id = uuidv1();
              setKRs([
                ...KRs,
                {
                  id,
                  text: newKRText,
                  status: newKRStatus,
                  responsable,
                  min: initialValue,
                  avance: initialValue,
                  max: maxValue,
                  meta: metaValue,
                  type: KRType
                }
              ]);
              actualizar([
                ...KRs,
                {
                  id,
                  text: newKRText,
                  status: newKRStatus,
                  responsable,
                  min: initialValue,
                  avance: initialValue,
                  max: maxValue,
                  meta: metaValue,
                  type: KRType
                }
              ]);
              setNewKRText('');
              setInitialValue(0);
              setMaxValue(100);
              setKRType('%');
              firestore
                .collection('empresas')
                .doc(idEmpresa)
                .collection('kanbanCards')
                .doc(cardId)
                .collection('krtime')
                .add({
                  krid: id,
                  krtext: newKRText,
                  date: new Date(),
                  min: initialValue,
                  avance: initialValue,
                  meta: metaValue,
                  max: maxValue
                });
            }}
          >
            Agregar
          </Button>
        </>
      )}

      {!newKR && (
        <Tooltip title="Agregar KR">
          <IconButton onClick={() => setNewKR(true)} disabled={disabled}>
            <Add />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}
