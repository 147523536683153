import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import checkFilled from '@iconify/icons-ant-design/check-circle-filled';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, Dialog, DialogContent, List, ListItem } from '@mui/material';
// utils
import { fShortenNumber } from 'src/utils/formatNumber';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

ObjsCompletados.propTypes = {
  data: PropTypes.array.isRequired
};

export default function ObjsCompletados({ data = [] }) {
  const [TOTAL, setTotal] = useState(0);
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTotal(data.filter((d) => d.status === 'Cerrado').length);
  }, [data]);

  return (
    <>
      <RootStyle onClick={clickOpen}>
        <IconWrapperStyle>
          <Icon icon={checkFilled} width={24} height={24} />
        </IconWrapperStyle>
        <Typography variant="h3">{fShortenNumber(TOTAL)}</Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          Objetivos completados
        </Typography>
      </RootStyle>
      <Dialog open={open} onClose={clickClose}>
        <DialogContent>
          <List>
            {data.map((dat) => (
              <ListItem key={dat.name}>{dat.name}</ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}
