import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText
} from '@mui/material';
import { Close, Add } from '@mui/icons-material';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

KRsAddR.propTypes = {
  responsable: PropTypes.array,
  setResponsable: PropTypes.func,
  onEditP: PropTypes.func,
  kr: PropTypes.object,
  idEmpresa: PropTypes.string
};

export default function KRsAddR({ responsable, setResponsable, onEditP, kr, idEmpresa }) {
  const [open, setOpen] = useState(false);
  const [integrantes, setIntegrantes] = useState([]);

  const addResponsable = (integran) => {
    const newArr = [integran];
    setResponsable(newArr);
    onEditP({ ...kr, responsable: newArr });
  };

  useEffect(() => {
    async function getIntegrantes() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .onSnapshot((doc) => {
          const { membersinfo } = doc.data();
          setIntegrantes(membersinfo);
        });
    }
    getIntegrantes();
  }, [idEmpresa]);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={clickOpen}>
        <Add />
      </IconButton>
      <Dialog open={open} onClose={clickClose}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton onClick={clickClose}>
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box>
            <List>
              {integrantes.map((inte, index) => (
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Avatar src={inte.avatar} />
                  </ListItemAvatar>
                  <ListItemText>
                    {inte.nombre}
                    {!responsable.find((j) => j.id === inte.id) && (
                      <Button variant="contained" onClick={() => addResponsable(inte)}>
                        <Add />
                      </Button>
                    )}
                    {responsable.find((j) => j.id === inte.id) && <Button variant="contained">Seleccionado</Button>}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
