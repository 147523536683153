import React, { useState } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { List, ListItemText, ListItemIcon, ListItem, Card } from '@mui/material';
import AsistenteProyecto from 'src/pages/dashboard/AsistenteProyecto';
import AgregarInvitados from './AgregarInvitados';
import SprintCalendarD from 'src/components/_dashboard/sprints/SprintCalendarD';
import KanbanPD from './KanbanPD';
import Channel from '../social/Channel';
// icon
import { Icon } from '@iconify/react';
import teamI from '@iconify/icons-ant-design/team-outline';
import linkout from '@iconify/icons-eva/external-link-fill';
import agile from '@iconify/icons-ant-design/dingding';
//
import { PATH_DASHBOARD } from 'src/routes/paths';
import PropTypes from 'prop-types';

PNavigator.propTypes = {
  proyecto: PropTypes.object
};

export default function PNavigator({ proyecto }) {
  const { pid } = useParams();
  const { id, idEmpresa } = proyecto;
  const [openMenuInte, setOpenMenuInte] = useState(false);
  const [openSP, setOpenSP] = useState(false);
  const [openK, setOpenK] = useState(false);

  return (
    <Card>
      <List>
        {pid !== id && (
          <ListItem
            component={NavLink}
            to={PATH_DASHBOARD.entorno.empresa + idEmpresa + '/proyecto/' + id}
            sx={{ color: '#637381' }}
          >
            <ListItemIcon>
              <Icon icon={linkout} width={20} height={20} />
            </ListItemIcon>
            <ListItemText>Ir</ListItemText>
          </ListItem>
        )}
        <AsistenteProyecto proyectId={id} listB />
        <KanbanPD open={openK} setOpen={setOpenK} projectId={id} idEmpresa={idEmpresa} />
        <ListItem
          button
          onClick={() => {
            setOpenMenuInte(true);
          }}
          sx={{ color: '#637381' }}
        >
          <ListItemIcon>
            <Icon icon={teamI} width={23} height={23} />
          </ListItemIcon>
          <ListItemText>Personas</ListItemText>
        </ListItem>
        <ListItem button onClick={() => setOpenSP(true)} sx={{ color: '#637381' }}>
          <ListItemIcon>
            <Icon icon={agile} width={20} height={20} />
          </ListItemIcon>
          <ListItemText>Agil</ListItemText>
        </ListItem>
      </List>
      <Channel collection="proyectosempresa" doc={id} />
      <SprintCalendarD open={openSP} setOpen={setOpenSP} idEmpresa={idEmpresa} />
      <AgregarInvitados projectId={id} idEmpresa={idEmpresa} open={openMenuInte} setOpen={setOpenMenuInte} />
    </Card>
  );
}
