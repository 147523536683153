import React from 'react';
import { Box } from '@mui/material';
import UserElement from './UserElement';
import PropTypes from 'prop-types';

UserList.propTypes = {
  users: PropTypes.array
};

export default function UserList({ users = [] }) {
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        {users.map((u) => (
          <UserElement key={u} userid={u} variant="icon" />
        ))}
      </Box>
    </>
  );
}
