import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import ProyectoTags from './ProyectoTags';
import PHero from './PHero';
import { AltaProyecto } from '../entorno';
import PuntosEnfoque from './PuntosEnfoque';
import useAuth from 'src/hooks/useAuth';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

ProyectCard.propTypes = {
  proyecto: PropTypes.object
};

export default function ProyectCard({ proyecto }) {
  const { user } = useAuth();
  const { nombre, vision, imagen, id } = proyecto;
  const [likess, setLikes] = useState([]);
  const [openE, setOpenE] = useState(false);
  const [PEs, setPEs] = useState([]);

  useEffect(() => {
    async function getPEs() {
      await firestore
        .collection('kobjsColunms')
        .where('pid', '==', id)
        .onSnapshot((queryS) => {
          const pes = [];
          queryS.forEach((doc) => pes.push({ id: doc.id, ...doc.data() }));
          setPEs(pes);
        });
    }
    getPEs();
  }, [id]);

  const clickOpenE = () => {
    setOpenE(true);
  };

  useEffect(() => {
    if (proyecto !== null) {
      setLikes(proyecto?.likes || []);
    }
  }, [proyecto]);

  const like = async () => {
    const newArr = [...likess];
    if (newArr.includes(user.id)) {
      setLikes(newArr.filter((d) => d !== user.id));
      await firestore
        .collection('proyectosempresa')
        .doc(id)
        .update({ likes: newArr.filter((d) => d !== user.id) });
    } else {
      newArr.push(user.id);
      setLikes(newArr);
      await firestore.collection('proyectosempresa').doc(id).update({ likes: newArr });
    }
  };

  return (
    <Card>
      <PHero nameP={nombre} vision={vision} img={imagen} likes={likess} like={like} onOpenEdit={clickOpenE} />
      <CardContent>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>
          Valores
        </Typography>
        <ProyectoTags post={proyecto} />
        <PuntosEnfoque PEs={PEs} />
        <AltaProyecto idProyecto={id} open={openE} setOpen={setOpenE} edit />
      </CardContent>
    </Card>
  );
}
