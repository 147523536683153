import React, { useState } from 'react';
import { ListItem, ListItemText, ListItemIcon, Dialog, DialogContent, Grid, Box } from '@mui/material';
import EquiposCard from './EquiposCard';
import MenuEquipos from './MenuEquipos';
// icon
import { Icon } from '@iconify/react';
import teamI from '@iconify/icons-ant-design/team-outline';
import PropTypes from 'prop-types';

EquiposD.propTypes = {
  idEmpresa: PropTypes.string
};

export default function EquiposD({ idEmpresa }) {
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
        <ListItemIcon>
          <Icon icon={teamI} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Equipos</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <Box sx={{ width: '70vw' }} />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <MenuEquipos idEmpresa={idEmpresa} />
            </Grid>
            <Grid item xs={12} lg={8}>
              <EquiposCard idEmpresa={idEmpresa} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
