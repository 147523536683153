import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { SprintRow, SprintMainP } from 'src/components/_dashboard/sprints';
//
import Page from 'src/components/Page';
//
import { firestore } from 'src/contexts/FirebaseContext';
import PermissionsC from 'src/contexts/PermissionsContext';
// hooks
import useAuth from 'src/hooks/useAuth';

export function SprintTable() {
  const { empid } = useParams();
  const { user } = useAuth();
  const [sprints, setSprints] = useState([]);

  useEffect(() => {
    // sprints
    async function getSprints() {
      await firestore
        .collection('empresas')
        .doc(empid)
        .collection('sprints')
        .where('status', '!=', 'archivado')
        .onSnapshot((queryS) => {
          const datas = [];
          queryS.forEach((doc) => {
            const data = doc.data();
            const { scrumMaster, productOwner, team, coach, involucrados, creator, publico } = data;
            if (
              scrumMaster?.includes(user.id) ||
              productOwner?.includes(user.id) ||
              team?.includes(user.id) ||
              coach?.includes(user.id) ||
              involucrados?.includes(user.id) ||
              creator === user.id ||
              publico
            ) {
              datas.push({ id: doc.id, ...data });
            }
          });
          setSprints(datas.filter((s) => s.status !== 'completado' || s.status !== 'detenido'));
        });
    }
    getSprints();
  }, [empid, user]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Nombre</TableCell>
            <TableCell>Objetivo</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell>fecha de comienzo</TableCell>
            <TableCell>fecha de finalizacion</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sprints.map((sp) => (
            <SprintRow idEmpresa={empid} sprint={sp} key={sp.id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function SprintsPage() {
  const { empid } = useParams();

  return (
    <Page title="Entorno: Sprints | Bunka">
      <PermissionsC idEmpresa={empid}>
        <SprintMainP idEmpresa={empid} />
      </PermissionsC>
    </Page>
  );
}
