import React from 'react';
import { Card, List } from '@mui/material';
import { NewTeamForm } from 'src/components/_dashboard/equipos';
import PropTypes from 'prop-types';

MenuEquipo.propTypes = {
  idEmpresa: PropTypes.string,
  equipo: PropTypes.object
};

export default function MenuEquipo({ idEmpresa, equipo }) {
  const { id } = equipo;
  return (
    <Card>
      <List>
        <NewTeamForm idDoc={id} idEmpresa={idEmpresa} edit listB />
      </List>
    </Card>
  );
}
