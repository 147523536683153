import React, { useState } from 'react';
import { Dialog, DialogContent, Box, Grid, ListItem, ListItemIcon, ListItemText, Card, List } from '@mui/material';
import SprintCalentar from 'src/pages/dashboard/SprintsCalendar';
import { MenuSprint } from '../entorno';
import { CTANav, CTAShow } from 'src/components/_dashboard/ctas';
import { ChooseCTA } from 'src/components/_dashboard/asistentemain/other';
// icon
import { Icon } from '@iconify/react';
import agile from '@iconify/icons-ant-design/dingding';
//
import usePlan, { getCTAState } from 'src/hooks/usePlan';
import useAuth from 'src/hooks/useAuth';
import PropTypes from 'prop-types';

SprintCalendarD.propTypes = {
  idEmpresa: PropTypes.string
};

export default function SprintCalendarD({ idEmpresa }) {
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const modulo = 'agile';
  const { CTAState } = usePlan();
  const etapaCTA = getCTAState(modulo, CTAState);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#506070' }}>
        <ListItemIcon>
          <Icon icon={agile} width={20} height={20} />
        </ListItemIcon>
        <ListItemText>Agil</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <Box sx={{ width: '80vw' }} />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <MenuSprint empid={idEmpresa} />
              {(user.email === 'kintaroponce.uni@gmail.com' || user.email === 'ocompudoc@gmail.com') && (
                <Card>
                  <List>
                    <CTANav />
                    <ChooseCTA modulo="agile" />
                  </List>
                </Card>
              )}
            </Grid>
            <Grid item xs={12} lg={8}>
              <CTAShow etapaCTA={etapaCTA} modulo={modulo} />
              <SprintCalentar idEmpresa={idEmpresa} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
