import React, { useState } from 'react';
import { ListItem, ListItemText, ListItemIcon, Dialog, DialogContent, Box, Button } from '@mui/material';
// icon
import { Icon } from '@iconify/react';
import btnI from '@iconify/icons-carbon/circle-solid';
import PropTypes from 'prop-types';

DialogG.propTypes = {
  icon: PropTypes.any,
  textButton: PropTypes.string,
  component: PropTypes.any
};

export default function DialogG({ icon, textButton = '', component, listB = false, btn = false }) {
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      {listB && (
        <ListItem button onClick={clickOpen} sx={{ color: '#506070' }}>
          <ListItemIcon>
            <Icon icon={icon || btnI} width={23} height={23} />
          </ListItemIcon>
          <ListItemText>{textButton}</ListItemText>
        </ListItem>
      )}
      {btn && (
        <Button variant="contained" onClick={clickOpen}>
          {textButton}
        </Button>
      )}
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <Box sx={{ width: '70vw' }} />
        <DialogContent>{component}</DialogContent>
      </Dialog>
    </>
  );
}
