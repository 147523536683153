import { useContext } from 'react';
import { permissionsContext } from 'src/contexts/PermissionsContext';

const usePermissions = () => useContext(permissionsContext);

export function tienePermiso(permiso, userid, permisos) {
  const users = permisos[permiso]?.users || [];
  return Boolean(users.find((id) => id === userid));
}

export default usePermissions;
