import React, { useState } from 'react';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogContent,
  Grid,
  Card,
  CardContent,
  List,
  Typography,
  Box
} from '@mui/material';
// icon
import { Icon } from '@iconify/react';
import org from '@iconify/icons-ant-design/team-outline';
// utils
import { fDate, parseDate } from 'src/utils/formatTime';
import { useSnackbar } from 'notistack';
// hooks
import useAuth from 'src/hooks/useAuth';
import usePlan, { getLimit, isAuthorizedL, setLimit } from 'src/hooks/usePlan';
import { firestore } from 'src/contexts/FirebaseContext';
//
import PropTypes from 'prop-types';

const moment = require('moment');

Organizacion.propTypes = {
  empresa: PropTypes.object
};

export default function Organizacion({ empresa }) {
  const { enqueueSnackbar } = useSnackbar();
  const { id, orgMod, expiresOrg } = empresa;
  const modulo = 'organizacion';
  const { user } = useAuth();
  const { plan } = usePlan();
  const limitMod = getLimit(modulo, plan);
  const autorizado = isAuthorizedL(modulo, plan);
  const today = new Date();
  const expiresODate = parseDate(expiresOrg || null);
  const expiredO = today > expiresODate;
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  const upgradeToPremium = async () => {
    setDisabled(true);
    await setLimit(modulo, plan, user.id, limitMod - 1);
    await firestore
      .collection('empresas')
      .doc(id)
      .update({ orgMod: true, expiresOrg: moment().add(1, 'year').toDate() });
    setDisabled(false);
    enqueueSnackbar('Modulo Organizacion Habilitado', { variant: 'success' });
  };

  const extendMembership = async () => {
    setDisabled(true);
    await setLimit(modulo, plan, user.id, limitMod - 1);
    await firestore
      .collection('empresas')
      .doc(id)
      .update({ orgMod: true, expiresOrg: moment(expiresODate).add(1, 'year').toDate() });
    setDisabled(false);
    enqueueSnackbar('Membersia Extendida', { variant: 'success' });
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#506070' }}>
        <ListItemIcon>
          <Icon icon={org} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Organizacion</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <Box sx={{ width: '80vw' }} />
        <DialogContent>
          <Grid container>
            <Grid item xs={12} lg={4}>
              <Card>
                <List>
                  {autorizado && !orgMod && expiredO && (
                    <ListItem button disabled={disabled} onClick={upgradeToPremium} sx={{ color: '#506070' }}>
                      <ListItemText>Habilitar Modulo</ListItemText>
                    </ListItem>
                  )}
                  {autorizado && orgMod && !expiredO && (
                    <ListItem button disabled={disabled} onClick={extendMembership}>
                      <ListItemText>Extender Membresia</ListItemText>
                    </ListItem>
                  )}
                </List>
              </Card>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Card>
                <CardContent>
                  <Typography variant="h4">Organizacion</Typography>
                  <Box>
                    <Typography>Membresias premium anuales Disponibles: {limitMod} </Typography>
                    <Typography>Modulo Organizacion: {orgMod ? 'Si' : 'No'}</Typography>
                    {orgMod && expiresOrg && <Typography>Expira: {fDate(parseDate(expiresOrg))}</Typography>}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
