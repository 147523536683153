import React, { useState, useEffect } from 'react';
// import { NavLink } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardHeader,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';
import AddObjetivo from './AddObjetivo';
import { firestore } from 'src/contexts/FirebaseContext';
// import { PATH_DASHBOARD } from 'src/routes/paths';
import PropTypes from 'prop-types';

ObjetivoRow.propTypes = {
  idP: PropTypes.string,
  idEmpresa: PropTypes.string
};

function ObjetivoRow({ idP, idEmpresa }) {
  const [name, setName] = useState('');
  const [complete, setComp] = useState('');

  useEffect(() => {
    async function getPD() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanCards')
        .doc(idP)
        .onSnapshot((doc) => {
          const { name, completed } = doc.data();
          setName(name);
          setComp(completed);
        });
    }
    getPD();
  }, [idP, idEmpresa]);

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{complete ? 'si' : 'no'}</TableCell>
    </TableRow>
  );
}

ObjetivosAsociados.propTypes = {
  idEmpresa: PropTypes.string,
  cardId: PropTypes.string
};

export default function ObjetivosAsociados({ idEmpresa, cardId }) {
  const [objs, setObjs] = useState([]);

  useEffect(() => {
    async function getPs() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanCards')
        .where('objsA', 'array-contains', cardId)
        .onSnapshot((queryS) => {
          const datas = [];
          queryS.forEach((doc) => {
            datas.push(doc.id);
          });
          setObjs(datas);
        });
    }
    getPs();
  }, [idEmpresa, cardId]);

  return (
    <Card>
      <CardHeader
        title="Objetivos alienados"
        action={
          <>
            <AddObjetivo idEmpresa={idEmpresa} cardId={cardId} />
          </>
        }
      />
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell>completado</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {objs.map((p) => (
                <ObjetivoRow key={p} idP={p} idEmpresa={idEmpresa} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
