import React, { useState, useEffect, useCallback } from 'react';
import {
  DialogActions,
  TextField,
  Typography,
  Box,
  Grid,
  MenuItem,
  Switch,
  FormControlLabel,
  LinearProgress,
  Card,
  CardContent,
  RadioGroup,
  Radio,
  Stack,
  Chip,
  Autocomplete,
  Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { UploadSingleFile } from 'src/components/upload';
import CTACompM from './CTACompM';
import { QuillEditor } from '../../editor';
import { styled } from '@mui/styles';
// icon

// formik
import { Form, useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
// firebase
import { firestore, storage as st } from 'src/contexts/FirebaseContext';
import { v4 as uuidv4 } from 'uuid';
// utils
import { fData, fPercent } from 'src/utils/formatNumber';
import { getURLFile } from 'src/utils/storagef';
import PropTypes from 'prop-types';

// -------------------------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

// -------------------------------------------------------------------------------------

AltaCTA.propTypes = {
  iddoc: PropTypes.string,
  edit: PropTypes.bool,
  defaultModule: PropTypes.string,
  forceModule: PropTypes.bool,
  defaultEtapa: PropTypes.string,
  forceEtapa: PropTypes.bool,
  reset: PropTypes.bool,
  next: PropTypes.func,
  back: PropTypes.func
};

export default function AltaCTA({
  edit = false,
  iddoc,
  defaultModule,
  forceModule = false,
  defaultEtapa,
  forceEtapa = false,
  reset = false,
  next,
  back
}) {
  const [uuidS, setUUIDS] = useState('');
  const [file, setFile] = useState({ preview: '', file: null });
  const [pF, setPF] = useState(0);
  const [pData, setPData] = useState('');
  const [buttons, setButtons] = useState([]);

  useEffect(() => {
    async function getBtns() {
      await firestore.collection('buttons').onSnapshot((queryS) => {
        const dat = [];
        queryS.forEach((doc) => {
          dat.push({ id: doc.id, ...doc.data() });
        });
        setButtons(dat);
      });
    }
    getBtns();
  }, []);

  useEffect(() => {
    const uuid = uuidv4();
    setUUIDS(uuid);
  }, [iddoc]);

  const modulos = [
    { name: 'Empresa Nav', mod: 'empresac' },
    { name: 'Empresa', mod: 'empresa' },
    { name: 'Sprints', mod: 'agile' },
    { name: 'Organizacion', mod: 'organizacion' },
    { name: 'Desarrollo personal', mod: 'desarrolloP' },
    { name: 'Finanzas personales', mod: 'finanzasP' },
    { name: 'Machine Learning', mod: 'ML' },
    { name: 'Home', mod: 'Home' }
  ];

  const mediaTypes = [
    { value: 'image', name: 'Imagen' },
    { value: 'video', name: 'Video' },
    { value: 'iframe', name: 'Embebido' }
  ];

  const etapas = [
    { value: 'atraccion', name: 'atracción' },
    { value: 'engage', name: 'enganche' },
    { value: 'deleitar', name: 'deleitar' }
  ];

  const formik = useFormik({
    initialValues: {
      titulo: '',
      descripcion: '',
      urlMedia: '',
      mediaType: 'iframe',
      modulo: defaultModule || 'empresa',
      show: false,
      etiquetas: [],
      etapa: defaultEtapa || 'atraccion',
      buttonId: ''
    },
    validationSchema: yup.object().shape({
      titulo: yup.string().required('Requerido')
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        setSubmitting(true);
        const data = values;
        console.log(data);
        if (edit) {
          if (file.file !== null && values.mediaType !== 'iframe') {
            await uploadMedia();
            data.urlMedia = await getURLFile(`ctas/${iddoc}`);
          }
          if (!Boolean(data.urlMedia) && values.mediaType !== 'iframe') {
            data.urlMedia = await getURLFile(`ctas/${iddoc}`);
          }
          await firestore
            .collection('ctas')
            .doc(iddoc)
            .update({ ...data });
        } else {
          if (file.file !== null && values.mediaType !== 'iframe') {
            await uploadMedia();
            data.urlMedia = await getURLFile(`ctas/${uuidS}`);
          }
          await firestore
            .collection('ctas')
            .doc(uuidS)
            .set({ ...data })
            .then(() => {
              if (reset) {
                setFile({ preview: '', file: null });
                setUUIDS(uuidv4());
                resetForm();
              }
            });
        }
        if (next) {
          next();
        }
        setSubmitting(false);
      } catch (err) {
        console.error(err);
        setSubmitting(false);
      }
    }
  });

  const { handleSubmit, getFieldProps, errors, touched, values, isSubmitting, setFieldValue } = formik;

  const uploadMedia = useCallback(async () => {
    const refF = st.ref().child(`ctas/${iddoc || uuidS}`);
    await refF
      .delete()
      .then(() => {
        console.log('borrado');
      })
      .catch((err) => {
        console.error(err);
      });
    return new Promise((resolve) => {
      if (file.file !== null) {
        refF.put(file.file).on('next', (snapshot) => {
          const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setPF(percent);
          setPData(`${fData(snapshot.bytesTransferred)} / ${fData(snapshot.totalBytes)}  (${fPercent(percent)})`);
          if (percent === 100) {
            setTimeout(() => resolve(), 1500);
          }
        });
      } else {
        resolve();
      }
    });
  }, [uuidS, file, iddoc]);

  useEffect(() => {
    async function getCTA() {
      await firestore
        .collection('ctas')
        .doc(iddoc)
        .get()
        .then((doc) => {
          const { titulo, descripcion, urlMedia, mediaType, modulo, show, etiquetas, visiblewhen, etapa, buttonId } =
            doc.data();
          setFieldValue('titulo', titulo || '');
          setFieldValue('descripcion', descripcion || '');
          setFieldValue('urlMedia', urlMedia || '');
          setFieldValue('mediaType', mediaType || '');
          setFieldValue('modulo', Boolean(defaultModule) && forceModule ? defaultModule : modulo || 'Home');
          setFieldValue('show', show || false);
          setFile({ file: null, preview: urlMedia || '' });
          setFieldValue('etiquetas', etiquetas || []);
          setFieldValue('etapa', Boolean(defaultEtapa) && forceEtapa ? defaultEtapa : etapa || 'atraccion');
          setFieldValue('buttonId', buttonId || '');
        });
    }
    if (edit) {
      getCTA();
    }
    // eslint-disable-next-line
  }, [edit, iddoc, defaultModule, forceModule, defaultEtapa, forceEtapa]);

  useEffect(() => {
    if (Boolean(defaultModule) && forceModule) {
      setFieldValue('modulo', defaultModule);
    }
    // eslint-disable-next-line
  }, [defaultModule, forceModule]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <Typography variant="h4">{edit ? <>Editar cta: {values.titulo}</> : <>Alta de Call To Action</>}</Typography>

          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <TextField
                          label="Titulo"
                          fullWidth
                          {...getFieldProps('titulo')}
                          error={Boolean(touched.titulo && errors.titulo)}
                          helperText={touched.titulo && errors.titulo}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <LabelStyle>Descripcion</LabelStyle>
                        <QuillEditor
                          id="post-content"
                          value={values.descripcion}
                          onChange={(val) => {
                            setFieldValue('descripcion', val);
                          }}
                          error={Boolean(touched.content && errors.content)}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <LabelStyle>Tipo de medio</LabelStyle>
                        <RadioGroup {...getFieldProps('mediaType')} row>
                          <Stack spacing={1} direction="row">
                            {mediaTypes.map((mediat) => (
                              <FormControlLabel
                                key={mediat.name}
                                value={mediat.value}
                                control={<Radio />}
                                label={mediat.name}
                              />
                            ))}
                          </Stack>
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        {values.mediaType !== 'iframe' && (
                          <>
                            <LabelStyle>Archivo</LabelStyle>
                            <UploadSingleFile
                              file={file}
                              accept={`${values.mediaType}/*`}
                              onDrop={(filec) => {
                                setPData(`${fData(filec[0].size)}`);
                                setFile({
                                  file: filec[0],
                                  preview: URL.createObjectURL(filec[0])
                                });
                              }}
                            />
                            <Box>
                              <LinearProgress variant="determinate" value={pF} />
                              {pData}
                            </Box>
                          </>
                        )}
                        {values.mediaType === 'iframe' && <TextField label="URL" {...getFieldProps('urlMedia')} />}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Switch {...getFieldProps('show')} checked={values.show} />}
                          label="Mostrar CTA"
                          labelPlacement="start"
                          sx={{ mb: 1, mx: 0 }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField label="boton" select fullWidth {...getFieldProps('buttonId')}>
                          {buttons.map((btn) => (
                            <MenuItem key={btn.id} value={btn.id}>
                              {btn.buttonText} ({btn.descripcion})
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          select
                          fullWidth
                          label="Ubicacion"
                          {...getFieldProps('modulo')}
                          error={Boolean(touched.modulo && errors.modulo)}
                          helperText={touched.modulo && errors.modulo}
                        >
                          {modulos.map((module) => (
                            <MenuItem key={module.name} value={module.mod}>
                              {module.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <LabelStyle>etapa</LabelStyle>
                        <RadioGroup {...getFieldProps('etapa')} row>
                          <Stack spacing={1} direction="row">
                            {etapas.map((mediat) => (
                              <FormControlLabel
                                key={mediat.name}
                                value={mediat.value}
                                control={<Radio />}
                                label={mediat.name}
                              />
                            ))}
                          </Stack>
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Autocomplete
                          multiple
                          freeSolo
                          value={values.etiquetas}
                          onChange={(event, newValue) => {
                            setFieldValue('etiquetas', newValue);
                          }}
                          options={[]}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip {...getTagProps({ index })} key={option} size="small" label={option} />
                            ))
                          }
                          renderInput={(params) => <TextField label="Etiquetas" {...params} />}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CTACompM CTA={values} previewFileAlt={file.preview} />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <DialogActions>
            {back && (
              <Button variant="contained" onClick={back}>
                Atras
              </Button>
            )}
            <LoadingButton variant="contained" loading={isSubmitting} type="submit">
              {Boolean(next) ? 'siguiente' : 'Guardar'}
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </>
  );
}
