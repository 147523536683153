import React, { useState } from 'react';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogContent,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  List
} from '@mui/material';
// icon
import { Icon } from '@iconify/react';
import shopping from '@iconify/icons-ant-design/shopping-cart-outline';
//
import PropTypes from 'prop-types';

ButtonCompra.propTypes = {
  modulo: PropTypes.string,
  altName: PropTypes.string,
  descripcion: PropTypes.string,
  href: PropTypes.string
};

export default function ButtonCompra({ modulo, altName, descripcion = '', href }) {
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
        <ListItemIcon>
          <Icon icon={shopping} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Comprar</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <Card>
                <List>
                  <a
                    href={
                      Boolean(href)
                        ? href
                        : `https://wa.me/+523322638033?text=Hola, quiero comprar el modulo de ${altName}`
                    }
                    style={{ textDecoration: 'none' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ListItem>
                      <ListItemIcon>
                        <Icon icon={shopping} width={23} height={23} />
                      </ListItemIcon>
                      <ListItemText>comprar</ListItemText>
                    </ListItem>
                  </a>
                </List>
              </Card>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Card>
                <CardContent>
                  <Typography variant="h4">{modulo}</Typography>
                  <Box component="p">{descripcion}</Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
