import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  IconButton,
  Dialog,
  DialogContent,
  Box,
  Typography,
  Grid,
  ListItem,
  ListItemText,
  List,
  Card,
  CardContent
} from '@mui/material';
import {
  contratarModulo,
  contratarModuloByDate,
  cancelarModulo,
  extendPlan,
  isAuthorized,
  getLimit,
  setLimit
} from 'src/hooks/usePlan';
// icons
import { Icon } from '@iconify/react';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// utils
import { parseDate, fDate } from 'src/utils/formatTime';
// proptypes
import PropTypes from 'prop-types';

ModsControl.propTypes = {
  userid: PropTypes.string,
  plan: PropTypes.object,
  modulo: PropTypes.string,
  altName: PropTypes.string,
  limitM: PropTypes.bool,
  dateApply: PropTypes.bool
};

export default function ModsControl({ userid, plan, modulo, altName, limitM = false, dateApply = false }) {
  const { expires, contratado } = plan[modulo];
  const [open, setOpen] = useState(false);
  const expiresDate = parseDate(expires);
  const autorizado = isAuthorized(modulo, plan);
  const limitMod = getLimit(modulo, plan);

  const expirationDateText = contratado ? fDate(expiresDate) : '-';

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  const contratarPlan = () => {
    if (dateApply) {
      contratarModulo(modulo, plan, userid);
    } else {
      contratarModuloByDate(modulo, plan, userid, 1);
    }
  };

  const revocarPlan = () => {
    cancelarModulo(modulo, plan, userid);
  };

  const darMembresia = () => {
    setLimit(modulo, plan, userid, limitMod + 1);
  };

  const removerMembresia = () => {
    setLimit(modulo, plan, userid, limitMod - 1);
  };

  return (
    <TableRow>
      <TableCell>{altName}</TableCell>
      <TableCell>{dateApply ? expirationDateText : 'no aplica'}</TableCell>
      <TableCell>{contratado ? 'si' : 'no'}</TableCell>
      <TableCell>
        <IconButton onClick={clickOpen}>
          <Icon icon={moreVerticalFill} width={23} height={23} />
        </IconButton>
        <Dialog open={open} onClose={clickClose}>
          <DialogContent>
            <Typography variant="h4">Manejo del Modulo {altName}</Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4}>
                <Card>
                  <List>
                    <ListItem button onClick={autorizado ? revocarPlan : contratarPlan} sx={{ color: '#506070' }}>
                      <ListItemText>{autorizado ? 'revocar' : 'autorizar (1 Dia)'}</ListItemText>
                    </ListItem>
                    {autorizado && dateApply && (
                      <ListItem
                        button
                        onClick={() => extendPlan(modulo, plan, userid, 1, 'day')}
                        sx={{ color: '#506070' }}
                      >
                        <ListItemText>Extender 1 Dia</ListItemText>
                      </ListItem>
                    )}
                    {limitM && (
                      <ListItem button onClick={darMembresia} sx={{ color: '#506070' }}>
                        <ListItemText>Dar una Membresia</ListItemText>
                      </ListItem>
                    )}
                    {limitM && limitMod !== 0 && (
                      <ListItem button onClick={removerMembresia} sx={{ color: '#506070' }}>
                        <ListItemText>Quitar una Membresia</ListItemText>
                      </ListItem>
                    )}
                  </List>
                </Card>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Card>
                  <CardContent>
                    <Box>Fecha de Expiracion: {dateApply ? expirationDateText : 'no aplica'}</Box>
                    {limitM && <Box>Membersias anuales dadas: {limitMod}</Box>}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </TableCell>
    </TableRow>
  );
}
