import React, { useState, useEffect, createContext, useContext } from 'react';
// material
import { Box, Grid, Container, Typography } from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import {
  AnalyticsTasks,
  AnalyticsNewUsers,
  AnalyticsBugReports,
  AnalyticsItemOrders,
  AnalyticsNewsUpdate,
  AnalyticsWeeklySales,
  AnalyticsOrderTimeline,
  AnalyticsCurrentVisits,
  AnalyticsWebsiteVisits,
  AnalyticsTrafficBySite,
  AnalyticsCurrentSubject,
  AnalyticsConversionRates
} from '../../components/_dashboard/general-analytics';
import { firestore } from 'src/contexts/FirebaseContext';

// ----------------------------------------------------------------------

const analyticsC = createContext({ data: [] });

export const useAnalytics = () => useContext(analyticsC);

export default function GeneralAnalytics() {
  const { themeStretch } = useSettings();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    async function getEvents() {
      await firestore.collection('events').onSnapshot((queryS) => {
        const eventss = [];
        queryS.forEach((doc) => {
          eventss.push({ id: doc.id, ...doc.data() });
        });
        setEvents(eventss);
      });
    }
    getEvents();
  }, []);

  return (
    <analyticsC.Provider value={{ data: events }}>
      <Page title="General: Analytics | Bunka">
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Hi, Welcome back</Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <AnalyticsWeeklySales />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AnalyticsNewUsers />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AnalyticsItemOrders />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AnalyticsBugReports />
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
              <AnalyticsWebsiteVisits />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <AnalyticsCurrentVisits />
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
              <AnalyticsConversionRates />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <AnalyticsCurrentSubject />
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
              <AnalyticsNewsUpdate />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <AnalyticsOrderTimeline />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <AnalyticsTrafficBySite />
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
              <AnalyticsTasks />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </analyticsC.Provider>
  );
}
