import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Chip,
  Grid,
  Stack,
  Radio,
  Switch,
  Select,
  TextField,
  InputLabel,
  Typography,
  RadioGroup,
  FormControl,
  Autocomplete,
  InputAdornment,
  FormHelperText,
  FormControlLabel
} from '@mui/material';
// utils
import { fData, fPercent } from 'src/utils/formatNumber';
import { v1 as uuidv1 } from 'uuid';
//
import { QuillEditor } from '../../editor';
import { UploadMultiFile } from '../../upload';
import { firestore, storage as st } from 'src/contexts/FirebaseContext';

// ----------------------------------------------------------------------

const PAY_OPTION = ['anual', 'mensual', 'pago único'];

const CATEGORY_OPTION = [
  { group: 'Clothing', classify: ['Shirts', 'T-shirts', 'Jeans', 'Leather'] },
  { group: 'Tailored', classify: ['Suits', 'Blazers', 'Trousers', 'Waistcoats'] },
  { group: 'Accessories', classify: ['Shoes', 'Backpacks and bags', 'Bracelets', 'Face masks'] }
];

const TAGS_OPTION = [
  'Toy Story 3',
  'Logan',
  'Full Metal Jacket',
  'Dangal',
  'The Sting',
  '2001: A Space Odyssey',
  "Singin' in the Rain",
  'Toy Story',
  'Bicycle Thieves',
  'The Kid',
  'Inglourious Basterds',
  'Snatch',
  '3 Idiots'
];

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

// ----------------------------------------------------------------------

ProductNewForm.propTypes = {
  isEdit: PropTypes.bool,
  iddoc: PropTypes.string
};

export default function ProductNewForm({ isEdit = false, iddoc }) {
  const { enqueueSnackbar } = useSnackbar();
  const [uuidS, setUUID] = useState('');
  const [files, setFiles] = useState([]);
  const [filesF, setFilesF] = useState('');
  const [pdata, setPData] = useState('');
  const [percentS, setPF] = useState('');

  useEffect(() => {
    setUUID(uuidv1());
  }, []);

  const NewProductSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    images: Yup.array(),
    price: Yup.number().required('Price is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      description: '',
      images: [],
      code: '',
      sku: '',
      price: '',
      priceSale: '',
      tags: [],
      color: '#FF0000',
      inStock: false,
      taxes: true,
      payType: 'mensual',
      category: CATEGORY_OPTION[0].classify[1] || ''
    },
    validationSchema: NewProductSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        const data = values;
        data.images = await uploadMedia();
        if (isEdit) {
          await firestore
            .collection('productos')
            .doc(iddoc)
            .set({ ...data, updatedAt: new Date() });
        } else {
          await firestore
            .collection('productos')
            .doc(uuidS)
            .set({ ...data, createdAt: new Date() });
          resetForm();
        }
        setSubmitting(false);
        enqueueSnackbar(!isEdit ? 'Create success' : 'Update success', { variant: 'success' });
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) => {
          return { file, preview: URL.createObjectURL(file) };
        })
      );
    },
    [setFiles]
  );

  const uploadAnImage = useCallback(
    async (file = null, index = 0) => {
      return new Promise((resolve, reject) => {
        const refF = st.ref().child(`products/${iddoc || uuidS}/${index}`);
        if (file !== null) {
          refF.put(file).on('next', (snapshot) => {
            const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setPF(percent);
            setPData(`${fData(snapshot.bytesTransferred)} / ${fData(snapshot.totalBytes)}  (${fPercent(percent)})`);
            setFilesF(`imagenes ${index + 1}/${files.length}`);
            if (percent === 100) {
              refF.getDownloadURL().then((url) => {
                setTimeout(() => resolve(url), 1500);
              });
            }
          });
        } else {
          refF
            .getDownloadURL()
            .then((url) => {
              resolve(url);
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },
    [files, iddoc, uuidS]
  );

  const uploadMedia = useCallback(async () => {
    const links = [];
    for (let i = 0; i < files.length; i++) {
      links.push(await uploadAnImage(files[i].file, i));
    }
    return new Promise((resolve) => {
      console.log('uploaded files:', links);
      resolve(links);
    });
  }, [uploadAnImage, files]);

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const handleRemove = (file) => {
    const filteredItems = files.filter((_file) => _file !== file);
    setFiles(filteredItems);
  };

  useEffect(() => {
    async function getData() {
      await firestore
        .collection('productos')
        .doc(iddoc)
        .get()
        .then((doc) => {
          const {
            name,
            description,
            tags,
            images,
            sku,
            code,
            price,
            priceSale,
            taxes,
            payType,
            category,
            inStock,
            color,
            productId
          } = doc.data();
          setFieldValue('name', name);
          setFieldValue('description', description);
          setFieldValue('tags', tags);
          setFieldValue('images', images);
          setFiles(
            images.map((url) => {
              return { file: null, preview: url };
            })
          );
          setFieldValue('sku', sku);
          setFieldValue('code', code);
          setFieldValue('price', price);
          setFieldValue('priceSale', priceSale);
          setFieldValue('taxes', taxes);
          setFieldValue('payType', payType || 'mensual');
          setFieldValue('category', category);
          setFieldValue('inStock', inStock);
          setFieldValue('color', color || '#FF0000');
          setFieldValue('productId', productId || '');
        });
    }
    if (isEdit && iddoc) {
      getData();
    }
    // eslint-disable-next-line
  }, [isEdit, iddoc]);

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="Product Name"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />

                <div>
                  <LabelStyle>Description</LabelStyle>
                  <QuillEditor
                    simple
                    id="product-description"
                    value={values.description}
                    onChange={(val) => setFieldValue('description', val)}
                    error={Boolean(touched.description && errors.description)}
                  />
                  {touched.description && errors.description && (
                    <FormHelperText error sx={{ px: 2 }}>
                      {touched.description && errors.description}
                    </FormHelperText>
                  )}
                </div>

                <div>
                  <LabelStyle>Add Images</LabelStyle>
                  <UploadMultiFile
                    showPreview
                    maxSize={31457280}
                    accept="image/*"
                    files={files}
                    onDrop={handleDrop}
                    onRemove={handleRemove}
                    onRemoveAll={handleRemoveAll}
                  />
                  {touched.images && errors.images && (
                    <FormHelperText error sx={{ px: 2 }}>
                      {touched.images && errors.images}
                    </FormHelperText>
                  )}
                  <div>
                    <Typography>{filesF}</Typography>
                    <Typography>{pdata}</Typography>
                    <Typography>{fPercent(percentS)}</Typography>
                  </div>
                </div>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Stack spacing={3}>
              <Card sx={{ p: 3 }}>
                <FormControlLabel
                  control={<Switch {...getFieldProps('inStock')} checked={values.inStock} />}
                  label="In stock"
                  sx={{ mb: 2 }}
                />

                <Stack spacing={3}>
                  <TextField fullWidth label="Color" type="color" {...getFieldProps('color')} />
                  <TextField fullWidth label="Product Code" {...getFieldProps('code')} />
                  <TextField fullWidth label="Product SKU" {...getFieldProps('sku')} />

                  <div>
                    <LabelStyle>Tipo de pago</LabelStyle>
                    <RadioGroup {...getFieldProps('gender')} row>
                      <Stack spacing={1} direction="row">
                        {PAY_OPTION.map((pay) => (
                          <FormControlLabel key={pay} value={pay} control={<Radio />} label={pay} />
                        ))}
                      </Stack>
                    </RadioGroup>
                  </div>

                  <FormControl fullWidth>
                    <InputLabel>Category</InputLabel>
                    <Select label="Category" native {...getFieldProps('category')} value={values.category}>
                      {CATEGORY_OPTION.map((category) => (
                        <optgroup key={category.group} label={category.group}>
                          {category.classify.map((classify) => (
                            <option key={classify} value={classify}>
                              {classify}
                            </option>
                          ))}
                        </optgroup>
                      ))}
                    </Select>
                  </FormControl>
                  <Autocomplete
                    multiple
                    freeSolo
                    value={values.tags}
                    onChange={(event, newValue) => {
                      setFieldValue('tags', newValue);
                    }}
                    options={TAGS_OPTION.map((option) => option)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip {...getTagProps({ index })} key={option} size="small" label={option} />
                      ))
                    }
                    renderInput={(params) => <TextField label="Tags" {...params} />}
                  />
                </Stack>
              </Card>

              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    placeholder="0.00"
                    label="Regular Price"
                    {...getFieldProps('price')}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      type: 'number'
                    }}
                    error={Boolean(touched.price && errors.price)}
                    helperText={touched.price && errors.price}
                  />

                  <TextField
                    fullWidth
                    placeholder="0.00"
                    label="Sale Price"
                    {...getFieldProps('priceSale')}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      type: 'number'
                    }}
                  />
                </Stack>

                <FormControlLabel
                  control={<Switch {...getFieldProps('taxes')} checked={values.taxes} />}
                  label="Price includes taxes"
                  sx={{ mt: 2 }}
                />
              </Card>

              <LoadingButton type="submit" fullWidth variant="contained" size="large" loading={isSubmitting}>
                {!isEdit ? 'Create Product' : 'Save Changes'}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
