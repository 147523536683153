import React from 'react';
import { Card, CardContent, CardHeader, Box, Button } from '@mui/material';
import { CTAVideo, CTAImg, CTAIframe } from './ctamedias';
import AltaCTAD from './AltaCTAD';
import AsistenteCTA from './AsistenteCTA';
import PropTypes from 'prop-types';

CTAElement.propTypes = {
  CTA: PropTypes.shape({
    id: PropTypes.string,
    titulo: PropTypes.string,
    descripcion: PropTypes.string,
    mediaType: PropTypes.string,
    urlMedia: PropTypes.string,
    etapa: PropTypes.string,
    show: PropTypes.bool
  }),
  gCTAs: PropTypes.array,
  defaultModule: PropTypes.string,
  onSelect: PropTypes.func
};

export default function CTAElement({ CTA, gCTAs = [], defaultModule, onSelect }) {
  const { id, titulo, mediaType, urlMedia, etapa, show } = CTA;
  const media = {
    iframe: <CTAIframe src={urlMedia} />,
    image: <CTAImg src={urlMedia} />,
    video: <CTAVideo src={urlMedia} />
  };
  return (
    <Card>
      <CardHeader
        title={titulo}
        action={
          <>
            <AltaCTAD edit iddoc={id} btn />
            {/* <AsistenteCTA ids={gCTAs} id={id} defaultModule={defaultModule} /> */}
            {onSelect && (
              <Button variant="contained" onClick={onSelect}>
                Seleccionar
              </Button>
            )}
          </>
        }
      />
      <CardContent>
        <Box>etapa: {etapa}</Box>
        <Box>mostrar: {show ? <>si</> : <>No</>}</Box>
        {media[mediaType]}
      </CardContent>
    </Card>
  );
}
