import React, { useState, useEffect } from 'react';
import { ListItem, ListItemText, ListItemIcon, Dialog, DialogContent, OutlinedInput, Typography } from '@mui/material';
import { styled } from '@mui/styles';
// icon
import { Icon } from '@iconify/react';
import profile from '@iconify/icons-ant-design/profile-fill';
//
import { useDispatch } from 'src/redux/store';
import { getBoard } from 'src/redux/slices/kanbanentregables';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

// -------------------------------------------------------------

const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'left'
});

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 18,
  flexShrink: 0,
  color: theme.palette.text.secondary
}));

// -------------------------------------------------------------

DepartamentoD.propTypes = {
  idEmpresa: PropTypes.string,
  cardId: PropTypes.string,
  card: PropTypes.object
};

export default function DepartamentoD({ idEmpresa, cardId, card }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [categoria, setCategoria] = useState(card.categoria || '');

  useEffect(() => {
    setCategoria(card.categoria || '');
  }, [card]);

  const handleCategoriaChange = async (event) => {
    setCategoria(event.target.value);
    setTimeout(async () => {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanCardsentregables')
        .doc(cardId)
        .update({ categoria: event.target.value })
        .then(() => {
          dispatch(getBoard(idEmpresa));
        });
    }, 1000);
  };

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
        <ListItemIcon>
          <Icon icon={profile} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Categoria</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose}>
        <DialogContent>
          <RowStyle>
            <LabelStyle>Categoaria</LabelStyle>
            <OutlinedInput
              value={categoria}
              onChange={handleCategoriaChange}
              sx={{
                typography: 'h6',
                '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' }
              }}
            />
          </RowStyle>
        </DialogContent>
      </Dialog>
    </>
  );
}
