import React, { useState, useEffect } from 'react';
import UserCard from 'src/components/_dashboard/user/cards/UserCard';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

ProfileC.propTypes = {
  userid: PropTypes.string
};

export default function ProfileC({ userid }) {
  const [userI, setUserI] = useState({});

  useEffect(() => {
    async function getUI() {
      await firestore
        .collection('users')
        .doc(userid)
        .onSnapshot((doc) => {
          setUserI({ id: doc.id, ...doc.data() });
        });
    }
    getUI();
  }, [userid]);
  return <UserCard user={userI} />;
}
