import React from 'react';
import { Card, List } from '@mui/material';
import { NewTeamForm } from 'src/components/_dashboard/equipos';
import PropTypes from 'prop-types';

MenuEquipos.propTypes = {
  idEmpresa: PropTypes.string
};

export default function MenuEquipos({ idEmpresa }) {
  return (
    <Card>
      <List>
        <NewTeamForm idEmpresa={idEmpresa} listB />
      </List>
    </Card>
  );
}
