import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
// material
import {
  Box,
  Card,
  Menu,
  Stack,
  Divider,
  Checkbox,
  MenuItem,
  CardHeader,
  CardContent,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogContent,
  Grid
} from '@mui/material';
import { DateTimePicker } from '@mui/lab';
import { Add } from '@mui/icons-material';
//
import { firestore } from 'src/contexts/FirebaseContext';

import { MIconButton } from '../../@material-extend';
import { v1 as uuidv1 } from 'uuid';
import useAuth from 'src/hooks/useAuth';
import { parseDate } from 'src/utils/formatTime';

const moment = require('moment');

// ----------------------------------------------------------------------

MoreMenuButton.propTypes = {
  taskid: PropTypes.string,
  collection: PropTypes.string,
  docId: PropTypes.string
};

function MoreMenuButton({ collection, docId, taskid }) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleComplete = async () => {
    await firestore.collection(collection).doc(docId).collection('todotasks').doc(taskid).update({ done: true });
  };

  const handleDelete = async () => {
    if (window.confirm('desea eliminar esta tarea?')) {
      await firestore.collection(collection).doc(docId).collection('todotasks').doc(taskid).delete();
    }
  };

  return (
    <>
      <MIconButton ref={anchorRef} size="large" onClick={handleOpen}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </MIconButton>

      <Menu
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={handleComplete}>
          <Icon icon={checkmarkCircle2Fill} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Marcar Como Completada
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem sx={{ color: 'error.main' }} onClick={handleDelete}>
          <Icon icon={trash2Outline} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Eliminar
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

TaskItem.propTypes = {
  task: PropTypes.object,
  collection: PropTypes.string,
  docId: PropTypes.string
};

function TaskItem({ task, collection, docId, ...other }) {
  const { name, done, id, fechaE } = task;
  const [edit, setEdit] = useState(false);
  const [completed, setCompleted] = useState(done || false);
  const [nombre, setNombre] = useState(name || '');

  const dateE = parseDate(fechaE) || new Date();

  useEffect(() => {
    setNombre(name || '');
    setCompleted(done);
  }, [name, done]);

  const setDone = async (newValue) => {
    await firestore.collection(collection).doc(docId).collection('todotasks').doc(id).update({ done: newValue });
  };

  const setName = async () => {
    await firestore.collection(collection).doc(docId).collection('todotasks').doc(id).update({ name: nombre });
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{ py: 0.75, ...(moment().toDate() > dateE && !done && { backgroundColor: 'error.main' }) }}
    >
      <Checkbox
        checked={completed}
        onChange={(e) => {
          setDone(e.target.checked);
          setCompleted(e.target.completed);
        }}
        {...other}
      />
      {!edit && (
        <Box onClick={() => setEdit(true)} sx={{ width: '100%', textAlign: 'left', pt: '10px' }}>
          <Typography
            variant="body2"
            sx={{
              ...(done && {
                color: 'text.disabled',
                textDecoration: 'line-through'
              }),
              width: '100%',
              textAlign: 'left'
            }}
          >
            {name}
          </Typography>
        </Box>
      )}
      {edit && (
        <TextField
          fullWidth
          value={nombre}
          onChange={(e) => setNombre(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              setEdit(false);
            }
            if (e.key === 'Enter') {
              setName();
              setEdit(false);
            }
          }}
          autoFocus
        />
      )}
      <Typography>{moment(dateE).format('LLL')}</Typography>
      <MoreMenuButton taskid={id} collection={collection} docId={docId} />
    </Stack>
  );
}

TodoTasks.propTypes = {
  collection: PropTypes.string,
  docId: PropTypes.string
};

export function TodoTasks({ collection, docId }) {
  const { user } = useAuth();
  const [TASKS, setTasks] = useState([]);
  const [taskName, setTaskName] = useState('');
  const [fecha, setFecha] = useState(new Date());

  useEffect(() => {
    async function getTasks() {
      await firestore
        .collection(collection)
        .doc(docId)
        .collection('todotasks')
        .onSnapshot((queryS) => {
          const taskss = [];
          queryS.forEach((doc) => {
            taskss.push({ id: doc.id, ...doc.data() });
          });
          setTasks(taskss);
        });
    }
    getTasks();
  }, [collection, docId]);

  const addTask = async () => {
    const taskNamec = taskName;
    setTaskName('');
    await firestore
      .collection(collection)
      .doc(docId)
      .collection('todotasks')
      .doc(uuidv1())
      .set({ userid: user.id, name: taskNamec, done: false, fechaE: fecha });
  };

  return (
    <Card>
      <CardHeader title="Tareas" />
      <CardContent>
        <Box sx={{ px: 3, py: 1 }}>
          {TASKS.map((task) => (
            <TaskItem key={task.id} task={task} collection={collection} docId={docId} />
          ))}
        </Box>
        <Box sx={{ px: 2, py: 1 }}>
          <Typography>Nueva Tarea</Typography>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  label="Nombre de la Tarea"
                  value={taskName}
                  onChange={(e) => setTaskName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      addTask();
                    }
                  }}
                  sx={{ margin: '2%' }}
                />
              </Grid>
              <Grid item xs={3}>
                <DateTimePicker
                  value={fecha}
                  onChange={(e) => setFecha(e)}
                  inputFormat="dd/MM/yyyy hh:mm a"
                  renderInput={(props) => (
                    <TextField {...props} fullWidth label="Fecha de entrega" sx={{ margin: '2%' }} />
                  )}
                />
              </Grid>
            </Grid>
            <Button variant="contained" onClick={addTask} sx={{ margin: '2%' }}>
              <Add />
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

TodoTaskDialog.propTypes = {
  collection: PropTypes.string,
  docId: PropTypes.string,
  showB: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.func
};

function TodoTaskDialog({ collection, docId, showB = false, open = false, setOpen }) {
  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      {showB && <Button onClick={clickOpen}>Tareas</Button>}
      <Dialog open={open} onClose={clickClose} maxWidth={1200}>
        <Box sx={{ width: '900px' }} />
        <DialogContent>
          <TodoTasks collection={collection} docId={docId} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default TodoTaskDialog;
