import { useContext } from 'react';
import { memberContext } from 'src/contexts/MemberContext';
import { parseDate } from 'src/utils/formatTime';
import { firestore } from 'src/contexts/FirebaseContext';

const moment = require('moment');

const usePlan = () => useContext(memberContext);

export default usePlan;

// used tokens

export function getUsedTokens(module, modules) {
  const plan = modules[module];
  return plan?.usedTokens || 0;
}

// tokens

export function getTokens(module, modules) {
  const plan = modules[module];
  return plan?.tokens || 0;
}

// has contrated

export function hasContrated(module, modules) {
  const plan = modules[module];
  return plan?.contratadoOnce || false;
}

// is contrated

export function isContrated(module, modules) {
  const plan = modules[module];
  return plan?.contratado || false;
}

// expires

export function getExpireDate(module, modules) {
  const plan = modules[module];
  if (plan) {
    return parseDate(plan.expires);
  }
  return true;
}

export function isExpired(module, modules) {
  const today = new Date();
  const plan = modules[module];
  if (plan) {
    return today > parseDate(plan.expires);
  }
  return true;
}

// autorizar

export function isAuthorized(module, modules) {
  const expired = isExpired(module, modules);
  const contratado = isContrated(module, modules);
  return !expired && contratado;
}

export function isAuthorizedL(module, modules) {
  const limit = getLimit(module, modules);
  const contratado = isContrated(module, modules);
  return limit !== 0 && contratado;
}

// contratar

export async function contratarModuloByDate(module, modules, userid, days) {
  // instancias temporales
  const modulesT = modules;
  const modulo = modules[module];
  // cambiar estado del expire date
  const expDate = parseDate(modulo.expires);
  const today = new Date();
  if (today > expDate) {
    modulo.expires = moment().add('day', days).toDate();
  } else {
    modulo.expires = moment(expDate).add('day', days).toDate();
  }
  // cambiar estados del modulo
  modulo.contratado = true;
  modulo.contratadoOnce = true;
  modulesT[module] = modulo;

  await firestore.collection('plans').doc(userid).update(modulesT);
}

export async function contratarModulo(module, modules, userid) {
  // instancias temporales
  const modulesT = modules;
  const modulo = modules[module];
  // cambiar estados del modulo
  modulo.contratado = true;
  modulo.contratadoOnce = true;
  modulesT[module] = modulo;

  await firestore.collection('plans').doc(userid).update(modulesT);
}

// cancelar plan

export async function cancelarModulo(module, modules, userid) {
  // instancias temporales
  const modulesT = modules;
  const modulo = modules[module];
  // cambiar estado del expire date
  const today = new Date();
  // cambiar estados del modulo
  modulo.contratado = false;
  modulo.expires = today;
  modulesT[module] = modulo;
  await firestore.collection('plans').doc(userid).update(modulesT);
}

// extender plan

export async function extendPlan(module, modules, userid, cantidad, tipo = 'day') {
  // instancias temporales
  const modulesT = modules;
  const modulo = modules[module];
  // cambiar estado del expire date
  const expDate = parseDate(modulo.expires);
  const today = new Date();
  if (today > expDate) {
    modulo.expires = moment().add(tipo, cantidad).toDate();
  } else {
    modulo.expires = moment(expDate).add(tipo, cantidad).toDate();
  }
  // cambiar estados del modulo
  modulo.contratado = true;
  modulo.contratadoOnce = true;
  modulesT[module] = modulo;

  await firestore.collection('plans').doc(userid).update(modulesT);
}

export function getLimit(module, modules) {
  const modulo = modules[module];
  return modulo?.limit || 0;
}

export async function setLimit(module, modules, userid, num) {
  // instancias temporales
  const modulesT = modules;
  const modulo = modules[module];
  // cambio de valor
  modulo.limit = num;
  if (num === 0) {
    modulo.contratado = false;
  }
  modulesT[module] = modulo;
  await firestore.collection('plans').doc(userid).update(modulesT);
}

// evaluaciones alternativas

export function isPremium(premium = false, expiresDate = new Date()) {
  const today = moment().toDate();
  const dateE = parseDate(expiresDate);
  return premium && dateE > today;
}

// criterio text

export function getState(module, modules) {
  const expired = isExpired(module, modules);
  const contratado = isContrated(module, modules);
  const contratadoOnce = hasContrated(module, modules);
  if (!expired && contratado && contratadoOnce) {
    return 'contratado';
  }
  if (expired && contratado && contratadoOnce) {
    return 'expirado';
  }
  return 'no_contratado';
}

// CTA Managment

export function getCTAState(module, modules) {
  return modules[module]?.state || 'atraccion';
}

export function getCTAStateAlt(premium, expiresP) {
  const today = new Date();
  const expD = parseDate(expiresP);
  const expired = today > expD;
  if (!premium && !expired) {
    return 'atraccion';
  }
  if (!expired) {
    return 'engage';
  }
  return 'deleitar';
}

export function getNextState(state) {
  switch (state) {
    case 'atraccion':
      return 'engage';
    case 'engage':
      return 'deleitar';
    case 'deleitar':
      return 'atraccion';
    default:
      return 'atraccion';
  }
}

export function changeCTAState(module, modules, userid) {
  const modulesT = modules;
  const modulo = modulesT[module];
  const newState = getNextState(modulo.state);
  modulo.state = newState;
  modulesT[module] = modulo;
  firestore.collection('stateCTA').doc(userid).update(modulesT);
}

export function addTagCTAState(module, modules, userid, tag) {
  const modulesT = modules;
  const modulo = modules[module];
  const newTags = modulo?.tags || [];
  if (!newTags.includes(tag)) {
    newTags.push(tag);
  }
  modulo.tags = newTags;
  modulesT[module] = modulo;
  firestore.collection('stateCTA').doc(userid).update(modulesT);
  firestore.collection('stateCTA').doc(userid).collection('tags').add({ tag, fecha: new Date(), module });
}

export async function excludeCTA(ctaid, modules, userid) {
  const modulesT = modules;
  const excluded = modulesT.excluded || [];
  excluded.push(ctaid);
  modulesT.excluded = excluded;
  firestore.collection('stateCTA').doc(userid).update(modulesT);
}
