import React, { useState } from 'react';
import { ListItem, ListItemText, ListItemIcon, Dialog, DialogContent, Button } from '@mui/material';
import AltaCTA from './AltaCTA';
// icons
import { Icon } from '@iconify/react';
import addI from '@iconify/icons-carbon/add';
//
import PropTypes from 'prop-types';

AltaCTAD.propTypes = {
  iddoc: PropTypes.string,
  edit: PropTypes.bool,
  listB: PropTypes.bool,
  btn: PropTypes.bool,
  defaultModule: PropTypes.string
};

export default function AltaCTAD({ edit, btn = false, listB = false, iddoc, defaultModule }) {
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      {listB && (
        <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
          <ListItemIcon>
            <Icon icon={addI} width={23} height={23} />
          </ListItemIcon>
          <ListItemText>Alta de CTA</ListItemText>
        </ListItem>
      )}
      {btn && (
        <Button varient="contained" onClick={clickOpen}>
          {edit ? <>Editar</> : <>Alta de CTA</>}
        </Button>
      )}
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <DialogContent>
          <AltaCTA edit={edit} iddoc={iddoc} defaultModule={defaultModule} reset />
        </DialogContent>
      </Dialog>
    </>
  );
}
