import React from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import PropTypes from 'prop-types';

StepThree.propTypes = {
  next: PropTypes.func
};

export default function StepThree({ next }) {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1" sx={{ textAlign: 'center' }}>
            Asistente de plan estratético
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box component="p" sx={{ fontSize: '30px' }}>
            Este Asistente te ayudará a definir las parte fundamentales de tu organización: Visión, Valores y puntos de
            enfoque
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ float: 'right', padding: '3%', margin: '2%' }}>
        <Button variant="contained" onClick={next} sx={{ margin: '2%' }}>
          Siguiente
        </Button>
      </Box>
    </>
  );
}
