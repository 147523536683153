import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
// material
import { Typography } from '@mui/material';
//
// import { varFadeInUp } from './variants';

// ----------------------------------------------------------------------

TextAnimate.propTypes = {
  text: PropTypes.string,
  // variants: PropTypes.object,
  sx: PropTypes.object
};

export default function TextAnimate({ text, sx, ...other }) {
  return (
    <Typography
      component={motion.h1}
      sx={{
        typography: 'h1',
        overflow: 'hidden',
        display: 'flex',
        width: '100%',
        ...sx
      }}
      {...other}
    >
      {text}
    </Typography>
  );
}
