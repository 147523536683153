import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';
import AddProyecto from './AddProyecto';
import { firestore } from 'src/contexts/FirebaseContext';
import { AltaProyecto } from 'src/components/_dashboard/entorno';
import { ProyectItem } from 'src/components/_dashboard/general-app/ProyectosFav';
import PropTypes from 'prop-types';

ProyectoRow.propTypes = {
  idProyect: PropTypes.string
};

function ProyectoRow({ idProyect }) {
  const [proyecto, setProyecto] = useState({});

  useEffect(() => {
    async function getPD() {
      await firestore
        .collection('proyectosempresa')
        .doc(idProyect)
        .onSnapshot((doc) => {
          setProyecto({ id: doc.id, ...doc.data() });
        });
    }
    getPD();
  }, [idProyect]);

  return (
    <TableRow>
      <TableCell>
        <ProyectItem proyecto={proyecto} allowInteraction />
      </TableCell>
    </TableRow>
  );
}

ProyectosAsociados.propTypes = {
  idEmpresa: PropTypes.string,
  idP: PropTypes.string,
  cardId: PropTypes.string
};

export default function ProyectosAsociados({ idEmpresa, idP, cardId }) {
  const [openAP, setOpenAP] = useState(false);
  const [proyects, setProyects] = useState([]);

  useEffect(() => {
    async function getPs() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('perfiles')
        .doc(idP)
        .collection('kanbanCardshab')
        .doc(cardId)
        .onSnapshot((doc) => {
          setProyects(doc.data().proyectosA || []);
        });
    }
    getPs();
  }, [idEmpresa, idP, cardId]);

  const onCreateP = async (idProyect) => {
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(idP)
      .collection('kanbanCardshab')
      .doc(cardId)
      .get()
      .then((doc) => {
        const proyectosA = doc.data().proyectosA || [];
        firestore
          .collection('empresas')
          .doc(idEmpresa)
          .collection('kanbanCardshab')
          .doc(cardId)
          .update({ proyectosA: [...proyectosA, idProyect] });
      });
  };

  return (
    <Card>
      <CardHeader
        title="Proyectos alienados"
        action={
          <>
            <AltaProyecto idEmpresa={idEmpresa} open={openAP} setOpen={setOpenAP} showB onCreateNew={onCreateP} />
            <AddProyecto idEmpresa={idEmpresa} cardId={cardId} proyectosA={proyects} />
          </>
        }
      />
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Proyecto</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {proyects.map((p) => (
                <ProyectoRow key={p} idProyect={p} idEmpresa={idEmpresa} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
