import React, { createContext, useState, useEffect } from 'react';
import { firestore } from './FirebaseContext';
import PropTypes from 'prop-types';

// ---------------------------------------------------------------

export const permissionsContext = createContext({ permisos: {} });

// ---------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

PermissionsC.propTypes = {
  children: PropTypes.any,
  idEmpresa: PropTypes.string
};

export default function PermissionsC({ children, idEmpresa }) {
  const [perfiles, setPerfiles] = useState([]);
  const [permisos, setPermisos] = useState({});

  useEffect(() => {
    async function getPerfiles() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('permisos')
        .onSnapshot((queryS) => {
          const perfs = [];
          queryS.forEach((doc) => {
            perfs.push({ id: doc.id, ...doc.data() });
          });
          setPerfiles(perfs);
        });
    }
    getPerfiles();
  }, [idEmpresa]);

  useEffect(() => {
    setPermisos(objFromArray(perfiles));
  }, [perfiles]);

  return <permissionsContext.Provider value={{ permisos }}>{children}</permissionsContext.Provider>;
}
