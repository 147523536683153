import React, { useState } from 'react';
import { Dialog, DialogContent, Avatar, IconButton, Box, Button, AppBar, Toolbar, DialogActions } from '@mui/material';
import { Icon } from '@iconify/react';
import closefill from '@iconify/icons-eva/close-fill';
import { Close } from '@mui/icons-material';
import UserCard from 'src/components/_dashboard/user/cards/UserCard';
import { alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';

KanbanUser.propTypes = {
  user: PropTypes.object.isRequired,
  removeUser: PropTypes.func.isRequired
};

function KanbanUser({ user, removeUser }) {
  const [open, setOpen] = useState(false);
  const [openDC, setOpenDC] = useState(false);

  // metodos
  const clickOpen = () => {
    setOpen(true);
  };
  const clickClose = () => {
    setOpen(false);
  };
  const clickOpenDC = () => {
    setOpenDC(true);
  };
  const clickCloseDC = () => {
    setOpenDC(false);
  };

  return (
    <>
      <Box>
        <IconButton onClick={clickOpen} key={user.id}>
          <Avatar alt={user.name} src={user.avatar} sx={{ m: 0.5, width: 36, height: 36 }} />
        </IconButton>
        <IconButton
          size="small"
          onClick={clickOpenDC}
          sx={{
            p: '2px',
            top: '-10px',
            right: '25px',
            color: 'common.white',
            position: 'relative',
            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48)
            }
          }}
        >
          <Icon icon={closefill} />
        </IconButton>
      </Box>
      <Dialog open={open} onClose={clickClose}>
        <DialogContent>
          <UserCard user={user} />
        </DialogContent>
      </Dialog>
      <Dialog open={openDC} onClose={clickCloseDC}>
        <AppBar position="relative">
          <Toolbar>
            <Button onClick={clickCloseDC}>
              <Close />
            </Button>
            <Box>
              <h3>Remover usuário</h3>
            </Box>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box>
            <p>¿Esta seguro que desea Remover a este usuario?</p>
          </Box>
          <DialogActions>
            <Button onClick={clickCloseDC}>Cancelar</Button>
            <Button onClick={() => removeUser(user)}>Eliminar</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default KanbanUser;
