import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import {
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  IconButton,
  Typography,
  AppBar,
  Toolbar,
  Grid,
  TextField,
  FormHelperText
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { QuillEditor } from '../../editor';
import { firestore } from 'src/contexts/FirebaseContext';
// redux
import { useDispatch } from '../../../redux/store';
import { createColumn, getBoard } from '../../../redux/slices/kobjs';
import PropTypes from 'prop-types';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

AltaPuntoF.propTypes = {
  projectId: PropTypes.string.isRequired,
  columnId: PropTypes.string.isRequired,
  edit: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default function AltaPuntoF({ projectId, columnId, edit = false, open = false, onClose }) {
  const dispatch = useDispatch();

  const clickClose = () => {
    onClose();
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      descripcion: '',
      color: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required('requerido')
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        if (edit) {
          await firestore
            .collection('kobjsColunms')
            .doc(columnId)
            .update({ ...values });
          dispatch(getBoard(projectId));
        } else {
          dispatch(createColumn({ ...values, pid: projectId }));
          resetForm();
          onClose();
        }
      } catch (error) {
        setSubmitting(false);
        console.log(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  useEffect(() => {
    async function getInfo() {
      await firestore
        .collection('kobjsColunms')
        .doc(columnId)
        .get()
        .then((doc) => {
          const { name, descripcion, color } = doc.data();
          setFieldValue('name', name);
          setFieldValue('descripcion', descripcion || '');
          setFieldValue('color', color || '');
        });
    }
    if (edit) {
      getInfo();
    }
    // eslint-disable-next-line
  }, [edit, columnId]);

  return (
    <>
      <Dialog open={open} onClose={clickClose}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton onClick={clickClose}>
              <Close />
            </IconButton>
            <Typography>Punto de enfoque</Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Focus area"
                      {...getFieldProps('name')}
                      error={Boolean(touched.focusarea && errors.focusarea)}
                      helperText={touched.focusarea && errors.focusarea}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div>
                      <LabelStyle>descripcion (Opcional)</LabelStyle>
                      <QuillEditor
                        id="pf-descripcion"
                        value={values.descripcion}
                        onChange={(val) => setFieldValue('descripcion', val)}
                        error={Boolean(touched.descripcion && errors.descripcion)}
                      />
                      {touched.descripcion && errors.descripcion && (
                        <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                          {touched.descripcion && errors.descripcion}
                        </FormHelperText>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Color"
                      {...getFieldProps('color')}
                      helperText={touched.color && errors.color}
                      error={Boolean(touched.color && errors.color)}
                    />
                  </Grid>
                </Grid>
              </Box>
              <DialogActions>
                <LoadingButton fullWidth type="submit" variant="contained" loading={isSubmitting}>
                  Guardar
                </LoadingButton>
              </DialogActions>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Dialog>
    </>
  );
}
