import React, { useState, useEffect, useCallback } from 'react';
import { IconButton, Dialog, DialogContent, AppBar, Toolbar, Box, Grid, Card, List } from '@mui/material';
import { Close } from '@mui/icons-material';
import CopyClipboard from 'src/components/CopyClipboard';
import { v1 as uuidv1 } from 'uuid';
import { firestore } from 'src/contexts/FirebaseContext';
import { CTAShow, CTANav } from 'src/components/_dashboard/ctas';
import { parseDate } from 'src/utils/formatTime';
// import InvitationList from './InvitationsList';
import { ChooseCTA } from 'src/components/_dashboard/asistentemain/other';
import { isPremium, getCTAStateAlt } from 'src/hooks/usePlan';
import useAuth from 'src/hooks/useAuth';
import PropTypes from 'prop-types';

const moment = require('moment');

InvitationGenerator.propTypes = {
  idEmpresa: PropTypes.string,
  empresa: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};

function InvitationGenerator({ idEmpresa, empresa, open = false, setOpen }) {
  const { user } = useAuth();
  const { membersids, premium, expiresP } = empresa;
  const modulo = 'empresa';
  const allowPAcces = isPremium(premium, expiresP);
  const [invitation, setInvitation] = useState('');
  const [uuid, setUuid] = useState('');

  const etapaCTA = getCTAStateAlt(premium, expiresP);

  useEffect(() => {
    setUuid(uuidv1());
  }, []);

  const crearInvitacion = useCallback(async () => {
    const data = {
      idEmpresa,
      uuid,
      caducidad: moment().add(1, 'days').toDate(),
      utilizado: false
    };
    await firestore.collection('invitaciones').doc(uuid).set(data);
    setInvitation(`${window.location.origin}/invitacion/${uuid}`);
  }, [idEmpresa, uuid]);

  const deleteInvitations = useCallback(async () => {
    await firestore
      .collection('invitaciones')
      .where('idEmpresa', '==', idEmpresa)
      .onSnapshot((queryS) => {
        const today = new Date();
        queryS.forEach((doc) => {
          const invitationT = doc.data();
          if (
            invitationT.utilizado ||
            parseDate(invitationT.caducidad) < today ||
            (!premium && membersids.length > 3)
          ) {
            firestore.collection('invitaciones').doc(doc.id).delete();
          }
        });
      });
  }, [idEmpresa, membersids, premium]);

  useEffect(() => {
    deleteInvitations();
  }, [deleteInvitations]);

  useEffect(() => {
    if (open) {
      crearInvitacion();
    }
  }, [crearInvitacion, open]);

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={clickClose}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton onClick={clickClose}>
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box>
            {(membersids?.length < 3 || allowPAcces) && <CopyClipboard value={invitation} />}
            <CTAShow etapaCTA={etapaCTA} modulo={modulo} />
            {(user.email === 'kintaroponce.uni@gmail.com' || user.email === 'ocompudoc@gmail.com') && (
              <Card sx={{ paddingTop: '3%' }}>
                <List>
                  <CTANav defaultModule="empresa" />
                  <ChooseCTA modulo="empresa" />
                </List>
              </Card>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default InvitationGenerator;
