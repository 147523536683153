import PropTypes from 'prop-types';
// material
import { Card, Typography, CardHeader, CardContent } from '@mui/material';
import { Timeline, TimelineDot, TimelineItem, TimelineContent, TimelineConnector, TimelineSeparator } from '@mui/lab';
// utils
import { fDateTime } from '../../../utils/formatTime';
import { orderBy } from 'lodash';
import { useAnalytics } from 'src/pages/dashboard/GeneralAnalyticsUser';
import { parseDate } from 'src/utils/formatTime';

// ----------------------------------------------------------------------

OrderItem.propTypes = {
  item: PropTypes.object,
  isLast: PropTypes.bool
};

function OrderItem({ item, isLast }) {
  const { type, name, fecha } = item;
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          color={
            (type === 'order1' && 'primary') ||
            (type === 'order2' && 'success') ||
            (type === 'order3' && 'info') ||
            (type === 'order4' && 'warning') ||
            'error'
          }
        />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Typography variant="subtitle2">{name}</Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {fDateTime(parseDate(fecha))}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

export default function AnalyticsOrderTimeline() {
  const { data } = useAnalytics();
  return (
    <Card
      sx={{
        '& .MuiTimelineItem-missingOppositeContent:before': {
          display: 'none'
        }
      }}
    >
      <CardHeader title="Secuencia de eventos" />
      <CardContent>
        <Timeline>
          {orderBy(data, ['fecha'], ['asc']).map((item, index) => (
            <OrderItem key={item.title} item={item} isLast={index === data.length - 1} />
          ))}
        </Timeline>
      </CardContent>
    </Card>
  );
}
