import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from '@mui/material';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

ObjRow.propTypes = {
  idobj: PropTypes.string
};

function ObjRow({ idobj }) {
  const [nombre, setName] = useState('');

  useEffect(() => {
    async function getData() {
      await firestore
        .collection('kobjsCards')
        .doc(idobj)
        .onSnapshot((doc) => {
          const { name } = doc.data();
          setName(name);
        });
    }
    getData();
  }, [idobj]);

  return (
    <TableRow>
      <TableCell>{nombre}</TableCell>
    </TableRow>
  );
}

ObjsAsociados.propTypes = {
  card: PropTypes.object
};

export default function ObjsAsociados({ card }) {
  const { objsA } = card;
  const objs = objsA || [];

  return (
    <Card>
      <CardHeader title="Objetivos alineados" />
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {objs.map((obj) => (
                <ObjRow key={obj} idobj={obj} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
