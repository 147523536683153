import React, { useState, useEffect } from 'react';
import { Grid, Card, Avatar, Typography, IconButton, Box } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import SvgIconStyle from 'src/components/SvgIconStyle';
import { getURLFile } from 'src/utils/storagef';
import { DialogPerfilItem } from './perfilitemnav';
// icon
import { Icon } from '@iconify/react';
import eyeFillI from '@iconify/icons-ant-design/eye-fill';
//
import PropTypes from 'prop-types';

const CardMediaStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  paddingTop: 'calc(100% * 9 / 16)',
  '&:before': {
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
    backdropFilter: 'blur(3px)',
    WebkitBackdropFilter: 'blur(3px)', // Fix on Mobile
    borderTopLeftRadius: theme.shape.borderRadiusMd,
    borderTopRightRadius: theme.shape.borderRadiusMd,
    backgroundColor: alpha(theme.palette.primary.darker, 0.72)
  }
}));

const CoverImgStyle = styled('img')({
  top: 0,
  zIndex: 8,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});
PerfilItem.propTypes = {
  perfil: PropTypes.object,
  idEmpresa: PropTypes.string,
  grid: PropTypes.bool
};

export default function PerfilItem({ perfil, idEmpresa, grid }) {
  const { id, nombre, descripcion } = perfil;
  const [openD, setOpenD] = useState(false);
  const [URLImg, setURLImg] = useState('');
  const ref = `empresas/${idEmpresa}/perfiles/${id}`;

  const clickOpenD = () => {
    setOpenD(true);
  };

  useEffect(() => {
    async function getImgUrl() {
      await getURLFile(ref).then((url) => {
        setURLImg(url);
      });
    }
    getImgUrl();
  }, [ref]);

  if (grid) {
    return (
      <Grid item xs={12} md={4} lg={3}>
        <Card>
          <CardMediaStyle>
            <SvgIconStyle
              color="paper"
              src="/static/icons/shape-avatar.svg"
              sx={{
                width: 144,
                height: 62,
                zIndex: 10,
                bottom: -26,
                position: 'absolute'
              }}
            />
            <Avatar
              alt={nombre}
              src={URLImg}
              sx={{
                width: 64,
                height: 64,
                zIndex: 11,
                position: 'absolute',
                transform: 'translateY(-50%)'
              }}
            />
            <CoverImgStyle alt="cover" src={URLImg} />
          </CardMediaStyle>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 6 }}>
            <IconButton onClick={clickOpenD}>
              <Icon icon={eyeFillI} width={23} height={23} />
            </IconButton>
          </Box>
          <Typography variant="subtitle1" align="center">
            {nombre}
          </Typography>
          <Typography variant="subtitle1" align="center">
            {descripcion}
          </Typography>
          <DialogPerfilItem idEmpresa={idEmpresa} perfil={perfil} open={openD} setOpen={setOpenD} />
        </Card>
      </Grid>
    );
  }

  return (
    <Card>
      <CardMediaStyle>
        <SvgIconStyle
          color="paper"
          src="/static/icons/shape-avatar.svg"
          sx={{
            width: 144,
            height: 62,
            zIndex: 10,
            bottom: -26,
            position: 'absolute'
          }}
        />
        <Avatar
          alt={nombre}
          src={URLImg}
          sx={{
            width: 64,
            height: 64,
            zIndex: 11,
            position: 'absolute',
            transform: 'translateY(-50%)'
          }}
        />
        <CoverImgStyle alt="cover" src={URLImg} />
      </CardMediaStyle>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 6 }}>
        <IconButton onClick={clickOpenD}>
          <Icon icon={eyeFillI} width={23} height={23} />
        </IconButton>
      </Box>
      <Typography variant="subtitle1" align="center">
        {nombre}
      </Typography>
      <Typography variant="subtitle1" align="center">
        {descripcion}
      </Typography>
      <DialogPerfilItem idEmpresa={idEmpresa} perfil={perfil} open={openD} setOpen={setOpenD} />
    </Card>
  );
}
