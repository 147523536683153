import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  Stack,
  Button
} from '@mui/material';
import ProfileView from 'src/components/_dashboard/general/PerfilView';
import { styled } from '@mui/styles';
import ProfileItemV from 'src/components/_dashboard/general/PerfilItemV';
// icon
import { Icon } from '@iconify/react';
import perfilesI from '@iconify/icons-ant-design/profile-fill';
import addP from '@iconify/icons-ant-design/plus';
import { firestore } from 'src/contexts/FirebaseContext';
//
import PropTypes from 'prop-types';

// ---------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary
}));

const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'left'
});

// ---------------------------------------------------------

PerfilesD.propTypes = {
  idEmpresa: PropTypes.string,
  depto: PropTypes.object
};

export default function PerfilesD({ idEmpresa, depto }) {
  const { id, perfiles } = depto;
  const [perfsS, setPerfs] = useState([]);
  const [open, setOpen] = useState(false);
  const [openD, setOpenD] = useState(false);

  useEffect(() => {
    async function getPerfiles() {
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('perfiles')
        .onSnapshot((queryS) => {
          const perfs = [];
          queryS.forEach((doc) => {
            perfs.push(doc.id);
          });
          setPerfs(perfs);
        });
    }
    getPerfiles();
  }, [idEmpresa, id]);

  const addPerf = async (userid) => {
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('departamentos')
      .doc(id)
      .update({ perfiles: [...(perfiles || []), userid] });
  };

  const removePerf = async (userid) => {
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('departamentos')
      .doc(id)
      .update({ perfiles: perfiles.filter((userids) => userids !== userid) });
  };

  const clickOpenD = () => {
    setOpenD(true);
  };

  const clickCloseD = () => {
    setOpenD(false);
  };

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
        <ListItemIcon>
          <Icon icon={perfilesI} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Perfiles</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose}>
        <DialogContent>
          <RowStyle>
            <LabelStyle>Perfiles</LabelStyle>
            <Stack direction="row" flexWrap="wrap" alignItems="center">
              {perfiles?.map((perf) => (
                <ProfileView key={perf} idEmpresa={idEmpresa} idP={perf} />
              ))}
              <IconButton onClick={clickOpenD}>
                <Icon icon={addP} width={23} height={23} />
              </IconButton>
            </Stack>
          </RowStyle>
          <Dialog open={openD} onClose={clickCloseD}>
            <DialogContent>
              <List>
                {perfsS.map((mam) => (
                  <ListItem key={mam}>
                    <ProfileItemV idEmpresa={idEmpresa} idP={mam} />
                    <Button
                      onClick={() => {
                        if (perfiles?.includes(mam)) {
                          removePerf(mam);
                        } else {
                          addPerf(mam);
                        }
                      }}
                    >
                      {perfiles?.includes(mam) ? 'quitar' : 'agregar'}
                    </Button>
                  </ListItem>
                ))}
              </List>
            </DialogContent>
          </Dialog>
        </DialogContent>
      </Dialog>
    </>
  );
}
