import React from 'react';
import { Card, List } from '@mui/material';
import { EmpresaPermisos, SprintPermisos } from './premisosgroups';
import PropTypes from 'prop-types';

PermisosNav.propTypes = {
  idEmpresa: PropTypes.string,
  empresa: PropTypes.object
};

export default function PermisosNav({ idEmpresa, empresa }) {
  return (
    <Card>
      <List>
        <EmpresaPermisos idEmpresa={idEmpresa} empresa={empresa} />
        <SprintPermisos idEmpresa={idEmpresa} empresa={empresa} />
      </List>
    </Card>
  );
}
