import React, { useState, useEffect } from 'react';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogContent,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  TextField,
  MenuItem
} from '@mui/material';
import { styled } from '@mui/styles';
import AltaCTA from './AltaCTA';
// icons
import { Icon } from '@iconify/react';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
import asistI from '@iconify/icons-ant-design/profile-outline';
import PropTypes from 'prop-types';

// ---------------------------------------------------------------------

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool
};

function QontoStepIcon({ active, completed }) {
  return (
    <Box
      sx={{
        zIndex: 9,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: active ? 'primary.main' : 'text.disabled'
      }}
    >
      {completed ? (
        <Box component={Icon} icon={checkmarkFill} sx={{ zIndex: 1, width: 20, height: 20, color: 'primary.main' }} />
      ) : (
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor'
          }}
        />
      )}
    </Box>
  );
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  top: 10,
  left: 'calc(-50% + 20px)',
  right: 'calc(50% + 20px)',
  '& .MuiStepConnector-line': {
    borderTopWidth: 2,
    borderColor: theme.palette.divider
  },
  '&.Mui-active, &.Mui-completed': {
    '& .MuiStepConnector-line': {
      borderColor: theme.palette.primary.main
    }
  }
}));

// ---------------------------------------------------------------------

const STEPS = ['Atraccion', 'Deleite', 'Engage'];
const modulos = [
  { name: 'Empresa', mod: 'empresa' },
  { name: 'Organizacion', mod: 'organizacion' },
  { name: 'Desarrollo personal', mod: 'desarrolloP' },
  { name: 'Finanzas personales', mod: 'finanzasP' },
  { name: 'Machine Learning', mod: 'ML' },
  { name: 'Home', mod: 'Home' }
];

AsistenteCTA.propTypes = {
  ids: PropTypes.array,
  id: PropTypes.string,
  defaultModule: PropTypes.string
};

export default function AsistenteCTA({ ids = [], id = '', defaultModule }) {
  const [open, setOpen] = useState(false);
  const [activeStep, setActStep] = useState(0);
  const [mod, setMod] = useState('empresa');

  useEffect(() => {
    const i = ids.findIndex((idd) => idd === id);
    const indexf = i === -1 ? 0 : i;
    setActStep(indexf);
  }, [ids, id]);

  const next = () => {
    setActStep(activeStep + 1);
  };

  const back = () => {
    if (activeStep > 0) {
      setActStep(activeStep - 1);
    }
  };

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#506070' }}>
        <ListItemIcon>
          <Icon icon={asistI} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Asistente CTA</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <DialogContent>
          <Typography>Asistente CTA</Typography>
          {!defaultModule && (
            <TextField select fullWidth label="Ubicacion" value={mod} onChange={(e) => setMod(e.target.value)}>
              {modulos.map((module) => (
                <MenuItem key={module.name} value={module.mod}>
                  {module.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          <Box>
            <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
              {STEPS.map((label) => (
                <Step key={label}>
                  <StepLabel
                    StepIconComponent={QontoStepIcon}
                    sx={{
                      '& .MuiStepLabel-label': {
                        typography: 'subtitle2',
                        color: 'text.disabled'
                      }
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box>
            {activeStep === 0 && (
              <AltaCTA
                edit={Boolean(ids[0])}
                iddoc={ids[0]}
                defaultEtapa="atraccion"
                forceEtapa
                defaultModule={defaultModule || mod}
                next={next}
              />
            )}
            {activeStep === 1 && (
              <AltaCTA
                edit={Boolean(ids[1])}
                iddoc={ids[1]}
                defaultEtapa="deleitar"
                forceEtapa
                defaultModule={defaultModule || mod}
                back={back}
                next={next}
              />
            )}
            {activeStep === 2 && (
              <AltaCTA
                edit={Boolean(ids[2])}
                iddoc={ids[2]}
                defaultEtapa="engage"
                forceEtapa
                defaultModule={defaultModule || mod}
                back={back}
                next={clickClose}
              />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
