import React from 'react';
import { Dialog, DialogContent, Grid, Box } from '@mui/material';
import { ValueCard, ValueNav } from './valuenav';
import PropTypes from 'prop-types';

ValueNavD.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  title: PropTypes.string,
  imgUrl: PropTypes.string,
  clickUpload: PropTypes.func,
  idEmpresa: PropTypes.string,
  pid: PropTypes.string
};

export default function ValueNavD({ open = false, setOpen, title, imgUrl, clickUpload, idEmpresa, pid = '' }) {
  const clickClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={clickClose} maxWidth={1200}>
      <Box sx={{ width: '83vw' }} />
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <ValueNav clickUpload={clickUpload} idEmpresa={idEmpresa} pid={pid} />
          </Grid>
          <Grid item xs={12} lg={9}>
            <ValueCard title={title} imgUrl={imgUrl} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
