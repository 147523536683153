import React, { useState, useEffect } from 'react';
import { TextField, Grid, List, ListItemText, ListItem, ListItemIcon, Box, Typography, Button } from '@mui/material';
import { Circle } from '@mui/icons-material';
import { firestore } from 'src/contexts/FirebaseContext';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

StepThree.propTypes = {
  back: PropTypes.func,
  idEmpresa: PropTypes.string,
  onClose: PropTypes.func
};

export default function StepThree({ back, idEmpresa, onClose }) {
  const [ids, setIds] = useState([]);
  const [puntos, setPuntos] = useState([]);
  const [nombre, setNombre] = useState('');
  const [msg, setMsg] = useState('');

  useEffect(() => {
    async function getIds() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanColumnOrder')
        .doc('order')
        .onSnapshot((doc) => {
          if (doc.exists) {
            const { columnOrder } = doc.data();
            setIds(columnOrder);
          }
        });
    }
    async function getPEs() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanColunms')
        .onSnapshot((queryS) => {
          const ps = [];
          queryS.forEach((doc) => {
            ps.push({ id: doc.id, ...doc.data() });
          });
          setPuntos(ps);
        });
    }
    getIds();
    getPEs();
  }, [idEmpresa]);

  const addPE = async () => {
    const idC = uuidv4();
    const newName = nombre;
    setNombre('');
    setMsg('agregando nueva columna');
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('kanbanColunms')
      .doc(idC)
      .set({ id: idC, name: newName, cardIds: [] });
    setMsg('indexando');
    if (ids.length !== 0) {
      const newcolumnOrder = [...ids, idC];
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanColumnOrder')
        .doc('order')
        .update({ columnOrder: newcolumnOrder });
    } else {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanColumnOrder')
        .doc('order')
        .set({ columnOrder: [idC] });
    }
    setMsg('completado');
    setTimeout(() => setMsg(''), 1500);
  };

  const preguntas = [
    '¿hacen que tu vision ocurra?',
    '¿Son totalmente diferentes unos de otros?',
    '¿Son absolutamente criticos para su exito?',
    '¿Juntos, pueden capturar el 100% de las actividades de tu negocio?'
  ];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h3">Puntos de Enfoque</Typography>
          <Box>
            Un punto de enfoque es un objetivo de alto nivel o intención que te ayudará a realizar tu visión. de la
            misma forma que tu visión, debería ser entendida por todos en la organización, deberías crear puntos de
            enfoque que todos puedan identificarse. son llamadas a la acción y apunta a tus empleados a la dirección
            correcta.
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>Tips:</Box>
          <Box>No más de 5</Box>
          <Box>Mantenlos cortos</Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <List>
              {ids.map((id) => (
                <ListItem key={id}>
                  <ListItemIcon>
                    <Circle />
                  </ListItemIcon>
                  <ListItemText>{puntos.find((p) => p.id === id)?.name}</ListItemText>
                </ListItem>
              ))}
            </List>
            <Box>
              <Typography>Nuevo punto de enfoque</Typography>
              <TextField
                label="Nombre"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && nombre !== '') {
                    addPE();
                  }
                }}
              />
              <Button
                variant="contained"
                onClick={() => {
                  if (nombre !== '') {
                    addPE();
                  }
                }}
              >
                Agregar
              </Button>
              {msg}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography>Prueba tus Punto de enfoque</Typography>
          <List>
            {preguntas.map((p) => (
              <ListItem key={p}>
                <ListItemIcon>
                  <Circle />
                </ListItemIcon>
                <ListItemText>{p}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>

      <Box sx={{ float: 'right', padding: '3%', margin: '2%', display: 'flex' }}>
        <Button variant="contained" onClick={back} sx={{ margin: '2%' }}>
          Atras
        </Button>
        <Button variant="contained" onClick={onClose} sx={{ margin: '2%' }}>
          Finalizar
        </Button>
      </Box>
    </>
  );
}
