import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import pinFill from '@iconify/icons-eva/pin-fill';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
// material
import { Box, Grid, Card, Button, Avatar, Typography } from '@mui/material';
import { firestore } from 'src/contexts/FirebaseContext';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

FollowerCard.propTypes = {
  follower: PropTypes.string,
  onToggle: PropTypes.func
};

function FollowerCard({ follower, onToggle }) {
  const { user } = useAuth();
  const [followerS, setFollowerS] = useState({ name: '', country: '', avatarUrl: '', isFollowed: false });

  useEffect(() => {
    async function getUserI() {
      await firestore
        .collection('users')
        .doc(follower)
        .onSnapshot((doc) => {
          const data = doc.data();
          const followers = doc.data().followers || [];
          setFollowerS({
            name: data.displayName,
            avatarUrl: data.photoURL,
            country: data.country,
            isFollowed: followers.includes(user.id)
          });
        });
    }
    getUserI();
  }, [follower, user]);

  const { name, country, avatarUrl, isFollowed } = followerS;
  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
      <Avatar alt={name} src={avatarUrl} sx={{ width: 48, height: 48 }} />
      <Box sx={{ flexGrow: 1, minWidth: 0, pl: 2, pr: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {name}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box component={Icon} icon={pinFill} sx={{ width: 16, height: 16, mr: 0.5, flexShrink: 0 }} />
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {country}
          </Typography>
        </Box>
      </Box>
      <Button
        size="small"
        onClick={onToggle}
        variant={isFollowed ? 'text' : 'outlined'}
        color={isFollowed ? 'primary' : 'inherit'}
        startIcon={isFollowed && <Icon icon={checkmarkFill} />}
      >
        {isFollowed ? 'Seguido' : 'Seguir'}
      </Button>
    </Card>
  );
}

ProfileFollowers.propTypes = {
  followers: PropTypes.array.isRequired,
  onToggleMyFollow: PropTypes.func
};

export default function ProfileFollowers({ followers, onToggleMyFollow }) {
  const { user } = useAuth();
  return (
    <Box sx={{ mt: 5 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Seguidores
      </Typography>

      <Grid container spacing={3}>
        {followers.map((follower) => (
          <Grid key={follower} item xs={12} md={4}>
            <FollowerCard follower={follower} onToggle={() => onToggleMyFollow(user.id, follower)} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
