import React from 'react';
// material
import { Box, Card, Divider, Typography, Grid, List } from '@mui/material';
import EmpresaHero from './EmpresaHero';
import EmpresaTags from './EmpresaTags';
// hooks
import usePermissions, { tienePermiso } from 'src/hooks/usePermissions';
import useAuth from 'src/hooks/useAuth';
import { TodoTasks } from 'src/components/_dashboard/general/TodoTask';
import { SprintTable } from 'src/pages/dashboard/SprintsPage';
import KanbanEmpresa from 'src/pages/dashboard/KanbanEmpresa';
import { CTANav } from 'src/components/_dashboard/ctas';
import { ChooseCTA } from 'src/components/_dashboard/asistentemain/other';
import CardEmpresa from 'src/components/_dashboard/entorno/CardEmpresa';
import MNavigator from 'src/components/_dashboard/entorno/MNavigator';
import PropTypes from 'prop-types';

EmpresaMainP.propTypes = {
  idEmpresa: PropTypes.string,
  empresa: PropTypes.object
};

export default function EmpresaMainP({ idEmpresa, empresa }) {
  // const { creator } = empresa;
  // const { permisos } = usePermissions();
  const { user } = useAuth();

  // const canSeeSprints = tienePermiso('ver sprints', user.id, permisos);

  return (
    <>
      <Card>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <MNavigator empresa={empresa} />
            {/* <EmpresaHero post={empresa} sx={{ margin: '3%' }} /> */}
            {(user.email === 'kintaroponce.uni@gmail.com' || user.email === 'ocompudoc@gmail.com') && (
              <Grid item xs={12}>
                <Card>
                  <List>
                    <CTANav />
                    <ChooseCTA modulo="Home" />
                  </List>
                </Card>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            <CardEmpresa empresa={empresa} />
            {/* <Card sx={{ margin: '3%', padding: '3px' }}>
              <EmpresaTags post={empresa} variant="slide" />
            </Card> */}
          </Grid>
        </Grid>

        {/* <Box sx={{ p: { xs: 3, md: 5 }, marginTop: '5%' }}>
          <KanbanEmpresa idEmpresa={idEmpresa} empresa={empresa} />
          {(canSeeSprints || user.id === creator) && <SprintTable />}
          <Typography variant="h6" sx={{ mb: 5 }}>
            {empresa.description}
          </Typography>
          <Divider />
          <TodoTasks collection="empresas" docId={idEmpresa} />
        </Box> */}
      </Card>
    </>
  );
}
