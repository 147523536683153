import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Avatar,
  IconButton,
  Box,
  Button,
  AppBar,
  Toolbar,
  DialogActions,
  Tooltip
} from '@mui/material';
import { Icon } from '@iconify/react';
import closefill from '@iconify/icons-eva/close-fill';
import { Close } from '@mui/icons-material';
import ProfileCard from '../entorno/organizacion/perfilespuesto/PerfilItem';
import { alpha } from '@mui/material/styles';
import { firestore } from 'src/contexts/FirebaseContext';
import { getURLFile } from 'src/utils/storagef';
import PropTypes from 'prop-types';

PerfilView.propTypes = {
  idEmpresa: PropTypes.string,
  idP: PropTypes.string.isRequired,
  removePerf: PropTypes.func
};

export default function PerfilView({ idEmpresa, idP, removePerf }) {
  const [open, setOpen] = useState(false);
  const [openDC, setOpenDC] = useState(false);
  const [userI, setUserI] = useState({});
  const [URLImg, setURLImg] = useState('');
  const ref = `empresas/${idEmpresa}/perfiles/${idP}`;

  useEffect(() => {
    async function getImgUrl() {
      await getURLFile(ref).then((url) => {
        setURLImg(url);
      });
    }
    getImgUrl();
  }, [ref]);

  useEffect(() => {
    async function getUI() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('perfiles')
        .doc(idP)
        .onSnapshot((doc) => {
          setUserI({ id: doc.id, ...doc.data() });
        });
    }
    getUI();
  }, [idEmpresa, idP]);

  // metodos
  const clickOpen = () => {
    setOpen(true);
  };
  const clickClose = () => {
    setOpen(false);
  };
  const clickOpenDC = () => {
    setOpenDC(true);
  };
  const clickCloseDC = () => {
    setOpenDC(false);
  };

  return (
    <>
      <Tooltip title={userI?.nombre}>
        <IconButton onClick={clickOpen} key={userI.id}>
          <Avatar alt={userI?.nombre} src={URLImg} sx={{ m: 0.5, width: 36, height: 36 }} />
        </IconButton>
      </Tooltip>
      {removePerf && (
        <IconButton
          size="small"
          onClick={clickOpenDC}
          sx={{
            p: '2px',
            top: '-10px',
            right: '25px',
            color: 'common.white',
            position: 'relative',
            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48)
            }
          }}
        >
          <Icon icon={closefill} />
        </IconButton>
      )}
      <Dialog open={open} onClose={clickClose}>
        <DialogContent>
          <ProfileCard idEmpresa={idEmpresa} perfil={userI} />
        </DialogContent>
      </Dialog>
      <Dialog open={openDC} onClose={clickCloseDC}>
        <AppBar position="relative">
          <Toolbar>
            <Button onClick={clickCloseDC}>
              <Close />
            </Button>
            <Box>
              <h3>Remover Perfil</h3>
            </Box>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box>
            <p>¿Esta seguro que desea Remover a este perfil?</p>
          </Box>
          <DialogActions>
            <Button onClick={clickCloseDC}>Cancelar</Button>
            <Button onClick={() => removePerf(idP)}>Eliminar</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
