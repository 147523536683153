import React, { useState, useEffect } from 'react';
import { ListItem, ListItemText, Dialog, DialogContent, Grid } from '@mui/material';
import { CTAElement } from '../../ctas';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

ChooseCTA.propTypes = {
  modulo: PropTypes.string
};

export default function ChooseCTA({ modulo }) {
  const [open, setOpen] = useState(false);
  const [ctas, setCTAs] = useState([]);

  useEffect(() => {
    async function getCTAs() {
      await firestore
        .collection('ctas')
        .orderBy('etapa', 'asc')
        .onSnapshot((queryS) => {
          const dat = [];
          queryS.forEach((doc) => {
            dat.push({ id: doc.id, ...doc.data() });
          });
          setCTAs(dat);
        });
    }
    getCTAs();
  }, []);

  const selectCTA = async (idCTA) => {
    await firestore.collection('ctas').doc(idCTA).update({ show: true, modulo });
    clickClose();
  };

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#506070' }}>
        <ListItemText>Seleccionar CTA</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <DialogContent>
          <Grid container spacing={3}>
            {ctas.map((cta) => (
              <Grid key={cta.id} item xs={12} md={6} lg={4}>
                <CTAElement
                  CTA={cta}
                  gCTAs={ctas.filter((c) => c.modulo === 'Home').map((cti) => cti.id)}
                  defaultModule={modulo}
                  onSelect={() => selectCTA(cta.id)}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
