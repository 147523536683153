import React from 'react';
import PropTypes from 'prop-types';

CTAIframe.propTypes = {
  src: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

export default function CTAIframe({ src, width, height }) {
  return (
    <iframe title="iframe CTA" src={src} width={width || '100%'} height={height || '100%'} style={{ border: 0 }} />
  );
}
