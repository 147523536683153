import React, { useState } from 'react';
import { ListItem, ListItemText, ListItemIcon, Dialog, DialogContent, Grid, Box } from '@mui/material';
import MenuPerf from './MenuPerf';
import PerfilesCard from './PerfilesCard';
// icon
import { Icon } from '@iconify/react';
import perfilesI from '@iconify/icons-ant-design/contacts-outline';
//
import PropTypes from 'prop-types';

DialogPerfiles.propTypes = {
  idEmpresa: PropTypes.string
};

export default function DialogPerfiles({ idEmpresa }) {
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
        <ListItemIcon>
          <Icon icon={perfilesI} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Perfiles de Puesto</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <Box sx={{ width: '85vw' }} />
        <DialogContent>
          <Grid container>
            <Grid item xs={12} lg={4}>
              <MenuPerf idEmpresa={idEmpresa} />
            </Grid>
            <Grid item xs={12} lg={8}>
              <PerfilesCard idEmpresa={idEmpresa} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
