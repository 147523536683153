import React, { useState, useEffect } from 'react';
import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box } from '@mui/material';
//
import { BaseOptionChart } from 'src/components/charts';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

KRsXPE.propTypes = {
  data: PropTypes.array
};

export default function KRsXPE({ data = [] }) {
  const [Labels, setLabels] = useState([]);
  const [CHART_DATA, setChartData] = useState([
    {
      name: 'KRs',
      type: 'column',
      data: []
    }
  ]);

  useEffect(() => {
    const PEs = [];
    data.forEach((obj) => {
      if (!PEs.includes(obj.status)) {
        PEs.push(obj.status);
      }
    });
    setLabels(PEs);
  }, [data]);

  useEffect(() => {
    if (Labels.length !== 0) {
      const dat = [];
      Labels.forEach((lab) => {
        dat.push(data.filter((obj) => obj.status === lab).length);
      });
      setChartData([
        {
          name: 'Objetivos',
          type: 'column',
          data: dat
        }
      ]);
    }
  }, [data, Labels]);

  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [2, 2, 3] },
    plotOptions: { bar: { columnWidth: '14%' } },
    fill: { type: ['solid'] },
    labels: Labels,
    xaxis: { type: 'text' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} Objetivos`;
          }
          return y;
        }
      }
    }
  });

  return (
    <Card>
      <CardHeader title="Objetivos" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="bar" series={CHART_DATA} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
