import React, { useState, useEffect } from 'react';
import {
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  Grid,
  Card,
  CardContent,
  List,
  Typography,
  Box
} from '@mui/material';
import AltaCTAD from './AltaCTAD';
import AsistenteCTA from './AsistenteCTA';
import CTAElement from './CTAElement';
import UserList from './UserList';
import { ButtonsNav } from './buttons';
import { AnalyticsD, BankingD, EcommerceD } from './other';
import { firestore } from 'src/contexts/FirebaseContext';
import DialogG from 'src/components/_dashboard/other/DialogG';
import NewProduct from 'src/components/_dashboard/e-commerce/ProductNewForm';
import ProductList from 'src/pages/dashboard/EcommerceProductList';
import PropTypes from 'prop-types';

CTANav.propTypes = {
  defaultModule: PropTypes.string
};

export default function CTANav({ defaultModule }) {
  const [open, setOpen] = useState(false);
  const [ctas, setCTAs] = useState([]);

  useEffect(() => {
    async function getCTAs() {
      await firestore
        .collection('ctas')
        .orderBy('etapa', 'asc')
        .onSnapshot((queryS) => {
          const dat = [];
          queryS.forEach((doc) => {
            dat.push({ id: doc.id, ...doc.data() });
          });
          setCTAs(dat);
        });
    }
    getCTAs();
  }, []);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
        <ListItemText>Geeks</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <Box sx={{ width: '60vw' }} />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <Card>
                <List>
                  <UserList />
                  <AltaCTAD listB defaultModule={defaultModule} />
                  <ButtonsNav />
                  <AnalyticsD />
                  <BankingD />
                  <EcommerceD />
                  <DialogG textButton="Nuevo producto" component={<NewProduct />} listB />
                  <DialogG textButton="Lista de productos" component={<ProductList />} listB />
                </List>
              </Card>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Card>
                <CardContent>
                  <Typography variant="h4">Lista de CTAs</Typography>
                  <Box sx={{ padding: '3%' }}>
                    <Typography variant="h3">Home</Typography>
                    <AsistenteCTA
                      ids={ctas.filter((c) => c.modulo === 'Home').map((cti) => cti.id)}
                      defaultModule="Home"
                    />
                    <Grid container spacing={3}>
                      {ctas
                        .filter((c) => c.modulo === 'Home')
                        .map((cta) => (
                          <Grid key={cta.id} item xs={12} md={6} lg={4}>
                            <CTAElement
                              CTA={cta}
                              gCTAs={ctas.filter((c) => c.modulo === 'Home').map((cti) => cti.id)}
                              defaultModule="Home"
                            />
                          </Grid>
                        ))}
                    </Grid>
                    <Typography variant="h3">Empresa</Typography>
                    <AsistenteCTA
                      ids={ctas.filter((c) => c.modulo === 'empresa').map((cti) => cti.id)}
                      defaultModule="empresa"
                    />
                    <Grid container spacing={3}>
                      {ctas
                        .filter((c) => c.modulo === 'empresa')
                        .map((cta) => (
                          <Grid key={cta.id} item xs={12} md={6} lg={4}>
                            <CTAElement
                              CTA={cta}
                              gCTAs={ctas.filter((c) => c.modulo === 'empresa').map((cti) => cti.id)}
                              defaultModule="empresa"
                            />
                          </Grid>
                        ))}
                    </Grid>
                    <Typography variant="h3">Finanzas Personales</Typography>
                    <AsistenteCTA
                      ids={ctas.filter((c) => c.modulo === 'finanzasP').map((cti) => cti.id)}
                      defaultModule="finanzasP"
                    />
                    <Grid container spacing={3}>
                      {ctas
                        .filter((c) => c.modulo === 'finanzasP')
                        .map((cta) => (
                          <Grid key={cta.id} item xs={12} md={6} lg={4}>
                            <CTAElement
                              CTA={cta}
                              gCTAs={ctas.filter((c) => c.modulo === 'finanzasP').map((cti) => cti.id)}
                              defaultModule="finanzasP"
                            />
                          </Grid>
                        ))}
                    </Grid>
                    <Typography variant="h3">Desarrollo Personal</Typography>
                    <AsistenteCTA
                      ids={ctas.filter((c) => c.modulo === 'desarrolloP').map((cti) => cti.id)}
                      defaultModule="desarrolloP"
                    />
                    <Grid container spacing={3}>
                      {ctas
                        .filter((c) => c.modulo === 'desarrolloP')
                        .map((cta) => (
                          <Grid key={cta.id} item xs={12} md={6} lg={4}>
                            <CTAElement
                              CTA={cta}
                              gCTAs={ctas.filter((c) => c.modulo === 'desarrolloP').map((cti) => cti.id)}
                              defaultModule="desarrolloP"
                            />
                          </Grid>
                        ))}
                    </Grid>
                    <Typography variant="h3">Organizacion</Typography>
                    <AsistenteCTA
                      ids={ctas.filter((c) => c.modulo === 'organizacion').map((cti) => cti.id)}
                      defaultModule="organizacion"
                    />
                    <Grid container spacing={3}>
                      {ctas
                        .filter((c) => c.modulo === 'organizacion')
                        .map((cta) => (
                          <Grid key={cta.id} item xs={12} md={6} lg={4}>
                            <CTAElement
                              CTA={cta}
                              gCTAs={ctas.filter((c) => c.modulo === 'organizacion').map((cti) => cti.id)}
                              defaultModule="organizacion"
                            />
                          </Grid>
                        ))}
                    </Grid>
                    <Typography variant="h3">Machine Learning</Typography>
                    <AsistenteCTA ids={ctas.filter((c) => c.modulo === 'ML').map((cti) => cti.id)} defaultModule="ML" />
                    <Grid container spacing={3}>
                      {ctas
                        .filter((c) => c.modulo === 'ML')
                        .map((cta) => (
                          <Grid key={cta.id} item xs={12} md={6} lg={4}>
                            <CTAElement
                              CTA={cta}
                              gCTAs={ctas.filter((c) => c.modulo === 'ML').map((cti) => cti.id)}
                              defaultModule="ML"
                            />
                          </Grid>
                        ))}
                    </Grid>
                    <Typography variant="h3">Sprints</Typography>
                    <AsistenteCTA
                      ids={ctas.filter((c) => c.modulo === 'Home').map((cti) => cti.id)}
                      defaultModule="agile"
                    />
                    <Grid container spacing={3}>
                      {ctas
                        .filter((c) => c.modulo === 'agile')
                        .map((cta) => (
                          <Grid key={cta.id} item xs={12} md={6} lg={4}>
                            <CTAElement
                              CTA={cta}
                              gCTAs={ctas.filter((c) => c.modulo === 'agile').map((cti) => cti.id)}
                              defaultModule="agile"
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
