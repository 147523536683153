import axios from 'axios';

const baseURL = process.env.REACT_APP_PAYS_URL || '';

export async function getPayID(quantity = 1, price = 10, description = '', urlS = '') {
  return new Promise((resolve) => {
    axios
      .post(`${baseURL}/create_preference`, { quantity, price, description, urlS: urlS || `${window.origin}/activate` })
      .then((resp) => {
        resolve(resp.data.id);
      });
  });
}

export async function getPayStatus(payID) {
  return new Promise((reolve, reject) => {
    axios
      .get(`${baseURL}/status/${payID}`)
      .then((resp) => {
        reolve(resp.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
