import React from 'react';
import { Card, List } from '@mui/material';
import AltaPerfil from './AltaPerfil';
import PropTypes from 'prop-types';

MenuPerf.propTypes = {
  idEmpresa: PropTypes.string
};

export default function MenuPerf({ idEmpresa }) {
  return (
    <Card sx={{ margin: '3%' }}>
      <List>
        <AltaPerfil idEmpresa={idEmpresa} listB />
      </List>
    </Card>
  );
}
