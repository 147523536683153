import React, { useState } from 'react';
import { ListItem, ListItemText, ListItemIcon, Dialog, DialogContent, Grid, Box } from '@mui/material';
import MenuDepto from './MenuDepto';
import DeptosCard from './DeptosCard';
// icon
import { Icon } from '@iconify/react';
import profileI from '@iconify/icons-ant-design/profile-filled';
//
import PropTypes from 'prop-types';

DialogDeptos.propTypes = {
  idEmpresa: PropTypes.string
};

export default function DialogDeptos({ idEmpresa }) {
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
        <ListItemIcon>
          <Icon icon={profileI} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Gestion</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <Box sx={{ width: '85vw' }} />
        <DialogContent>
          <Grid container>
            <Grid item xs={12} lg={4}>
              <MenuDepto idEmpresa={idEmpresa} />
            </Grid>
            <Grid item xs={12} lg={8}>
              <DeptosCard idEmpresa={idEmpresa} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
