import React, { useState, useEffect, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import {
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  Button,
  IconButton,
  Typography,
  Chip,
  AppBar,
  Toolbar,
  Grid,
  TextField,
  Autocomplete,
  FormHelperText,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
// icon
import { Icon } from '@iconify/react';
import editI from '@iconify/icons-ant-design/edit-filled';
import addI from '@iconify/icons-ant-design/usergroup-add-outline';
//
import { UploadSingleFile } from '../../upload';
import { v1 as uuidv1 } from 'uuid';
import { firestore, storage as st } from 'src/contexts/FirebaseContext';
import useAuth from 'src/hooks/useAuth';
import { useDispatch } from 'src/redux/store';
import { getProyecto } from 'src/redux/slices/proyectos';
import { PATH_DASHBOARD } from 'src/routes/paths';
import PropTypes from 'prop-types';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

const VALORES = ['Respeto', 'Trabajo en Equipo'];

AltaProyecto.propTypes = {
  idEmpresa: PropTypes.string,
  idProyecto: PropTypes.string,
  edit: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  showB: PropTypes.bool,
  listB: PropTypes.bool,
  redirect: PropTypes.bool,
  onCreateNew: PropTypes.func
};

export default function AltaProyecto({
  idEmpresa,
  idProyecto,
  edit = false,
  open = false,
  setOpen,
  showB = false,
  listB = false,
  redirect = false,
  onCreateNew
}) {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [proyectid, setProyectid] = useState('');
  const [redirectP, setRedirect] = useState(false);
  useEffect(() => {
    setProyectid(uuidv1());
  }, []);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  const validation = Yup.object().shape({
    nombre: Yup.string().required('nombre es Requerido'),
    descripcion: Yup.string().required('Descripcion es requerida')
  });

  const formik = useFormik({
    initialValues: {
      nombre: '',
      descripcion: '',
      comocolaboramos: '',
      vision: '',
      iniciativa: '',
      valores: [],
      imagen: null,
      tokenasana: '',
      tokenslack: '',
      fechainicio: new Date(),
      fechafin: new Date(),
      status: '',
      color: '#AA0000',
      likes: []
    },
    validationSchema: validation,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        console.log(values);
        if (edit) {
          await firestore
            .collection('proyectosempresa')
            .doc(idProyecto)
            .update(values)
            .then(() => {
              dispatch(getProyecto(idProyecto));
            });
          setOpen(false);
        } else {
          await firestore
            .collection('proyectosempresa')
            .doc(proyectid)
            .set({
              idEmpresa,
              creator: user.id,
              ...values,
              likes: [],
              membersids: [user.id],
              membersinfo: [
                {
                  id: user.id,
                  nombre: user.displayName,
                  avatar: user.photoURL
                }
              ]
            })
            .then(() => {
              setProyectid(uuidv1());
              if (redirect) {
                setRedirect(true);
              }
              if (typeof onCreateNew === 'function') {
                onCreateNew(proyectid);
              }
            });
          resetForm();
        }
      } catch (err) {
        console.error(err);
        setSubmitting(false);
      }
      setSubmitting(false);
      setOpen(false);
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  useEffect(() => {
    async function getData() {
      await firestore
        .collection('proyectosempresa')
        .doc(idProyecto)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const {
              nombre,
              descripcion,
              comocolaboramos,
              vision,
              iniciativa,
              valores,
              imagen,
              tokenasana,
              tokenslack,
              fechainicio,
              fechafin,
              status,
              color,
              likes
            } = doc.data();
            setFieldValue('nombre', nombre);
            setFieldValue('descripcion', descripcion);
            setFieldValue('comocolaboramos', comocolaboramos);
            setFieldValue('vision', vision);
            setFieldValue('iniciativa', iniciativa);
            setFieldValue('valores', valores);
            setFieldValue('imagen', imagen);
            setFieldValue('tokenasana', tokenasana);
            setFieldValue('tokenslack', tokenslack);
            setFieldValue('fechainicio', fechainicio);
            setFieldValue('fechafin', fechafin);
            setFieldValue('status', status);
            setFieldValue('color', color);
            setFieldValue('likes', likes || []);
          } else {
            console.log('No such document!');
          }
        });
    }
    if (edit && idProyecto) {
      getData();
    }
    // eslint-disable-next-line
  }, [edit, idProyecto]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        const format = file.name.split('.')[1];
        const fileT = new File([file], 'cover.' + format, { type: file.type });
        const uploadCover = st.ref().child(`proyectos/${proyectid}/imagen.${format}`);
        uploadCover.put(fileT).then(() => {
          uploadCover.getDownloadURL().then((url) => {
            setFieldValue('imagen', url);
          });
        });
      }
    },
    [setFieldValue, proyectid]
  );

  // nombre
  // vision de proyecto
  // valores
  // como colaboramos
  // descripcion
  // imagen
  // token de asana
  // token de slack
  // color

  return (
    <>
      {showB && listB && (
        <ListItem>
          <ListItemIcon>
            <Icon icon={edit ? editI : addI} width={23} height={23} />
          </ListItemIcon>
          <ListItemText>{edit ? <>Editar</> : <>Alta de Proyecto</>}</ListItemText>
        </ListItem>
      )}
      {showB && !listB && (
        <Button onClick={clickOpen} sx={{ margin: '2px', color: '#637381' }}>
          {edit ? <>Editar</> : <>Alta de Proyecto</>}
        </Button>
      )}
      <Dialog open={open} onClose={clickClose}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton onClick={clickClose}>
              <Close />
            </IconButton>
            <Typography variant="h4">{edit ? <>Editar</> : <>Alta de Proyecto</>}</Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Nombre de proyecto"
                      {...getFieldProps('nombre')}
                      error={Boolean(touched.nombre && errors.nombre)}
                      helperText={touched.nombre && errors.nombre}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      minRows={3}
                      maxRows={5}
                      label="Descripción"
                      {...getFieldProps('descripcion')}
                      fullWidth
                      error={Boolean(touched.descripcion && errors.descripcion)}
                      helperText={touched.descripcion && errors.descripcion}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      minRows={3}
                      maxRows={5}
                      label="Como colaboramos"
                      {...getFieldProps('comocolaboramos')}
                      fullWidth
                      error={Boolean(touched.comocolaboramos && errors.comocolaboramos)}
                      helperText={touched.comocolaboramos && errors.comocolaboramos}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      multiline
                      minRows={3}
                      maxRows={5}
                      label="Visión"
                      {...getFieldProps('vision')}
                      fullWidth
                      error={Boolean(touched.vision && errors.vision)}
                      helperText={touched.vision && errors.vision}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      freeSolo
                      value={values.valores}
                      onChange={(event, newValue) => {
                        setFieldValue('valores', newValue);
                      }}
                      options={VALORES.map((option) => option)}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip {...getTagProps({ index })} key={option} size="small" label={option} />
                        ))
                      }
                      renderInput={(params) => <TextField {...params} label="Valores" />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div>
                      <LabelStyle>Logo</LabelStyle>
                      <UploadSingleFile
                        maxSize={9145728}
                        accept="image/*"
                        file={values.imagen}
                        onDrop={handleDrop}
                        error={Boolean(touched.cover && errors.cover)}
                      />
                      {touched.cover && errors.cover && (
                        <FormHelperText error sx={{ px: 2 }}>
                          {touched.cover && errors.cover}
                        </FormHelperText>
                      )}
                    </div>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Asana Token"
                      {...getFieldProps('tokenasana')}
                      error={Boolean(touched.tokenasana && errors.tokenasana)}
                      helperText={touched.tokenasana && errors.tokenasana}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Slack Token"
                      {...getFieldProps('tokenslack')}
                      error={Boolean(touched.tokenslack && errors.tokenslack)}
                      helperText={touched.tokenslack && errors.tokenslack}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Iniciativa"
                      {...getFieldProps('iniciativa')}
                      error={Boolean(touched.iniciativa && errors.iniciativa)}
                      helperText={touched.iniciativa && errors.iniciativa}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="color"
                      fullWidth
                      label="Color"
                      {...getFieldProps('color')}
                      error={Boolean(touched.color && errors.color)}
                      helperText={touched.color && errors.color}
                    />
                  </Grid>
                </Grid>
              </Box>
              <DialogActions>
                <LoadingButton loading={isSubmitting} type="submit" variant="contained">
                  Guardar
                </LoadingButton>
              </DialogActions>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Dialog>
      {redirectP ? (
        <Navigate to={PATH_DASHBOARD.entorno.empresa + idEmpresa + '/proyecto/' + idProyecto} replace />
      ) : (
        <></>
      )}
    </>
  );
}
