import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, Typography, Grid } from '@mui/material';
import ProfileView from '../perfilespuesto/PerfilItem';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

CardRH.propTypes = {
  idEmpresa: PropTypes.string
};

export default function CardRH({ idEmpresa }) {
  const [perfiles, setPerfiles] = useState([]);

  useEffect(() => {
    async function getPerfiles() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('perfiles')
        .onSnapshot((queryS) => {
          const perfs = [];
          queryS.forEach((doc) => {
            perfs.push({ id: doc.id, ...doc.data() });
          });
          setPerfiles(perfs.filter((p) => p.miembros.length === 0));
        });
    }
    getPerfiles();
  }, [idEmpresa]);

  return (
    <Card>
      <CardHeader title="Recursos Humanos" />
      <CardContent>
        <Typography variant="h4">Vacantes</Typography>
        {perfiles.length === 0 && <Typography variant="subtitle2">No hay Vacantes</Typography>}
        <Grid container>
          {perfiles.map((perf) => (
            <ProfileView key={perf.id} perfil={perf} idEmpresa={idEmpresa} grid />
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
