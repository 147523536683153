import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

ProfileView.propTypes = {
  idEmpresa: PropTypes.string,
  idP: PropTypes.string
};

export default function ProfileView({ idEmpresa, idP }) {
  const [nombre, setNombre] = useState('');

  useEffect(() => {
    async function getNameP() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('perfiles')
        .doc(idP)
        .onSnapshot((doc) => {
          setNombre(doc.data().nombre);
        });
    }
    getNameP();
  }, [idEmpresa, idP]);

  return <Typography>{nombre}</Typography>;
}
