import { Icon } from '@iconify/react';
import { capitalCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import heartFill from '@iconify/icons-eva/heart-fill';
// import peopleFill from '@iconify/icons-eva/people-fill';
// import roundPermMedia from '@iconify/icons-ic/round-perm-media';
import roundAccountBox from '@iconify/icons-ic/round-account-box';
// material
import { styled } from '@mui/material/styles';
import { Tab, Box, Card, Tabs, Container, Button } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import {
  getPosts,
  getGallery,
  getFriends,
  getProfile,
  getProfile2,
  getFollowers,
  onToggleFollow,
  getMyFollowers,
  onToggleMyFollow
} from '../../redux/slices/user';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
// hooks
import useAuth from '../../hooks/useAuth';
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import {
  Profile,
  ProfileCover /* ,
  ProfileFriends,
  ProfileGallery, */,
  ProfileFollowers
} from '../../components/_dashboard/user/profile';
import { createNotification, generateNotification } from 'src/utils/notifications';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

const TabsWrapperStyle = styled('div')(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center'
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(3)
  }
}));

// ----------------------------------------------------------------------

UserProfile.propTypes = {
  userId: PropTypes.string
};

export default function UserProfile({ userId }) {
  const { themeStretch } = useSettings();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { myProfile, profile, posts, followers /* , friends, gallery */ } = useSelector((state) => state.user);
  const { user } = useAuth();
  const [currentTab, setCurrentTab] = useState('Perfil');
  // const [findFriends, setFindFriends] = useState('');

  useEffect(() => {
    dispatch(getProfile(userId || user.id));
    dispatch(getProfile2(userId || id || user.id));
    dispatch(getPosts(userId || id || user.id));
    dispatch(getFollowers(userId || id || user.id));
    dispatch(getMyFollowers(user.id));
    dispatch(getFriends());
    dispatch(getGallery());
  }, [dispatch, user, id, userId]);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleToggleFollow = (followerId, userid) => {
    dispatch(onToggleFollow({ followerId, userid }));
    if (followers.includes(user.id)) {
      const noti = generateNotification(user.displayName + ' a te sigue', '', user.avatar, 'avatar', userid, null);
      createNotification(noti);
    }
  };

  const handleToggleMyFollow = (followerId, userid) => {
    dispatch(onToggleMyFollow({ followerId, userid }));
    if (followers.includes(user.id)) {
      const noti = generateNotification(user.displayName + ' te sigue', '', user.avatar, 'avatar', userid, null);
      createNotification(noti);
    }
  };
  /*
  const handleFindFriends = (event) => {
    setFindFriends(event.target.value);
  }; */

  if (!myProfile) {
    return null;
  }

  if (!profile) {
    return null;
  }
  const PROFILE_TABS = [
    {
      value: 'Perfil',
      icon: <Icon icon={roundAccountBox} width={20} height={20} />,
      component: <Profile myProfile={Boolean(id) ? profile : myProfile} posts={posts} />
    },
    {
      value: 'seguidores',
      icon: <Icon icon={heartFill} width={20} height={20} />,
      component: <ProfileFollowers followers={followers} onToggleMyFollow={handleToggleMyFollow} />
    }
    /* {
      value: 'friends',
      icon: <Icon icon={peopleFill} width={20} height={20} />,
      component: <ProfileFriends friends={friends} findFriends={findFriends} onFindFriends={handleFindFriends} />
    },
    {
      value: 'gallery',
      icon: <Icon icon={roundPermMedia} width={20} height={20} />,
      component: <ProfileGallery gallery={gallery} />
    } */
  ];

  return (
    <Page title="User: Profile | Bunka">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Profile"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'User', href: PATH_DASHBOARD.user.root },
            { name: Boolean(id) ? profile?.displayName : user.displayName }
          ]}
        />
        <Card
          sx={{
            mb: 3,
            height: 280,
            position: 'relative'
          }}
        >
          <ProfileCover myProfile={Boolean(id) ? profile : myProfile} />

          <TabsWrapperStyle>
            {user.id !== id && (
              <Button
                sx={{ marginRight: '2%' }}
                startIcon={followers.includes(user.id) && <Icon icon={checkmarkFill} />}
                onClick={() => {
                  handleToggleFollow(user.id, id);
                }}
              >
                {followers.includes(user.id) ? <>Seguido</> : <>Seguir</>}
              </Button>
            )}
            <Tabs
              value={currentTab}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              onChange={handleChangeTab}
            >
              {PROFILE_TABS.map((tab) => (
                <Tab disableRipple key={tab.value} value={tab.value} icon={tab.icon} label={capitalCase(tab.value)} />
              ))}
            </Tabs>
          </TabsWrapperStyle>
        </Card>

        {PROFILE_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
    </Page>
  );
}
