import React, { useState } from 'react';
import { Dialog, DialogContent, ListItem, ListItemText, ListItemIcon, Box } from '@mui/material';
import KanbanSprint from 'src/pages/dashboard/KanbanSprint';
import { Icon } from '@iconify/react';
import kanban from '@iconify/icons-ant-design/fund-projection-screen';
import PropTypes from 'prop-types';

KanbanSprintD.propTypes = {
  idEmpresa: PropTypes.string,
  spid: PropTypes.string
};

export default function KanbanSprintD({ idEmpresa, spid }) {
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };
  const clickClose = () => {
    setOpen(false);
  };
  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
        <ListItemIcon>
          <Icon icon={kanban} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Ejecucion</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <Box sx={{ minWidth: '50vw' }} />
        <DialogContent>
          <KanbanSprint idEmpresa={idEmpresa} spid={spid} />
        </DialogContent>
      </Dialog>
    </>
  );
}
