import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { storage as st } from 'src/contexts/FirebaseContext';
import { UploadSingleFile } from 'src/components/upload';
import FileView from './FileView';
import PropTypes from 'prop-types';

FileList.propTypes = {
  direction: PropTypes.string.isRequired,
  idBucket: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  fileType: PropTypes.string
};

export default function FileList({ direction, idBucket, onSelect, fileType }) {
  const [msg, setMsg] = useState('');
  const [filev, setFilev] = useState(null);
  const [listFiles, setListFiles] = useState([]);

  const BucketRef = direction + '/' + idBucket;

  // metodos

  const getListFiles = useCallback(async () => {
    const ref = st.ref().child(BucketRef);
    await ref.listAll().then((bucket) => {
      const list = bucket._delegate.items;
      const data = [];
      list.forEach((item) => {
        const path = item._location.path_;
        const link = st.ref().child(path);
        const routeSplitted = path.split('/');
        const nameFile = routeSplitted[routeSplitted.length - 1];
        link.getDownloadURL().then((url) => {
          data.push({ url, path, nameFile });
        });
      });
      setListFiles(data);
    });
  }, [BucketRef]);

  useEffect(() => {
    getListFiles();
  }, [getListFiles]);

  /* const upload = async () => {
    if (filev.file !== null) {
      const refFv = st.ref().child(BucketRef + '/' + filev.file.name);
      setMsg('subiendo archivo...');
      await refFv.put(filev.file).then(() => {
        setMsg('archivo subido');
        getListFiles();
        setTimeout(() => {
          setMsg('');
        }, 3000);
      });
    } else {
      setMsg('inserta un archivo');
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  }; */
  const uploadF = async (file) => {
    if (file !== null) {
      console.log(file);
      const refFv = st.ref().child(BucketRef + '/' + file.name);
      setMsg('subiendo archivo...');
      await refFv.put(file).then(() => {
        setMsg('archivo subido');
        refFv.getDownloadURL().then((url) => {
          onSelect(url);
        });
        getListFiles();
        setTimeout(() => {
          setMsg('');
        }, 3000);
      });
    } else {
      setMsg('inserta un archivo');
      setTimeout(() => {
        setMsg('');
      }, 3000);
    }
  };

  return (
    <>
      <Box component="div">
        {msg}
        <UploadSingleFile
          file={filev}
          onDrop={(file) => {
            setFilev({
              file: file[0],
              preview: URL.createObjectURL(file[0])
            });
            uploadF(file[0]);
          }}
          caption="(solo video *.mp4 y otros seran aceptadas)"
          accept={fileType}
        />
      </Box>
      <Box>
        <Typography>Lista de archivos</Typography>
        <Grid container spacing={3}>
          {listFiles.map((file, i) => (
            <FileView key={i} file={file} onSelect={onSelect} getListFiles={getListFiles} />
          ))}
        </Grid>
      </Box>
    </>
  );
}
