import React from 'react';
import { TextField, Grid, Box, Button, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Circle } from '@mui/icons-material';
import PropTypes from 'prop-types';

StepOne.propTypes = {
  formik: PropTypes.object,
  next: PropTypes.func,
  back: PropTypes.func
};

export default function StepOne({ formik, back, next }) {
  const { errors, touched, getFieldProps } = formik;

  const preguntas = ['¿Comunica porque existimos?', '¿Inspira a nuestra gente?', '¿Puede memorizarse fácilmente?'];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="h4" sx={{ textAlign: 'center' }}>
              Visión
            </Typography>
            <Box>
              Una buena Visiíon provee de inspiración para las estratégias y las operaciones diarias de tu organización
              y debería describir un mundo ideal
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>Tips: Mantenlo corto y apasionado</Box>
          <Box>Atrevete a soñar</Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            multiline
            minRows={3}
            maxRows={5}
            label="Visión"
            {...getFieldProps('vision')}
            fullWidth
            error={Boolean(touched.vision && errors.vision)}
            helperText={touched.vision && errors.vision}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography>Prueba tu visión</Typography>
            <List>
              {preguntas.map((p) => (
                <ListItem key={p}>
                  <ListItemIcon>
                    <Circle />
                  </ListItemIcon>
                  <ListItemText>{p}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ float: 'right', padding: '3%', margin: '2%', display: 'flex' }}>
        <Button variant="contained" onClick={back} sx={{ margin: '2%' }}>
          Atras
        </Button>
        <Button variant="contained" onClick={next} sx={{ margin: '2%' }}>
          Siguiente
        </Button>
      </Box>
    </>
  );
}
