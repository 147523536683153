import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  IconButton,
  ListItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogContent,
  FormControlLabel,
  Stack,
  Card,
  FormHelperText,
  Typography,
  Switch,
  Box,
  TextField,
  Grid,
  MenuItem
} from '@mui/material';
import { UploadAvatar } from '../../../../upload';
// utils
import { fData } from '../../../../../utils/formatNumber';
import { LoadingButton } from '@mui/lab';
// icon
import { Icon } from '@iconify/react';
import editI from '@iconify/icons-ant-design/edit-filled';
import addPI from '@iconify/icons-ant-design/user-add';
//
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { firestore, storage as st } from 'src/contexts/FirebaseContext';
import { v1 as uuidv1 } from 'uuid';
import ProfileView from '../departamentos/utils/ProfileView';
import PropTypes from 'prop-types';

AltaPerfil.propTypes = {
  idEmpresa: PropTypes.string,
  iddoc: PropTypes.string,
  edit: PropTypes.bool,
  listB: PropTypes.bool,
  iconB: PropTypes.bool,
  normalB: PropTypes.bool
};

export default function AltaPerfil({ idEmpresa, iddoc, edit = false, listB = false, iconB = false, normalB = false }) {
  const [open, setOpen] = useState(false);
  const [fileS, setFile] = useState(null);

  const [perfiles, setPerfiles] = useState([]);

  useEffect(() => {
    async function getPerfiles() {
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('perfiles')
        .onSnapshot((queryS) => {
          const perfs = [];
          queryS.forEach((doc) => {
            if (doc.id !== iddoc) {
              perfs.push(doc.id);
            }
          });
          setPerfiles(perfs);
        });
    }
    getPerfiles();
  }, [idEmpresa, iddoc]);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  const validation = yup.object().shape({
    nombre: yup.string().required('requerido'),
    descripcion: yup.string().min(10).required('requerido')
  });

  const formik = useFormik({
    validationSchema: validation,
    initialValues: {
      nombre: '',
      descripcion: '',
      about: '',
      cubierto: false,
      school: '',
      jefep: [],
      miembros: []
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        if (edit) {
          if (fileS !== null) {
            const imgRef = `empresas/${idEmpresa}/perfiles/${iddoc}`;
            const refF = st.ref().child(imgRef);
            await refF.put(fileS.file);
          }
          await firestore
            .collection('empresas')
            .doc(idEmpresa)
            .collection('perfiles')
            .doc(iddoc)
            .update({ ...values });
        } else {
          const newDocId = uuidv1();
          if (fileS !== null) {
            const imgRef = `empresas/${idEmpresa}/perfiles/${newDocId}`;
            const refF = st.ref().child(imgRef);
            await refF.put(fileS.file);
          }
          await firestore
            .collection('empresas')
            .doc(idEmpresa)
            .collection('perfiles')
            .doc(newDocId)
            .set({ ...values })
            .then(() => {
              resetForm();
            });
        }
        clickClose();
        setSubmitting(false);
      } catch (err) {
        setSubmitting(false);
      }
    }
  });

  const { errors, touched, getFieldProps, isSubmitting, handleSubmit, setFieldValue } = formik;

  useEffect(() => {
    async function fetchDoc() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('perfiles')
        .doc(iddoc)
        .get()
        .then((doc) => {
          const { nombre, descripcion, miembros, cubierto, jefep } = doc.data();
          setFieldValue('nombre', nombre);
          setFieldValue('descripcion', descripcion);
          setFieldValue('jefep', jefep || []);
          setFieldValue('miembros', miembros || []);
          setFieldValue('cubierto', cubierto || false);
        });
    }
    if (edit) {
      fetchDoc();
    }
    // eslint-disable-next-line
  }, [edit, idEmpresa, iddoc]);

  const handleDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setFile({ file, preview: URL.createObjectURL(file) });
    }
  }, []);

  return (
    <>
      {iconB && (
        <IconButton onClick={clickOpen} sx={{ color: '#637381' }}>
          <Icon icon={edit ? editI : addPI} width={23} height={23} />
        </IconButton>
      )}
      {listB && (
        <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
          <ListItemIcon>
            <Icon icon={edit ? editI : addPI} width={23} height={23} />
          </ListItemIcon>
          <ListItemText>{edit ? 'Editar' : 'Alta de Perfil'}</ListItemText>
        </ListItem>
      )}
      {normalB && (
        <Button onClick={clickOpen} sx={{ color: '#637381' }}>
          <Icon icon={edit ? editI : addPI} width={23} height={23} />
          {edit ? 'Editar' : 'Alta de Perfil'}
        </Button>
      )}
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <DialogContent>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
                    <UploadAvatar
                      accept="image/*"
                      file={fileS}
                      maxSize={3145728}
                      onDrop={handleDrop}
                      error={Boolean(touched.photoURL && errors.photoURL)}
                      caption={
                        <Typography
                          variant="caption"
                          sx={{
                            mt: 2,
                            mx: 'auto',
                            display: 'block',
                            textAlign: 'center',
                            color: 'text.secondary'
                          }}
                        >
                          Permitido *.jpeg, *.jpg, *.png, *.gif
                          <br /> max size of {fData(3145728)}
                        </Typography>
                      }
                    />

                    <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                      {touched.photoURL && errors.photoURL}
                    </FormHelperText>

                    <FormControlLabel
                      control={<Switch {...getFieldProps('cubierto')} color="primary" />}
                      labelPlacement="start"
                      label="Cubierto"
                      sx={{ mt: 5 }}
                    />
                  </Card>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={{ xs: 2, md: 3 }}>
                      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                        <TextField
                          fullWidth
                          label="Nombre"
                          {...getFieldProps('nombre')}
                          error={Boolean(errors.nombre) && Boolean(touched.nombre)}
                          helperText={Boolean(touched.nombre) && errors.nombre}
                        />
                        <TextField
                          fullWidth
                          label="Descripcion"
                          {...getFieldProps('descripcion')}
                          error={Boolean(errors.descripcion) && Boolean(touched.descripcion)}
                          helperText={Boolean(touched.descripcion) && errors.descripcion}
                        />
                      </Stack>
                      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                        <TextField
                          {...getFieldProps('about')}
                          fullWidth
                          multiline
                          minRows={4}
                          maxRows={4}
                          label="Sobre El Perfil"
                        />
                      </Stack>
                      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                        <TextField fullWidth label="Nivel de Escolaridad" {...getFieldProps('school')} />
                      </Stack>
                      <TextField label="jefe" select {...getFieldProps('jefe')}>
                        {perfiles.map((option) => (
                          <MenuItem key={option} value={option}>
                            <ProfileView idEmpresa={idEmpresa} idP={option} />
                          </MenuItem>
                        ))}
                      </TextField>
                    </Stack>

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                      <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        Guardar Cambios
                      </LoadingButton>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Dialog>
    </>
  );
}
