import React from 'react';
import { Dialog, DialogContent, ListItem, ListItemText, ListItemIcon, Box } from '@mui/material';
import { Icon } from '@iconify/react';
import kanban from '@iconify/icons-ant-design/fund-projection-screen';
import KanbanEmpresa from 'src/pages/dashboard/KanbanEmpresa';
import PropTypes from 'prop-types';

KanbanED.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  idEmpresa: PropTypes.string,
  empresa: PropTypes.object
};

export default function KanbanED({ open = false, setOpen, idEmpresa, empresa }) {
  const clickClose = () => {
    setOpen(false);
  };
  const clickOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#506070' }}>
        <ListItemIcon>
          <Icon icon={kanban} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Objetivos</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <Box sx={{ minWidth: '50vw' }} />
        <DialogContent>
          <KanbanEmpresa idEmpresa={idEmpresa} empresa={empresa} />
        </DialogContent>
      </Dialog>
    </>
  );
}
