import React from 'react';
import { Card, List } from '@mui/material';
import AltaDepartamento from '../AltaDepartamento';
import PerfilesD from './PerfilesD';
import PropTypes from 'prop-types';

MenuDepto.propTypes = {
  depto: PropTypes.object,
  idEmpresa: PropTypes.string
};

export default function MenuDepto({ idEmpresa, depto }) {
  const { id } = depto;
  return (
    <Card>
      <List>
        <AltaDepartamento idEmpresa={idEmpresa} iddoc={id} edit listB />
        <PerfilesD idEmpresa={idEmpresa} depto={depto} />
      </List>
    </Card>
  );
}
