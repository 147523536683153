import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Grid,
  Stack,
  LinearProgress,
  Card,
  CardHeader,
  CardContent,
  AppBar,
  Toolbar,
  Typography,
  MenuItem,
  List,
  ListItemText,
  ListItemIcon,
  ListItem,
  Tooltip,
  TextField,
  FormControlLabel,
  Checkbox,
  OutlinedInput
} from '@mui/material';
import { styled } from '@mui/styles';
import { Close } from '@mui/icons-material';
import { MobileDateRangePicker } from '@mui/lab';
import { MIconButton } from '../../@material-extend';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { Involucrados, KRsList, ObjetivosAsociados } from './controlpanel';
import { DocView, NewDocsForm } from '../docs';
import { ProgressChart, KRsChart } from './controlpanel/charts';
import { TodoTasks } from 'src/components/_dashboard/general/TodoTask';
import { useDispatch } from 'src/redux/store';
import { getBoard } from 'src/redux/slices/kobjs';
import { getBoard as getBoardS } from 'src/redux/slices/kanbansprint';
import { SprintList } from './components';
import KanbanTaskAttachments from './KanbanTaskAttachments';
//
import { Icon } from '@iconify/react';
import eyeoutline from '@iconify/icons-eva/eye-outline';
import plusFill from '@iconify/icons-eva/plus-fill';
import krsI from '@iconify/icons-ant-design/control-fill';
import personI from '@iconify/icons-eva/person-fill';
import tasksI from '@iconify/icons-eva/file-text-fill';
import alineacionesI from '@iconify/icons-ant-design/file-sync-outlined';
import heartoutline from '@iconify/icons-eva/heart-outline';
import heartfill from '@iconify/icons-eva/heart-fill';
import checkmarkCircle2Outline from '@iconify/icons-eva/checkmark-circle-2-outline';
import radioButtonOffOutline from '@iconify/icons-eva/radio-button-off-outline';
import flash from '@iconify/icons-eva/flash-fill';
import flashoff from '@iconify/icons-eva/flash-off-fill';
import agile from '@iconify/icons-ant-design/dingding';
import deleteI from '@iconify/icons-eva/trash-fill';
import { fPercent } from 'src/utils/formatNumber';
import { firestore } from 'src/contexts/FirebaseContext';
import { useDatePicker, DisplayTime } from './KanbanTaskAdd';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { createNotification, generateNotification } from 'src/utils/notifications';
import useAuth from 'src/hooks/useAuth';
import Channel from '../social/Channel';
import UserElement from 'src/components/_dashboard/general/UserElement';
import UserAvatar from 'src/components/_dashboard/general/UserAvatar';
import { captureEventC } from 'src/utils/events';

const PRIORITIZES = ['baja', 'media', 'alta', 'no definido'];

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary
}));

const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'left'
});

KanbanKRsTasks.propTypes = {
  card: PropTypes.object.isRequired,
  cardId: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  idEmpresa: PropTypes.string,
  showB: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onDeleteTask: PropTypes.func
};

export default function KanbanKRsTasks({
  card,
  cardId,
  projectId,
  idEmpresa,
  showB = false,
  open = false,
  setOpen,
  onDeleteTask
}) {
  const { user } = useAuth();
  const { krs } = card;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { spid } = useParams();
  const [name, setName] = useState(card.name || '');
  const [completed, setCompleted] = useState(card.completed);
  const [haciendo, setHaciendo] = useState(card.haciendo || false);
  const [due, setDue] = useState(card.due || [null, null]);
  const [attachments, setAttachments] = useState(card.attachments || []);
  const [prioritize, setPrioritize] = useState(card.prioritize || 'no definido');
  const [openDKR, setOpenDKR] = useState(false);
  const [openInv, setOpenInv] = useState(false);
  const [openTT, setOpenTT] = useState(false);
  const [openObj, setOpenObj] = useState(false);
  const [openSP, setOpenSP] = useState(false);
  const [progreso, setProgreso] = useState(0);
  const [docExist, setDocExists] = useState(false);
  const [docC, setDoc] = useState('');
  const [docCS, setDocS] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setResponsable(card.responsable || []);
    setAttachments(card.attachments || []);
  }, [card]);

  const handleChangePrioritize = async (event) => {
    setPrioritize(event.target.value);
    captureEventC('change-priority-objetive', 'empresa', user.id);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ prioritize: event.target.value })
      .then(() => {
        if (spid) {
          dispatch(getBoardS(idEmpresa, spid));
        } else {
          dispatch(getBoard(projectId));
        }
      });
  };

  const handleChangeComplete = async (event) => {
    setCompleted(event.target.checked);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ completed: event.target.checked, haciendo: false })
      .then(() => {
        if (spid) {
          dispatch(getBoardS(idEmpresa, spid));
        } else {
          dispatch(getBoard(projectId));
        }
        if (event.target.checked) {
          enqueueSnackbar('Tarea completada', { variant: 'success' });
        }
      });
    if (event.target.checked) {
      const informado = card.informado || [];
      const lider = card.lider || [];
      const ProjectId = card.pid || projectId;
      informado.forEach((u) => {
        const noti = generateNotification(
          'se ha completado el objetivo',
          'objetivo: ' + name,
          u.avatar,
          'avatar',
          u.id,
          PATH_DASHBOARD.entorno.empresa + idEmpresa + '/proyecto/' + ProjectId
        );
        createNotification(noti);
      });
      lider.forEach((u) => {
        const noti = generateNotification(
          'se ha completado el objetivo',
          'objetivo: ' + name,
          u.avatar,
          'avatar',
          u.id,
          PATH_DASHBOARD.entorno.empresa + idEmpresa + '/proyecto/' + ProjectId
        );
        createNotification(noti);
      });
    }
  };

  const handleChangeHaciendo = async (event) => {
    setHaciendo(event.target.checked);
    const eventT = event.target.checked ? 'doing-objetive' : 'not-doing-objetive';
    captureEventC(eventT, 'empresa', user.id);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ haciendo: event.target.checked })
      .then(() => {
        if (spid) {
          dispatch(getBoardS(idEmpresa, spid));
        } else {
          dispatch(getBoard(projectId));
        }
        enqueueSnackbar('Marcado como haciendo', { variant: 'success' });
      });
    if (event.target.checked) {
      const informado = card.informado || [];
      const lider = card.lider || [];
      const ProjectId = card.pid || projectId;
      informado.forEach((u) => {
        const noti = generateNotification(
          'se ha marcado como haciendo un objetivo',
          'objetivo: ' + name,
          u.avatar,
          'avatar',
          u.id,
          PATH_DASHBOARD.entorno.empresa + idEmpresa + '/proyecto/' + ProjectId
        );
        createNotification(noti);
      });
      lider.forEach((u) => {
        const noti = generateNotification(
          'se ha marcado como haciendo un objetivo',
          'objetivo: ' + name,
          u.avatar,
          'avatar',
          u.id,
          PATH_DASHBOARD.entorno.empresa + idEmpresa + '/proyecto/' + ProjectId
        );
        createNotification(noti);
      });
    }
  };

  const firestoresetduedate = async (val) => {
    await firestore.collection('kobjsCards').doc(cardId).update({
      due: val
    });
    if (spid) {
      dispatch(getBoardS(idEmpresa, spid));
    } else {
      dispatch(getBoard(projectId));
    }
  };

  useEffect(() => {
    async function getUsers() {
      await firestore
        .collection('proyectosempresa')
        .doc(card.pid || projectId)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const membersids = doc.data().membersids || [];
            const dat = [];
            membersids.forEach((mem) => dat.push({ id: mem }));
            setUsers(dat);
          }
        });
    }
    getUsers();
  }, [projectId, card]);

  useEffect(() => {
    async function registrarCambios(porcent) {
      await firestore
        .collection('kobjsCards')
        .doc(cardId)
        .collection('krProgress')
        .add({ date: new Date(), avance: porcent });
    }
    if (krs !== undefined) {
      let progress = 0;
      krs.forEach((kr) => {
        const max = kr.max || 100;
        const min = kr.min || 0;
        progress += ((kr.avance - min) / max) * 100;
      });
      const porcentaje = progress / krs.length;
      if (porcentaje !== progreso) {
        registrarCambios(porcentaje);
      }
      setProgreso(porcentaje);
    }
    // eslint-disable-next-line
  }, [krs, cardId]);

  useEffect(() => {
    async function getDoc() {
      await firestore
        .collection('kobjsCards')
        .doc(cardId)
        .collection('docs')
        .doc('doc')
        .onSnapshot((doc) => {
          setDocExists(doc.exists);
          if (doc.exists) {
            const { content, secciones } = doc.data();
            setDoc(content);
            setDocS(secciones || []);
          }
        });
    }
    getDoc();
  }, [cardId]);

  const {
    dueDate,
    startTime,
    endTime,
    isSameDays,
    isSameMonths,
    onChangeDueDate,
    openPicker,
    onOpenPicker,
    onClosePicker
  } = useDatePicker({
    date: due
  });

  const [openMenur, setOpenMenur] = useState(false);
  const [responsable, setResponsable] = useState(card.responsable || []);
  const [diabledMenuOptions, setDisabledMenuOptions] = useState(false);
  const [likes, setLikes] = useState(card.likes || []);

  const like = async () => {
    const newArr = [...likes];
    if (newArr.includes(user.id)) {
      setLikes(newArr.filter((d) => d !== user.id));
      await firestore
        .collection('kobjsCards')
        .doc(cardId)
        .update({ likes: newArr.filter((d) => d !== user.id) });
    } else {
      newArr.push(user.id);
      setLikes(newArr);
      await firestore.collection('kobjsCards').doc(cardId).update({ likes: newArr });
    }
    if (spid) {
      dispatch(getBoardS(idEmpresa, spid));
    } else {
      dispatch(getBoard(projectId));
    }
  };

  const addResponsable = async (user) => {
    setDisabledMenuOptions(true);
    const newArr = [...responsable, user];
    captureEventC('add-responsable-objetive', 'empresa', user.id);
    setResponsable(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ responsable: newArr })
      .then(() => {
        if (spid) {
          dispatch(getBoardS(idEmpresa, spid));
        } else {
          dispatch(getBoard(projectId));
        }
        setDisabledMenuOptions(false);
      });

    const projectID = card.pid || projectId;
    const noti = generateNotification(
      'se te ha asigando un objetivo en Responsable',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + idEmpresa + '/proyecto/' + projectID
    );
    createNotification(noti);
  };

  const removeResponsable = async (user) => {
    const newArr = [...responsable.filter((as) => as.id !== user.id)];
    captureEventC('remove-responsable-objetive', 'empresa', user.id);
    setResponsable(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ responsable: newArr })
      .then(() => {
        if (spid) {
          dispatch(getBoardS(idEmpresa, spid));
        } else {
          dispatch(getBoard(projectId));
        }
      });
    const projectID = card.pid || projectId;
    const noti = generateNotification(
      'se te han quitado en un objetivo en responsable',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + idEmpresa + '/proyecto/' + projectID
    );
    createNotification(noti);
  };

  const handlecardNameChange = async (event) => {
    setName(event.target.value);
    setTimeout(async () => {
      await firestore
        .collection('kobjsCards')
        .doc(cardId)
        .update({ name: event.target.value })
        .then(() => {
          if (spid) {
            dispatch(getBoardS(idEmpresa, spid));
          } else {
            dispatch(getBoard(projectId));
          }
        });
      captureEventC('change-name-objetive', 'empresa', user.id);
    }, 1000);
  };

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  const clickOpenDKR = () => {
    setOpenDKR(true);
  };

  const clickCloseDKR = () => {
    setOpenDKR(false);
  };

  const clickOpenInv = () => {
    setOpenInv(true);
  };

  const clickCloseInv = () => {
    setOpenInv(false);
  };

  const clickOpenTT = () => {
    setOpenTT(true);
  };

  const clickCloseTT = () => {
    setOpenTT(false);
  };

  const clickOpenObj = () => {
    setOpenObj(true);
  };

  const clickCloseObj = () => {
    setOpenObj(false);
  };

  const clickOpenSP = () => {
    setOpenSP(true);
  };

  return (
    <>
      {showB && (
        <Button variant="contained" onClick={clickOpen}>
          <Icon icon={eyeoutline} color="white" width={30} height={30} />
        </Button>
      )}
      <Dialog open={open} onClose={clickClose} maxWidth={1250}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton onClick={clickClose}>
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box component="div" sx={{ width: '85vw' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Card>
                  <CardContent>
                    <LinearProgress variant="determinate" value={progreso} />
                    {fPercent(progreso)}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={3}>
                <Card>
                  <CardContent>
                    <List>
                      <ListItem button onClick={clickOpenDKR} sx={{ margin: '2px', color: '#637381' }}>
                        <ListItemIcon>
                          <Icon icon={krsI} width={20} height={20} />
                        </ListItemIcon>
                        <ListItemText>Resultado clave ({krs?.length || 0})</ListItemText>
                      </ListItem>
                      <ListItem button onClick={clickOpenInv} sx={{ margin: '2px', color: '#637381' }}>
                        <ListItemIcon>
                          <Icon icon={personI} width={20} height={20} />
                        </ListItemIcon>
                        <ListItemText>Personas</ListItemText>
                      </ListItem>
                      <ListItem button onClick={clickOpenTT} sx={{ margin: '2px', color: '#637381' }}>
                        <ListItemIcon>
                          <Icon icon={tasksI} width={20} height={20} />
                        </ListItemIcon>
                        <ListItemText>Tareas</ListItemText>
                      </ListItem>
                      <ListItem button onClick={clickOpenSP} sx={{ margin: '2px', color: '#637381' }}>
                        <ListItemIcon>
                          <Icon icon={agile} width={20} height={20} />
                        </ListItemIcon>
                        <ListItemText>Agil</ListItemText>
                      </ListItem>
                      <ListItem button onClick={clickOpenObj} sx={{ margin: '2px', color: '#637381' }}>
                        <ListItemIcon>
                          <Icon icon={alineacionesI} width={20} height={20} />
                        </ListItemIcon>
                        <ListItemText>Alineaciones</ListItemText>
                      </ListItem>
                      {!docExist && <NewDocsForm cardId={cardId} listB />}
                      {docExist && <DocView content={docC} secciones={docCS} cardId={cardId} listB />}
                      <Channel collection="pobjsposts" doc={projectId + cardId} />
                    </List>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={9}>
                <Card sx={{ p: 3 }}>
                  <CardHeader
                    title={
                      <>
                        <OutlinedInput
                          fullWidth
                          multiline
                          size="small"
                          placeholder="Nombre de la tarea"
                          value={name}
                          onChange={handlecardNameChange}
                          sx={{
                            typography: 'h6',
                            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' }
                          }}
                        />
                      </>
                    }
                    action={
                      <>
                        <Tooltip title="Like">
                          <FormControlLabel
                            sx={{ position: 'relative' }}
                            value={likes.includes(user.id)}
                            checked={likes.includes(user.id)}
                            control={
                              <Checkbox
                                disableRipple
                                value={likes.includes(user.id)}
                                checked={likes.includes(user.id)}
                                icon={<Icon icon={heartoutline} width={20} height={20} />}
                                checkedIcon={<Icon icon={heartfill} width={20} height={20} />}
                                onChange={like}
                                sx={{ position: 'relative' }}
                              />
                            }
                            label=""
                          />
                        </Tooltip>
                        <Tooltip title={completed ? 'Completada' : 'Marcar como completada'}>
                          <FormControlLabel
                            sx={{ position: 'relative' }}
                            value={completed}
                            checked={completed}
                            control={
                              <Checkbox
                                disableRipple
                                checked={completed}
                                icon={<Icon icon={radioButtonOffOutline} width={20} height={20} />}
                                checkedIcon={<Icon icon={checkmarkCircle2Outline} width={20} height={20} />}
                                onChange={handleChangeComplete}
                                sx={{ position: 'relative' }}
                              />
                            }
                            label=""
                          />
                        </Tooltip>
                        <Tooltip title={haciendo ? 'Marcar como no haciendo' : 'Marcar como haciendo'}>
                          <FormControlLabel
                            sx={{ position: 'relative' }}
                            value={haciendo}
                            checked={haciendo}
                            control={
                              <Checkbox
                                disableRipple
                                checked={haciendo}
                                value={haciendo}
                                icon={<Icon icon={flashoff} width={20} height={20} />}
                                checkedIcon={<Icon icon={flash} width={20} height={20} />}
                                onChange={handleChangeHaciendo}
                              />
                            }
                            label=""
                          />
                        </Tooltip>
                        <Tooltip title="Eliminar objetivo">
                          <IconButton onClick={onDeleteTask}>
                            <Icon icon={deleteI} width={23} height={23} />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                  />

                  <Stack spacing={2}>
                    <RowStyle>
                      <LabelStyle> Fecha de entrega</LabelStyle>
                      <>
                        {startTime && endTime ? (
                          <DisplayTime
                            startTime={startTime}
                            endTime={endTime}
                            isSameDays={isSameDays}
                            isSameMonths={isSameMonths}
                            onOpenPicker={onOpenPicker}
                            sx={{ typography: 'body2' }}
                          />
                        ) : (
                          <Tooltip title="Fecha de entrega">
                            <MIconButton
                              onClick={onOpenPicker}
                              sx={{
                                p: 1,
                                ml: 0.5,
                                border: (theme) => `dashed 1px ${theme.palette.divider}`
                              }}
                            >
                              <Icon icon={plusFill} width={20} height={20} />
                            </MIconButton>
                          </Tooltip>
                        )}

                        <MobileDateRangePicker
                          open={openPicker}
                          onClose={onClosePicker}
                          onOpen={onOpenPicker}
                          value={dueDate}
                          onChange={(value) => {
                            onChangeDueDate(value);
                            setDue(value);
                            firestoresetduedate(value);
                          }}
                          renderInput={() => {}}
                        />
                      </>
                    </RowStyle>

                    <RowStyle>
                      <LabelStyle>Prioridad</LabelStyle>
                      <TextField
                        fullWidth
                        select
                        size="small"
                        value={prioritize}
                        onChange={handleChangePrioritize}
                        sx={{
                          '& svg': { display: 'none' },
                          '& fieldset': { display: 'none' },
                          '& .MuiSelect-select': { p: 0, display: 'flex', alignItems: 'center' }
                        }}
                      >
                        {PRIORITIZES.map((option) => (
                          <MenuItem key={option} value={option}>
                            <Box
                              sx={{
                                mr: 1,
                                width: 14,
                                height: 14,
                                borderRadius: 0.5,
                                bgcolor: 'error.main',
                                ...(option === 'baja' && { bgcolor: 'info.main' }),
                                ...(option === 'media' && { bgcolor: 'warning.main' }),
                                ...(option === 'no definido' && { bgcolor: 'gray' })
                              }}
                            />
                            <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                              {option}
                            </Typography>
                          </MenuItem>
                        ))}
                      </TextField>
                    </RowStyle>
                    <RowStyle>
                      <Stack direction="row">
                        <LabelStyle sx={{ mt: 1.5 }}>Responsable</LabelStyle>
                        <Stack direction="row" flexWrap="wrap" alignItems="center">
                          {responsable.map((u) => (
                            <UserAvatar key={u.id} userid={u.id} removeUser={removeResponsable} />
                          ))}
                          <Tooltip title="Agregar responsable">
                            <MIconButton
                              disabled={responsable.length !== 0}
                              sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                              onClick={() => setOpenMenur(true)}
                            >
                              <Icon icon={plusFill} width={20} height={20} />
                            </MIconButton>
                          </Tooltip>
                          <Dialog open={openMenur} onClose={() => setOpenMenur(false)}>
                            <DialogContent>
                              <List>
                                {users
                                  .filter((u) => !Boolean(responsable.find((d) => d.id === u.id)))
                                  .map((u) => {
                                    return (
                                      <MenuItem
                                        key={'userr' + u.id}
                                        onClick={() => {
                                          addResponsable(u);
                                          setOpenMenur(false);
                                        }}
                                        disabled={diabledMenuOptions}
                                      >
                                        <UserElement userid={u.id} />
                                      </MenuItem>
                                    );
                                  })}
                              </List>
                            </DialogContent>
                          </Dialog>
                        </Stack>
                      </Stack>
                    </RowStyle>
                    <Stack direction="row">
                      <LabelStyle sx={{ mt: 2 }}>Archivos adjuntos</LabelStyle>
                      <Stack direction="row" flexWrap="wrap">
                        <KanbanTaskAttachments
                          attachments={attachments}
                          setAttachments={setAttachments}
                          cardId={cardId}
                          projectId={projectId}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} md={12}>
                <ProgressChart cardId={cardId} />
              </Grid>
            </Grid>
          </Box>
          <DialogActions>
            <Dialog open={openObj} onClose={clickCloseObj} maxWidth="lg">
              <DialogContent>{idEmpresa && <ObjetivosAsociados idEmpresa={idEmpresa} cardId={cardId} />}</DialogContent>
            </Dialog>
            <Dialog open={openTT} onClose={clickCloseTT} maxWidth="lg">
              <DialogContent>
                <Stack spacing={3}>
                  <TodoTasks collection="kobjsCards" docId={cardId} />
                </Stack>
              </DialogContent>
            </Dialog>
            <Dialog open={openInv} onClose={clickCloseInv} maxWidth="lg">
              <DialogContent>
                <Stack spacing={3}>
                  <Involucrados card={card} idEmpresa={idEmpresa} projectId={projectId} />
                </Stack>
              </DialogContent>
            </Dialog>
            <Dialog open={openDKR} onClose={clickCloseDKR} maxWidth="lg">
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={3}>
                      <KRsList card={card} cardId={cardId} projectId={projectId} />
                    </Stack>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <KRsChart cardId={cardId} />
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
            <SprintList idEmpresa={idEmpresa} open={openSP} setOpen={setOpenSP} cardId={cardId} />
            <Button onClick={clickClose}>Cerrar</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
