import React, { useState, useEffect, useCallback } from 'react';
import { Grid } from '@mui/material';
import CTACompM from './CTACompM';
import usePlan from 'src/hooks/usePlan';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

CTAShow.propTypes = {
  modulo: PropTypes.string,
  etapaCTA: PropTypes.string
};

export default function CTAShow({ modulo, etapaCTA }) {
  const [ctas, setCTAs] = useState([]);
  const { CTAState } = usePlan();

  const getExcluded = useCallback(() => {
    return CTAState.excluded || [];
  }, [CTAState]);

  useEffect(() => {
    async function getCTAs() {
      await firestore
        .collection('ctas')
        .where('show', '==', true)
        .where('modulo', '==', modulo)
        .where('etapa', '==', etapaCTA)
        .onSnapshot((queryS) => {
          const datCta = [];
          queryS.forEach((doc) => {
            if (!getExcluded().includes(doc.id)) {
              datCta.push({ id: doc.id, ...doc.data() });
            }
          });
          setCTAs(datCta);
        });
    }
    getCTAs();
  }, [etapaCTA, modulo, getExcluded]);

  return (
    <Grid container spacing={3}>
      {ctas.map((CTA) => (
        <Grid key={CTA.id} item xs={12}>
          <CTACompM CTA={CTA} />
        </Grid>
      ))}
    </Grid>
  );
}
