import React, { useState } from 'react';
import { IconButton, Dialog, DialogContent, Box, Typography, Grid, List, Card, Avatar } from '@mui/material';
import { ModControlPanelD } from './modulos';
import { UserCRM } from './crm';
import ProfileCard from 'src/components/_dashboard/general/ProfileCard';
import GeneralAnalyticsUser from 'src/pages/dashboard/GeneralAnalyticsUser';
import PropTypes from 'prop-types';

UserNav.propTypes = {
  userid: PropTypes.string.isRequired,
  avatar: PropTypes.string
};

export default function UserNav({ userid = '', avatar }) {
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={clickOpen}>
        <Avatar alt="avatar" src={avatar} />
      </IconButton>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <Box sx={{ width: '80vw' }} />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <Card>
                <List>
                  <ModControlPanelD userid={userid} />
                  <UserCRM userid={userid} />
                </List>
              </Card>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Card>
                <Typography variant="h3">Gestion del Usuario</Typography>
                <Box>
                  <ProfileCard userid={userid} />
                  <GeneralAnalyticsUser userid={userid} />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
