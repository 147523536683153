import React, { useState, useEffect } from 'react';
// icon
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import teamI from '@iconify/icons-ant-design/team-outline';
// material
import {
  Stack,
  Tooltip,
  Typography,
  Dialog,
  DialogContent,
  ListItemIcon,
  ListItemText,
  ListItem,
  List,
  MenuItem
} from '@mui/material';
import { styled } from '@mui/styles';
import UserAvatar from 'src/components/_dashboard/general/UserAvatar';
import UserElement from 'src/components/_dashboard/general/UserElement';
//
import { MIconButton } from 'src/components/@material-extend';
import { firestore } from 'src/contexts/FirebaseContext';
// hooks
import useAuth from 'src/hooks/useAuth';
import usePermissions, { tienePermiso } from 'src/hooks/usePermissions';
//
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary
}));

const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
});

// ----------------------------------------------------------------------

Personas.propTypes = {
  sprint: PropTypes.object.isRequired,
  spid: PropTypes.string,
  idEmpresa: PropTypes.string
};

export default function Personas({ sprint, spid, idEmpresa }) {
  const [users, setUsers] = useState([]);
  const { user } = useAuth();
  const { permisos } = usePermissions();
  const [creador, setCreador] = useState('');

  useEffect(() => {
    async function getCreator() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .onSnapshot((doc) => {
          const { creator } = doc.data();
          setCreador(creator);
        });
    }
    getCreator();
  }, [idEmpresa]);

  const canEditSprint = tienePermiso('editar sprints', user.id, permisos);

  const allowEdit = canEditSprint || user.id === creador;

  /**
   * scrumMaster: [],
   * productOwner: [],
   * team: [user.id],
   * coach: [],
   * involucrados: []
   */

  const [scrumMaster, setScrumMaster] = useState(sprint.scrumMaster || []);
  const [productOwner, setProductOwner] = useState(sprint.productOwner || []);
  const [team, setTeam] = useState(sprint.team || []);
  const [coach, setCoach] = useState(sprint.coach || []);
  const [involucrados, setInvolucrados] = useState(sprint.involucrados || []);

  const [open, setOpen] = useState(false);
  const [openMenuSM, setOpenMenuSM] = useState(false);
  const [openMenuPO, setOpenMenuPO] = useState(false);
  const [openMenuT, setOpenMenuT] = useState(false);
  const [openMenuC, setOpenMenuC] = useState(false);
  const [openMenuI, setOpenMenuI] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setScrumMaster(sprint.scrumMaster || []);
    setProductOwner(sprint.productOwner || []);
    setTeam(sprint.team || []);
    setCoach(sprint.coach || []);
    setInvolucrados(sprint.involucrados || []);
  }, [sprint]);

  useEffect(() => {
    async function getUsers() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setUsers(doc.data().membersids);
          }
        });
    }
    getUsers();
  }, [idEmpresa]);

  // scrum master

  const addScrumMaster = async (userr) => {
    const newArr = [...scrumMaster, userr];
    setScrumMaster(newArr);
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('sprints')
      .doc(spid)
      .update({ scrumMaster: newArr });
  };

  const removeScrumMaster = async (userr) => {
    const newArr = [...scrumMaster.filter((as) => as !== userr)];
    setScrumMaster(newArr);
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('sprints')
      .doc(spid)
      .update({ scrumMaster: newArr });
  };

  // product owner

  const addProductOwner = async (userr) => {
    const newArr = [...productOwner, userr];
    setProductOwner(newArr);
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('sprints')
      .doc(spid)
      .update({ productOwner: newArr });
  };

  const removeProductOwner = async (userr) => {
    const newArr = [...productOwner.filter((as) => as !== userr)];
    setProductOwner(newArr);
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('sprints')
      .doc(spid)
      .update({ productOwner: newArr });
  };

  // team

  const addTeam = async (userr) => {
    const newArr = [...team, userr];
    setTeam(newArr);
    await firestore.collection('empresas').doc(idEmpresa).collection('sprints').doc(spid).update({ team: newArr });
  };

  const removeTeam = async (userr) => {
    const newArr = [...team.filter((as) => as !== userr)];
    setTeam(newArr);
    await firestore.collection('empresas').doc(idEmpresa).collection('sprints').doc(spid).update({ team: newArr });
  };

  // coach

  const addCoach = async (userr) => {
    const newArr = [...coach, userr];
    setCoach(newArr);
    await firestore.collection('empresas').doc(idEmpresa).collection('sprints').doc(spid).update({ coach: newArr });
  };

  const removeCoach = async (userr) => {
    const newArr = [...coach.filter((as) => as !== userr)];
    setCoach(newArr);
    await firestore.collection('empresas').doc(idEmpresa).collection('sprints').doc(spid).update({ coach: newArr });
  };

  // involucrados

  const addInvolucrados = async (userr) => {
    const newArr = [...involucrados, userr];
    setInvolucrados(newArr);
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('sprints')
      .doc(spid)
      .update({ involucrados: newArr });
  };

  const removeInvolucrados = async (userr) => {
    const newArr = [...involucrados.filter((as) => as !== userr)];
    setInvolucrados(newArr);
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('sprints')
      .doc(spid)
      .update({ involucrados: newArr });
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
        <ListItemIcon>
          <Icon icon={teamI} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Involucrados</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose}>
        <DialogContent>
          <sprintHeader title="Involucrados" subheader="en el objetivo" />
          <Stack>
            <RowStyle>
              <Stack direction="row">
                <LabelStyle sx={{ mt: 1.5 }}>Scrum Master</LabelStyle>
                <Stack direction="row" flexWrap="wrap" alignItems="center">
                  {scrumMaster.map((userid) => (
                    <UserAvatar key={userid} userid={userid} removeUser={removeScrumMaster} />
                  ))}
                  <Tooltip title="Agregar ScrumMaster">
                    <MIconButton
                      disabled={scrumMaster.length !== 0 || !allowEdit}
                      sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                      onClick={() => setOpenMenuSM(true)}
                    >
                      <Icon icon={plusFill} width={20} height={20} />
                    </MIconButton>
                  </Tooltip>
                  <Dialog open={openMenuSM} onClose={() => setOpenMenuSM(false)}>
                    <DialogContent>
                      <List>
                        {users
                          .filter((u) => !Boolean(scrumMaster.find((d) => d === u)))
                          .map((u) => {
                            return (
                              <MenuItem
                                key={'userr' + u.id}
                                onClick={() => {
                                  addScrumMaster(u);
                                  setOpenMenuSM(false);
                                }}
                              >
                                <UserElement userid={u} />
                              </MenuItem>
                            );
                          })}
                      </List>
                    </DialogContent>
                  </Dialog>
                </Stack>
              </Stack>
            </RowStyle>
            <RowStyle>
              <Stack direction="row">
                <LabelStyle sx={{ mt: 1.5 }}>Product owner</LabelStyle>
                <Stack direction="row" flexWrap="wrap" alignItems="center">
                  {productOwner.map((userid) => (
                    <UserAvatar key={userid} userid={userid} removeUser={removeProductOwner} />
                  ))}
                  <Tooltip title="Agregar responsable">
                    <MIconButton
                      disabled={productOwner.length !== 0 || !allowEdit}
                      sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                      onClick={() => setOpenMenuPO(true)}
                    >
                      <Icon icon={plusFill} width={20} height={20} />
                    </MIconButton>
                  </Tooltip>
                  <Dialog open={openMenuPO} onClose={() => setOpenMenuPO(false)}>
                    <DialogContent>
                      <List>
                        {users
                          .filter((u) => !Boolean(productOwner.find((d) => d === u)))
                          .map((u) => {
                            return (
                              <MenuItem
                                key={'userr' + u.id}
                                onClick={() => {
                                  addProductOwner(u);
                                  setOpenMenuPO(false);
                                }}
                              >
                                <UserElement userid={u} />
                              </MenuItem>
                            );
                          })}
                      </List>
                    </DialogContent>
                  </Dialog>
                </Stack>
              </Stack>
            </RowStyle>
            <RowStyle>
              <Stack direction="row">
                <LabelStyle sx={{ mt: 1.5 }}>Equipo</LabelStyle>
                <Stack direction="row" flexWrap="wrap" alignItems="center">
                  {team.map((userid) => (
                    <UserAvatar key={userid} userid={userid} removeUser={removeTeam} />
                  ))}
                  <Tooltip title="Agregar responsable">
                    <MIconButton
                      disabled={!allowEdit}
                      sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                      onClick={() => setOpenMenuT(true)}
                    >
                      <Icon icon={plusFill} width={20} height={20} />
                    </MIconButton>
                  </Tooltip>
                  <Dialog open={openMenuT} onClose={() => setOpenMenuT(false)}>
                    <DialogContent>
                      <List>
                        {users
                          .filter((u) => !Boolean(team.find((d) => d === u)))
                          .map((u) => {
                            return (
                              <MenuItem
                                key={'userr' + u.id}
                                onClick={() => {
                                  addTeam(u);
                                  setOpenMenuT(false);
                                }}
                              >
                                <UserElement userid={u} />
                              </MenuItem>
                            );
                          })}
                      </List>
                    </DialogContent>
                  </Dialog>
                </Stack>
              </Stack>
            </RowStyle>
            <RowStyle>
              <Stack direction="row">
                <LabelStyle sx={{ mt: 1.5 }}>Coach</LabelStyle>
                <Stack direction="row" flexWrap="wrap" alignItems="center">
                  {coach.map((userid) => (
                    <UserAvatar key={userid} userid={userid} removeUser={removeCoach} />
                  ))}
                  <Tooltip title="Agregar responsable">
                    <MIconButton
                      disabled={coach.length !== 0 || !allowEdit}
                      sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                      onClick={() => setOpenMenuC(true)}
                    >
                      <Icon icon={plusFill} width={20} height={20} />
                    </MIconButton>
                  </Tooltip>
                  <Dialog open={openMenuC} onClose={() => setOpenMenuC(false)}>
                    <DialogContent>
                      <List>
                        {users
                          .filter((u) => !Boolean(coach.find((d) => d === u)))
                          .map((u) => {
                            return (
                              <MenuItem
                                key={'userr' + u.id}
                                onClick={() => {
                                  addCoach(u);
                                  setOpenMenuC(false);
                                }}
                              >
                                <UserElement userid={u} />
                              </MenuItem>
                            );
                          })}
                      </List>
                    </DialogContent>
                  </Dialog>
                </Stack>
              </Stack>
            </RowStyle>
            <RowStyle>
              <Stack direction="row">
                <LabelStyle sx={{ mt: 1.5 }}>Personas</LabelStyle>
                <Stack direction="row" flexWrap="wrap" alignItems="center">
                  {involucrados.map((userid) => (
                    <UserAvatar key={userid} userid={userid} removeUser={removeInvolucrados} />
                  ))}
                  <Tooltip title="Agregar responsable">
                    <MIconButton
                      disabled={!allowEdit}
                      sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                      onClick={() => setOpenMenuI(true)}
                    >
                      <Icon icon={plusFill} width={20} height={20} />
                    </MIconButton>
                  </Tooltip>
                  <Dialog open={openMenuI} onClose={() => setOpenMenuI(false)}>
                    <DialogContent>
                      <List>
                        {users
                          .filter((u) => !Boolean(involucrados.find((d) => d === u)))
                          .map((u) => {
                            return (
                              <MenuItem
                                key={'userr' + u.id}
                                onClick={() => {
                                  addInvolucrados(u);
                                  setOpenMenuI(false);
                                }}
                              >
                                <UserElement userid={u} />
                              </MenuItem>
                            );
                          })}
                      </List>
                    </DialogContent>
                  </Dialog>
                </Stack>
              </Stack>
            </RowStyle>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
