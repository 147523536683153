import React, { useState } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { List, Card, CardContent, ListItem, ListItemText, ListItemIcon, Collapse, Box } from '@mui/material';
import KanbanED from './KanbanED';
import { EntregablesD } from './organizacion/nav';
import Channel from '../social/Channel';
// icons
import { Icon } from '@iconify/react';
import linkout from '@iconify/icons-eva/external-link-fill';
import rockect from '@iconify/icons-ant-design/rocket-fill';
import calendarFill from '@iconify/icons-eva/calendar-fill';
import personFill from '@iconify/icons-eva/person-fill';
import arrowRight from '@iconify/icons-eva/arrow-ios-forward-fill';
//
import { ListaProyectos } from 'src/components/_dashboard/entorno';
import Organizacion from './organizacion/Organizacion';
import AsistenteEmpresa from 'src/pages/dashboard/AsistenteEmpresa';
import SprintCalendarD from 'src/components/_dashboard/sprints/SprintCalendarD';
import Memberships from './Memberships';
// hooks
import useAuth from 'src/hooks/useAuth';
import usePermissions, { tienePermiso } from 'src/hooks/usePermissions';
//
import PropTypes from 'prop-types';

// ----------------------------------------------------------

MNavigator.propTypes = {
  empresa: PropTypes.object
};

export default function MNavigator({ empresa }) {
  // auth and permissions
  const { user } = useAuth();
  const { permisos } = usePermissions();
  const gestPE = tienePermiso('gestionar plan estrategico', user.id, permisos);
  const verKanban = tienePermiso('ver kanban', user.id, permisos);
  const modulomanage = tienePermiso('module manage', user.id, permisos);
  //
  const { empid, pid } = useParams();
  const { id, creator } = empresa;
  const isOwner = user.id === creator;
  const [openPO, setOpenPO] = useState(false);
  const [openK, setOpenK] = useState(false);
  // collapsables
  const [openEs, setOpenEs] = useState(false);
  const [openEj, setOpenEj] = useState(false);
  const [openEh, setOpenEh] = useState(false);

  return (
    <Card>
      <CardContent>
        <List>
          {empresa.id !== empid && !pid && (
            <ListItem component={NavLink} to={'/dashboard/entorno/empresa/' + empresa.id} sx={{ color: '#637381' }}>
              <ListItemIcon>
                <Icon icon={linkout} width={20} height={20} />
              </ListItemIcon>
              <ListItemText>Ir</ListItemText>
            </ListItem>
          )}
          <ListItem button onClick={() => setOpenEs((prev) => !prev)} sx={{ color: '#637381' }}>
            <ListItemIcon>
              <Icon icon={rockect} width={23} height={23} />
            </ListItemIcon>
            <ListItemText sx={{ width: '100%' }}>
              <Box sx={{ width: '100%' }}>
                Estrategia{' '}
                <Box sx={{ float: 'right' }}>
                  <Icon icon={arrowRight} width={23} height={23} rotate={openEs ? 1 : 0} />
                </Box>
              </Box>
            </ListItemText>
          </ListItem>
          <Collapse in={openEs}>
            <List>
              {(isOwner || gestPE) && <AsistenteEmpresa listB idEmpresa={empresa?.id} />}
              {(isOwner || verKanban) && <KanbanED open={openK} setOpen={setOpenK} idEmpresa={id} empresa={empresa} />}
              <ListaProyectos idEmpresa={id} open={openPO} setOpen={setOpenPO} showB listB />
            </List>
          </Collapse>
          <ListItem button onClick={() => setOpenEj((prev) => !prev)} sx={{ color: '#637381' }}>
            <ListItemIcon>
              <Icon icon={calendarFill} width={23} height={23} />
            </ListItemIcon>
            <ListItemText>
              <Box sx={{ width: '100%' }}>
                Ejecución{' '}
                <Box sx={{ float: 'right' }}>
                  <Icon icon={arrowRight} width={23} height={23} rotate={openEj ? 1 : 0} />
                </Box>
              </Box>
            </ListItemText>
          </ListItem>
          <Collapse in={openEj}>
            <List>
              <SprintCalendarD idEmpresa={id} /> <EntregablesD idEmpresa={id} />
            </List>
          </Collapse>
          <ListItem button onClick={() => setOpenEh((prev) => !prev)} sx={{ color: '#637381' }}>
            <ListItemIcon>
              <Icon icon={personFill} width={23} height={23} />
            </ListItemIcon>
            <ListItemText>
              <Box sx={{ width: '100%' }}>
                Factor humano{' '}
                <Box sx={{ float: 'right' }}>
                  <Icon icon={arrowRight} width={23} height={23} rotate={openEh ? 1 : 0} />
                </Box>
              </Box>
            </ListItemText>
          </ListItem>
          <Collapse in={openEh}>
            <List>
              <Organizacion empresa={empresa} />
            </List>
          </Collapse>
          {(isOwner || modulomanage) && <Memberships empresa={empresa} />}
          <Channel collection="empresas" doc={id} />
        </List>
      </CardContent>
    </Card>
  );
}
