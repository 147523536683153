import React from 'react';
import { Card, List } from '@mui/material';
import AltaPerfil from '../AltaPerfil';
import Channel from 'src/components/_dashboard/social/Channel';
import { ObjetivosKanban, CompetenciasKanban, HabilidadesKanban, PersonasD } from './nav';
import PropTypes from 'prop-types';

MenuPerfil.propTypes = {
  perfil: PropTypes.object,
  idEmpresa: PropTypes.string
};

export default function MenuPerfil({ perfil, idEmpresa }) {
  const { id } = perfil;
  return (
    <Card>
      <List>
        <AltaPerfil listB edit iddoc={id} idEmpresa={idEmpresa} />
        <ObjetivosKanban idEmpresa={idEmpresa} idP={id} />
        <CompetenciasKanban idEmpresa={idEmpresa} idP={id} />
        <HabilidadesKanban idEmpresa={idEmpresa} idP={id} />
        <PersonasD idEmpresa={idEmpresa} perfil={perfil} />
        <Channel collection="perfilesposts" doc={idEmpresa + id} />
      </List>
    </Card>
  );
}
