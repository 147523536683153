import React, { useState } from 'react';
import { ListItem, ListItemText, ListItemIcon, Dialog, DialogContent } from '@mui/material';
import Analytics from 'src/pages/dashboard/GeneralAnalytics';
// icon
import { Icon } from '@iconify/react';
import analyticsI from '@iconify/icons-carbon/chart-combo-stacked';
//

export default function AnalyticsD() {
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#506070' }}>
        <ListItemIcon>
          <Icon icon={analyticsI} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Analytics</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <DialogContent>
          <Analytics />
        </DialogContent>
      </Dialog>
    </>
  );
}
