import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
// material
import { Container, Stack, Skeleton, Grid, Button } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getBoard, persistColumn, persistCard } from '../../redux/slices/kanbanempresa';
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { KanbanColumn, KanbanColumnAdd } from '../../components/_dashboard/kanbanempresa';
import { firestore } from 'src/contexts/FirebaseContext';
// icon
import { Icon } from '@iconify/react';
import refreshI from '@iconify/icons-eva/refresh-fill';
// hooks
import useAuth from 'src/hooks/useAuth';
import usePermissions, { tienePermiso } from 'src/hooks/usePermissions';
//
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

const SkeletonLoad = (
  <>
    {[...Array(3)].map((_, index) => (
      <Grid item xs={12} md={3} key={index}>
        <Skeleton variant="rectangular" width="100%" sx={{ paddingTop: '115%', borderRadius: 2 }} />
      </Grid>
    ))}
  </>
);

Kanban.propTypes = {
  idEmpresa: PropTypes.string.isRequired,
  empresa: PropTypes.object.isRequired
};

export default function Kanban({ idEmpresa, empresa }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { nombre, creator } = empresa;
  const { board } = useSelector((state) => state.kanbanempresa);
  const { user } = useAuth();
  const { permisos } = usePermissions();
  // permisos
  const isOwner = user.id === creator;
  const verKanban = tienePermiso('ver kanban', user.id, permisos);
  const editKanban = tienePermiso('editar kanban', user.id, permisos);
  const deleteKanban = tienePermiso('eliminar kanban', user.id, permisos);
  const KanbanPermissions = {
    isOwner,
    editKanban,
    deleteKanban
  };
  //

  useEffect(() => {
    dispatch(getBoard(idEmpresa));
  }, [dispatch, idEmpresa]);

  const onDragEnd = (result) => {
    // Reorder card
    const { destination, source, draggableId, type } = result;
    console.log(source, destination, draggableId, type);

    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    if (type === 'column') {
      const newColumnOrder = Array.from(board.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);
      if (editKanban || isOwner) {
        dispatch(persistColumn(newColumnOrder, idEmpresa));
      }
      enqueueSnackbar('Actializacion exitosa', { variant: 'success' });
      return;
    }

    const start = board.columns[source.droppableId];
    const finish = board.columns[destination.droppableId];

    if (start.id === finish.id) {
      const updatedCardIds = [...start.cardIds];
      updatedCardIds.splice(source.index, 1);
      updatedCardIds.splice(destination.index, 0, draggableId);

      const updatedColumn = {
        ...start,
        cardIds: updatedCardIds
      };

      if (editKanban || isOwner) {
        firestore
          .collection('empresas')
          .doc(idEmpresa)
          .collection('kanbanColunms')
          .doc(updatedColumn.id)
          .update({ ...updatedColumn });
      }

      dispatch(
        persistCard({
          columns: {
            ...board.columns,
            [updatedColumn.id]: updatedColumn
          }
        })
      );

      return;
    }

    const startCardIds = [...start.cardIds];
    startCardIds.splice(source.index, 1);
    const updatedStart = {
      ...start,
      cardIds: startCardIds
    };

    const finishCardIds = [...finish.cardIds];
    finishCardIds.splice(destination.index, 0, draggableId);
    const updatedFinish = {
      ...finish,
      cardIds: finishCardIds
    };

    if (editKanban || isOwner) {
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanColunms')
        .doc(updatedStart.id)
        .update({ ...updatedStart });
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanColunms')
        .doc(updatedFinish.id)
        .update({ ...updatedFinish });
    }

    dispatch(
      persistCard({
        columns: {
          ...board.columns,
          [updatedStart.id]: updatedStart,
          [updatedFinish.id]: updatedFinish
        }
      })
    );
    enqueueSnackbar('Actualizacion exitosa', { variant: 'success' });
  };

  return (
    <>
      {(user.id === creator || verKanban) && (
        <Container maxWidth={false} sx={{ height: '100%', marginBottom: '5%' }}>
          <Button
            onClick={() => {
              dispatch(getBoard(idEmpresa));
            }}
            sx={{ color: '#637381' }}
          >
            <Icon icon={refreshI} width={25} height={25} />
            Actualizar
          </Button>
          <HeaderBreadcrumbs heading={nombre} links={[{ name: 'puntos de enfoque' }]} />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="all-columns" direction="horizontal" type="column">
              {(provided) => (
                <Stack
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  direction="row"
                  alignItems="flex-start"
                  spacing={3}
                  sx={{ height: 'calc(100% - 32px)', overflowY: 'hidden' }}
                >
                  {board?.columnOrder?.map((columnId, index) => {
                    const column = board.columns[columnId];
                    return (
                      <KanbanColumn
                        index={index}
                        key={columnId}
                        column={column}
                        idEmpresa={idEmpresa}
                        kanbanP={KanbanPermissions}
                      />
                    );
                  })}

                  {!board?.columnOrder.length && SkeletonLoad}

                  {provided.placeholder}
                  <KanbanColumnAdd idEmpresa={idEmpresa} />
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        </Container>
      )}
    </>
  );
}
