import React, { useState } from 'react';
import { Card, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import Integrantes from '../../Integrantes';
import InvitationGenerator from '../../InvitationGenerator';
import AddPerson from './AddPerson';
import { DialogEquipos } from '../equipos';
// icon
import { Icon } from '@iconify/react';
import teamI from '@iconify/icons-ant-design/team-outline';
import addMemberI from '@iconify/icons-ant-design/user-add';
//
import PropTypes from 'prop-types';

MenuPersons.propTypes = {
  idEmpresa: PropTypes.string,
  empresa: PropTypes.object
};

export default function MenuPersons({ idEmpresa, empresa }) {
  const [openInt, setOpenInt] = useState(false);
  const [openInv, setOpenInv] = useState(false);

  const clickOpenInv = () => {
    setOpenInv(true);
  };
  const clickOpenInt = () => {
    setOpenInt(true);
  };

  return (
    <Card>
      <List>
        <ListItem button onClick={clickOpenInv} sx={{ color: '#637381' }}>
          <ListItemIcon>
            <Icon icon={addMemberI} width={25} height={25} />
          </ListItemIcon>
          <ListItemText>Invitar Persona</ListItemText>
        </ListItem>
        <ListItem
          button
          onClick={() => {
            clickOpenInt();
          }}
          sx={{ color: '#637381' }}
        >
          <ListItemIcon>
            <Icon icon={teamI} width={25} height={25} />
          </ListItemIcon>
          <ListItemText>Gestionar Integrantes</ListItemText>
        </ListItem>
        <DialogEquipos idEmpresa={idEmpresa} />
        <AddPerson idEmpresa={idEmpresa} listB />
      </List>
      <Integrantes empresa={empresa} idEmpresa={idEmpresa} open={openInt} setOpen={setOpenInt} />
      <InvitationGenerator idEmpresa={idEmpresa} empresa={empresa} open={openInv} setOpen={setOpenInv} />
    </Card>
  );
}
