import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import PostInput from './PostInput';
import PostItem from './PostItem';
// icon
import { Icon } from '@iconify/react';
import social from '@iconify/icons-ant-design/aliyun-outline';
//
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

Channel.propTypes = {
  collection: PropTypes.string,
  doc: PropTypes.string
};

export default function Channel({ collection, doc }) {
  const [open, setOpen] = useState(false);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function getPosts() {
      await firestore
        .collection(collection)
        .doc(doc)
        .collection('posts')
        .orderBy('createdAt', 'desc')
        .onSnapshot((queryS) => {
          const postss = [];
          queryS.forEach((doc) => {
            postss.push({ id: doc.id, ...doc.data() });
          });
          setPosts(postss);
        });
    }
    getPosts();
  }, [collection, doc]);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#506070' }}>
        <ListItemIcon>
          <Icon icon={social} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Canal</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose}>
        <DialogContent>
          <PostInput collection={collection} doc={doc} />
          {posts.map((post) => (
            <PostItem key={post.id} post={post} collection={collection} doc={doc} />
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
}
