import React, { useState } from 'react';
import { ListItem, ListItemText, ListItemIcon, Dialog, DialogContent, Grid, Box } from '@mui/material';
import PermisosNav from './PermisosNav';
import PermisosCard from './PermisosCard';
import { useSnackbar } from 'notistack';
import { isPremium } from 'src/hooks/usePlan';
// icon
import { Icon } from '@iconify/react';
import permissions from '@iconify/icons-ant-design/safety';
import PropTypes from 'prop-types';

PermisosD.propTypes = {
  idEmpresa: PropTypes.string,
  empresa: PropTypes.object
};

export default function PermisosD({ idEmpresa, empresa }) {
  const { expiresP, premium } = empresa;
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const allowPremium = isPremium(premium, expiresP);

  const clickOpen = () => {
    if (allowPremium) {
      setOpen(true);
    } else {
      enqueueSnackbar('Esta función es premium, haz click en el boton comprar', { variant: 'error' });
    }
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
        <ListItemIcon>
          <Icon icon={permissions} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Permisos</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <Box sx={{ width: '70vw' }} />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <PermisosNav empresa={empresa} idEmpresa={idEmpresa} />
            </Grid>
            <Grid item xs={12} lg={8}>
              <PermisosCard empresa={empresa} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
