import React, { useState } from 'react';
import { Box, Grid, Button } from '@mui/material';
import { storage as st } from 'src/contexts/FirebaseContext';
import { useSnackbar } from 'notistack';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';

FileView.propTypes = {
  file: PropTypes.object,
  onSelect: PropTypes.func,
  getListFiles: PropTypes.func
};

export default function FileView({ file, onSelect, getListFiles }) {
  const { path, nameFile, url } = file;
  const { enqueueSnackbar } = useSnackbar();
  const [showB, setShowB] = useState(false);

  const onCopy = () => {
    enqueueSnackbar('Copiado', { variant: 'success' });
  };

  return (
    <Grid item xs={4}>
      <Box component="div" sx={{ margin: '2%' }} onMouseOver={() => setShowB(true)}>
        {showB && (
          <Box component="div" sx={{ zIndex: 2, position: 'absolute' }} onMouseOut={() => setShowB(false)}>
            <Button
              variant="contained"
              onClick={() => {
                onSelect(file.url);
              }}
            >
              Seleccionar
            </Button>
            <CopyToClipboard text={url} onCopy={onCopy}>
              <Button variant="contained">Copiar enlace</Button>
            </CopyToClipboard>
            <Button
              variant="contained"
              onClick={async () => {
                if (window.confirm('desea eliminra este archivo?')) {
                  const refFile = st.ref().child(path);
                  await refFile.delete();
                  enqueueSnackbar('Eliminado', { variant: 'success' });
                  await getListFiles();
                }
              }}
            >
              Eliminar
            </Button>
          </Box>
        )}
        <iframe title={nameFile} width="100%" height="auto" src={url} style={{ border: 'none', zIndex: 1 }} />
      </Box>
    </Grid>
  );
}
