import React, { useState, useEffect } from 'react';
import { Card, CardContent, Box, Grid, Typography, IconButton, Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { CTAIframe, CTAImg, CTAVideo } from './ctamedias';
import { ButtonCustom } from './buttons';
import { firestore } from 'src/contexts/FirebaseContext';
import Markdown from 'src/components/Markdown';
import useAuth from 'src/hooks/useAuth';
import usePlan, { changeCTAState, excludeCTA } from 'src/hooks/usePlan';
import PropTypes from 'prop-types';

CTACompM.propTypes = {
  CTA: PropTypes.shape({
    id: PropTypes.string,
    titulo: PropTypes.string,
    descripcion: PropTypes.string,
    mediaType: PropTypes.string,
    urlMedia: PropTypes.string,
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.string,
    buttonType: PropTypes.string,
    buttonId: PropTypes.string,
    modulo: PropTypes.string
  }),
  previewFileAlt: PropTypes.string,
  idEmpresa: PropTypes.string
};

export default function CTACompM({ CTA, previewFileAlt, idEmpresa = '' }) {
  const { id, titulo, descripcion, mediaType, urlMedia, buttonId = '', modulo } = CTA;
  const { CTAState } = usePlan();
  const { user } = useAuth();
  const [btn, setBtn] = useState(undefined);

  useEffect(() => {
    async function getBtn() {
      await firestore
        .collection('buttons')
        .doc(buttonId)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setBtn({ ...doc.data() });
          }
        });
    }
    if (Boolean(buttonId)) {
      getBtn();
    }
  }, [buttonId]);

  const removeCTA = async () => {
    await firestore.collection('ctas').doc(id).update({ show: false });
  };

  const changeState = () => {
    changeCTAState(modulo, CTAState, user.id);
  };

  const media = {
    iframe: <CTAIframe src={Boolean(previewFileAlt) ? previewFileAlt : urlMedia} />,
    image: <CTAImg src={Boolean(previewFileAlt) ? previewFileAlt : urlMedia} />,
    video: <CTAVideo src={Boolean(previewFileAlt) ? previewFileAlt : urlMedia} />
  };

  return (
    <Card>
      <CardContent>
        {(user.email === 'kintaroponce.uni@gmail.com' || user.email === 'ocompudoc@gmail.com') && (
          <IconButton onClick={removeCTA}>
            <Close />
          </IconButton>
        )}
        <Button onClick={() => excludeCTA(id, CTAState, user.id)}>No volver a motrar</Button>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            {media[mediaType]}
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h5" sx={{ textAlign: 'center' }}>
                {titulo}
              </Typography>
              <Box component="p" sx={{ textAlign: 'center', textJustify: 'inter-character', padding: '1%' }}>
                <Markdown children={descripcion} />
              </Box>
              <Box
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '3%', padding: '3%' }}
              >
                {btn && <ButtonCustom BTN={btn} onClickB={changeState} idEmpresa={idEmpresa} />}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
