import React from 'react';
import { Card, CardContent, Box } from '@mui/material';
import ButtonCustom from './ButtonCustom';
import AltaBoton from './AltaBoton';
import PropTypes from 'prop-types';

ButtonElement.propTypes = {
  BTN: PropTypes.object
};

export default function ButtonElement({ BTN }) {
  const { id, buttonText } = BTN;
  return (
    <Card>
      <CardContent>
        <AltaBoton edit iddoc={id} btn />
        <Box sx={{ margin: '3%' }}>
          <ButtonCustom BTN={BTN} />
        </Box>
      </CardContent>
    </Card>
  );
}
