import React, { useState } from 'react';
import { ListItem, ListItemText, ListItemIcon, Dialog, DialogContent } from '@mui/material';
import PermissionItem from './PermissionItem';
// icon
import { Icon } from '@iconify/react';
import agile from '@iconify/icons-ant-design/dingding';
//
import PropTypes from 'prop-types';

SprintPage.propTypes = {
  idEmpresa: PropTypes.string,
  empresa: PropTypes.object
};

export default function SprintPage({ idEmpresa, empresa }) {
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
        <ListItemIcon>
          <Icon icon={agile} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Permisos Sprint</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose}>
        <DialogContent>
          <PermissionItem idEmpresa={idEmpresa} empresa={empresa} permiso="ver sprints" altName="Ver sprints" />
          <PermissionItem idEmpresa={idEmpresa} empresa={empresa} permiso="editar sprints" altName="Editar sprints" />
          <PermissionItem idEmpresa={idEmpresa} empresa={empresa} permiso="crear sprints" altName="Crear sprints" />
        </DialogContent>
      </Dialog>
    </>
  );
}
