import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, Box, Typography, TextField, MenuItem } from '@mui/material';
import { fDateTime } from 'src/utils/formatTime';
import { fPercent } from 'src/utils/formatNumber';
import { firestore } from 'src/contexts/FirebaseContext';
//
import { merge, orderBy } from 'lodash';
import ReactApexChart from 'react-apexcharts';
//
import BaseOptionChart from 'src/components/charts/BaseOptionChart';
import PropTypes from 'prop-types';

const moment = require('moment');

ProgressChart.propTypes = {
  cardId: PropTypes.string,
  idEmpresa: PropTypes.string,
  idP: PropTypes.string
};

export default function ProgressChart({ cardId, idEmpresa, idP }) {
  const [data, setData] = useState([]);
  const [CHART_DATA, setChartData] = useState([{ name: 'Desktops', data: [0] }]);
  const [LABELS, setLabels] = useState(['null']);
  const [vista, setVista] = useState('day');

  const VISTAS = [
    { name: 'Día', value: 'day' },
    { name: 'Semanal', value: 'week' },
    { name: 'Mensual', value: 'month' },
    { name: 'Todo', value: 'all' }
  ];

  useEffect(() => {
    async function getKRsH() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('perfiles')
        .doc(idP)
        .collection('kanbanCardshab')
        .doc(cardId)
        .collection('krProgress')
        .onSnapshot((queryS) => {
          const dat = [];
          queryS.forEach((doc) => {
            const { avance, date } = doc.data();
            dat.push({ avance, date });
          });
          setData(orderBy(dat, ['date'], ['asc']));
        });
    }
    getKRsH();
  }, [cardId, idEmpresa, idP]);

  useEffect(() => {
    const dates = [];
    const labels = [];
    const avances = [];
    data.forEach((d) => {
      const { avance, date } = d;
      const dateD = date.toDate();
      if (labels.length !== 0) {
        const lastdate = moment(dates[dates.length - 1])
          .startOf(vista)
          .toDate()
          .getTime();
        const datein = moment(dateD).startOf(vista).toDate().getTime();
        if (lastdate === datein) {
          dates.pop();
          labels.pop();
          avances.pop();
        }
        dates.push(dateD);
        labels.push(fDateTime(dateD));
        avances.push(avance);
      } else {
        dates.push(dateD);
        labels.push(fDateTime(dateD));
        avances.push(avance);
      }
    });

    setChartData([{ name: 'progreso', data: avances }]);
    setLabels(labels);
  }, [data, vista]);

  const chartOptions = merge(BaseOptionChart(), {
    xaxis: {
      categories: LABELS
    },
    yaxis: {
      labels: {
        formatter(y) {
          return `${fPercent(y)}`;
        }
      }
    },
    tooltip: { x: { show: false }, marker: { show: false } }
  });

  return (
    <Card>
      <CardHeader
        title={<Typography variant="h5">Historial del Progreso de KRs</Typography>}
        action={
          <TextField select label="Vista" value={vista} onChange={(e) => setVista(e.target.value)}>
            {VISTAS.map((vist) => (
              <MenuItem key={vist.value} value={vist.value}>
                {vist.name}
              </MenuItem>
            ))}
          </TextField>
        }
      />
      <CardContent>
        <Box component="div" sx={{ padding: '5px', margin: '5px' }}>
          <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={320} width={1100} />
        </Box>
      </CardContent>
    </Card>
  );
}
