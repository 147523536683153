import React, { useState, useEffect } from 'react';
import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Grid,
  Stack,
  Card,
  CardContent,
  CardHeader,
  AppBar,
  Toolbar,
  Typography,
  MenuItem,
  List,
  OutlinedInput,
  TextField
} from '@mui/material';
import { styled } from '@mui/styles';
import { Close } from '@mui/icons-material';
// import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useDispatch } from 'src/redux/store';
import { getBoard } from 'src/redux/slices/kanbanentregables';
// icon
import { Icon } from '@iconify/react';
import eyeoutline from '@iconify/icons-eva/eye-outline';
//
import { FechasD, DepartamentoD, CategoriaD } from './controlpanelnav';
import { firestore } from 'src/contexts/FirebaseContext';

// -------------------------------------------------------------------------

const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'left'
});

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 18,
  flexShrink: 0,
  color: theme.palette.text.secondary
}));

// --------------------------------------------------------------------------

KanbanKRsTasks.propTypes = {
  card: PropTypes.object.isRequired,
  cardId: PropTypes.string.isRequired,
  showB: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  idEmpresa: PropTypes.string.isRequired
};

export default function KanbanKRsTasks({ idEmpresa, card, cardId, showB = false, open = false, setOpen }) {
  const dispatch = useDispatch();
  // const { enqueueSnackbar } = useSnackbar();
  // campos en cuestion
  const [name, setName] = useState(card.name || '');
  const [impacto, setImpacto] = useState(card.impacto || '');
  const [recomendaciones, setRecomendaciones] = useState(card.recomendaciones || '');
  const [obstaculos, setObstaculos] = useState(card.obstaculos || '');
  const [descripcion, setDescripcion] = useState(card.descripcion || '');
  const [proyecto, setProyecto] = useState(card.proyecto || '');
  const [iniciativa, setIniciativa] = useState(card.iniciativa || '');
  const [status, setStatus] = useState(card.status || '');
  const [avance, setAvance] = useState(card.avance || '');
  const [accionesR, setAccionesR] = useState(card.accionesR || '');
  // actualizar campos
  useEffect(() => {
    setName(card.name || '');
    setImpacto(card.impacto || '');
    setRecomendaciones(card.recomendaciones || '');
    setObstaculos(card.obstaculos || '');
    setDescripcion(card.descripcion || '');
    setProyecto(card.proyecto || '');
    setIniciativa(card.iniciativa || '');
    setStatus(card.status || '');
    setAvance(card.avance || '');
    setAccionesR(card.accionesR || '');
  }, [card]);

  // departamentos
  const [proyects, setProyects] = useState([]);
  useEffect(() => {
    async function getProyects() {
      await firestore
        .collection('proyectosempresa')
        .where('idEmpresa', '==', idEmpresa)
        .onSnapshot((queryS) => {
          const dat = [];
          queryS.forEach((doc) => {
            dat.push({ id: doc.id, ...doc.data() });
          });
          setProyects(dat);
        });
    }
    getProyects();
  }, [idEmpresa]);

  // metodos
  const handlecardNameChange = async (event) => {
    setName(event.target.value);
    setTimeout(async () => {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanCardsentregables')
        .doc(cardId)
        .update({ name: event.target.value })
        .then(() => {
          dispatch(getBoard(idEmpresa));
        });
    }, 1000);
  };

  const handleImpactoChange = async (event) => {
    setImpacto(event.target.value);
    setTimeout(async () => {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanCardsentregables')
        .doc(cardId)
        .update({ impacto: event.target.value })
        .then(() => {
          dispatch(getBoard(idEmpresa));
        });
    }, 1000);
  };

  const handleRecomendacionesChange = async (event) => {
    setRecomendaciones(event.target.value);
    setTimeout(async () => {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanCardsentregables')
        .doc(cardId)
        .update({ recomendaciones: event.target.value })
        .then(() => {
          dispatch(getBoard(idEmpresa));
        });
    }, 1000);
  };

  const handleObstaculosChange = async (event) => {
    setObstaculos(event.target.value);
    setTimeout(async () => {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanCardsentregables')
        .doc(cardId)
        .update({ obstaculos: event.target.value })
        .then(() => {
          dispatch(getBoard(idEmpresa));
        });
    }, 1000);
  };

  const handleDescripcionChange = async (event) => {
    setDescripcion(event.target.value);
    setTimeout(async () => {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanCardsentregables')
        .doc(cardId)
        .update({ descripcion: event.target.value })
        .then(() => {
          dispatch(getBoard(idEmpresa));
        });
    }, 1000);
  };

  const handleProyectoChange = async (event) => {
    setProyecto(event.target.value);
    setTimeout(async () => {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanCardsentregables')
        .doc(cardId)
        .update({ proyecto: event.target.value })
        .then(() => {
          dispatch(getBoard(idEmpresa));
        });
    }, 1000);
  };

  const handleIniciativaChange = async (event) => {
    setIniciativa(event.target.value);
    setTimeout(async () => {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanCardsentregables')
        .doc(cardId)
        .update({ iniciativa: event.target.value })
        .then(() => {
          dispatch(getBoard(idEmpresa));
        });
    }, 1000);
  };

  const handleStatusChange = async (event) => {
    setStatus(event.target.value);
    setTimeout(async () => {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanCardsentregables')
        .doc(cardId)
        .update({ status: event.target.value })
        .then(() => {
          dispatch(getBoard(idEmpresa));
        });
    }, 1000);
  };

  const handleAvanceChange = async (event) => {
    setAvance(event.target.value);
    setTimeout(async () => {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanCardsentregables')
        .doc(cardId)
        .update({ avance: event.target.value })
        .then(() => {
          dispatch(getBoard(idEmpresa));
        });
    }, 1000);
  };

  const handleAccionesRChange = async (event) => {
    setAccionesR(event.target.value);
    setTimeout(async () => {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanCardsentregables')
        .doc(cardId)
        .update({ acciones: event.target.value })
        .then(() => {
          dispatch(getBoard(idEmpresa));
        });
    }, 1000);
  };

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      {showB && (
        <Button variant="contained" onClick={clickOpen}>
          <Icon icon={eyeoutline} color="white" width={30} height={30} />
        </Button>
      )}
      <Dialog open={open} onClose={clickClose} maxWidth={1250}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton onClick={clickClose}>
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box component="div" sx={{ width: '85vw' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <Card>
                  <CardContent>
                    <List>
                      <FechasD card={card} cardId={cardId} idEmpresa={idEmpresa} />
                      <DepartamentoD card={card} cardId={cardId} idEmpresa={idEmpresa} />
                      <CategoriaD card={card} cardId={cardId} idEmpresa={idEmpresa} />
                    </List>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={9}>
                <Card sx={{ p: 3 }}>
                  <CardHeader
                    title={
                      <>
                        <OutlinedInput
                          fullWidth
                          multiline
                          size="small"
                          placeholder="Titulo"
                          label="Titulo"
                          value={name}
                          onChange={handlecardNameChange}
                          sx={{
                            typography: 'h6',
                            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' }
                          }}
                        />
                      </>
                    }
                  />

                  <Stack spacing={2}>
                    <RowStyle>
                      <LabelStyle>Descripcion</LabelStyle>
                      <OutlinedInput
                        multiline
                        value={descripcion}
                        onChange={handleDescripcionChange}
                        sx={{
                          typography: 'h6',
                          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' }
                        }}
                      />
                    </RowStyle>
                    <RowStyle>
                      <LabelStyle>Impacto</LabelStyle>
                      <OutlinedInput
                        value={impacto}
                        onChange={handleImpactoChange}
                        sx={{
                          typography: 'h6',
                          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' }
                        }}
                      />
                    </RowStyle>
                    <RowStyle>
                      <LabelStyle>Recomendaciones</LabelStyle>
                      <OutlinedInput
                        value={recomendaciones}
                        onChange={handleRecomendacionesChange}
                        sx={{
                          typography: 'h6',
                          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' }
                        }}
                      />
                    </RowStyle>
                    <RowStyle>
                      <LabelStyle>Obtaculos</LabelStyle>
                      <OutlinedInput
                        value={obstaculos}
                        onChange={handleObstaculosChange}
                        sx={{
                          typography: 'h6',
                          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' }
                        }}
                      />
                    </RowStyle>
                    <RowStyle>
                      <LabelStyle>Proyecto</LabelStyle>
                      <TextField
                        select
                        fullWidth
                        value={proyecto}
                        onChange={handleProyectoChange}
                        sx={{
                          typography: 'h6',
                          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' }
                        }}
                      >
                        {proyects.map((dep) => (
                          <MenuItem key={dep.id} value={dep.id}>
                            {dep.nombre}
                          </MenuItem>
                        ))}
                      </TextField>
                    </RowStyle>
                    <RowStyle>
                      <LabelStyle>Iniciativa</LabelStyle>
                      <OutlinedInput
                        value={iniciativa}
                        onChange={handleIniciativaChange}
                        sx={{
                          typography: 'h6',
                          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' }
                        }}
                      />
                    </RowStyle>
                    <RowStyle>
                      <LabelStyle>Status</LabelStyle>
                      <OutlinedInput
                        value={status}
                        onChange={handleStatusChange}
                        sx={{
                          typography: 'h6',
                          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' }
                        }}
                      />
                    </RowStyle>
                    <RowStyle>
                      <LabelStyle>Avance</LabelStyle>
                      <OutlinedInput
                        value={avance}
                        onChange={handleAvanceChange}
                        sx={{
                          typography: 'h6',
                          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' }
                        }}
                      />
                    </RowStyle>
                    <RowStyle>
                      <LabelStyle>Acciones Recomendadas</LabelStyle>
                      <OutlinedInput
                        value={accionesR}
                        onChange={handleAccionesRChange}
                        sx={{
                          typography: 'h6',
                          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' }
                        }}
                      />
                    </RowStyle>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <DialogActions>
            <Button onClick={clickClose}>Cerrar</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}
