import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useCallback, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogContent,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  FormHelperText,
  FormControlLabel,
  IconButton
} from '@mui/material';
// icon
import { Icon } from '@iconify/react';
import addPerson from '@iconify/icons-ant-design/user-add';
import editI from '@iconify/icons-ant-design/edit-fill';
// utils
import { fData } from 'src/utils/formatNumber';
//
import Label from '../../../../Label';
import { UploadAvatar } from '../../../../upload';
import countries from 'src/components/_dashboard/user/countries';
import { firestore, storage as st } from 'src/contexts/FirebaseContext';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

AddPerson.propTypes = {
  edit: PropTypes.bool,
  userId: PropTypes.string,
  idEmpresa: PropTypes.string,
  listB: PropTypes.bool,
  iconB: PropTypes.bool
};

export default function AddPerson({ edit, userId, idEmpresa, listB = false, iconB = false }) {
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const isYou = user.id === userId;
  const [fileS, setFile] = useState(null);
  const NewUserSchema = Yup.object().shape({
    displayName: Yup.string().required('Name is required'),
    email: Yup.string().email(),
    phoneNumber: Yup.string(),
    address: Yup.string(),
    country: Yup.string(),
    company: Yup.string(),
    state: Yup.string(),
    city: Yup.string(),
    role: Yup.string(),
    avatarUrl: Yup.mixed()
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      displayName: '',
      email: '',
      phoneNumber: '',
      address: '',
      country: '',
      state: '',
      city: '',
      zipCode: '',
      photoURL: null,
      isVerified: true,
      status: false,
      company: '',
      role: '',
      editable: true
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        if (edit) {
          let photoURL = '';
          if (fileS !== null) {
            const refT = `users/${userId}/avatar`;
            const refF = st.ref().child(refT);
            await refF.put(fileS.file);
            photoURL = await refF.getDownloadURL();
          }
          await firestore
            .collection('users')
            .doc(userId)
            .update({ ...values, photoURL });
          enqueueSnackbar('Update success', { variant: 'success' });
        } else {
          await firestore
            .collection('users')
            .add({ ...values })
            .then(async (doc) => {
              let photoURL = '';
              if (fileS !== null) {
                const refT = `users/${doc.id}/avatar`;
                const refF = st.ref().child(refT);
                await refF.put(fileS.file);
                photoURL = await refF.getDownloadURL();
                await firestore.collection('users').doc(doc.id).update({ photoURL });
              }
              firestore
                .collection('empresas')
                .doc(idEmpresa)
                .get()
                .then((docc) => {
                  const { membersinfo, membersids } = docc.data();
                  firestore
                    .collection('empresas')
                    .doc(idEmpresa)
                    .update({
                      membersinfo: [...membersinfo, { id: doc.id, nombre: values.name, avatar: photoURL }],
                      membersids: [...membersids, doc.id]
                    });
                });
            });
          resetForm();
          enqueueSnackbar('Create success', { variant: 'success' });
        }
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  useEffect(() => {
    async function getUser() {
      await firestore
        .collection('users')
        .doc(userId)
        .get()
        .then((doc) => {
          const {
            displayName,
            email,
            phoneNumber,
            address,
            country,
            state,
            city,
            zipCode,
            avatarUrl,
            isVerified,
            status,
            company,
            role,
            editable
          } = doc.data();
          setFieldValue('displayName', displayName || '');
          setFieldValue('email', email);
          setFieldValue('phoneNumber', phoneNumber);
          setFieldValue('address', address);
          setFieldValue('country', country);
          setFieldValue('state', state);
          setFieldValue('city', city);
          setFieldValue('zipCode', zipCode);
          setFieldValue('avatarUrl', avatarUrl);
          setFieldValue('isVerified', isVerified);
          setFieldValue('status', status);
          setFieldValue('company', company);
          setFieldValue('role', role);
          setFieldValue('editable', editable);
        });
    }
    if (edit) {
      getUser();
    }
    // eslint-disable-next-line
  }, [edit, userId]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setFile({
          file,
          preview: URL.createObjectURL(file)
        });
        setFieldValue('photoURL', {
          file,
          preview: URL.createObjectURL(file)
        });
      }
    },
    [setFieldValue]
  );

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      {listB && (
        <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
          <ListItemIcon>
            <Icon icon={edit ? editI : addPerson} width={23} height={23} />
          </ListItemIcon>
          <ListItemText>{edit ? <>Editar </> : <>Agregar Persona</>}</ListItemText>
        </ListItem>
      )}
      {iconB && (
        <IconButton onClick={clickOpen}>
          <Icon icon={edit ? editI : addPerson} width={23} height={23} />
        </IconButton>
      )}
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <DialogContent>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Card sx={{ py: 10, px: 3 }}>
                    {edit && (
                      <Label
                        color={values.status !== 'active' ? 'error' : 'success'}
                        sx={{ textTransform: 'uppercase', position: 'absolute', top: 24, right: 24 }}
                      >
                        {values.status}
                      </Label>
                    )}

                    <Box sx={{ mb: 5 }}>
                      <UploadAvatar
                        accept="image/*"
                        file={fileS}
                        maxSize={3145728}
                        onDrop={handleDrop}
                        error={Boolean(touched.avatarUrl && errors.avatarUrl)}
                        caption={
                          <Typography
                            variant="caption"
                            sx={{
                              mt: 2,
                              mx: 'auto',
                              display: 'block',
                              textAlign: 'center',
                              color: 'text.secondary'
                            }}
                          >
                            Allowed *.jpeg, *.jpg, *.png, *.gif
                            <br /> max size of {fData(3145728)}
                          </Typography>
                        }
                      />
                      <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                        {touched.avatarUrl && errors.avatarUrl}
                      </FormHelperText>
                    </Box>

                    {edit && (
                      <FormControlLabel
                        labelPlacement="start"
                        control={
                          <Switch
                            onChange={(event) => setFieldValue('status', event.target.checked ? 'banned' : 'active')}
                            checked={values.status !== 'active'}
                          />
                        }
                        label={
                          <>
                            <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                              Banned
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                              Apply disable account
                            </Typography>
                          </>
                        }
                        sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
                      />
                    )}

                    <FormControlLabel
                      labelPlacement="start"
                      control={<Switch {...getFieldProps('isVerified')} checked={values.isVerified} />}
                      label={
                        <>
                          <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                            Email Verified
                          </Typography>
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Disabling this will automatically send the user a verification email
                          </Typography>
                        </>
                      }
                      sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
                    />
                  </Card>
                </Grid>

                <Grid item xs={12} md={8}>
                  <Card sx={{ p: 3 }}>
                    <Stack spacing={3}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          label="Nombre completo"
                          {...getFieldProps('displayName')}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        />
                        <TextField
                          fullWidth
                          label="Direccion de Email"
                          {...getFieldProps('email')}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          label="Numero de Telefono"
                          {...getFieldProps('phoneNumber')}
                          disabled={!isYou}
                          error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                          helperText={touched.phoneNumber && errors.phoneNumber}
                        />
                        <TextField
                          select
                          fullWidth
                          label="Pais"
                          placeholder="Pais"
                          {...getFieldProps('country')}
                          disabled={!isYou}
                          SelectProps={{ native: true }}
                          error={Boolean(touched.country && errors.country)}
                          helperText={touched.country && errors.country}
                        >
                          <option value="" />
                          {countries.map((option) => (
                            <option key={option.code} value={option.label}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          label="Estado"
                          {...getFieldProps('state')}
                          error={Boolean(touched.state && errors.state)}
                          helperText={touched.state && errors.state}
                        />
                        <TextField
                          fullWidth
                          label="Ciudad"
                          {...getFieldProps('city')}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          label="Direccion"
                          {...getFieldProps('address')}
                          error={Boolean(touched.address && errors.address)}
                          helperText={touched.address && errors.address}
                        />
                        <TextField fullWidth label="Zip/Code" disabled={!isYou} {...getFieldProps('zipCode')} />
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          label="Compañia"
                          {...getFieldProps('company')}
                          error={Boolean(touched.company && errors.company)}
                          helperText={touched.company && errors.company}
                        />
                        <TextField
                          fullWidth
                          label="Rol"
                          {...getFieldProps('role')}
                          error={Boolean(touched.role && errors.role)}
                          helperText={touched.role && errors.role}
                        />
                      </Stack>

                      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                          {!edit ? 'Create User' : 'Save Changes'}
                        </LoadingButton>
                      </Box>
                    </Stack>
                  </Card>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Dialog>
    </>
  );
}
