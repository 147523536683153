import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import instagramFilled from '@iconify/icons-ant-design/instagram-filled';
import phoneFilled from '@iconify/icons-ant-design/phone-filled';
import whatsappFilled from '@iconify/icons-ant-design/whats-app';
// material
import { styled } from '@mui/material/styles';
import { Link, Card, CardHeader, Stack } from '@mui/material';
import MessageHistory from '../../general/MessageHistory';

// ----------------------------------------------------------------------

const IconStyle = styled(Icon)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2)
}));

// ----------------------------------------------------------------------

ProfileSocialInfo.propTypes = {
  profile: PropTypes.object
};

export default function ProfileSocialInfo({ profile }) {
  const { facebookLink, instagramLink, linkedinLink, twitterLink, cellphoneNum, whatsphoneNum } = profile;

  const [openD, setOpenD] = useState(false);

  const clickOpenD = () => {
    setOpenD(true);
  };

  const SOCIALS = [
    {
      name: 'Linkedin',
      icon: <IconStyle icon={linkedinFill} color="#006097" />,
      href: linkedinLink,
      text: linkedinLink
    },
    {
      name: 'Twitter',
      icon: <IconStyle icon={twitterFill} color="#1C9CEA" />,
      href: twitterLink,
      text: twitterLink
    },
    {
      name: 'Instagram',
      icon: <IconStyle icon={instagramFilled} color="#D7336D" />,
      href: instagramLink,
      text: instagramLink
    },
    {
      name: 'Facebook',
      icon: <IconStyle icon={facebookFill} color="#1877F2" />,
      href: facebookLink,
      text: facebookLink
    },
    {
      name: 'Telefono',
      icon: <IconStyle icon={phoneFilled} color="#1850FF" />,
      href: 'tel:' + cellphoneNum,
      text: cellphoneNum
    },
    {
      name: 'Whatsapp',
      icon: <IconStyle icon={whatsappFilled} color="#0DC142" />,
      href: 'https://wa.me/' + whatsphoneNum,
      text: whatsphoneNum
    }
  ];

  return (
    <>
      <Card>
        <CardHeader title="Contacto" />
        <Stack spacing={2} sx={{ p: 3 }}>
          {SOCIALS.map((link) => {
            if (Boolean(link.text)) {
              return (
                <Stack key={link.name} direction="row" alignItems="center">
                  {link.icon}
                  <Link
                    component="button"
                    variant="body2"
                    color="text.primary"
                    target="_blank"
                    onClick={() => {
                      if (link.name === 'Whatsapp') {
                        clickOpenD();
                      } else {
                        window.open(link.href, '_blank');
                      }
                    }}
                    noWrap
                  >
                    {link.text}
                  </Link>
                </Stack>
              );
            }
            return <></>;
          })}
        </Stack>
      </Card>
      <MessageHistory open={openD} setOpen={setOpenD} link={'https://wa.me/' + whatsphoneNum} />
    </>
  );
}
