import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
// import { Link as RouterLink } from 'react-router-dom';
// import linkout from '@iconify/icons-eva/external-link-fill';
import homeFill from '@iconify/icons-eva/home-fill';
// import list from '@iconify/icons-eva/list-fill';
// material
import { MIconButton } from 'src/components/@material-extend';
// material
import { Box, Card, CardHeader, Typography, Stack, IconButton } from '@mui/material';
import { ProyectDialog } from 'src/components/_dashboard/proyecto';
//
import Scrollbar from '../../Scrollbar';
import { firestore } from 'src/contexts/FirebaseContext';
import useAuth from 'src/hooks/useAuth';
// icon
import starfill from '@iconify/icons-eva/star-fill';
import staroutline from '@iconify/icons-eva/star-outline';

// ----------------------------------------------------------------------

MoreMenuButton.propTypes = {
  proyecto: PropTypes.object,
  allowInteraction: PropTypes.bool
};

function MoreMenuButton({ proyecto, allowInteraction = false }) {
  const { nombre, imagen } = proyecto;
  /* const menuRef = useRef(null); */
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (allowInteraction) {
      setOpen(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: 48,
          height: 48,
          flexShrink: 0,
          display: 'flex',
          borderRadius: 1.5,
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'background.neutral'
        }}
      >
        <MIconButton
          onClick={handleOpen}
          // ref={menuRef}
          id="demo-positioned-button"
          aria-controls="demo-positioned-menu"
          aria-haspopup="true"
        >
          {imagen !== null && <img src={imagen} alt={nombre} width={24} height={24} />}
          {imagen === null && <Icon icon={homeFill} width={24} height={24} />}
        </MIconButton>
      </Box>

      <ProyectDialog proyecto={proyecto} open={open} setOpen={setOpen} />
      {/* <Menu
        open={open}
        anchorEl={menuRef.current}
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <MenuItem>
          <Icon icon={linkout} width={20} height={20} />
          <RouterLink to={'/dashboard/entorno/empresa/' + emp.idEmpresa + '/proyecto/' + emp.id}>
            <Typography variant="body2" sx={{ ml: 2 }}>
              Ir al proyecto
            </Typography>
          </RouterLink>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpen(false);
            openTT();
          }}
        >
          <Icon icon={list} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Tareas
          </Typography>
        </MenuItem>
        {/* <MenuItem>
          <Icon icon={printerFill} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Print
          </Typography>
        </MenuItem>
        <MenuItem>
          <Icon icon={shareFill} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Share
          </Typography>
        </MenuItem>
        <MenuItem>
          <Icon icon={archiveFill} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Archive
          </Typography>
        </MenuItem>

        <Divider />
        <MenuItem sx={{ color: 'error.main' }}>
          <Icon icon={trash2Outline} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Delete
          </Typography>
        </MenuItem>
      </Menu> */}
    </>
  );
}

// ----------------------------------------------------------------------

ProyectItem.propTypes = {
  proyecto: PropTypes.shape({
    nombre: PropTypes.string,
    logo: PropTypes.string,
    descripcion: PropTypes.string,
    id: PropTypes.string,
    likes: PropTypes.array
  }),
  allowInteraction: PropTypes.bool
};

export function ProyectItem({ proyecto, allowInteraction = false }) {
  const { user } = useAuth();
  const { nombre, id, descripcion, likes } = proyecto;

  const like = async () => {
    const newArr = [...likes];
    if (newArr.includes(user.id)) {
      await firestore
        .collection('proyectosempresa')
        .doc(id)
        .update({ likes: newArr.filter((d) => d !== user.id) });
    } else {
      newArr.push(user.id);
      await firestore.collection('proyectosempresa').doc(id).update({ likes: newArr });
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2}>
        <MoreMenuButton proyecto={proyecto} allowInteraction={allowInteraction} />

        <Box sx={{ flexGrow: 1, minWidth: 160 }}>
          <Typography variant="subtitle2">{nombre}</Typography>
          <Stack direction="row" alignItems="center" sx={{ mt: 0.5, color: 'text.secondary' }}>
            <Typography variant="caption" sx={{ ml: 0.5, mr: 1 }}>
              {descripcion}
            </Typography>
          </Stack>
        </Box>

        <Stack alignItems="flex-end" sx={{ pr: 3 }}>
          {allowInteraction && (
            <IconButton button onClick={like} sx={{ color: '#FAAF00' }}>
              <Icon icon={likes.includes(user.id) ? starfill : staroutline} />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </>
  );
}

export default function ProyectosFav() {
  const { user } = useAuth();
  const [proyectos, setProyectos] = useState([]);

  useEffect(() => {
    async function getEmpresas() {
      await firestore.collection('proyectosempresa').onSnapshot((queryS) => {
        const proyects = [];
        queryS.forEach((doc) => {
          const { likes } = doc.data();
          if (likes !== undefined) {
            if (likes.includes(user.id)) {
              proyects.push({ id: doc.id, ...doc.data() });
            }
          }
        });
        setProyectos(proyects);
      });
    }
    getEmpresas();
  }, [user]);

  return (
    <Card>
      <CardHeader title="Proyectos Favoritos" />
      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {proyectos.map((app) => (
            <ProyectItem key={app.id} proyecto={app} allowInteraction />
          ))}
        </Stack>
      </Scrollbar>
    </Card>
  );
}
