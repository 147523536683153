import React from 'react';
import { Card, CardHeader, CardContent, Grid } from '@mui/material';
import ProfileCard from 'src/components/_dashboard/general/ProfileCard';
import PropTypes from 'prop-types';

PersonsCard.propTypes = {
  empresa: PropTypes.object
};

export default function PersonsCard({ empresa }) {
  const { membersids } = empresa;

  return (
    <Card>
      <CardHeader title="Personas" />
      <CardContent>
        <Grid container spacing={3}>
          {membersids.map((memb) => (
            <Grid key={memb.id} item xs={12} md={4} lg={3}>
              <ProfileCard userid={memb} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
