import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'src/redux/store';
import { getBoard } from 'src/redux/slices/kanbansprint';
import plusFill from '@iconify/icons-eva/plus-fill';
import attach2Fill from '@iconify/icons-eva/attach-2-fill';
import heartoutline from '@iconify/icons-eva/heart-outline';
import heartfill from '@iconify/icons-eva/heart-fill';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import moreHorizontalFill from '@iconify/icons-eva/more-horizontal-fill';
// material
import { MobileDateRangePicker } from '@mui/lab';
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Drawer,
  Button,
  Avatar,
  Tooltip,
  Divider,
  MenuItem,
  TextField,
  Typography,
  OutlinedInput,
  ListItemAvatar,
  ListItemText,
  Dialog,
  DialogContent,
  List
} from '@mui/material';
//
import Scrollbar from '../../Scrollbar';
import { MIconButton, MHidden } from '../../@material-extend';
import KanbanTaskCommentList from './KanbanTaskCommentList';
import KanbanTaskAttachments from './KanbanTaskAttachments';
import KanbanTaskCommentInput from './KanbanTaskCommentInput';
import KanbanControlPanelT from './KanbanControlPanelT';
import KanbanUser from './KanbanUser';
import { useDatePicker, DisplayTime } from './KanbanTaskAdd';
import { firestore } from 'src/contexts/FirebaseContext';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

const PRIORITIZES = ['low', 'medium', 'hight', 'undefined'];

KanbanTaskDetails.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  card: PropTypes.object,
  onDeleteTask: PropTypes.func,
  cardId: PropTypes.string,
  projectId: PropTypes.string,
  idEmpresa: PropTypes.string.isRequired,
  spid: PropTypes.string.isRequired
};

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary
}));

// ----------------------------------------------------------------------

export default function KanbanTaskDetails({ card, isOpen, onClose, onDeleteTask, cardId, projectId, idEmpresa, spid }) {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [name, setName] = useState(card.name);
  const [taskCompleted, setTaskCompleted] = useState(card.completed);
  const [prioritize, setPrioritize] = useState(card.prioritize || 'undefined');
  const [description, setDescription] = useState(card.description || '');
  const [due, setDue] = useState(card.due || [null, null]);
  const [attachments, setAttachments] = useState(card.attachments || []);
  const [apoyo, setApoyo] = useState(card.apoyo || []);
  const [responsable, setResponsable] = useState(card.responsable || []);
  const [lider, setLider] = useState(card.lider || []);
  const [soporte, setSoporte] = useState(card.soporte || []);
  const [consultado, setConsultado] = useState(card.consultado || []);
  const [informado, setInformado] = useState(card.informado || []);
  const [likes, setLikes] = useState(card.likes || []);
  const { comments } = card;
  const [openMenua, setOpenMenua] = useState(false);
  const [openMenur, setOpenMenur] = useState(false);
  const [openMenul, setOpenMenul] = useState(false);
  const [openMenus, setOpenMenus] = useState(false);
  const [openMenuc, setOpenMenuc] = useState(false);
  const [openMenui, setOpenMenui] = useState(false);
  const [openCP, setOpenCP] = useState(false);
  const [diabledMenuOptions, setDisabledMenuOptions] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setAttachments(card.attachments || []);
    setLikes(card.likes || []);
  }, [card]);

  useEffect(() => {
    async function getUsers() {
      await firestore
        .collection('proyectosempresa')
        .doc(projectId)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setUsers(doc.data().membersinfo);
          }
        });
    }
    getUsers();
  }, [projectId]);

  const like = async () => {
    const newArr = [...likes];
    if (newArr.includes(user.id)) {
      setLikes(newArr.filter((d) => d !== user.id));
      await firestore
        .collection('kobjsCards')
        .doc(cardId)
        .update({ likes: newArr.filter((d) => d !== user.id) });
    } else {
      newArr.push(user.id);
      setLikes(newArr);
      await firestore.collection('kobjsCards').doc(cardId).update({ likes: newArr });
    }
    dispatch(getBoard(idEmpresa, spid));
  };

  const addApoyoa = async (user) => {
    setDisabledMenuOptions(true);
    const newArr = [...apoyo, user];
    setApoyo(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ apoyo: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, spid));
        setDisabledMenuOptions(false);
      });
  };

  const removeApoyoa = async (user) => {
    const newArr = [...apoyo.filter((as) => as.id !== user.id)];
    setApoyo(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ apoyo: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, spid));
      });
  };

  const addResponsable = async (user) => {
    setDisabledMenuOptions(true);
    const newArr = [...responsable, user];
    setResponsable(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ responsable: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, spid));
        setDisabledMenuOptions(false);
      });
  };

  const removeResponsable = async (user) => {
    const newArr = [...responsable.filter((as) => as.id !== user.id)];
    setResponsable(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ responsable: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, spid));
      });
  };
  const addLider = async (user) => {
    setDisabledMenuOptions(true);
    const newArr = [...lider, user];
    setLider(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ lider: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, spid));
        setDisabledMenuOptions(false);
      });
  };

  const removeLider = async (user) => {
    const newArr = [...lider.filter((as) => as.id !== user.id)];
    setLider(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ lider: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, spid));
      });
  };
  const addSoporte = async (user) => {
    setDisabledMenuOptions(true);
    const newArr = [...soporte, user];
    setSoporte(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ soporte: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, spid));
        setDisabledMenuOptions(false);
      });
  };

  const removeSoporte = async (user) => {
    const newArr = [...soporte.filter((as) => as.id !== user.id)];
    setSoporte(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ soporte: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, spid));
      });
  };
  const addConsultado = async (user) => {
    setDisabledMenuOptions(true);
    const newArr = [...consultado, user];
    setConsultado(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ consultado: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, spid));
        setDisabledMenuOptions(false);
      });
  };

  const removeConsultado = async (user) => {
    const newArr = [...consultado.filter((as) => as.id !== user.id)];
    setConsultado(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ consultado: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, spid));
      });
  };

  const addInformado = async (user) => {
    setDisabledMenuOptions(true);
    const newArr = [...informado, user];
    setInformado(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ informado: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, spid));
        setDisabledMenuOptions(false);
      });
  };

  const removeInformado = async (user) => {
    const newArr = [...informado.filter((as) => as.id !== user.id)];
    setInformado(newArr);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ informado: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, spid));
      });
  };

  const {
    dueDate,
    startTime,
    endTime,
    isSameDays,
    isSameMonths,
    onChangeDueDate,
    openPicker,
    onOpenPicker,
    onClosePicker
  } = useDatePicker({
    date: due
  });

  const handleAttach = () => {
    fileInputRef.current.click();
  };

  const firestoresetduedate = async (val) => {
    await firestore.collection('kobjsCards').doc(cardId).update({
      due: val
    });
  };

  const handlecardNameChange = async (event) => {
    setName(event.target.value);
    setTimeout(async () => {
      await firestore
        .collection('kobjsCards')
        .doc(cardId)
        .update({ name: event.target.value })
        .then(() => {
          dispatch(getBoard(idEmpresa, spid));
        });
    }, 1000);
  };

  const handleToggleCompleted = async () => {
    setTaskCompleted((prev) => !prev);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ completed: !taskCompleted })
      .then(() => {
        dispatch(getBoard(idEmpresa, spid));
      });
  };

  const handleChangePrioritize = async (event) => {
    setPrioritize(event.target.value);
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .update({ prioritize: event.target.value })
      .then(() => {
        dispatch(getBoard(idEmpresa, spid));
      });
  };

  const handleChangeDescription = async (event) => {
    setDescription(event.target.value);
    setTimeout(async () => {
      await firestore.collection('kobjsCards').doc(cardId).update({ description: event.target.value });
    }, 1000);
  };

  return (
    <>
      <Drawer open={isOpen} onClose={onClose} anchor="right" PaperProps={{ sx: { width: { xs: 1, sm: 480 } } }}>
        <Stack p={2.5} direction="row" alignItems="center">
          <MHidden width="smUp">
            <Tooltip title="Back">
              <MIconButton onClick={onClose} sx={{ mr: 1 }}>
                <Icon icon={arrowIosBackFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          </MHidden>

          <Button
            size="small"
            variant="outlined"
            color={taskCompleted ? 'primary' : 'inherit'}
            startIcon={!taskCompleted && <Icon icon={checkmarkFill} width={16} height={16} />}
            onClick={handleToggleCompleted}
          >
            {taskCompleted ? 'Completado' : 'marcar como completado'}
          </Button>

          <Stack direction="row" spacing={1} justifyContent="flex-end" flexGrow={1}>
            <Tooltip title="Like">
              <MIconButton size="small" onClick={like}>
                <Icon icon={likes.includes(user.id) ? heartfill : heartoutline} width={20} height={20} />
              </MIconButton>
            </Tooltip>

            <Tooltip title="Archivos adjuntos">
              <MIconButton size="small" onClick={handleAttach}>
                <Icon icon={attach2Fill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
            <input ref={fileInputRef} type="file" style={{ display: 'none' }} />

            {Boolean(lider.find((l) => l.id === user.id)) && (
              <Tooltip title="Eliminar tarea">
                <MIconButton onClick={onDeleteTask} size="small">
                  <Icon icon={trash2Outline} width={20} height={20} />
                </MIconButton>
              </Tooltip>
            )}

            <Tooltip title="Más Acciones">
              <MIconButton size="small">
                <Icon icon={moreHorizontalFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Divider />
        <KanbanControlPanelT
          card={card}
          cardId={cardId}
          projectId={projectId}
          showB
          open={openCP}
          setOpen={setOpenCP}
        />

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ px: 2.5, py: 3 }}>
            <OutlinedInput
              fullWidth
              multiline
              size="small"
              placeholder="Nombre de la tarea"
              value={name}
              onChange={handlecardNameChange}
              sx={{
                typography: 'h6',
                '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' }
              }}
            />
            <Stack direction="row">
              <LabelStyle sx={{ mt: 1.5 }}>Responsable</LabelStyle>
              <Stack direction="row" flexWrap="wrap" alignItems="center">
                {responsable.map((user) => (
                  <KanbanUser key={user.id} user={user} removeUser={removeResponsable} />
                ))}
                <Tooltip title="Add assignee">
                  <MIconButton
                    disabled={responsable.length !== 0}
                    sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                    onClick={() => setOpenMenur(true)}
                  >
                    <Icon icon={plusFill} width={20} height={20} />
                  </MIconButton>
                </Tooltip>
                <Dialog open={openMenur} onClose={() => setOpenMenur(false)}>
                  <DialogContent>
                    <List>
                      {users
                        .filter((u) => !Boolean(responsable.find((d) => d.id === u.id)))
                        .map((u) => {
                          return (
                            <MenuItem
                              key={'userr' + u.id}
                              onClick={() => {
                                addResponsable(u);
                                setOpenMenur(false);
                              }}
                              disabled={diabledMenuOptions}
                            >
                              <ListItemAvatar>
                                <Avatar src={u.avatar} />
                              </ListItemAvatar>
                              <ListItemText>{u.nombre}</ListItemText>
                            </MenuItem>
                          );
                        })}
                    </List>
                  </DialogContent>
                </Dialog>
              </Stack>
            </Stack>

            <Stack direction="row">
              <LabelStyle sx={{ mt: 1.5 }}>Apoyo</LabelStyle>
              <Stack direction="row" flexWrap="wrap" alignItems="center">
                {apoyo.map((user) => (
                  <KanbanUser key={user.id} user={user} removeUser={removeApoyoa} />
                ))}
                <Tooltip title="Add assignee">
                  <MIconButton
                    sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                    onClick={() => setOpenMenua(true)}
                  >
                    <Icon icon={plusFill} width={20} height={20} />
                  </MIconButton>
                </Tooltip>
                <Dialog open={openMenua} onClose={() => setOpenMenua(false)}>
                  <DialogContent>
                    <List>
                      {users
                        .filter((u) => !Boolean(apoyo.find((d) => d.id === u.id)))
                        .map((u) => {
                          return (
                            <MenuItem
                              key={'usera' + u.id}
                              onClick={() => {
                                addApoyoa(u);
                              }}
                              disabled={diabledMenuOptions}
                            >
                              <ListItemAvatar>
                                <Avatar src={u.avatar} />
                              </ListItemAvatar>
                              <ListItemText>{u.nombre}</ListItemText>
                            </MenuItem>
                          );
                        })}
                    </List>
                  </DialogContent>
                </Dialog>
              </Stack>
            </Stack>

            <Stack direction="row">
              <LabelStyle sx={{ mt: 1.5 }}>Lider</LabelStyle>
              <Stack direction="row" flexWrap="wrap" alignItems="center">
                {lider.map((user) => (
                  <KanbanUser key={user.id} user={user} removeUser={removeLider} />
                ))}
                <Tooltip title="Add assignee">
                  <MIconButton
                    sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                    onClick={() => setOpenMenul(true)}
                  >
                    <Icon icon={plusFill} width={20} height={20} />
                  </MIconButton>
                </Tooltip>
                <Dialog open={openMenul} onClose={() => setOpenMenul(false)}>
                  <DialogContent>
                    <List>
                      {users
                        .filter((u) => !Boolean(lider.find((d) => d.id === u.id)))
                        .map((u) => {
                          return (
                            <MenuItem
                              key={'userl' + u.id}
                              onClick={() => {
                                addLider(u);
                              }}
                              disabled={diabledMenuOptions}
                            >
                              <ListItemAvatar>
                                <Avatar src={u.avatar} />
                              </ListItemAvatar>
                              <ListItemText>{u.nombre}</ListItemText>
                            </MenuItem>
                          );
                        })}
                    </List>
                  </DialogContent>
                </Dialog>
              </Stack>
            </Stack>

            <Stack direction="row">
              <LabelStyle sx={{ mt: 1.5 }}>Soporte</LabelStyle>
              <Stack direction="row" flexWrap="wrap" alignItems="center">
                {soporte.map((user) => (
                  <KanbanUser key={user.id} user={user} removeUser={removeSoporte} />
                ))}
                <Tooltip title="Add assignee">
                  <MIconButton
                    sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                    onClick={() => setOpenMenus(true)}
                  >
                    <Icon icon={plusFill} width={20} height={20} />
                  </MIconButton>
                </Tooltip>
                <Dialog open={openMenus} onClose={() => setOpenMenus(false)}>
                  <DialogContent>
                    <List>
                      {users
                        .filter((u) => !Boolean(soporte.find((d) => d.id === u.id)))
                        .map((u) => {
                          return (
                            <MenuItem
                              key={'users' + u.id}
                              onClick={() => {
                                addSoporte(u);
                              }}
                              disabled={diabledMenuOptions}
                            >
                              <ListItemAvatar>
                                <Avatar src={u.avatar} />
                              </ListItemAvatar>
                              <ListItemText>{u.nombre}</ListItemText>
                            </MenuItem>
                          );
                        })}
                    </List>
                  </DialogContent>
                </Dialog>
              </Stack>
            </Stack>

            <Stack direction="row">
              <LabelStyle sx={{ mt: 1.5 }}>Consultado</LabelStyle>
              <Stack direction="row" flexWrap="wrap" alignItems="center">
                {consultado.map((user) => (
                  <KanbanUser key={user.id} user={user} removeUser={removeConsultado} />
                ))}
                <Tooltip title="Add assignee">
                  <MIconButton
                    sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                    onClick={() => setOpenMenuc(true)}
                  >
                    <Icon icon={plusFill} width={20} height={20} />
                  </MIconButton>
                </Tooltip>
                <Dialog open={openMenuc} onClose={() => setOpenMenuc(false)}>
                  <DialogContent>
                    <List>
                      {users
                        .filter((u) => !Boolean(consultado.find((d) => d.id === u.id)))
                        .map((u) => {
                          return (
                            <MenuItem
                              key={'userc' + u.id}
                              onClick={() => {
                                addConsultado(u);
                              }}
                              disabled={diabledMenuOptions}
                            >
                              <ListItemAvatar>
                                <Avatar src={u.avatar} />
                              </ListItemAvatar>
                              <ListItemText>{u.nombre}</ListItemText>
                            </MenuItem>
                          );
                        })}
                    </List>
                  </DialogContent>
                </Dialog>
              </Stack>
            </Stack>

            <Stack direction="row">
              <LabelStyle sx={{ mt: 1.5 }}>Informado</LabelStyle>
              <Stack direction="row" flexWrap="wrap" alignItems="center">
                {informado.map((user) => (
                  <KanbanUser key={user.id} user={user} removeUser={removeInformado} />
                ))}
                <Tooltip title="Add assignee">
                  <MIconButton
                    sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                    onClick={() => setOpenMenui(true)}
                  >
                    <Icon icon={plusFill} width={20} height={20} />
                  </MIconButton>
                </Tooltip>
                <Dialog open={openMenui} onClose={() => setOpenMenui(false)}>
                  <DialogContent>
                    <List>
                      {users
                        .filter((u) => !Boolean(informado.find((d) => d.id === u.id)))
                        .map((u) => {
                          return (
                            <MenuItem
                              key={'useri' + u.id}
                              onClick={() => {
                                addInformado(u);
                              }}
                              disabled={diabledMenuOptions}
                            >
                              <ListItemAvatar>
                                <Avatar src={u.avatar} />
                              </ListItemAvatar>
                              <ListItemText>{u.nombre}</ListItemText>
                            </MenuItem>
                          );
                        })}
                    </List>
                  </DialogContent>
                </Dialog>
              </Stack>
            </Stack>

            <Stack direction="row" alignItems="center">
              <LabelStyle> Fecha de entrega</LabelStyle>
              <>
                {startTime && endTime ? (
                  <DisplayTime
                    startTime={startTime}
                    endTime={endTime}
                    isSameDays={isSameDays}
                    isSameMonths={isSameMonths}
                    onOpenPicker={onOpenPicker}
                    sx={{ typography: 'body2' }}
                  />
                ) : (
                  <Tooltip title="Agregar persona">
                    <MIconButton
                      onClick={onOpenPicker}
                      sx={{
                        p: 1,
                        ml: 0.5,
                        border: (theme) => `dashed 1px ${theme.palette.divider}`
                      }}
                    >
                      <Icon icon={plusFill} width={20} height={20} />
                    </MIconButton>
                  </Tooltip>
                )}

                <MobileDateRangePicker
                  open={openPicker}
                  onClose={onClosePicker}
                  onOpen={onOpenPicker}
                  value={dueDate}
                  onChange={(value) => {
                    onChangeDueDate(value);
                    setDue(value);
                    firestoresetduedate(value);
                  }}
                  renderInput={() => {}}
                />
              </>
            </Stack>

            <Stack direction="row" alignItems="center">
              <LabelStyle>Prioridad</LabelStyle>
              <TextField
                fullWidth
                select
                size="small"
                value={prioritize}
                onChange={handleChangePrioritize}
                sx={{
                  '& svg': { display: 'none' },
                  '& fieldset': { display: 'none' },
                  '& .MuiSelect-select': { p: 0, display: 'flex', alignItems: 'center' }
                }}
              >
                {PRIORITIZES.map((option) => (
                  <MenuItem key={option} value={option}>
                    <Box
                      sx={{
                        mr: 1,
                        width: 14,
                        height: 14,
                        borderRadius: 0.5,
                        bgcolor: 'error.main',
                        ...(option === 'low' && { bgcolor: 'info.main' }),
                        ...(option === 'medium' && { bgcolor: 'warning.main' }),
                        ...(option === 'undefined' && { bgcolor: 'gray' })
                      }}
                    />
                    <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                      {option}
                    </Typography>
                  </MenuItem>
                ))}
              </TextField>
            </Stack>

            <Stack direction="row">
              <LabelStyle sx={{ mt: 2 }}>Descripción</LabelStyle>
              <OutlinedInput
                fullWidth
                multiline
                rows={3}
                size="small"
                placeholder="Descripcion"
                value={description}
                onChange={handleChangeDescription}
                sx={{ typography: 'body2' }}
              />
            </Stack>

            <Stack direction="row">
              <LabelStyle sx={{ mt: 2 }}>Archivos adjuntos</LabelStyle>
              <Stack direction="row" flexWrap="wrap">
                <KanbanTaskAttachments
                  attachments={attachments}
                  setAttachments={setAttachments}
                  cardId={cardId}
                  projectId={projectId}
                />
              </Stack>
            </Stack>
          </Stack>

          {comments.length > 0 && <KanbanTaskCommentList comments={comments} />}
        </Scrollbar>

        <Divider />

        <KanbanTaskCommentInput cardId={cardId} idEmpresa={idEmpresa} spid={spid} />
      </Drawer>
    </>
  );
}
