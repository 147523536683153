import { useEffect, createContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
// material
import { Container, Stack, Skeleton, Grid, Button, FormControlLabel, Checkbox, Toolbar } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import { getBoard, persistColumn, persistCard } from '../../redux/slices/kobjs';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// components
// import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { KanbanColumn, KanbanColumnAdd } from '../../components/_dashboard/kobjs';
import { firestore } from 'src/contexts/FirebaseContext';
// icon
import { Icon } from '@iconify/react';
import refreshI from '@iconify/icons-eva/refresh-fill';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

export const KobjsF = createContext({ showCompleted: true });

const SkeletonLoad = (
  <>
    {[...Array(3)].map((_, index) => (
      <Grid item xs={12} md={3} key={index}>
        <Skeleton variant="rectangular" width="100%" sx={{ paddingTop: '115%', borderRadius: 2 }} />
      </Grid>
    ))}
  </>
);

KObjs.propTypes = {
  projectId: PropTypes.string.isRequired,
  idEmpresa: PropTypes.string.isRequired
};

export default function KObjs({ projectId, idEmpresa }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { board } = useSelector((state) => state.kobjs);
  const [showCompleted, setShowCompleted] = useState(false);

  useEffect(() => {
    dispatch(getBoard(projectId));
  }, [dispatch, projectId]);

  const onDragEnd = (result) => {
    // Reorder card
    const { destination, source, draggableId, type } = result;

    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    if (type === 'column') {
      const newColumnOrder = Array.from(board.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      dispatch(persistColumn(newColumnOrder, projectId));
      enqueueSnackbar('Actializacion exitosa', { variant: 'success' });
      return;
    }

    const start = board.columns[source.droppableId];
    const finish = board.columns[destination.droppableId];

    if (start.id === finish.id) {
      const updatedCardIds = [...start.cardIds];
      updatedCardIds.splice(source.index, 1);
      updatedCardIds.splice(destination.index, 0, draggableId);

      const updatedColumn = {
        ...start,
        cardIds: updatedCardIds
      };

      firestore
        .collection('kobjsColunms')
        .doc(updatedColumn.id)
        .update({ ...updatedColumn });

      dispatch(
        persistCard(
          {
            columns: {
              ...board.columns,
              [updatedColumn.id]: updatedColumn
            }
          },
          projectId
        )
      );

      return;
    }

    const startCardIds = [...start.cardIds];
    startCardIds.splice(source.index, 1);
    const updatedStart = {
      ...start,
      cardIds: startCardIds
    };

    const finishCardIds = [...finish.cardIds];
    finishCardIds.splice(destination.index, 0, draggableId);
    const updatedFinish = {
      ...finish,
      cardIds: finishCardIds
    };

    firestore
      .collection('kobjsColunms')
      .doc(updatedStart.id)
      .update({ ...updatedStart });
    firestore
      .collection('kobjsColunms')
      .doc(updatedFinish.id)
      .update({ ...updatedFinish });

    dispatch(
      persistCard(
        {
          columns: {
            ...board.columns,
            [updatedStart.id]: updatedStart,
            [updatedFinish.id]: updatedFinish
          }
        },
        projectId
      )
    );
    enqueueSnackbar('Actualizacion exitosa', { variant: 'success' });
  };

  return (
    <KobjsF.Provider value={{ showCompleted }}>
      <Container maxWidth={false} sx={{ height: '100%' }}>
        <Toolbar>
          <Button
            onClick={() => {
              dispatch(getBoard(projectId));
            }}
            sx={{ margin: '1%', color: '#637381' }}
          >
            <Icon icon={refreshI} width={25} height={25} />
            Actualizar
          </Button>
          <FormControlLabel
            sx={{ margin: '1%' }}
            control={
              <Checkbox
                value={showCompleted}
                onChange={(e) => {
                  setShowCompleted(e.target.checked);
                }}
              />
            }
            label="Mostrar completadas"
          />
        </Toolbar>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="all-columns" direction="horizontal" type="column">
            {(provided) => (
              <Stack
                {...provided.droppableProps}
                ref={provided.innerRef}
                direction="row"
                alignItems="flex-start"
                spacing={3}
                sx={{ height: 'calc(100% - 32px)', overflowY: 'hidden' }}
              >
                {board?.columnOrder?.map((columnId, index) => {
                  const column = board.columns[columnId];
                  return (
                    <KanbanColumn
                      index={index}
                      key={columnId}
                      column={column}
                      projectId={projectId}
                      idEmpresa={idEmpresa}
                    />
                  );
                })}

                {!board?.columnOrder.length && SkeletonLoad}

                {provided.placeholder}
                <KanbanColumnAdd projectId={projectId} />
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      </Container>
    </KobjsF.Provider>
  );
}
