import React, { useState } from 'react';
import { ListItem, ListItemText, ListItemIcon, Dialog, DialogContent, Card, List, Grid } from '@mui/material';
import KanbanEntregables from 'src/pages/dashboard/KanbanEntregables';
// icon
import { Icon } from '@iconify/react';
import packect from '@iconify/icons-eva/inbox-fill';
//
import PropTypes from 'prop-types';

EntrgeablesD.propTypes = {
  idEmpresa: PropTypes.string
};

export default function EntrgeablesD({ idEmpresa }) {
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#506070' }}>
        <ListItemIcon>
          <Icon icon={packect} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Entregables</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <Card>
                <List>
                  <ListItem>
                    <ListItemText>nav</ListItemText>
                  </ListItem>
                </List>
              </Card>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Card>
                <KanbanEntregables idEmpresa={idEmpresa} />
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
