import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useState, useRef, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import roundSend from '@iconify/icons-ic/round-send';
import heartFill from '@iconify/icons-eva/heart-fill';
import shareFill from '@iconify/icons-eva/share-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import messageSquareFill from '@iconify/icons-eva/message-square-fill';
import deleteI from '@iconify/icons-ant-design/delete-filled';
// material
import {
  Box,
  Link,
  Card,
  Stack,
  Paper,
  Avatar,
  Checkbox,
  TextField,
  Typography,
  CardHeader,
  IconButton,
  AvatarGroup,
  InputAdornment,
  FormControlLabel,
  Menu,
  MenuItem,
  Button
} from '@mui/material';
// utils
import { fDate, parseDate } from 'src/utils/formatTime';
import { fShortenNumber } from 'src/utils/formatNumber';
// hooks
import useAuth from 'src/hooks/useAuth';
//
import MyAvatar from 'src/components/MyAvatar';
import EmojiPicker from 'src/components/EmojiPicker';
import { firestore, storage as st } from 'src/contexts/FirebaseContext';
import { v1 as uuidv1 } from 'uuid';
import ProfileInfoD from 'src/components/_dashboard/user/profile/ProfileInfoD';

// ----------------------------------------------------------------------

ProfilePostCard.propTypes = {
  post: PropTypes.object,
  collection: PropTypes.string,
  doc: PropTypes.string
};

export default function ProfilePostCard({ post, collection, doc }) {
  const { user } = useAuth();
  const commentInputRef = useRef(null);
  const fileInputRef = useRef(null);
  const menuRef = useRef(null);
  const [plikes, setPlikes] = useState(post.personLikes);
  const [isLiked, setLiked] = useState(plikes.includes(user.id));
  const [likes, setLikes] = useState(post.personLikes.length);
  const [message, setMessage] = useState('');
  const [comments, setComments] = useState(post.comments || []);
  const hasComments = comments.length > 0;
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    async function getD() {
      await firestore
        .collection(collection)
        .doc(doc)
        .collection('posts')
        .doc(post.id)
        .onSnapshot((docc) => {
          if (docc.exists) {
            const { personLikes, comments } = docc.data();
            setComments(comments);
            setPlikes(personLikes);
          }
        });
    }
    getD();
  }, [user, post, collection, doc]);

  const handleLike = async () => {
    const newArr = [...plikes, user.id];
    setLiked(true);
    setLikes((prevLikes) => prevLikes + 1);
    setPlikes(newArr);
    await firestore.collection(collection).doc(doc).collection('posts').doc(post.id).update({ personLikes: newArr });
  };

  const handleUnlike = async () => {
    const newArr = plikes.filter((d) => d !== user.id);
    setLiked(false);
    setLikes((prevLikes) => prevLikes - 1);
    setPlikes(newArr);
    await firestore.collection(collection).doc(doc).collection('posts').doc(post.id).update({ personLikes: newArr });
  };

  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
  };

  /* const handleClickAttach = () => {
    fileInputRef.current.click();
  }; */

  const handleClickComment = () => {
    commentInputRef.current.focus();
  };

  const handleComment = async () => {
    const idc = uuidv1();
    const author = {
      id: user.id,
      name: user.displayName,
      avatarUrl: user.photoURL
    };
    const comment = {
      id: idc,
      author,
      message,
      createdAt: new Date()
    };
    const newArr = [...comments, comment];
    setComments(newArr);
    setMessage('');
    await firestore.collection(collection).doc(doc).collection('posts').doc(post.id).update({ comments: newArr });
  };

  const deletePost = async () => {
    if (window.confirm('desea eliminar el post?')) {
      await firestore.collection(collection).doc(doc).collection('posts').doc(post.id).delete();
      const refF = st.refFromURL(post.media);
      try {
        if (!post.type.includes('iframe')) {
          await refF.delete();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <Card sx={{ margin: '2%' }}>
      <CardHeader
        disableTypography
        avatar={<Avatar src={post.author.avatarUrl} />}
        title={
          <Link
            to={'/dashboard/user/profile/' + post.author.id}
            variant="subtitle2"
            color="text.primary"
            component={RouterLink}
          >
            {post.author.name}
          </Link>
        }
        subheader={
          <Typography variant="caption" sx={{ display: 'block', color: 'text.secondary' }}>
            {fDate(post.createdAt.toDate())}
          </Typography>
        }
        action={
          <>
            <IconButton ref={menuRef} onClick={clickOpen}>
              <Icon icon={moreVerticalFill} width={20} height={20} />
            </IconButton>
            <Menu anchorEl={menuRef.current} open={open} onClose={clickClose}>
              {post.author.id === user.id && (
                <MenuItem
                  onClick={() => {
                    clickClose();
                    deletePost();
                  }}
                >
                  <Icon icon={deleteI} width={25} height={25} /> Borrar
                </MenuItem>
              )}
            </Menu>
          </>
        }
      />

      <Stack spacing={3} sx={{ p: 3 }}>
        <Typography variant="body1">{post.message}</Typography>
        {Boolean(post.media) &&
          (post.type?.includes('image') || post.type?.includes('video') || post.type?.includes('iframe') ? (
            <Box sx={{ position: 'relative', pt: '4%', height: '350px' }}>
              {post.type?.includes('image') && (
                <Box
                  component="img"
                  alt="post media"
                  src={post.media}
                  sx={{
                    top: 0,
                    width: 1,
                    height: 1,
                    borderRadius: 1,
                    objectFit: 'cover',
                    position: 'absolute'
                  }}
                />
              )}
              {post.type?.includes('video') && (
                <Box
                  component="video"
                  controls="controls"
                  alt="post media"
                  src={post.media}
                  sx={{
                    top: 0,
                    width: '100%',
                    height: 'auto',
                    borderRadius: 1,
                    objectFit: 'cover',
                    position: 'absolute'
                  }}
                />
              )}
              {post.type?.includes('iframe') && (
                <iframe title="post iframe" src={post.media} style={{ border: 0, width: '100%', height: '100%' }} />
              )}
            </Box>
          ) : (
            <Button component="a" href={post.media} target="_blank">
              Decargar contenido
            </Button>
          ))}

        <Stack direction="row" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                color="error"
                checked={isLiked}
                icon={<Icon icon={heartFill} width={20} height={20} />}
                checkedIcon={<Icon icon={heartFill} width={20} height={20} />}
                onChange={isLiked ? handleUnlike : handleLike}
              />
            }
            label={fShortenNumber(likes)}
            sx={{ minWidth: 72, mr: 0 }}
          />
          <AvatarGroup max={4} sx={{ '& .MuiAvatar-root': { width: 32, height: 32 } }}>
            {plikes.map((person) => (
              <ProfileInfoD key={person} userid={person} />
            ))}
          </AvatarGroup>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClickComment}>
            <Icon icon={messageSquareFill} width={20} height={20} />
          </IconButton>
          <IconButton>
            <Icon icon={shareFill} width={20} height={20} />
          </IconButton>
        </Stack>

        {hasComments && (
          <Stack spacing={1.5}>
            {comments.map((comment) => (
              <Stack key={comment.id} direction="row" spacing={2}>
                <ProfileInfoD userid={comment.author.id} />
                <Paper sx={{ p: 1.5, flexGrow: 1, bgcolor: 'background.neutral' }}>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems={{ sm: 'center' }}
                    justifyContent="space-between"
                    sx={{ mb: 0.5 }}
                  >
                    <Typography variant="subtitle2">{comment.author.name}</Typography>
                    <Typography variant="caption" sx={{ color: 'text.disabled' }}>
                      {fDate(parseDate(comment.createdAt))}
                    </Typography>
                  </Stack>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {comment.message}
                  </Typography>
                </Paper>
              </Stack>
            ))}
          </Stack>
        )}

        <Stack direction="row" alignItems="center">
          <MyAvatar />
          <TextField
            fullWidth
            size="small"
            value={message}
            inputRef={commentInputRef}
            placeholder="escribe un comentario…"
            onKeyDown={(e) => {
              if (e.key === 'Enter' && message !== '') {
                handleComment();
              }
            }}
            onChange={handleChangeMessage}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {/* <IconButton size="small" onClick={handleClickAttach}>
                    <Icon icon={roundAddPhotoAlternate} width={24} height={24} />
                  </IconButton> */}
                  <EmojiPicker alignRight value={message} setValue={setMessage} />
                </InputAdornment>
              )
            }}
            sx={{
              ml: 2,
              mr: 1,
              '& fieldset': {
                borderWidth: `1px !important`,
                borderColor: (theme) => `${theme.palette.grey[500_32]} !important`
              }
            }}
          />
          <IconButton
            onClick={() => {
              handleComment();
            }}
            disabled={message === ''}
          >
            <Icon icon={roundSend} width={24} height={24} />
          </IconButton>
          <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
        </Stack>
      </Stack>
    </Card>
  );
}
