import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, Box, Typography, TextField, MenuItem } from '@mui/material';
import { fDateTime } from 'src/utils/formatTime';
import { firestore } from 'src/contexts/FirebaseContext';
//
import { merge, orderBy } from 'lodash';
import ReactApexChart from 'react-apexcharts';
//
import BaseOptionChart from 'src/components/charts/BaseOptionChart';
import PropTypes from 'prop-types';

const moment = require('moment');

KRChart.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  cardId: PropTypes.string,
  krid: PropTypes.string,
  showB: PropTypes.bool
};

export default function KRChart({ open = false, setOpen, cardId, krid, showB = false }) {
  const [data, setData] = useState([]);
  const [CHART_DATA, setChartData] = useState([{ name: 'Desktops', data: [0] }]);
  const [LABELS, setLabels] = useState(['null']);
  const [vista, setVista] = useState('day');

  const VISTAS = [
    { name: 'Día', value: 'day' },
    { name: 'Semanal', value: 'week' },
    { name: 'Mensual', value: 'month' },
    { name: 'Todo', value: 'all' }
  ];

  useEffect(() => {
    async function getKRsH() {
      await firestore
        .collection('kobjsCards')
        .doc(cardId)
        .collection('krtime')
        .where('krid', '==', krid)
        .onSnapshot((queryS) => {
          const dat = [];
          queryS.forEach((doc) => {
            const { krtext, avance, meta, max, min, date } = doc.data();
            dat.push({ krtext, avance, meta, max, min, date });
          });
          setData(orderBy(dat, ['date'], ['asc']));
        });
    }
    getKRsH();
  }, [cardId, krid]);

  useEffect(() => {
    const dates = [];
    const labels = [];
    const metavalues = [];
    const avances = [];
    data.forEach((d) => {
      const { max, avance, meta, date } = d;
      const dateD = date.toDate();
      if (labels.length !== 0) {
        const lastdate = moment(dates[dates.length - 1])
          .startOf(vista)
          .toDate()
          .getTime();
        const datein = moment(dateD).startOf(vista).toDate().getTime();
        if (lastdate === datein && vista !== 'all') {
          dates.pop();
          labels.pop();
          metavalues.pop();
          avances.pop();
        }
        dates.push(dateD);
        labels.push(fDateTime(dateD));
        metavalues.push(meta || max || 100);
        avances.push(avance);
      } else {
        dates.push(dateD);
        labels.push(fDateTime(dateD));
        metavalues.push(meta || 100);
        avances.push(avance);
      }
    });

    setChartData([
      { name: 'meta', data: metavalues },
      { name: 'avance', data: avances }
    ]);
    setLabels(labels);
  }, [data, vista]);

  const chartOptions = merge(BaseOptionChart(), {
    xaxis: {
      categories: LABELS
    },
    tooltip: { x: { show: false }, marker: { show: false } }
  });

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      {showB && <Button onClick={clickOpen}>Grafica de Krs</Button>}
      <Dialog open={open} onClose={clickClose} maxWidth="1200px">
        <Box sx={{ wdith: '1100px' }} />
        <DialogContent>
          <Typography variant="h5">Historial del KR</Typography>
          <TextField select label="Vista" value={vista} onChange={(e) => setVista(e.target.value)}>
            {VISTAS.map((vist) => (
              <MenuItem key={vist.value} value={vist.value}>
                {vist.name}
              </MenuItem>
            ))}
          </TextField>
          <Box component="div" sx={{ padding: '5px', margin: '5px' }}>
            <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={320} width={900} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
