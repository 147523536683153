import React, { useEffect, useCallback } from 'react';
import { mercadopagopk } from 'src/config';
import { useMercadopago } from 'react-sdk-mercadopago';
import { getPayID } from 'src/_apis_/pagos';
import PropTypes from 'prop-types';

ButtonMercP.propTypes = {
  buttonText: PropTypes.string,
  quantity: PropTypes.number,
  price: PropTypes.number,
  description: PropTypes.string,
  producto: PropTypes.string,
  idEmpresa: PropTypes.string,
  elementId: PropTypes.string,
  productId: PropTypes.string
};

export default function ButtonMercP({
  buttonText = '',
  quantity,
  price,
  description,
  producto = '',
  idEmpresa = '',
  productId = '',
  elementId
}) {
  const mercp = useMercadopago.v2(mercadopagopk, { locale: 'es-MX' });

  const checkout = useCallback(async () => {
    try {
      const pages = {
        empresap: `${window.origin}/activate/empresa/${idEmpresa}`,
        producto: `${window.origin}/gracias?product_id=${productId}`
      };
      document.getElementById(elementId || 'button-checkout').innerHTML = '';
      const payID = await getPayID(quantity || 1, price || 10, description || '', pages[producto]);
      mercp.checkout({
        preference: {
          id: payID
        },
        render: {
          container: Boolean(elementId) ? `#${elementId}` : '#button-checkout', // Class name where the payment button will be displayed
          label: buttonText // Change the payment button text (optional)
        }
      });
    } catch (err) {
      console.error(err);
    }
  }, [mercp, buttonText, quantity, price, description, producto, idEmpresa, elementId]);

  useEffect(() => {
    checkout();
  }, [checkout]);

  return (
    <>
      <div id={elementId || 'button-checkout'}> </div>
    </>
  );
}
