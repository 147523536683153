import React, { useState, useEffect } from 'react';
import { ListItem, ListItemText, ListItemIcon, Dialog, DialogContent, Grid, Card, List, Box } from '@mui/material';
// icon
import { Icon } from '@iconify/react';
import btnI from '@iconify/icons-carbon/circle-solid';
//
import ButtonElement from './ButtonElement';
import AltaBoton from './AltaBoton';

import { firestore } from 'src/contexts/FirebaseContext';

export default function ButtonsNav() {
  const [open, setOpen] = useState(false);
  const [btns, setBtns] = useState([]);

  useEffect(() => {
    async function getBtns() {
      await firestore.collection('buttons').onSnapshot((queryS) => {
        const dat = [];
        queryS.forEach((doc) => {
          dat.push({ id: doc.id, ...doc.data() });
        });
        setBtns(dat);
      });
    }
    getBtns();
  }, []);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#506070' }}>
        <ListItemIcon>
          <Icon icon={btnI} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Buttons</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <Box sx={{ width: '60vw' }} />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <Card>
                <List>
                  <AltaBoton listB />
                </List>
              </Card>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Card>
                <Grid container spacing={3}>
                  {btns.map((bt) => (
                    <Grid key={bt.id} item xs={12} md={6} lg={4}>
                      <ButtonElement BTN={bt} />
                    </Grid>
                  ))}
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
