import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material';
import AddProyecto from './AddProyecto';
import { firestore } from 'src/contexts/FirebaseContext';
import { AltaProyecto } from 'src/components/_dashboard/entorno';
import { ProyectItem } from 'src/components/_dashboard/general-app/ProyectosFav';
import PropTypes from 'prop-types';

ProyectoRow.propTypes = {
  idP: PropTypes.string
};

function ProyectoRow({ idP }) {
  const [proyecto, setProyecto] = useState(undefined);

  useEffect(() => {
    async function getPD() {
      await firestore
        .collection('proyectosempresa')
        .doc(idP)
        .onSnapshot((doc) => {
          setProyecto({ id: doc.id, ...doc.data() });
        });
    }
    getPD();
  }, [idP]);

  return (
    <TableRow>
      <TableCell>{proyecto && <ProyectItem proyecto={proyecto} allowInteraction />}</TableCell>
    </TableRow>
  );
}

ProyectosAsociados.propTypes = {
  idEmpresa: PropTypes.string,
  cardId: PropTypes.string
};

export default function ProyectosAsociados({ idEmpresa, cardId }) {
  const [openAP, setOpenAP] = useState(false);
  const [proyects, setProyects] = useState([]);

  useEffect(() => {
    async function getPs() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanCards')
        .doc(cardId)
        .onSnapshot((doc) => {
          setProyects(doc.data().proyectosA || []);
        });
    }
    getPs();
  }, [idEmpresa, cardId]);

  const onCreateP = async (idP) => {
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('kanbanCards')
      .doc(cardId)
      .get()
      .then((doc) => {
        const proyectosA = doc.data().proyectosA || [];
        firestore
          .collection('empresas')
          .doc(idEmpresa)
          .collection('kanbanCards')
          .doc(cardId)
          .update({ proyectosA: [...proyectosA, idP] })
          .catch((err) => {
            console.error(err);
          });
      });
  };

  return (
    <Card>
      <CardHeader
        title="Proyectos alienados"
        action={
          <>
            <AltaProyecto idEmpresa={idEmpresa} open={openAP} setOpen={setOpenAP} showB onCreateNew={onCreateP} />
            <AddProyecto idEmpresa={idEmpresa} cardId={cardId} proyectosA={proyects} />
          </>
        }
      />
      <CardContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Proyecto</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {proyects.map((p) => (
                <ProyectoRow key={p} idP={p} idEmpresa={idEmpresa} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
