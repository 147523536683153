import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import bookFill from '@iconify/icons-eva/book-fill';
import heartFill from '@iconify/icons-eva/heart-fill';
// import shareFill from '@iconify/icons-eva/share-fill';
// import printerFill from '@iconify/icons-eva/printer-fill';
// import downloadFill from '@iconify/icons-eva/download-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import editFill from '@iconify/icons-eva/edit-fill';
// material
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Menu,
  Table,
  Avatar,
  Divider,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  Typography,
  TableContainer,
  Stack,
  Slider,
  Grid,
  TextField
} from '@mui/material';
import EditKR from './EditKR';
//
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import { useDispatch } from 'src/redux/store';
import { getBoard } from 'src/redux/slices/kanbanempresa';
import { MIconButton } from 'src/components/@material-extend';
import { firestore } from 'src/contexts/FirebaseContext';
import KRsTask from './KRsTask';
import KRsAddR from './KRsAddR';
import KanbanUser from 'src/components/_dashboard/kobjs/KanbanUser';
import KRChart from './charts/KRChart';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

AvatarIcon.propTypes = {
  icon: PropTypes.object
};

function AvatarIcon({ icon }) {
  return (
    <Avatar
      sx={{
        width: 48,
        height: 48,
        color: 'text.secondary',
        bgcolor: 'background.neutral'
      }}
    >
      <Icon icon={icon} width={24} height={24} />
    </Avatar>
  );
}

function renderAvatar(transitions) {
  if (transitions.category === 'Books') {
    return <AvatarIcon icon={bookFill} />;
  }
  if (transitions.category === 'Beauty & Health') {
    return <AvatarIcon icon={heartFill} />;
  }
  return transitions.avatar ? (
    <Avatar
      alt={transitions.category}
      src={transitions.avatar}
      sx={{ width: 48, height: 48, boxShadow: (theme) => theme.customShadows.z8 }}
    />
  ) : null;
}

MoreMenuButton.propTypes = {
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onPrint: PropTypes.func,
  onShare: PropTypes.func,
  onEdit: PropTypes.func,
  onShowChart: PropTypes.func,
  krId: PropTypes.string
};

// eslint-disable-next-line
function MoreMenuButton({ onDownload, onPrint, onShare, onDelete, onEdit, onShowChart, krId }) {
  const menuRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <>
        <MIconButton ref={menuRef} size="large" onClick={handleOpen}>
          <Icon icon={moreVerticalFill} width={20} height={20} />
        </MIconButton>
      </>

      <Menu
        open={open}
        anchorEl={menuRef.current}
        onClose={handleClose}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={onEdit}>
          <Icon icon={editFill} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Editar
          </Typography>
        </MenuItem>
        <MenuItem onClick={onShowChart}>
          <Icon icon={eyeFill} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Mostrar gráfica
          </Typography>
        </MenuItem>
        {/* <MenuItem onClick={onDownload}>
          <Icon icon={downloadFill} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Download
          </Typography>
        </MenuItem>
        <MenuItem onClick={onPrint}>
          <Icon icon={printerFill} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Print
          </Typography>
        </MenuItem>
        <MenuItem onClick={onShare}>
          <Icon icon={shareFill} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Share
          </Typography>
        </MenuItem> */}
        <Divider />
        <MenuItem onClick={() => onDelete(krId)} sx={{ color: 'error.main' }}>
          <Icon icon={trash2Outline} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Eliminar
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

KRrow.propTypes = {
  row: PropTypes.object,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onPrint: PropTypes.func,
  onShare: PropTypes.func,
  onEditP: PropTypes.func,
  cardId: PropTypes.string,
  idEmpresa: PropTypes.string.isRequired,
  spid: PropTypes.string.isRequired
};

function KRrow({ row, onDownload, onPrint, onShare, onDelete, onEditP, cardId, idEmpresa, spid }) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const [openD, setOpenD] = useState(false);
  const [openC, setOpenC] = useState(false);
  const [resp, setResp] = useState(row.responsable || []);
  const [progreso, setProgreso] = useState(row.avance || 0);
  const [initialValue, setInitialValue] = useState(row.min || 0);
  const [maxValue, setMaxValue] = useState(row.max || 100);
  const [KRType, setKRType] = useState('%');

  useEffect(() => {
    setResp(row.responsable || []);
    setProgreso(row.avance || 0);
    setInitialValue(row.min || 0);
    setMaxValue(row.max || 100);
    setKRType(row.type || '%');
  }, [row]);

  const onEdit = () => {
    setOpenD(true);
  };

  const showChart = () => {
    setOpenC(true);
  };

  return (
    <TableRow>
      <TableCell align="right">
        <MoreMenuButton
          onDownload={onDownload}
          onPrint={onPrint}
          onShare={onShare}
          onDelete={onDelete}
          onEdit={onEdit}
          onShowChart={showChart}
          krId={row.id}
        />
        <EditKR
          open={openD}
          onClose={() => setOpenD(false)}
          kr={row}
          onSave={onEditP}
          cardId={cardId}
          idEmpresa={idEmpresa}
          spid={spid}
        />
        <KRChart open={openC} setOpen={setOpenC} cardId={cardId} krid={row.id} />
      </TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ position: 'relative' }}>{renderAvatar(row)}</Box>
          <Box sx={{ ml: 2 }}>
            <Typography variant="h5" sx={{ color: 'text.primary' }}>
              {row.text}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Stack direction="row" flexWrap="wrap" alignItems="center">
          {resp.map((respp) => (
            <KanbanUser
              key={respp.id}
              user={respp}
              removeUser={(a) => {
                console.log(a);
                onEditP({
                  text: row.text,
                  status: row.status,
                  responsable: [],
                  id: row.id,
                  avance: row.avance,
                  min: initialValue,
                  meta: row.meta,
                  max: maxValue,
                  type: KRType
                });
              }}
            />
          ))}
          {resp.length === 0 && (
            <KRsAddR
              responsable={resp}
              setResponsable={setResp}
              onEditP={onEditP}
              kr={row}
              idEmpresa={idEmpresa}
              spid={spid}
            />
          )}
        </Stack>
      </TableCell>
      <TableCell>
        <Grid container>
          <Grid
            item
            xs={10}
            sx={{ margin: '3px' }}
            onMouseUpCapture={() => {
              onEditP({
                text: row.text,
                status: row.status,
                responsable: row.responsable || [],
                id: row.id,
                avance: progreso,
                min: initialValue,
                meta: row.meta,
                max: maxValue,
                type: KRType
              });
            }}
          >
            <Slider
              min={initialValue || 0}
              max={maxValue || 100}
              step={1}
              value={progreso}
              onChange={(e) => {
                setProgreso(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h6">
              {progreso} {KRType}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell>
        <Label
          variant={isLight ? 'ghost' : 'filled'}
          color={
            (row.status === 'completado' && 'success') ||
            (row.status === 'en_progreso' && 'warning') ||
            (row.status === 'creado' && 'info') ||
            'error'
          }
        >
          <TextField
            select
            value={row.status}
            onChange={(e) => {
              onEditP({
                text: row.text,
                status: e.target.value,
                responsable: row.responsable || [],
                id: row.id,
                avance: row.avance,
                min: initialValue,
                meta: row.meta,
                max: maxValue,
                type: KRType
              });
            }}
            sx={{
              '& svg': { display: 'none' },
              '& fieldset': { display: 'none' },
              '& .MuiSelect-select': { p: 0, display: 'flex', alignItems: 'center' }
            }}
          >
            <MenuItem value="creado">Creado</MenuItem>
            <MenuItem value="completado">Completado</MenuItem>
            <MenuItem value="en_progreso">En progreso</MenuItem>
            <MenuItem value="parado">Parado</MenuItem>
          </TextField>
          {/* sentenceCase(row.status || 'undefined') */}
        </Label>
      </TableCell>
    </TableRow>
  );
}

KRsList.propTypes = {
  card: PropTypes.object.isRequired,
  cardId: PropTypes.string.isRequired,
  idEmpresa: PropTypes.string.isRequired,
  idP: PropTypes.string
};

export default function KRsList({ card, cardId, idEmpresa, idP }) {
  const dispatch = useDispatch();
  const [KRs, setKRs] = useState(card.krs || []);

  useEffect(() => {
    setKRs(card.krs || []);
  }, [card]);
  const handleClickSave = async (kr) => {
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(idP)
      .collection('kanbanCardscomp')
      .doc(cardId)
      .get()
      .then(async (doc) => {
        const { krs } = doc.data();
        const newArr = krs;
        const index = newArr.findIndex((k) => k.id === kr.id);
        if (krs[index].avance !== kr.avance) {
          firestore
            .collection('empresas')
            .doc(idEmpresa)
            .collection('perfiles')
            .doc(idP)
            .collection('kanbanCardscomp')
            .doc(cardId)
            .collection('krtime')
            .add({
              krid: kr.id,
              krtext: kr.text,
              date: new Date(),
              avance: kr.avance,
              max: kr.max || 100,
              meta: kr.meta || 100,
              min: kr.min || 0
            });
        }
        newArr[index] = kr;
        await firestore
          .collection('empresas')
          .doc(idEmpresa)
          .collection('perfiles')
          .doc(idP)
          .collection('kanbanCardscomp')
          .doc(cardId)
          .update({ krs: newArr });
      });
    dispatch(getBoard(idEmpresa, idP));
  };
  const handleClickDownload = () => {};
  const handleClickPrint = () => {};
  const handleClickShare = () => {};
  const handleClickDelete = async (id) => {
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(idP)
      .collection('kanbanCardscomp')
      .doc(cardId)
      .get()
      .then((doc) => {
        const { krs } = doc.data();
        firestore
          .collection('empresas')
          .doc(idEmpresa)
          .collection('perfiles')
          .doc(idP)
          .collection('kanbanCardscomp')
          .doc(cardId)
          .update({ krs: krs.filter((k) => k.id !== id) });
      });
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(idP)
      .collection('kanbanCardscomp')
      .doc(cardId)
      .collection('krtime')
      .where('krid', '==', id)
      .get()
      .then((queryS) => {
        queryS.forEach((doc) => {
          firestore
            .collection('empresas')
            .doc(idEmpresa)
            .collection('perfiles')
            .doc(idP)
            .collection('kanbanCardscomp')
            .doc(cardId)
            .collection('krtime')
            .doc(doc.id)
            .delete();
        });
      });
    dispatch(getBoard(idEmpresa, idP));
  };

  return (
    <>
      <Card>
        <CardHeader title="Resultados Clave" sx={{ mb: 3 }} />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 750 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Descripcion</TableCell>
                  <TableCell>Responsable</TableCell>
                  <TableCell>progreso</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {KRs.map((row, index) => (
                  <KRrow
                    key={index}
                    row={row}
                    onDownload={handleClickDownload}
                    onPrint={handleClickPrint}
                    onShare={handleClickShare}
                    onDelete={handleClickDelete}
                    onEditP={handleClickSave}
                    idEmpresa={idEmpresa}
                    cardId={cardId}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Divider />

        <Box sx={{ p: 2, textAlign: 'left' }}>
          <KRsTask card={card} cardId={cardId} idEmpresa={idEmpresa} />
        </Box>
      </Card>
    </>
  );
}
