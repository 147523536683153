import { filter, random } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import { firestore } from 'src/contexts/FirebaseContext';

// ----------------------------------------------------------------------

async function getUsersFirestore() {
  const users = [];
  await firestore
    .collection('users')
    .get()
    .then((queryS) => {
      queryS.forEach((doc) => {
        const { displayName, photoURL, position, role } = doc.data();
        users.push({
          id: doc.id,
          name: displayName,
          avatarUrl: photoURL,
          cover: photoURL,
          position: position || '',
          follower: random(9999),
          following: random(9999),
          totalPost: random(9999),
          role
        });
      });
    });
  return users;
}

async function getUserListFirestore() {
  const users = [];
  await firestore
    .collection('users')
    .get()
    .then((queryS) => {
      queryS.forEach((doc) => {
        const { displayName, photoURL, role } = doc.data();
        users.push({
          ...doc.data(),
          name: displayName,
          avatarUrl: photoURL,
          id: doc.id,
          role,
          status: 'offline',
          isVerified: true
        });
      });
    });
  return users;
}

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  users: []
};

const slice = createSlice({
  name: 'usercrm',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // DELETE USERS
    deleteUser(state, action) {
      const deleteUsert = filter(state.userList, (user) => user.id !== action.payload);
      state.users = deleteUsert;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { deleteUser } = slice.actions;

// ----------------------------------------------------------------------

export function getUserList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const users = await getUserListFirestore();
      dispatch(slice.actions.getUsersSuccess(users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const users = await getUsersFirestore();
      dispatch(slice.actions.getUsersSuccess(users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUsersArray(users = []) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getUsersSuccess(users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
