import React, { createContext, useState, useEffect } from 'react';
import { firestore } from 'src/contexts/FirebaseContext';
import useAuth from 'src/hooks/useAuth';
import PropTypes from 'prop-types';

const initialPlan = {
  empresa: { expires: new Date(), contratado: false, contratadoOnce: false, limit: 0, free: 1 },
  desarrolloP: { expires: new Date(), contratado: false, contratadoOnce: false },
  finanzasP: { expires: new Date(), contratado: false, contratadoOnce: false },
  ML: { expires: new Date(), contratado: false, contratadoOnce: false, usedTokes: 0, tokens: 0 },
  organizacion: { expires: new Date(), contratado: false, contratadoOnce: false, limit: 0, free: 0 }
};

const CTAStateInitial = {
  excluded: [],
  empresa: { state: 'atraccion', tags: [] },
  desarrolloP: { state: 'atraccion', tags: [] },
  finanzasP: { state: 'atraccion', tags: [] },
  ML: { state: 'atraccion', tags: [] },
  organizacion: { state: 'atraccion', tags: [] },
  Home: { state: 'atraccion', tags: [] }
};

export const memberContext = createContext({ plan: initialPlan, CTAState: CTAStateInitial });

MemberC.propTypes = {
  children: PropTypes.any
};

export default function MemberC({ children }) {
  const { user } = useAuth();
  const [memberPlan, setMemberPlan] = useState(initialPlan);
  const [CTAState, setCTAState] = useState(CTAStateInitial);

  useEffect(() => {
    async function getPlan() {
      await firestore
        .collection('plans')
        .doc(user.id)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setMemberPlan({ ...doc.data() });
          } else {
            if (Boolean(user.id)) {
              firestore.collection('plans').doc(user.id).set(initialPlan);
            }
          }
        });
    }
    getPlan();
    async function getStateCTA() {
      await firestore
        .collection('stateCTA')
        .doc(user.id)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setCTAState({ ...doc.data() });
          } else {
            if (Boolean(user.id)) {
              firestore.collection('stateCTA').doc(user.id).set(CTAStateInitial);
            }
          }
        });
    }
    getStateCTA();
  }, [user]);

  return <memberContext.Provider value={{ plan: memberPlan, CTAState }}>{children}</memberContext.Provider>;
}
