import React, { useEffect, useState } from 'react';
import {
  Container,
  Card,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { SprintRow } from 'src/components/_dashboard/sprints';
//
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
//
import { PATH_DASHBOARD } from 'src/routes/paths';
import { firestore } from 'src/contexts/FirebaseContext';
import SprintsCalendar from 'src/pages/dashboard/SprintsCalendar';
// hooks
import usePermissions, { tienePermiso } from 'src/hooks/usePermissions';
import useAuth from 'src/hooks/useAuth';
//
import PropTypes from 'prop-types';

SprtintsMainP.propTypes = {
  idEmpresa: PropTypes.string
};

export default function SprtintsMainP({ idEmpresa }) {
  const { user } = useAuth();
  const { permisos } = usePermissions();
  const [nombreEmpresa, setNombreEmpresa] = useState('');
  const [creador, setCreador] = useState('');
  const [sprints, setSprints] = useState([]);
  const [sprintsArc, setSprintsArc] = useState([]);
  const [openAcc, setOpneAcc] = useState(false);

  const canSeeSprints = tienePermiso('ver sprints', user.id, permisos);

  useEffect(() => {
    // nombre de la empresa
    async function getEmpName() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .onSnapshot((doc) => {
          const { nombre, creator } = doc.data();
          setNombreEmpresa(nombre);
          setCreador(creator);
        });
    }
    // sprints
    async function getSprints() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .where('status', '!=', 'archivado')
        .onSnapshot((queryS) => {
          const datas = [];
          queryS.forEach((doc) => {
            const data = doc.data();
            const { scrumMaster, productOwner, team, coach, involucrados, creator, publico } = data;
            if (
              scrumMaster?.includes(user.id) ||
              productOwner?.includes(user.id) ||
              team?.includes(user.id) ||
              coach?.includes(user.id) ||
              involucrados?.includes(user.id) ||
              creator === user.id ||
              publico
            ) {
              datas.push({ id: doc.id, ...data });
            }
          });
          setSprints(datas);
        });
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .where('status', '==', 'archivado')
        .onSnapshot((queryS) => {
          const datas = [];
          queryS.forEach((doc) => {
            const data = doc.data();
            const { scrumMaster, productOwner, team, coach, involucrados, creator, publico } = data;
            if (
              scrumMaster?.includes(user.id) ||
              productOwner?.includes(user.id) ||
              team?.includes(user.id) ||
              coach?.includes(user.id) ||
              involucrados?.includes(user.id) ||
              creator === user.id ||
              publico
            ) {
              datas.push({ id: doc.id, ...data });
            }
          });
          setSprintsArc(datas);
        });
    }
    getSprints();
    getEmpName();
  }, [idEmpresa, user]);

  return (
    <>
      {(canSeeSprints || user.id === creador) && (
        <Container>
          <HeaderBreadcrumbs
            heading="Spints"
            links={[
              { name: 'Dashboard', href: PATH_DASHBOARD.root },
              { name: 'Entorno', href: PATH_DASHBOARD.entorno.root },
              { name: 'Empresa', href: PATH_DASHBOARD.entorno.root },
              { name: nombreEmpresa, href: PATH_DASHBOARD.entorno.empresa + idEmpresa },
              { name: 'sprints' }
            ]}
          />
          <Card>
            <CardContent>
              <SprintsCalendar idEmpresa={idEmpresa} />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Nombre</TableCell>
                      <TableCell>Objetivo</TableCell>
                      <TableCell>Estado</TableCell>
                      <TableCell>fecha de comienzo</TableCell>
                      <TableCell>fecha de finalizacion</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sprints.map((sp) => (
                      <SprintRow idEmpresa={idEmpresa} sprint={sp} key={sp.id} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Accordion expanded={openAcc} onClick={() => setOpneAcc(!openAcc)}>
                <AccordionSummary>Sprints Archivados</AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell>Nombre</TableCell>
                          <TableCell>Objetivo</TableCell>
                          <TableCell>Estado</TableCell>
                          <TableCell>fecha de comienzo</TableCell>
                          <TableCell>fecha de finalizacion</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sprintsArc.map((sp) => (
                          <SprintRow idEmpresa={idEmpresa} sprint={sp} key={sp.id} />
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
        </Container>
      )}
    </>
  );
}
