import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
// utils
import axios from '../../utils/axios';
import { firestore } from 'src/contexts/FirebaseContext';

// Firestore conecction

async function getBoardFirestore(idEmpresa) {
  const cards = [];
  const columns = [];
  const columnorder = [];
  await firestore
    .collection('empresas')
    .doc(idEmpresa)
    .collection('kanbanCardsentregables')
    .get()
    .then((queryS) => {
      queryS.forEach((doc) => {
        const {
          objsA,
          assignee,
          attachments,
          name,
          prioritize,
          due,
          completed,
          comments,
          description,
          apoyo,
          responsable,
          lider,
          soporte,
          informado,
          consultado,
          haciendo,
          krs,
          likes
        } = doc.data();
        cards.push({
          id: doc.id,
          objsA,
          assignee,
          attachments,
          name,
          prioritize,
          due,
          completed,
          comments,
          description,
          apoyo,
          responsable,
          lider,
          soporte,
          informado,
          consultado,
          haciendo,
          krs,
          likes
        });
      });
    });
  await firestore
    .collection('empresas')
    .doc(idEmpresa)
    .collection('kanbanColunmsentregables')
    .get()
    .then((queryS) => {
      queryS.forEach((doc) => {
        columns.push({ id: doc.id, ...doc.data() });
      });
    });
  await firestore
    .collection('empresas')
    .doc(idEmpresa)
    .collection('kanbanColumnOrder')
    .doc('orderentregables')
    .get()
    .then((doc) => {
      const { columnOrder } = doc.data();
      columnOrder.forEach((column) => {
        columnorder.push(column);
      });
    });
  const Board = {
    cards,
    columns,
    columnOrder: columnorder
  };
  console.log(Board);
  return Board;
}

// async function updateColumnFirestore() {}

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: false,
  board: {
    cardsA: [],
    cards: {},
    columns: {},
    columnOrder: []
  }
};

const slice = createSlice({
  name: 'kanbanentregables',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    getBoardSuccess(state, action) {
      state.isLoading = false;
      const board = action.payload;
      const cardsA = board.cards;
      const cards = objFromArray(board.cards);
      const columns = objFromArray(board.columns);
      const { columnOrder } = board;
      state.board = {
        cardsA,
        cards,
        columns,
        columnOrder
      };
    },

    // CREATE NEW COLUMN
    createColumnSuccess(state, action) {
      const { newColumn, idEmpresa } = action.payload;
      const id = uuidv4();
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanColunmsentregables')
        .doc(id)
        .set({ id, ...newColumn });
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanColumnOrder')
        .doc('orderentregables')
        .get()
        .then((doc) => {
          if (doc.exists) {
            const { columnOrder } = doc.data();
            const newcolumnOrder = [...columnOrder, id];
            firestore
              .collection('empresas')
              .doc(idEmpresa)
              .collection('kanbanColumnOrder')
              .doc('orderentregables')
              .update({ columnOrder: newcolumnOrder });
          } else {
            firestore
              .collection('empresas')
              .doc(idEmpresa)
              .collection('kanbanColumnOrder')
              .doc('orderentregables')
              .set({ columnOrder: [id] });
          }
        });
      state.isLoading = false;
      state.board.columns = {
        ...state.board.columns,
        [id]: { id, ...newColumn }
      };
      state.board.columnOrder.push(id);
    },

    persistCard(state, action) {
      const { columns } = action.payload;
      state.board.columns = columns;
    },

    persistColumn(state, action) {
      const { newColumnOrder, idEmpresa } = action.payload;
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanColumnOrder')
        .doc('orderentregables')
        .update({ columnOrder: newColumnOrder });
      state.board.columnOrder = newColumnOrder;
    },

    addTask(state, action) {
      const { task, idEmpresa } = action.payload;
      const { card, columnId } = task;
      firestore.collection('empresas').doc(idEmpresa).collection('kanbanCardsentregables').doc(card.id).set(card);
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanColunmsentregables')
        .doc(columnId)
        .get()
        .then((doc) => {
          const { cardIds } = doc.data();
          firestore
            .collection('empresas')
            .doc(idEmpresa)
            .collection('kanbanColunmsentregables')
            .doc(columnId)
            .update({ cardIds: [...cardIds, card.id] });
        });
      state.board.cards[card.id] = card;
      state.board.columns[columnId].cardIds.push(card.id);
    },

    deleteTask(state, action) {
      const { task, idEmpresa } = action.payload;
      const { cardId, columnId } = task;
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanColunmsentregables')
        .doc(columnId)
        .get()
        .then((doc) => {
          const { cardIds } = doc.data();
          firestore
            .collection('empresas')
            .doc(idEmpresa)
            .collection('kanbanColunmsentregables')
            .doc(columnId)
            .update({ cardIds: cardIds.filter((id) => id !== cardId) });
        });
      firestore.collection('empresas').doc(idEmpresa).collection('kanbanCards').doc(cardId).delete();
      state.board.columns[columnId].cardIds = state.board.columns[columnId].cardIds.filter((id) => id !== cardId);
      state.board.cards = omit(state.board.cards, [cardId]);
    },

    // UPDATE COLUMN
    updateColumnSuccess(state, action) {
      const { column, idEmpresa } = action.payload;
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanColunmsentregables')
        .doc(column.id)
        .update({ name: column.name });
      state.isLoading = false;
      state.board.columns[column.id] = column;
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      const { columnId, idEmpresa } = action.payload;
      const deletedColumn = state.board.columns[columnId];
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanColumnOrder')
        .doc('orderentregables')
        .get()
        .then((doc) => {
          const { columnOrder } = doc.data();
          const newcolumnOrder = columnOrder.filter((col) => col !== columnId);
          firestore
            .collection('empresas')
            .doc(idEmpresa)
            .collection('kanbanColumnOrder')
            .doc('orderentregables')
            .update({ columnOrder: newcolumnOrder });
        });
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('kanbanColunmsentregables')
        .doc(columnId)
        .get()
        .then((doc) => {
          const { cardIds } = doc.data();
          cardIds.forEach((id) => {
            firestore.collection('empresas').doc(idEmpresa).collection('kanbanCardsentregables').doc(id).delete();
          });
        });
      firestore.collection('empresas').doc(idEmpresa).collection('kanbanColunmsentregables').doc(columnId).delete();
      state.isLoading = false;
      state.board.columns = omit(state.board.columns, [columnId]);
      state.board.cards = omit(state.board.cards, [...deletedColumn.cardIds]);
      state.board.columnOrder = state.board.columnOrder.filter((c) => c !== columnId);
    }
  }
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getBoard(idEmpresa) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await getBoardFirestore(idEmpresa);
      console.log(resp);
      dispatch(slice.actions.getBoardSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createColumn(newColumn, idEmpresa) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const NC = { cardIds: [], ...newColumn };
      dispatch(slice.actions.createColumnSuccess({ newColumn: NC, idEmpresa }));
      getBoard(idEmpresa);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateColumn(columnId, idEmpresa, updateColumn) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('updatecollumn');
      const response = await axios.post('/api/kanban/columns/update', {
        columnId,
        updateColumn
      });
      dispatch(slice.actions.updateColumnSuccess({ column: response.data.column, idEmpresa }));
      getBoard(idEmpresa);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteColumn(columnId, idEmpresa) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // await firestore.collection('empresas').doc(idEmpresa).collection('kanbanColunms').doc(columnId).delete();
      dispatch(slice.actions.deleteColumnSuccess({ columnId, idEmpresa }));
      getBoard(idEmpresa);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function persistColumn(newColumnOrder, idEmpresa) {
  return (dispatch) => {
    dispatch(slice.actions.persistColumn({ newColumnOrder, idEmpresa }));
    getBoard(idEmpresa);
  };
}

// ----------------------------------------------------------------------

export function persistCard(columns) {
  return (dispatch) => {
    dispatch(slice.actions.persistCard(columns));
    getBoard();
  };
}

// ----------------------------------------------------------------------

export function addTask(task, idEmpresa) {
  return (dispatch) => {
    dispatch(slice.actions.addTask({ task, idEmpresa }));
    getBoard(idEmpresa);
  };
}

// ----------------------------------------------------------------------

export function deleteTask(task, idEmpresa) {
  return (dispatch) => {
    dispatch(slice.actions.deleteTask({ task, idEmpresa }));
    getBoard(idEmpresa);
  };
}
