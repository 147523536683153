import React, { useState, useEffect } from 'react';
import { IconButton, Paper, Stack, ListItem, ListItemText, Typography } from '@mui/material';
import { TreeView, TreeItem } from '@mui/lab';
import { useTheme } from '@mui/styles';
import { Icon } from '@iconify/react';
import tree from '@iconify/icons-carbon/tree-view';
import { firestore } from 'src/contexts/FirebaseContext';
import useAuth from 'src/hooks/useAuth';
import { EmpNav } from 'src/components/_dashboard/entorno';
import { ProyectDialog } from 'src/components/_dashboard/proyecto';
import PermissionsC from 'src/contexts/PermissionsContext';
import { isPremium } from 'src/hooks/usePlan';
import PropTypes from 'prop-types';

// -----------------------------------------------------------------------

ProyectItem.propTypes = {
  proyect: PropTypes.object
};

function ProyectItem({ proyect }) {
  const [openP, setOpenP] = useState(false);
  const clickOpen = () => {
    setOpenP(true);
  };
  return (
    <>
      <TreeItem
        key={proyect.id}
        label={
          <>
            <ListItem button onClick={clickOpen}>
              <ListItemText>{proyect.nombre}</ListItemText>
            </ListItem>
          </>
        }
      />
      <ProyectDialog open={openP} setOpen={setOpenP} proyecto={proyect} />
    </>
  );
}

// -----------------------------------------------------------------------

ProyectsEmp.propTypes = {
  idEmpresa: PropTypes.string.isRequired
};

function ProyectsEmp({ idEmpresa }) {
  const { user } = useAuth();
  const [proyects, setProyects] = useState([]);

  useEffect(() => {
    async function getInfo() {
      await firestore
        .collection('proyectosempresa')
        .where('idEmpresa', '==', idEmpresa)
        .orderBy('nombre', 'asc')
        .onSnapshot((querys) => {
          const proyectss = [];
          querys.forEach((doc) => {
            const { creator, membersids } = doc.data();
            if (creator === user.id || membersids.includes(user.id)) {
              proyectss.push({ id: doc.id, ...doc.data() });
            }
          });
          setProyects(proyectss);
        });
    }
    getInfo();
  }, [idEmpresa, user]);
  return (
    <>
      {proyects.map((proyect) => (
        <ProyectItem key={'pro' + proyect.id} proyect={proyect} allowInteraction />
      ))}
    </>
  );
}

// ------------------------------------------------------------------------------------------

EmpresaItem.propTypes = {
  emp: PropTypes.object
};

function EmpresaItem({ emp }) {
  const [openN, setOpenN] = useState(false);
  const clickOpen = () => {
    setOpenN(true);
  };
  return (
    <>
      <TreeItem
        key={emp.id}
        nodeId={'emp' + emp.id}
        label={
          <ListItem onClick={clickOpen}>
            <ListItemText>{emp.nombre}</ListItemText>
          </ListItem>
        }
      >
        <ProyectsEmp idEmpresa={emp.id} />
        <EmpNav empresa={emp} open={openN} setOpen={setOpenN} />
      </TreeItem>
    </>
  );
}

// ------------------------------------------------------------------------------------------

const ITEM_ON_ROW = {
  width: 'calc((100%/2) - 16px)',
  '&:nth-of-type(3n+1)': { order: 1 },
  '&:nth-of-type(3n+2)': { order: 2 },
  '&:nth-of-type(3n)': { order: 3 }
};

export default function NavMenuPopover() {
  const [open, setOpen] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const { user } = useAuth();
  const theme = useTheme();

  const [expanded, setExpanded] = useState([]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  useEffect(() => {
    async function getInfo() {
      await firestore
        .collection('empresas')
        .orderBy('nombre', 'asc')
        .onSnapshot((querys) => {
          const emps = [];
          querys.forEach((doc) => {
            const { membersids, premium, expiresP } = doc.data();
            const memb = isPremium(premium, expiresP) ? membersids : membersids.slice(0, 2);
            const newEmp = { id: doc.id, ...doc.data() };
            newEmp.membersids = memb;
            emps.push(newEmp);
          });
          setEmpresas(emps.filter((em) => em.creator === user.id || Boolean(em.membersids.includes(user.id))));
        });
    }
    getInfo();
  }, [user]);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={() => {
          if (open) {
            clickClose();
          } else {
            clickOpen();
          }
        }}
      >
        <Icon icon={tree} width={30} height={30} />
      </IconButton>
      {open && (
        <Paper
          onMouseEnter={clickOpen}
          sx={{
            p: 3,
            width: '60%',
            position: 'absolute',
            borderRadius: 2,
            [theme.breakpoints.up('xs')]: { left: 122, top: 55 },
            [theme.breakpoints.up('lg')]: { left: 222, top: 55 },
            left: 222,
            zIndex: (theme) => theme.zIndex.modal,
            boxShadow: (theme) => theme.customShadows.z20,
            overflowY: 'auto'
          }}
        >
          <Stack flexWrap="wrap" alignContent="space-between" height={300}>
            <Stack spacing={1.25} sx={{ mb: 2.5, ...ITEM_ON_ROW }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'fontWeightBold' }} noWrap>
                Empresas
              </Typography>
              <TreeView expanded={expanded} onNodeToggle={handleToggle}>
                {empresas.map((emp) => (
                  <PermissionsC key={emp.id} idEmpresa={emp.id}>
                    <EmpresaItem emp={emp} allowInteraction />
                  </PermissionsC>
                ))}
              </TreeView>
            </Stack>
          </Stack>
        </Paper>
      )}
    </>
  );
}
