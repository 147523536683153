import { createSlice } from '@reduxjs/toolkit';
// utils
import { firestore } from 'src/contexts/FirebaseContext';
import { isPremium } from 'src/hooks/usePlan';

// ----------------------------------------------------------------------

async function getBlogs() {
  const blogs = [];
  await firestore
    .collection('empresas')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const { membersids, premium, expiresP } = doc.data();
        const memb = isPremium(premium, expiresP) ? membersids : membersids.slice(0, 2);
        const newEmp = { id: doc.id, ...doc.data() };
        newEmp.membersids = memb;
        blogs.push(newEmp);
      });
    });
  return blogs;
}
/*
async function getRecentBlogs() {
  const blogs = [];
  await firestore
    .collection('empresas')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        blogs.push(doc.data());
      });
    });
  return blogs;
}
*/

async function fetchBlog(id) {
  return new Promise((resolve) => {
    firestore
      .collection('empresas')
      .doc(id)
      .get()
      .then((doc) => {
        const { membersids, premium, expiresP } = doc.data();
        const memb = isPremium(premium, expiresP) ? membersids : membersids.slice(0, 2);
        const newEmp = { id: doc.id, ...doc.data() };
        newEmp.membersids = memb;
        resolve(newEmp);
      });
  });
}

// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: false,
  posts: [],
  post: null,
  recentPosts: [],
  hasMore: true,
  index: 0,
  step: 11
};

const slice = createSlice({
  name: 'empresas',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET POST INFINITE
    getPostsInitial(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    getMorePosts(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getPostSuccess(state, action) {
      state.isLoading = false;
      state.post = action.payload;
    },

    // GET RECENT POST
    getRecentPostsSuccess(state, action) {
      state.isLoading = false;
      state.recentPosts = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMorePosts } = slice.actions;

// ----------------------------------------------------------------------

export function getAllEmpresas() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const blogs = await getBlogs();
      dispatch(slice.actions.getPostsSuccess(blogs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEmpresasInitial(index, step) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(step);
      const blogs = await getBlogs();
      const blogsInitial = blogs;
      const results = blogsInitial.length;
      const maxLength = blogs.length;
      dispatch(slice.actions.getPostsInitial(blogs));

      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEmpresa(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const blog = await fetchBlog(id);
      dispatch(slice.actions.getPostSuccess(blog));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getRecentEmpresas(title) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const titles = title.replaceAll('-', ' ');
      const blogs = await getBlogs();
      dispatch(slice.actions.getRecentPostsSuccess(blogs.filter((blog) => blog.id !== titles)));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
