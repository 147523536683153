// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

MyAvatar.propTypes = {
  src: PropTypes.string
};

export default function MyAvatar({ src, ...other }) {
  const { user } = useAuth();

  return (
    <MAvatar
      src={src || user.photoURL}
      alt={user.displayName}
      color={user.photoURL ? 'default' : createAvatar(user.displayName).color}
      {...other}
    >
      {createAvatar(user.displayName).name}
    </MAvatar>
  );
}
