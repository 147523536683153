import { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import { useDispatch } from 'src/redux/store';
import { getBoard } from 'src/redux/slices/kobjs';
import checkmarkCircle2Outline from '@iconify/icons-eva/checkmark-circle-2-outline';
import radioButtonOffOutline from '@iconify/icons-eva/radio-button-off-outline';
import flash from '@iconify/icons-eva/flash-fill';
import flashoff from '@iconify/icons-eva/flash-off-fill';
import moreOptions from '@iconify/icons-eva/more-horizontal-fill';
import settings from '@iconify/icons-eva/settings-fill';
import eyefill from '@iconify/icons-eva/eye-fill';
import list from '@iconify/icons-eva/list-fill';
import fileFill from '@iconify/icons-eva/file-add-fill';
import { firestore } from 'src/contexts/FirebaseContext';
// material
import { Paper, Typography, Box, Checkbox, FormControlLabel, Grid, Menu, MenuItem, IconButton } from '@mui/material';
import { useTheme } from '@mui/styles';
//
import TodoTasksC from 'src/components/_dashboard/general/TodoTask';
import KanbanTaskDetails from './KanbanTaskDetails';
import KanbanControlPanelT from './KanbanControlPanelT';
import { KobjsF } from 'src/pages/dashboard/KObjs';
import { AltaProyecto } from 'src/components/_dashboard/entorno';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { createNotification, generateNotification } from 'src/utils/notifications';

// ----------------------------------------------------------------------

KanbanTaskCard.propTypes = {
  card: PropTypes.object,
  index: PropTypes.number,
  onDeleteTask: PropTypes.func,
  cardId: PropTypes.string,
  idEmpresa: PropTypes.string
};

export default function KanbanTaskCard({ card, onDeleteTask, index, cardId, idEmpresa }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { showCompleted } = useContext(KobjsF);
  const { name, attachments, prioritize } = card;
  const [openDetails, setOpenDetails] = useState(false);
  const [completed, setCompleted] = useState(card.completed);
  const [haciendo, setHaciendo] = useState(card.haciendo || false);
  const menuRef = useRef(null);
  const [menuO, setMenuO] = useState(false);
  const [openCP, setOpenCP] = useState(false);
  const [openTT, setOpenTT] = useState(false);
  const [openAP, setOpenAP] = useState(false);

  useEffect(() => {
    setHaciendo(card.haciendo);
    setCompleted(card.completed);
  }, [card]);

  const handleOpenAP = () => {
    setOpenAP(true);
  };

  const handleOpenTT = () => {
    setOpenTT(true);
  };

  const handleOpenControlPanel = () => {
    setOpenCP(true);
  };

  const handleOpenDetails = () => {
    setOpenDetails(true);
    setMenuO(false);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };
  const handleChangeHaciendo = async (event) => {
    setHaciendo(event.target.checked);
    setMenuO(false);
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('kanbanCardsentregables')
      .doc(cardId)
      .update({ haciendo: event.target.checked })
      .then(() => {
        dispatch(getBoard(idEmpresa));
        enqueueSnackbar('Marcado como haciendo', { variant: 'success' });
      });
    if (event.target.checked) {
      const informado = card.informado || [];
      const lider = card.lider || [];
      informado.forEach((u) => {
        const noti = generateNotification(
          'se ha marcado como haciendo un objetivo',
          'objetivo: ' + name,
          u.avatar,
          'avatar',
          u.id,
          PATH_DASHBOARD.entorno.empresa + idEmpresa
        );
        createNotification(noti);
      });
      lider.forEach((u) => {
        const noti = generateNotification(
          'se ha marcado como haciendo un objetivo',
          'objetivo: ' + name,
          u.avatar,
          'avatar',
          u.id,
          PATH_DASHBOARD.entorno.empresa + idEmpresa
        );
        createNotification(noti);
      });
    }
  };
  const handleChangeComplete = async (event) => {
    setCompleted(event.target.checked);
    if (event.target.checked) {
      setHaciendo(false);
    }
    setMenuO(false);
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('kanbanCardsentregables')
      .doc(cardId)
      .update({ completed: event.target.checked, haciendo: false })
      .then(() => {
        dispatch(getBoard(idEmpresa));
        if (event.target.checked) {
          enqueueSnackbar('Tarea Terminada', { variant: 'success' });
        }
      });
    if (event.target.checked) {
      const informado = card.informado || [];
      const lider = card.lider || [];
      informado.forEach((u) => {
        const noti = generateNotification(
          'se ha completado el objetivo',
          'objetivo: ' + name,
          u.avatar,
          'avatar',
          u.id,
          PATH_DASHBOARD.entorno.empresa + idEmpresa
        );
        createNotification(noti);
      });
      lider.forEach((u) => {
        const noti = generateNotification(
          'se ha completado el objetivo',
          'objetivo: ' + name,
          u.avatar,
          'avatar',
          u.id,
          PATH_DASHBOARD.entorno.empresa + idEmpresa
        );
        createNotification(noti);
      });
    }
  };

  const comp = () => (
    <Draggable draggableId={card.id} index={index}>
      {(provided) => (
        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <Paper
            sx={{
              px: 1,
              width: 1,
              position: 'relative',
              ...(prioritize === 'alta' && { bgcolor: 'error.main' }),
              ...(prioritize === 'baja' && { bgcolor: 'info.main' }),
              ...(prioritize === 'media' && { bgcolor: 'warning.main' }),
              boxShadow: (theme) => theme.customShadows.z1,
              '&:hover': {
                boxShadow: (theme) => theme.customShadows.z16
              },
              ...(attachments.length > 0 && {
                pt: 1
              })
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={2}>
                <IconButton onClick={() => setMenuO(true)} ref={menuRef}>
                  <Icon icon={moreOptions} />
                </IconButton>
                <Menu
                  open={menuO}
                  onClose={() => setMenuO(false)}
                  anchorEl={menuRef.current}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                  }}
                >
                  <MenuItem>
                    <FormControlLabel
                      sx={{ position: 'relative' }}
                      value={completed}
                      checked={completed}
                      control={
                        <Checkbox
                          disableRipple
                          checked={completed}
                          icon={<Icon icon={radioButtonOffOutline} width={20} height={20} />}
                          checkedIcon={<Icon icon={checkmarkCircle2Outline} width={20} height={20} />}
                          onChange={handleChangeComplete}
                          sx={{ position: 'relative' }}
                        />
                      }
                      label={completed ? 'Completada' : 'Marcar como completada'}
                    />
                  </MenuItem>
                  <MenuItem>
                    <FormControlLabel
                      sx={{ position: 'relative' }}
                      value={haciendo}
                      checked={haciendo}
                      control={
                        <Checkbox
                          disableRipple
                          checked={haciendo}
                          value={haciendo}
                          icon={<Icon icon={flashoff} width={20} height={20} />}
                          checkedIcon={<Icon icon={flash} width={20} height={20} />}
                          onChange={handleChangeHaciendo}
                        />
                      }
                      label={haciendo ? 'Marcar como no haciendo' : 'Marcar como haciendo'}
                    />
                  </MenuItem>
                  <MenuItem onClick={handleOpenDetails}>
                    <Icon icon={eyefill} width={20} height={20} color={theme.palette.primary.main} />
                    Propiedades
                  </MenuItem>
                  <MenuItem onClick={handleOpenControlPanel}>
                    <Icon icon={settings} width={20} height={20} color={theme.palette.primary.main} />
                    Panel de control
                  </MenuItem>
                  <MenuItem onClick={handleOpenTT}>
                    <Icon icon={list} width={20} height={20} color={theme.palette.primary.main} />
                    Tareas
                  </MenuItem>
                  <MenuItem onClick={handleOpenAP}>
                    <Icon icon={fileFill} width={20} height={20} color={theme.palette.primary.main} />
                    Crear proyecto
                  </MenuItem>
                </Menu>
              </Grid>
              <Grid item xs={10}>
                <Box onClick={handleOpenControlPanel} sx={{ cursor: 'pointer' }}>
                  {attachments.length > 0 && (
                    <Box
                      sx={{
                        pt: '10%',
                        borderRadius: 1,
                        overflow: 'hidden',
                        position: 'relative',
                        transition: (theme) =>
                          theme.transitions.create('opacity', {
                            duration: theme.transitions.duration.shortest
                          }),
                        ...(completed && {
                          opacity: 0.99
                        })
                      }}
                    >
                      <Box
                        component="img"
                        src={attachments[0]}
                        sx={{ position: 'relative', top: 0, width: '100%', height: 'auto' }}
                      />
                    </Box>
                  )}

                  <Typography
                    variant="subtitle2"
                    sx={{
                      py: 1,
                      pl: 2,
                      transition: (theme) =>
                        theme.transitions.create('opacity', {
                          duration: theme.transitions.duration.shortest
                        }),
                      ...(completed && { opacity: 0.48 })
                    }}
                  >
                    {name}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>

          <KanbanTaskDetails
            cardId={cardId}
            card={card}
            isOpen={openDetails}
            onClose={handleCloseDetails}
            onDeleteTask={() => {
              if (window.confirm('desea eliminar este objetivo?')) {
                onDeleteTask(card.id);
              }
            }}
            idEmpresa={idEmpresa}
          />
          <TodoTasksC collection="kobjsCards" docId={cardId} open={openTT} setOpen={setOpenTT} />
          <KanbanControlPanelT card={card} cardId={cardId} open={openCP} setOpen={setOpenCP} idEmpresa={idEmpresa} />
          <AltaProyecto
            idEmpresa={idEmpresa}
            open={openAP}
            setOpen={setOpenAP}
            onCreateNew={async (idP) => {
              await firestore
                .collection('empresas')
                .doc(idEmpresa)
                .collection('kanbanCardsentregables')
                .doc(cardId)
                .get()
                .then((doc) => {
                  const proyectosA = doc.data().proyectosA || [];
                  firestore
                    .collection('empresas')
                    .doc(idEmpresa)
                    .collection('kanbanCardsentregables')
                    .doc(cardId)
                    .update({ proyectosA: [...proyectosA, idP] });
                });
            }}
          />
        </div>
      )}
    </Draggable>
  );

  const filter = () => {
    if (showCompleted) {
      return comp();
    }
    return card.completed ? <></> : comp();
  };

  return filter();
}
