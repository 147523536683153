import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogContent,
  ListItem,
  ListItemText
} from '@mui/material';
import StateMod from './StateMod';
// firebase
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

UserCRM.propTypes = {
  userid: PropTypes.string
};

export default function UserCRM({ userid }) {
  const [open, setOpen] = useState(false);
  const [crm, setCRM] = useState(undefined);

  useEffect(() => {
    async function getC() {
      await firestore
        .collection('stateCTA')
        .doc(userid)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setCRM({ ...doc.data() });
          }
        });
    }
    getC();
  }, [userid]);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#506070' }}>
        <ListItemText>Seguimiento</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose}>
        <DialogContent>
          <Typography variant="h4">Seguimiento</Typography>
          <Box>
            {crm && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Modulo</TableCell>
                    <TableCell>Estado</TableCell>
                    <TableCell>Etiquetas</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>

                <TableBody>
                  <StateMod modulo="empresa" crm={crm} userid={userid} altName="Empresa" />
                  <StateMod modulo="desarrolloP" crm={crm} userid={userid} altName="Desarrollo Personal" />
                  <StateMod modulo="finanzasP" crm={crm} userid={userid} altName="finanzas Personales" />
                </TableBody>
              </Table>
            )}
            {!crm && <Typography variant="h4">Datos No Disponibles</Typography>}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
