import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, Grid, Typography } from '@mui/material';
import PerfilItem from './PerfilItem';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

PerfilesCard.propTypes = {
  idEmpresa: PropTypes.string
};

export default function PerfilesCard({ idEmpresa }) {
  const [perfiles, setPerfiles] = useState([]);

  useEffect(() => {
    async function getPerfiles() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('perfiles')
        .onSnapshot((queryS) => {
          const perfs = [];
          queryS.forEach((doc) => {
            perfs.push({ id: doc.id, ...doc.data() });
          });
          setPerfiles(perfs);
        });
    }
    getPerfiles();
  }, [idEmpresa]);
  return (
    <Card sx={{ margin: '3%' }}>
      <CardHeader title={<Typography variant="h4">Perfiles de puesto</Typography>} />
      <CardContent>
        <Grid container spacing={3}>
          {perfiles.map((perf) => (
            <PerfilItem key={perf.id} perfil={perf} idEmpresa={idEmpresa} grid />
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
