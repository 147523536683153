import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths';
// import linkout from '@iconify/icons-eva/external-link-fill';
// import list from '@iconify/icons-eva/list-fill';
import homeFill from '@iconify/icons-eva/home-fill';
// material
import { MIconButton } from 'src/components/@material-extend';
// material
import { Box, Card, CardHeader, Typography, Stack, ListItem, ListItemText, IconButton } from '@mui/material';
// utils
import { EmpNav } from '../entorno';
import { isPremium } from 'src/hooks/usePlan';
// icon
import starfill from '@iconify/icons-eva/star-fill';
import staroutline from '@iconify/icons-eva/star-outline';
//
import Scrollbar from '../../Scrollbar';
import { firestore } from 'src/contexts/FirebaseContext';
import PermissionsC from 'src/contexts/PermissionsContext';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

MoreMenuButton.propTypes = {
  emp: PropTypes.object,
  allowInteraction: PropTypes.bool
};

function MoreMenuButton({ emp, allowInteraction = false }) {
  const { nombre, logo } = emp;
  const menuRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (allowInteraction) {
      setOpen(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: 48,
          height: 48,
          flexShrink: 0,
          display: 'flex',
          borderRadius: 1.5,
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'background.neutral'
        }}
      >
        <MIconButton
          onClick={handleOpen}
          ref={menuRef}
          id="demo-positioned-button"
          aria-controls="demo-positioned-menu"
          aria-haspopup="true"
        >
          {logo !== null && <img src={logo} alt={nombre} width={24} height={24} />}
          {logo === null && <Icon icon={homeFill} width={24} height={24} />}
        </MIconButton>
      </Box>

      <EmpNav open={open} setOpen={setOpen} empresa={emp} />

      {/* <MNavigator open={open} anchorEl={menuRef.current} setOpen={setOpen} empresa={emp} variant="menu" /> */}

      {/* <Menu
        open={open}
        anchorEl={menuRef.current}
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <MenuItem component={NavLink} to={'/dashboard/entorno/empresa/' + emp.id}>
          <Icon icon={linkout} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Ir a empresa
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpen(false);
            openTT();
          }}
        >
          <Icon icon={list} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Tareas
          </Typography>
        </MenuItem>
        <MenuItem>
          <Icon icon={printerFill} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Print
          </Typography>
        </MenuItem>
        <MenuItem>
          <Icon icon={shareFill} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Share
          </Typography>
        </MenuItem>
        <MenuItem>
          <Icon icon={archiveFill} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Archive
          </Typography>
        </MenuItem>

        <Divider />
        <MenuItem sx={{ color: 'error.main' }}>
          <Icon icon={trash2Outline} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Delete
          </Typography>
        </MenuItem>
      </Menu> */}
    </>
  );
}

// ----------------------------------------------------------------------

SprintItem.propTypes = {
  idEmpresa: PropTypes.string,
  sprint: PropTypes.object
};

function SprintItem({ sprint, idEmpresa }) {
  const { id, nombre } = sprint;
  return (
    <ListItem component={NavLink} to={PATH_DASHBOARD.entorno.empresa + idEmpresa + '/sprints/' + id}>
      <ListItemText>{nombre}</ListItemText>
    </ListItem>
  );
}

// ----------------------------------------------------------------------

EmpresaItem.propTypes = {
  empresa: PropTypes.shape({
    likes: PropTypes.array,
    nombre: PropTypes.string,
    logo: PropTypes.string,
    id: PropTypes.string
  }),
  allowInteraction: PropTypes.bool
};

export function EmpresaItem({ empresa, allowInteraction = false }) {
  const { user } = useAuth();
  const { nombre, id, likes } = empresa;

  const like = async () => {
    const newArr = [...likes];
    if (newArr.includes(user.id)) {
      await firestore
        .collection('empresas')
        .doc(id)
        .update({ likes: newArr.filter((d) => d !== user.id) });
    } else {
      newArr.push(user.id);
      await firestore.collection('empresas').doc(id).update({ likes: newArr });
    }
  };

  return (
    <>
      <PermissionsC idEmpresa={id}>
        <Box>
          <Stack direction="row" alignItems="center" spacing={2}>
            <MoreMenuButton emp={empresa} allowInteraction={allowInteraction} />

            <Box sx={{ flexGrow: 1, minWidth: 160 }}>
              <Typography variant="subtitle2">{nombre}</Typography>
            </Box>

            <Stack alignItems="flex-end" sx={{ pr: 3 }}>
              {allowInteraction && (
                <IconButton button onClick={like} sx={{ color: '#FAAF00' }}>
                  <Icon icon={likes.includes(user.id) ? starfill : staroutline} />
                </IconButton>
              )}
            </Stack>
          </Stack>
        </Box>
      </PermissionsC>
    </>
  );
}

export default function EmpresasFav() {
  const { user } = useAuth();
  const [empresas, setEmpresas] = useState([]);

  useEffect(() => {
    async function getEmpresas() {
      await firestore
        .collection('empresas')
        .where('likes', 'array-contains', user.id)
        .onSnapshot((queryS) => {
          const emps = [];
          queryS.forEach((doc) => {
            const { membersids, premium, expiresP } = doc.data();
            const memb = isPremium(premium, expiresP) ? membersids : membersids.slice(0, 2);
            const newEmp = { id: doc.id, ...doc.data() };
            newEmp.membersids = memb;
            emps.push(newEmp);
          });
          setEmpresas(emps.filter((em) => em.creator === user.id || Boolean(em.membersids.includes(user.id))));
        });
    }
    getEmpresas();
  }, [user]);

  return (
    <Card>
      <CardHeader title="Empresas" />
      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {empresas.map((app) => (
            <EmpresaItem key={app.id} empresa={app} allowInteraction />
          ))}
        </Stack>
      </Scrollbar>
    </Card>
  );
}
