import React from 'react';
import { Dialog, DialogContent, Grid, Box } from '@mui/material';
import MenuDepto from './MenuDepto';
import DeptoCard from './DeptoCard';
import PropTypes from 'prop-types';

DialogDeptoItem.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  depto: PropTypes.object,
  idEmpresa: PropTypes.string
};

export default function DialogDeptoItem({ open = false, setOpen, depto, idEmpresa }) {
  const clickClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={clickClose} maxWidth="lg">
      <Box sx={{ width: '70vw' }} />
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <MenuDepto depto={depto} idEmpresa={idEmpresa} />
          </Grid>
          <Grid item xs={12} lg={8}>
            <DeptoCard depto={depto} idEmpresa={idEmpresa} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
