import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, TextField, Button, Box } from '@mui/material';
import { firestore } from 'src/contexts/FirebaseContext';
import { Icon } from '@iconify/react';
import trashI from '@iconify/icons-eva/trash-fill';
import useAuth from 'src/hooks/useAuth';
import PropTypes from 'prop-types';

MessageHistory.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  showB: PropTypes.bool,
  link: PropTypes.string
};

export default function MessageHistory({ link, open = false, setOpen, showB = false }) {
  const { user } = useAuth();
  const [mesage, setMesage] = useState('');
  const [mesages, setMesages] = useState([]);

  useEffect(() => {
    async function getHM() {
      await firestore
        .collection('users')
        .doc(user.id)
        .collection('MH')
        .onSnapshot((queryS) => {
          const msgs = [];
          queryS.forEach((doc) => {
            msgs.push({ id: doc.id, ...doc.data() });
          });
          setMesages(msgs);
        });
    }
    getHM();
  }, [user]);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      {showB && <Button onClick={clickOpen}>Historial de Mensaje</Button>}
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <Box sx={{ width: '82vw' }} />
        <DialogContent>
          <TextField
            inputProps={{ list: 'message-history' }}
            fullWidth
            label="mensaje a enviar"
            type="text"
            value={mesage}
            onChange={(e) => setMesage(e.target.value)}
          />
          <datalist id="message-history">
            {mesages.map((m) => (
              <option key={m.message} value={m.message} />
            ))}
          </datalist>

          <Button
            onClick={() => {
              const message = Boolean(mesage) ? `?text=${mesage}` : '';
              if (Boolean(mesage) && !mesages.find((m) => m.message === mesage)) {
                firestore.collection('users').doc(user.id).collection('MH').add({ message: mesage });
              }
              window.open(link + message, '_blank');
            }}
            sx={{ margin: '5px' }}
          >
            Enviar
          </Button>
          <Button
            onClick={() => {
              if (window.confirm('desea eliminar el historial de mensajes?')) {
                firestore
                  .collection('users')
                  .doc(user.id)
                  .collection('MH')
                  .get()
                  .then((queryS) => {
                    queryS.forEach((doc) =>
                      firestore.collection('users').doc(user.id).collection('MH').doc(doc.id).delete()
                    );
                  });
              }
            }}
            sx={{ float: 'right', margin: '5px' }}
          >
            <Icon icon={trashI} width={23} height={26} />
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
