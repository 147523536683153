import React from 'react';
import { Box } from '@mui/material';
import UserElement from 'src/components/_dashboard/general/UserElement';
import { useEcommerceD } from 'src/pages/dashboard/GeneralEcommerce';

export default function ListP() {
  const { payments } = useEcommerceD();
  return (
    <>
      <Box>
        {payments.map((p) => (
          <Box key={p.key}>
            <UserElement userid={p.userid} />
          </Box>
        ))}
      </Box>
    </>
  );
}
