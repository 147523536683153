import React from 'react';
import {
  Box,
  Button,
  Grid,
  Autocomplete,
  Chip,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { Circle } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';

const VALORES = ['Respeto', 'Trabajo en Equipo'];

StepTwo.propTypes = {
  formik: PropTypes.object,
  back: PropTypes.func
};

export default function StepTwo({ formik, back }) {
  const { values, isSubmitting, setFieldValue } = formik;

  const preguntas = ['¿Tu gente puede traducirlas a acciones?', '¿Son tangibles?', '¿Pueden memorizarse facilmente?'];

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h2">Valores</Typography>
          <Box component="p">
            Los Valores responden a una simple pregunta: ¿como hacemos lo que hacemos?, lides y empleados deben de estar
            orgullosos de ellos, como ellos definen la cultura de la organización e identidad. Los Valores son el núcleo
            de los principios de todas las asociaciones, y clientes deberían experimentarlo a traves de cada servicio y
            producto.
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>Tips: No más de 4</Box>
          <Box>Empieza con un verbo</Box>
          <Box>Mantenlos cortos</Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            multiple
            freeSolo
            value={values.valores}
            onChange={(event, newValue) => {
              setFieldValue('valores', newValue);
            }}
            options={VALORES.map((option) => option)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip {...getTagProps({ index })} key={option} size="small" label={option} />
              ))
            }
            renderInput={(params) => <TextField {...params} label="Valores" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography>Prueba tus Valores</Typography>
            <List>
              {preguntas.map((p) => (
                <ListItem key={p}>
                  <ListItemIcon>
                    <Circle />
                  </ListItemIcon>
                  <ListItemText>{p}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ float: 'right', padding: '3%', margin: '2%', display: 'flex' }}>
        <Button variant="contained" onClick={back} sx={{ margin: '2%' }}>
          Atras
        </Button>
        <LoadingButton variant="contained" type="submit" loading={isSubmitting} sx={{ margin: '2%' }}>
          Siguiente
        </LoadingButton>
      </Box>
    </>
  );
}
