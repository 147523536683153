import React, { useState, useEffect } from 'react';
import { Card, Avatar, Typography, Box } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import SvgIconStyle from 'src/components/SvgIconStyle';
import { getURLFile } from 'src/utils/storagef';
import UserList from 'src/components/_dashboard/general/UserList';
import PropTypes from 'prop-types';

const CardMediaStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  paddingTop: 'calc(100% * 9 / 16)',
  '&:before': {
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
    backdropFilter: 'blur(3px)',
    WebkitBackdropFilter: 'blur(3px)', // Fix on Mobile
    borderTopLeftRadius: theme.shape.borderRadiusMd,
    borderTopRightRadius: theme.shape.borderRadiusMd,
    backgroundColor: alpha(theme.palette.primary.darker, 0.72)
  }
}));

const CoverImgStyle = styled('img')({
  top: 0,
  zIndex: 8,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

EquipoItem.propTypes = {
  equipo: PropTypes.object,
  idEmpresa: PropTypes.string
};

export default function EquipoItem({ equipo, idEmpresa }) {
  const { id, nombre, descripcion, miembros } = equipo;
  const [URLImg, setURLImg] = useState('');
  const ref = `empresas/${idEmpresa}/equipos/${id}`;

  useEffect(() => {
    async function getImgUrl() {
      await getURLFile(ref).then((url) => {
        setURLImg(url);
      });
    }
    getImgUrl();
  }, [ref]);

  return (
    <Card>
      <CardMediaStyle>
        <SvgIconStyle
          color="paper"
          src="/static/icons/shape-avatar.svg"
          sx={{
            width: 144,
            height: 62,
            zIndex: 10,
            bottom: -26,
            position: 'absolute'
          }}
        />
        <Avatar
          alt={nombre}
          src={URLImg}
          sx={{
            width: 64,
            height: 64,
            zIndex: 11,
            position: 'absolute',
            transform: 'translateY(-50%)'
          }}
        />
        <CoverImgStyle alt="cover" src={URLImg} />
      </CardMediaStyle>
      <Typography variant="subtitle1" align="center" sx={{ mt: 6 }}>
        {nombre}
      </Typography>
      <Typography variant="subtitle1" align="center">
        {descripcion}
      </Typography>
      <Box sx={{ padding: '3%' }}>
        <UserList users={miembros} />
      </Box>
    </Card>
  );
}
