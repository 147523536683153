import { Box } from '@mui/material';
import LogoSVG from './LogoSVG.svg';
import PropTypes from 'prop-types';

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ padding: '3%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box component="img" src={LogoSVG} sx={{ width: '45%', height: 'auto', ...sx }} />
    </Box>
  );
}
