import React, { useState, useEffect } from 'react';
import { Card, Typography, Divider, Box, CardContent, Dialog } from '@mui/material';
import Markdown from 'src/components/Markdown';
import EmpresaTags from './EmpresaTags';
import PuntosEnfoque from './PuntosEnfoque';
//
import useAuth from 'src/hooks/useAuth';
import { firestore } from 'src/contexts/FirebaseContext';
import EHero from './EHero';
import EditEmpresaD from './EditEmpresaD';
import PropTypes from 'prop-types';

CardEmpresa.propTypes = {
  empresa: PropTypes.object
};

export default function CardEmpresa({ empresa }) {
  const { id } = empresa;
  const { user } = useAuth();
  const [openD, setOpenD] = useState(false);
  const [likes, setLikes] = useState([]);
  const [PEs, setPEs] = useState([]);

  const clickOpenD = () => {
    setOpenD(true);
  };

  useEffect(() => {
    async function getPEs() {
      await firestore
        .collection('empresas')
        .doc(id)
        .collection('kanbanColunms')
        .onSnapshot((queryS) => {
          const pes = [];
          queryS.forEach((doc) => {
            pes.push({ id: doc.id, ...doc.data() });
          });
          setPEs(pes);
        });
    }
    getPEs();
  }, [id]);

  useEffect(() => {
    if (empresa !== null) {
      setLikes(empresa.likes || []);
    }
  }, [empresa]);

  const like = async () => {
    const newArr = [...likes];
    if (newArr.includes(user.id)) {
      setLikes(newArr.filter((d) => d !== user.id));
      await firestore
        .collection('empresas')
        .doc(id)
        .update({ likes: newArr.filter((d) => d !== user.id) });
    } else {
      newArr.push(user.id);
      setLikes(newArr);
      await firestore.collection('empresas').doc(id).update({ likes: newArr });
    }
  };

  return (
    <Card>
      <EHero
        nameE={empresa?.nombre}
        vision={empresa?.vision}
        img={empresa?.logo}
        likes={likes}
        like={like}
        empresa={empresa}
        onOpenEdit={clickOpenD}
      />
      <CardContent>
        <Box sx={{ my: 2 }}>
          <Typography variant="h3" sx={{ textAlign: 'center' }}>
            Valores
          </Typography>
          <EmpresaTags post={empresa} />
          <Divider />
          <Typography variant="h3" sx={{ marginBottom: '5%', textAlign: 'center' }}>
            Puntos de Enfoque
          </Typography>
          <PuntosEnfoque PEs={PEs} idEmpresa={id} />
          <Divider />
        </Box>
        <Markdown children={empresa?.body || ''} />
      </CardContent>
      <EditEmpresaD open={openD} setOpen={setOpenD} idEmpresa={id} />
    </Card>
  );
}

// -------------------------------------------------------

CardEmpresaD.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  empresa: PropTypes.object
};

export function CardEmpresaD({ open = false, setOpen, empresa }) {
  const clickClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={clickClose}>
      <CardEmpresa empresa={empresa} />
    </Dialog>
  );
}
