import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import UserProfile from 'src/pages/dashboard/UserProfile';
import PropTypes from 'prop-types';

UserCardNav.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func
};

export default function UserCardNav({ open = false, setOpen, user }) {
  const { id } = user;
  const clickClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={clickClose} maxWidth="lg">
      <DialogContent>
        <UserProfile userid={id} />
      </DialogContent>
    </Dialog>
  );
}
