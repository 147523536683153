import React, { useState, useEffect, createContext, useContext } from 'react';
// material
import { Box, Grid, Container, Typography } from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import {
  AnalyticsTasks,
  AnalyticsNewUsers,
  AnalyticsBugReports,
  AnalyticsItemOrders,
  AnalyticsNewsUpdate,
  AnalyticsWeeklySales,
  AnalyticsOrderTimeline,
  AnalyticsCurrentVisits,
  AnalyticsWebsiteVisits,
  AnalyticsTrafficBySite,
  AnalyticsCurrentSubject,
  AnalyticsConversionRates
} from '../../components/_dashboard/general-analytics-user';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

const analyticsC = createContext({ data: [], emps: [], empsm: [], proyects: [], proyectsm: [] });

export const useAnalytics = () => useContext(analyticsC);

GeneralAnalyticsUser.propTypes = {
  userid: PropTypes.string
};

export default function GeneralAnalyticsUser({ userid }) {
  const { themeStretch } = useSettings();
  const [events, setEvents] = useState([]);
  const [emps, setEmps] = useState([]);
  const [proyects, setProyects] = useState([]);
  const [empsm, setEmpsm] = useState([]);
  const [proyectsm, setProyectsm] = useState([]);

  useEffect(() => {
    async function getEvents() {
      await firestore
        .collection('events')
        .where('userid', '==', userid)
        .onSnapshot((queryS) => {
          const eventss = [];
          queryS.forEach((doc) => {
            eventss.push({ id: doc.id, ...doc.data() });
          });
          setEvents(eventss);
        });
    }
    getEvents();
    async function getMoreC() {
      await firestore
        .collection('empresas')
        .where('creator', '==', userid)
        .onSnapshot((queryS) => {
          const dat = [];
          queryS.forEach((doc) => {
            dat.push({ id: doc.id, ...doc.data() });
          });
          setEmps(dat);
        });
      await firestore
        .collection('proyectosempresa')
        .where('creator', '==', userid)
        .onSnapshot((queryS) => {
          const dat = [];
          queryS.forEach((doc) => {
            dat.push({ id: doc.id, ...doc.data() });
          });
          setProyects(dat);
        });
      await firestore
        .collection('empresas')
        .where('creator', '!=', userid)
        .where('membersids', 'array-contains', userid)
        .onSnapshot((queryS) => {
          const dat = [];
          queryS.forEach((doc) => {
            dat.push({ id: doc.id, ...doc.data() });
          });
          setEmpsm(dat);
        });
      await firestore
        .collection('proyectosempresa')
        .where('creator', '!=', userid)
        .where('membersids', 'array-contains', userid)
        .onSnapshot((queryS) => {
          const dat = [];
          queryS.forEach((doc) => {
            dat.push({ id: doc.id, ...doc.data() });
          });
          setProyectsm(dat);
        });
    }
    getMoreC();
  }, [userid]);

  return (
    <analyticsC.Provider value={{ data: events, emps, empsm, proyects, proyectsm }}>
      <Page title="General: Analytics | Bunka">
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">Analiticas</Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <AnalyticsWeeklySales />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AnalyticsNewUsers />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AnalyticsItemOrders />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AnalyticsBugReports />
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
              <AnalyticsWebsiteVisits />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <AnalyticsCurrentVisits />
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
              <AnalyticsConversionRates />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <AnalyticsCurrentSubject />
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
              <AnalyticsNewsUpdate />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <AnalyticsOrderTimeline />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <AnalyticsTrafficBySite />
            </Grid>

            <Grid item xs={12} md={6} lg={8}>
              <AnalyticsTasks />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </analyticsC.Provider>
  );
}
