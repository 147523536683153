import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  MenuItem,
  Dialog,
  DialogContent,
  Card,
  CardContent,
  Menu,
  TextField
} from '@mui/material';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { firestore } from 'src/contexts/FirebaseContext';
import Channel from 'src/components/_dashboard/social/Channel';
import PropTypes from 'prop-types';

const CATEGORIES = [
  '/static/faqs/ic_account.svg',
  '/static/faqs/ic_payment.svg',
  '/static/faqs/ic_delivery.svg',
  '/static/faqs/ic_package.svg',
  '/static/faqs/ic_refund.svg',
  '/static/faqs/ic_assurances.svg'
];

PEDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  changeIcon: PropTypes.func,
  icon: PropTypes.number,
  label: PropTypes.string,
  id: PropTypes.string,
  onDelete: PropTypes.func,
  allowDelete: PropTypes.bool
};

export function PEDialog({ open = false, setOpen, changeIcon, icon, label, id, onDelete, allowDelete = false }) {
  const [openI, setOpenI] = useState(false);
  const [openE, setOpenE] = useState(false);
  const [PEText, setPEText] = useState(label);

  useEffect(() => {
    setPEText(label);
  }, [label]);

  const clickOpenE = () => {
    setOpenE(true);
  };

  const clickCloseE = () => {
    setOpenE(false);
  };

  const clickClose = () => {
    setOpen(false);
    setOpenI(false);
  };

  const clickOpenI = () => {
    setOpenI(true);
  };

  const clickCloseI = () => {
    setOpenI(false);
  };

  const menuRef = useRef(null);

  const RenamePE = async () => {
    await firestore.collection('kobjsColunms').doc(id).update({ name: PEText });
  };

  return (
    <Dialog open={open} onClose={clickClose}>
      <Box sx={{ width: '70vw' }} />
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Card>
              <List>
                <ListItem button onClick={clickOpenE}>
                  <ListItemIcon>
                    <Icon icon={editFill} wdith={23} height={23} />
                  </ListItemIcon>
                  <ListItemText>Editar</ListItemText>
                </ListItem>
                {allowDelete && (
                  <ListItem button onClick={onDelete}>
                    <ListItemIcon>
                      <Icon icon={trash2Outline} wdith={23} height={23} />
                    </ListItemIcon>
                    <ListItemText> Eliminar</ListItemText>
                  </ListItem>
                )}
                <Channel collection="PEs" doc={id + label} />
              </List>
            </Card>
          </Grid>
          <Grid item xs={8}>
            <Card>
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    textAlign: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '100%'
                  }}
                >
                  <Typography variant="subtitle2">{label}</Typography>
                  <Box
                    component="img"
                    onClick={clickOpenI}
                    src={CATEGORIES[icon]}
                    sx={{ mb: 2, width: 100, height: 100, margin: '2%' }}
                    ref={menuRef}
                  />
                </Box>
              </CardContent>
              <Menu open={openI} onClose={clickCloseI} anchorEl={menuRef.current}>
                {CATEGORIES.map((img, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => {
                      clickCloseI();
                      changeIcon(i);
                    }}
                  >
                    <img src={img} alt="algo" />
                  </MenuItem>
                ))}
              </Menu>
              <Dialog open={openE} onClose={clickCloseE}>
                <DialogContent>
                  <TextField
                    label="Punto de Enfoque"
                    value={PEText}
                    onChange={(e) => setPEText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        RenamePE();
                        clickCloseE();
                      }
                    }}
                  />
                </DialogContent>
              </Dialog>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

// --------------------------------------------------------------

CategoryCard.propTypes = {
  category: PropTypes.object
};

function CategoryCard({ category }) {
  const { id, label, icon } = category;

  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const changeIcon = async (num) => {
    await firestore.collection('kobjsColunms').doc(id).update({ icon: num });
  };

  return (
    <Paper
      sx={{
        px: 2,
        height: 260,
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        boxShadow: (theme) => theme.customShadows.z8,
        margin: '3%'
      }}
    >
      <Box component="img" onClick={clickOpen} src={CATEGORIES[icon]} sx={{ mb: 2, width: 80, height: 80 }} />
      <Typography variant="subtitle2">{label}</Typography>
      <PEDialog open={open} setOpen={setOpen} changeIcon={changeIcon} icon={icon} label={label} id={id} />
    </Paper>
  );
}

// --------------------------------------------------------------

PuntosEnfoque.propTypes = {
  PEs: PropTypes.array
};

export default function PuntosEnfoque({ PEs = [] }) {
  const [PEA, setPEA] = useState([]);

  useEffect(() => {
    const PEAA = Array(Math.ceil(PEs.length / 4));
    for (let i = 0; i < PEAA.length; i += 1) {
      PEAA[i] = i;
    }
    setPEA(PEAA);
  }, [PEs]);

  const getFlexNum = (numq) => {
    if (numq === 0) {
      return 12;
    }
    return 12 / numq;
  };

  return (
    <>
      <Box>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>
          Puntos de Enfoque
        </Typography>
        <Grid container spacing={2}>
          {PEA.map((i) => (
            <>
              {PEs.slice(4 * i, 4 * (i + 1)).map((pe) => (
                <Grid
                  key={pe}
                  xs={getFlexNum(PEs.slice(1 * i, 1 * (i + 1)).length)}
                  md={getFlexNum(PEs.slice(2 * i, 2 * (i + 1)).length)}
                  lg={getFlexNum(PEs.slice(3 * i, 3 * (i + 1)).length)}
                >
                  <CategoryCard category={{ ...pe, icon: pe.icon || 0, label: pe.name }} sx={{ m: 0.5 }} />
                </Grid>
              ))}
            </>
          ))}
        </Grid>
      </Box>
    </>
  );
}
