import React, { useState, useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  Box,
  Grid,
  DialogContent,
  Dialog,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  FormControlLabel,
  Switch,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';
import { LoadingButton, DateTimePicker } from '@mui/lab';
import { Close } from '@mui/icons-material';
// icono
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
import sprintI from '@iconify/icons-ant-design/clock-circle-filled';
import fileaddI from '@iconify/icons-ant-design/file-add-filled';
import setting from '@iconify/icons-ant-design/setting-fill';
//
import { firestore } from 'src/contexts/FirebaseContext';
import { v1 as uuidv1, v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
// hooks
import useAuth from 'src/hooks/useAuth';
import usePermissions, { tienePermiso } from 'src/hooks/usePermissions';
//

NewSprintForm.propTypes = {
  idEmpresa: PropTypes.string,
  edit: PropTypes.bool,
  spid: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  showB: PropTypes.bool,
  listB: PropTypes.bool,
  iconB: PropTypes.bool
};

export default function NewSprintForm({
  idEmpresa,
  edit = false,
  spid,
  open = false,
  setOpen,
  showB = false,
  listB = false,
  iconB = false
}) {
  const { user } = useAuth();
  const { permisos } = usePermissions();
  const [creador, setCreador] = useState('');
  const [proyectsids, setProyectsids] = useState([]);
  const [objs, setObjs] = useState([]);

  const canEditSprint = tienePermiso('editar sprints', user.id, permisos);
  const canCreateSprint = tienePermiso('crear sprints', user.id, permisos);

  const allowEdit = (canEditSprint && edit) || user.id === creador;
  const allowCreate = (canCreateSprint && !edit) || user.id === creador;

  useEffect(() => {
    async function getPids() {
      await firestore
        .collection('proyectosempresa')
        .where('idEmpresa', '==', idEmpresa)
        .onSnapshot((queryS) => {
          const ids = [];
          queryS.forEach((doc) => ids.push(doc.id));
          setProyectsids(ids);
        });
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .onSnapshot((doc) => {
          const { creator } = doc.data();
          setCreador(creator);
        });
    }
    getPids();
  }, [idEmpresa]);

  useEffect(() => {
    async function getObjs(pid) {
      const objsids = [];
      await firestore
        .collection('kobjsCards')
        .where('pid', '==', pid)
        .get()
        .then((queryS) => {
          queryS.forEach((doc) => {
            objsids.push(doc.id);
          });
        });
      return objsids;
    }
    proyectsids.forEach(async (id) => {
      const idobjsp = await getObjs(id);
      setObjs([...objs, ...idobjsp]);
    });
    // eslint-disable-next-line
  }, [proyectsids]);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  const validation = Yup.object().shape({
    nombre: Yup.string().required('Obligatorio'),
    objetivo: Yup.string().required('obligatorio'),
    fechaE: Yup.date(),
    fechaF: Yup.date().when(
      'fechaE',
      (fechaE, schema) => fechaE && schema.min(fechaE, 'End date must be later than start date')
    )
  });

  const formik = useFormik({
    initialValues: {
      nombre: '',
      objetivo: '',
      fechaE: new Date(),
      fechaF: new Date(),
      status: 'creado',
      allDay: false,
      publico: true,
      objetivos: [],
      likes: [],
      scrumMaster: [],
      productOwner: [],
      team: [user.id],
      coach: [],
      involucrados: []
    },
    validationSchema: validation,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      try {
        if (edit) {
          await firestore
            .collection('empresas')
            .doc(idEmpresa)
            .collection('sprints')
            .doc(spid)
            .update({ ...values });
          setOpen(false);
        } else {
          const newIdDoc = uuidv1();
          // nuevo sprint
          await firestore
            .collection('empresas')
            .doc(idEmpresa)
            .collection('sprints')
            .doc(newIdDoc)
            .set({ ...values, creator: user.id })
            .then(() => {
              resetForm();
              setOpen(false);
            });
          // instanciar el kanban
          const columns = [uuidv4(), uuidv4(), uuidv4(), uuidv4()];
          const namesC = ['Programado', 'En progreso', 'Verificar', 'Cerrado'];
          await firestore
            .collection('empresas')
            .doc(idEmpresa)
            .collection('sprints')
            .doc(newIdDoc)
            .collection('kanbanColumnOrder')
            .doc('order')
            .set({ columnOrder: columns });
          columns.forEach(async (c, i) => {
            await firestore
              .collection('empresas')
              .doc(idEmpresa)
              .collection('sprints')
              .doc(newIdDoc)
              .collection('kanbanColunms')
              .doc(c)
              .set({ id: c, name: namesC[i], cardIds: [] });
          });
        }
        setSubmitting(false);
      } catch (err) {
        setSubmitting(false);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  useEffect(() => {
    async function getSprintInfo() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .doc(spid)
        .get()
        .then((doc) => {
          const {
            nombre,
            objetivo,
            fechaE,
            fechaF,
            status,
            objetivos,
            allDay,
            likes,
            scrumMaster,
            productOwner,
            team,
            coach,
            involucrados,
            publico
          } = doc.data();
          setFieldValue('nombre', nombre);
          setFieldValue('objetivo', objetivo);
          setFieldValue('fechaE', fechaE.toDate());
          setFieldValue('fechaF', fechaF.toDate());
          setFieldValue('status', status);
          setFieldValue('allDay', allDay || false);
          setFieldValue('objetivos', objetivos);
          setFieldValue('likes', likes);
          setFieldValue('scrumMaster', scrumMaster || []);
          setFieldValue('productOwner', productOwner || []);
          setFieldValue('team', team || []);
          setFieldValue('coach', coach || []);
          setFieldValue('involucrados', involucrados || []);
          setFieldValue('publico', publico || true);
        });
    }
    if (edit && open) {
      getSprintInfo();
    }
    // eslint-disable-next-line
  }, [edit, open, idEmpresa, spid]);

  return (
    <>
      {showB && iconB && (
        <IconButton onClick={clickOpen} sx={{ color: '#637381' }}>
          {edit ? (
            <Icon icon={setting} width={23} height={23} />
          ) : (
            <>
              <Icon icon={fileaddI} width={23} height={23} />
              <Icon icon={sprintI} width={23} height={23} />
            </>
          )}
        </IconButton>
      )}
      {showB && listB && (
        <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
          <ListItemIcon>
            {edit ? (
              <Icon icon={editFill} width={23} height={23} />
            ) : (
              <>
                <Icon icon={fileaddI} width={23} height={23} />
                <Icon icon={sprintI} width={23} height={23} />
              </>
            )}
          </ListItemIcon>
          <ListItemText>{edit ? <>Editar</> : <>Nuevo Sprint</>}</ListItemText>
        </ListItem>
      )}
      {showB && !listB && !iconB && (
        <Button variant={edit ? 'contained' : 'text'} onClick={clickOpen} sx={{ margin: '1%', color: '#637381' }}>
          {edit ? (
            <>
              <Icon icon={editFill} width={23} height={23} /> editar
            </>
          ) : (
            <>
              <Icon icon={fileaddI} width={23} height={23} />
              <Icon icon={sprintI} width={23} height={23} /> Nuevo Sprint
            </>
          )}
        </Button>
      )}
      <Dialog open={open} onClose={clickClose} maxWidth={1000}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton onClick={clickClose}>
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box sx={{ width: '900px' }}>
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Nombre"
                      {...getFieldProps('nombre')}
                      disabled={!(allowCreate || allowEdit)}
                      error={Boolean(touched.nombre && errors.nombre)}
                      helperText={touched.nombre && errors.nombre}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="Objetivo"
                      {...getFieldProps('objetivo')}
                      disabled={!(allowCreate || allowEdit)}
                      error={Boolean(touched.objetivo && errors.objetivo)}
                      helperText={touched.objetivo && errors.objetivo}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DateTimePicker
                      value={values.fechaE}
                      onChange={(e) => setFieldValue('fechaE', e)}
                      label="Fecha de comienzo"
                      inputFormat="dd/MM/yyyy hh:mm a"
                      renderInput={(props) => (
                        <TextField
                          disabled={!(allowCreate || allowEdit)}
                          label="Fecha de comienzo"
                          error={Boolean(touched.fechaE && errors.fechaE)}
                          helperText={touched.fechaE && errors.fechaE}
                          {...props}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DateTimePicker
                      value={values.fechaF}
                      onChange={(e) => setFieldValue('fechaF', e)}
                      label="Fecha de finalizacion"
                      inputFormat="dd/MM/yyyy hh:mm a"
                      renderInput={(props) => (
                        <TextField
                          disabled={!(allowCreate || allowEdit)}
                          label="Fecha de finalizacion"
                          error={Boolean(touched.fechaF && errors.fechaF)}
                          helperText={touched.fechaF && errors.fechaF}
                          {...props}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={!(allowCreate || allowEdit)}
                          {...getFieldProps('allDay')}
                          checked={values.allDay}
                        />
                      }
                      label="todo el Día"
                      labelPlacement="start"
                      sx={{ mx: 0, width: '100%', justifyContent: 'space-between' }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={!(allowCreate || allowEdit)}
                          {...getFieldProps('publico')}
                          checked={values.publico}
                        />
                      }
                      label="Publico"
                      labelPlacement="start"
                      sx={{ mx: 0, width: '100%', justifyContent: 'space-between' }}
                    />
                  </Grid>
                </Grid>
                <LoadingButton
                  type="submit"
                  loading={isSubmitting}
                  variant="contained"
                  disabled={!(allowCreate || allowEdit)}
                >
                  Guardar
                </LoadingButton>
              </Form>
            </FormikProvider>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
