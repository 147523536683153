import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
// import editFill from '@iconify/icons-eva/edit-fill';
// import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreHorizontalFill from '@iconify/icons-eva/more-horizontal-fill';
// material
import { Stack, OutlinedInput /* , MenuItem, Box, Typography */ } from '@mui/material';
//
import { firestore } from 'src/contexts/FirebaseContext';
// import MenuPopover from '../../MenuPopover';
import { MIconButton } from '../../@material-extend';
import { AltaPuntoF } from 'src/components/_dashboard/proyecto';
import { PEDialog } from '../proyecto/PuntosEnfoque';

// ----------------------------------------------------------------------

KanbanColumnToolBar.propTypes = {
  columnName: PropTypes.string,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  columnId: PropTypes.string,
  cardIds: PropTypes.array,
  projectId: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.number
};

// eslint-disable-next-line
export default function KanbanColumnToolBar({
  columnName,
  onDelete,
  onUpdate,
  columnId,
  projectId,
  cardIds = [],
  id,
  icon
}) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openD, setOpenD] = useState(false);
  const [value, setValue] = useState(columnName);

  useEffect(() => {
    setValue(columnName);
  }, [columnName]);

  const handleOpen = () => {
    setOpen(true);
  };

  /* const handleClose = () => {
    setOpen(false);
  }; */

  /* const handleOpenD = () => {
    setOpenD(true);
  }; */

  const handleCloseD = () => {
    setOpenD(false);
  };

  /* const handleClickEdit = () => {
    handleOpenD();
    handleClose();
  }; */

  const handleChangeColumnName = (event) => {
    setValue(event.target.value);
  };

  const handleUpdateColumn = (event) => {
    if (event.key === 'Enter') {
      onUpdate(event.target.value);
    }
  };

  const changeIcon = async (num) => {
    await firestore.collection('kobjsColunms').doc(id).update({ icon: num });
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{ pt: 3 }}>
        <OutlinedInput
          multiline
          size="small"
          placeholder="Section name"
          value={value}
          onChange={handleChangeColumnName}
          onKeyUp={handleUpdateColumn}
          sx={{
            typography: 'h6',
            fontWeight: 'fontWeightBold',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent'
            }
          }}
        />

        <MIconButton ref={anchorRef} size="small" onClick={handleOpen} color={open ? 'inherit' : 'default'}>
          <Icon icon={moreHorizontalFill} width={20} height={20} />
        </MIconButton>
      </Stack>
      <PEDialog
        open={open}
        setOpen={setOpen}
        changeIcon={changeIcon}
        icon={icon || 0}
        label={columnName}
        onDelete={onDelete}
        allowDelete={cardIds.length === 0}
      />
      {/* <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ py: 1, width: 'auto' }}>
        <MenuItem onClick={handleClickEdit} sx={{ py: 0.75, px: 1.5 }}>
          <Box component={Icon} icon={editFill} sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }} />
          <Typography variant="body2">Edit section</Typography>
        </MenuItem>
        <MenuItem onClick={onDelete} sx={{ py: 0.75, px: 1.5 }}>
          <Box component={Icon} icon={trash2Outline} sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }} />
          <Typography variant="body2">Delete section</Typography>
        </MenuItem>
      </MenuPopover> */}
      <AltaPuntoF edit open={openD} onClose={handleCloseD} projectId={projectId} columnId={columnId} />
    </>
  );
}
