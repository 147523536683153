import React from 'react';
import { Card } from '@mui/material';
import PropTypes from 'prop-types';

CardItem.propTypes = {
  src: PropTypes.string
};

export default function CardItem({ src }) {
  return (
    <Card sx={{ padding: '3%' }}>
      <iframe
        src={src}
        width="100%"
        height="320"
        title="Primeros pasos Bunka"
        scrolling="no"
        webkitallowfullscreen
        mozallowfullscreen
        allowFullScreen
        style={{ border: '0' }}
      />
    </Card>
  );
}
