import React, { useMemo, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { CTAButtonIframe } from '../ctamedias';
import { AltaEmpresa } from '../../asistentemain/modulos/navs/empresamod';
import ButtonMercP from './ButtonMercP';
import ButtonProduct from './ButtonProduct';
import AsistenteEmpresa from 'src/pages/dashboard/AsistenteEmpresa';
import InvitationGenerator from 'src/components/_dashboard/entorno/InvitationGenerator';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

ButtonCustom.propTypes = {
  BTN: PropTypes.shape({
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.string,
    buttonType: PropTypes.string,
    price: PropTypes.number,
    quantity: PropTypes.number,
    description: PropTypes.string,
    producto: PropTypes.string,
    productId: PropTypes.string
  }),
  onClickB: PropTypes.func,
  idEmpresa: PropTypes.string
};

export default function ButtonCustom({ BTN, onClickB, idEmpresa }) {
  const { buttonText, buttonUrl, buttonType, price, quantity, description, producto, productId } = BTN;
  const [empresa, setEmpresa] = useState(undefined);

  useEffect(() => {
    async function getEmp() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .onSnapshot((doc) => {
          setEmpresa({ id: doc.id, ...doc.data() });
        });
    }
    if (Boolean(idEmpresa) && buttonType === 'invGenerator') {
      getEmp();
    }
  }, [idEmpresa, buttonType]);

  const buttonT = {
    url: (
      <Button variant="contained" href={buttonUrl} onClick={onClickB} target="_blank">
        {buttonText}
      </Button>
    ),
    iframe: <CTAButtonIframe buttonText={buttonText} src={buttonUrl} onClick={onClickB} />,
    newEmp: <AltaEmpresa btnText={buttonText} btn onCreate={onClickB} />,
    pay: useMemo(
      () => (
        <ButtonMercP
          buttonText={buttonText}
          price={price}
          quantity={quantity}
          description={description}
          producto={producto}
          idEmpresa={idEmpresa}
        />
      ),
      [buttonText, price, quantity, description, producto, idEmpresa]
    ),
    product: useMemo(() => <ButtonProduct productId={productId} buttonText={buttonText} />, [productId, buttonText]),
    planEst: useMemo(() => <AsistenteEmpresa buttonText={buttonText} idEmpresa={idEmpresa} />, [idEmpresa, buttonText]),
    invGenerator: useMemo(() => <InvitationGenerator idEmpresa={idEmpresa} empresa={empresa} />, [idEmpresa, empresa])
  };
  return <>{buttonT[buttonType]}</>;
}
