import { filter, random, orderBy } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { firestore } from 'src/contexts/FirebaseContext';

// ----------------------------------------------------------------------

async function getUsersFirestore() {
  const users = [];
  await firestore
    .collection('users')
    .get()
    .then((queryS) => {
      queryS.forEach((doc) => {
        const { displayName, photoURL, position, role } = doc.data();
        users.push({
          id: doc.id,
          name: displayName,
          avatarUrl: photoURL,
          cover: photoURL,
          position: position || '',
          follower: random(9999),
          following: random(9999),
          totalPost: random(9999),
          role
        });
      });
    });
  return users;
}

async function getUserListFirestore() {
  const users = [];
  await firestore
    .collection('users')
    .get()
    .then((queryS) => {
      queryS.forEach((doc) => {
        const { displayName, photoURL, role } = doc.data();
        users.push({
          ...doc.data(),
          name: displayName,
          avatarUrl: photoURL,
          id: doc.id,
          role,
          status: 'offline',
          isVerified: true
        });
      });
    });
  return users;
}

async function getFollowingF(id) {
  const users = [];
  await firestore
    .collection('users')
    .where('followers', 'array-contains', id)
    .get()
    .then((queryS) => {
      queryS.forEach((doc) => users.push(doc.id));
    });
  return users;
}

async function getProfileFirestore(id) {
  let user = {};
  await firestore
    .collection('users')
    .doc(id)
    .get()
    .then(async (doc) => {
      const { photoURL, about } = doc.data();
      const followersA = doc.data().followers || [];
      const following = await getFollowingF(id);
      user = {
        ...doc.data(),
        quote: about,
        id: doc.id,
        cover: photoURL,
        follower: followersA.length,
        following: following.length
      };
    });
  return user;
}

async function getPostsFirestore(userid) {
  const posts = [];
  await firestore
    .collection('users')
    .doc(userid)
    .collection('posts')
    .get()
    .then((queryS) => {
      queryS.forEach((doc) => {
        posts.push({ id: doc.id, ...doc.data() });
      });
    });
  return orderBy(posts, ['createdAt'], ['desc']);
}

async function getFollowersF(userid) {
  const follows = [];
  await firestore
    .collection('users')
    .doc(userid)
    .get()
    .then((doc) => {
      const folls = doc.data().followers || [];
      folls.forEach((d) => {
        follows.push(d);
      });
    });
  return follows;
}

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  myProfile: null,
  profile: null,
  posts: [],
  users: [],
  userList: [],
  followers: [],
  myfollowers: [],
  friends: [],
  gallery: [],
  cards: null,
  addressBook: [],
  invoices: [],
  notifications: null
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    getProfileSuccess2(state, action) {
      state.isLoading = false;
      state.profile = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // DELETE USERS
    deleteUser(state, action) {
      const deleteUser = filter(state.userList, (user) => user.id !== action.payload);
      state.userList = deleteUser;
    },

    // GET FOLLOWERS
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      state.followers = action.payload;
    },

    // ON TOGGLE FOLLOW
    onToggleFollow(state, action) {
      const { followerId, userid } = action.payload;
      const following = state.followers.includes(followerId);
      let handleToggle;
      if (following) {
        handleToggle = state.followers.filter((d) => d !== followerId);
      } else {
        handleToggle = [...state.followers, followerId];
      }
      state.profile.follower = handleToggle.length;
      firestore.collection('users').doc(userid).update({ followers: handleToggle });
      state.followers = handleToggle;
    },

    // GET FOLLOWERS
    getMyFollowersSuccess(state, action) {
      state.isLoading = false;
      state.myfollowers = action.payload;
    },

    // ON TOGGLE FOLLOW
    onToggleMyFollow(state, action) {
      const { followerId, userid } = action.payload;
      const following = state.myfollowers.includes(followerId);
      let handleToggle;
      if (following) {
        handleToggle = state.myfollowers.filter((d) => d !== followerId);
      } else {
        handleToggle = [...state.myfollowers, followerId];
      }
      firestore.collection('users').doc(userid).update({ followers: handleToggle });
      state.myfollowers = handleToggle;
    },

    // GET FRIENDS
    getFriendsSuccess(state, action) {
      state.isLoading = false;
      state.friends = action.payload;
    },

    // GET GALLERY
    getGallerySuccess(state, action) {
      state.isLoading = false;
      state.gallery = action.payload;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    // GET CARDS
    getCardsSuccess(state, action) {
      state.isLoading = false;
      state.cards = action.payload;
    },

    // GET ADDRESS BOOK
    getAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addressBook = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteUser, onToggleMyFollow } = slice.actions;

// ----------------------------------------------------------------------

export function getProfile(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const profile = await getProfileFirestore(id);
      dispatch(slice.actions.getProfileSuccess(profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProfile2(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const profile = await getProfileFirestore(id);
      dispatch(slice.actions.getProfileSuccess2(profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPosts(userid) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getPostsFirestore(userid);
      dispatch(slice.actions.getPostsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFollowers(userid) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getFollowersF(userid);
      dispatch(slice.actions.getFollowersSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMyFollowers(userid) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getFollowersF(userid);
      dispatch(slice.actions.getFollowersSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getFriends() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/friends');
      dispatch(slice.actions.getFriendsSuccess(response.data.friends));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGallery() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/gallery');
      dispatch(slice.actions.getGallerySuccess(response.data.gallery));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const users = await getUserListFirestore();
      dispatch(slice.actions.getUserListSuccess(users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCards() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/cards');
      dispatch(slice.actions.getCardsSuccess(response.data.cards));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAddressBook() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/address-book');
      dispatch(slice.actions.getAddressBookSuccess(response.data.addressBook));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/invoices');
      dispatch(slice.actions.getInvoicesSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/notifications-settings');
      dispatch(slice.actions.getNotificationsSuccess(response.data.notifications));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const users = await getUsersFirestore();
      dispatch(slice.actions.getUsersSuccess(users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
