import React, { useState, useEffect } from 'react';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogContent,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  List
} from '@mui/material';
import { EmpresaPremium, Organizacion } from './memberships';
// icons
import { Icon } from '@iconify/react';
import membershipI from '@iconify/icons-eva/credit-card-outline';
// utils
import { parseDate } from 'src/utils/formatTime';
//
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

Memberships.propTypes = {
  empresa: PropTypes.object
};

export default function Memberships({ empresa }) {
  const [open, setOpen] = useState(false);
  const [proyects, setProyects] = useState([]);
  const { id, nombre, premium, expiresP, orgMod, expiresOrg } = empresa;

  const today = new Date().getTime();
  const expiresPDate = parseDate(expiresP);
  const expiresOrgDate = parseDate(expiresOrg);
  const expiredP = today > expiresPDate;
  const expiredO = today > expiresOrgDate;

  useEffect(() => {
    async function getProyects() {
      await firestore
        .collection('proyectosempresa')
        .where('idEmpresa', '==', id)
        .onSnapshot((queryS) => {
          const proyes = [];
          queryS.forEach((doc) => {
            proyes.push(doc.id);
          });
          setProyects(proyes);
        });
    }
    getProyects();
  }, [id]);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#506070' }}>
        <ListItemIcon>
          <Icon icon={membershipI} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Modulos</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <Box sx={{ width: '60vw' }} />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <Card>
                <List>
                  <EmpresaPremium empresa={empresa} />
                  <Organizacion empresa={empresa} />
                </List>
              </Card>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Card>
                <CardContent>
                  <Typography variant="h4">{nombre}</Typography>
                  <Box>
                    <Typography>Tipo de Empresa: {premium ? 'premium' : 'gratis'}</Typography>
                    {expiredP && premium && <Typography>Membresia Expirada</Typography>}
                    <Typography>Modulo de Organizacion: {orgMod ? 'si' : 'no'}</Typography>
                    {expiredO && orgMod && <Typography>Membresia Expirada</Typography>}
                    <Typography>proyectos: {proyects.length}</Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
