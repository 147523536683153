import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback, useState, useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { Navigate } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { paramCase } from 'change-case';
// material
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import {
  Card,
  Grid,
  Chip,
  Stack,
  Switch,
  TextField,
  Typography,
  Autocomplete,
  FormHelperText,
  FormControlLabel
} from '@mui/material';
//
import { QuillEditor } from '../../editor';
import { UploadSingleFile } from '../../upload';
//
import { v1 as uuidv1 } from 'uuid';
import { firestore, storage as st } from 'src/contexts/FirebaseContext';
import useAuth from 'src/hooks/useAuth';
import { captureEvent } from 'src/utils/events';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

const VALORES = ['Tranformación digital', 'Tegnología'];

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

// ----------------------------------------------------------------------

NuevaEmpresaForm.propTypes = {
  edit: PropTypes.bool,
  idPost: PropTypes.string
};

export default function NuevaEmpresaForm({ edit = false, idPost }) {
  const [blogid, setBlogid] = useState('');
  const [redirect, setRedirect] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();

  // idblog
  useEffect(() => {
    setBlogid(uuidv1());
  }, []);

  const NewBlogSchema = Yup.object().shape({
    nombre: Yup.string().required('nombre es requerido'),
    description: Yup.string().required('Descripcion es requerida')
  });

  const formik = useFormik({
    initialValues: {
      nombre: '',
      description: '',
      content: '',
      logo: null,
      tags: [],
      activa: true,
      comments: true,
      mision: '',
      vision: '',
      valores: [],
      premium: false,
      expiresP: null,
      orgMod: false,
      expiresOrg: null
    },
    validationSchema: NewBlogSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const { nombre, description, content, tags, activa, mision, vision, valores, logo } = values;
        if (edit) {
          await firestore.collection('empresas').doc(idPost).update({
            nombre,
            description,
            body: content,
            tags,
            activa,
            mision,
            vision,
            valores,
            logo
          });
          captureEvent('newEmp', user.id);
        } else {
          await firestore
            .collection('empresas')
            .doc(blogid)
            .set({
              id: blogid,
              creator: user.id,
              nombre,
              description,
              body: content,
              tags,
              activa,
              mision,
              vision,
              valores,
              logo,
              createdAt: new Date(),
              likes: [],
              membersids: [user.id],
              membersinfo: [
                {
                  id: user.id,
                  nombre: user.displayName,
                  avatar: user.photoURL
                }
              ]
            })
            .then(() => {
              setBlogid(uuidv1());
            });
        }
        if (!edit) {
          setRedirect(true);
        }
        setSubmitting(false);
        enqueueSnackbar('Post success', { variant: 'success' });
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  useEffect(() => {
    async function getBlogInfo() {
      await firestore
        .collection('empresas')
        .doc(idPost)
        .get()
        .then((doc) => {
          const {
            nombre,
            description,
            body,
            tags,
            logo,
            activa,
            mision,
            vision,
            valores,
            premium,
            expiresP,
            orgMod,
            expiresOrg
          } = doc.data();
          setFieldValue('nombre', nombre);
          setFieldValue('description', description);
          setFieldValue('content', body);
          setFieldValue('tags', tags);
          setFieldValue('logo', logo);
          setFieldValue('activa', activa);
          setFieldValue('mision', mision);
          setFieldValue('vision', vision);
          setFieldValue('valores', valores);
          setFieldValue('premium', premium || false);
          setFieldValue('expiresP', expiresP || null);
          setFieldValue('orgMod', orgMod || false);
          setFieldValue('expiresOrg', expiresOrg || null);
        });
    }
    if (edit && idPost) {
      getBlogInfo();
    }
    // eslint-disable-next-line
  }, [edit, idPost]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        const format = file.name.split('.')[1];
        const fileT = new File([file], 'cover.' + format, { type: file.type });
        const uploadCover = st.ref().child(`empresas/${blogid}/logo.${format}`);
        uploadCover.put(fileT).then(() => {
          uploadCover.getDownloadURL().then((url) => {
            setFieldValue('logo', url);
          });
        });
      }
    },
    [setFieldValue, blogid]
  );

  return (
    <>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    label="Nombre de la empresa"
                    {...getFieldProps('nombre')}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                  />

                  <TextField
                    fullWidth
                    multiline
                    minRows={3}
                    maxRows={5}
                    label="Descripción"
                    {...getFieldProps('description')}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />

                  <div>
                    <LabelStyle>Información General</LabelStyle>
                    <QuillEditor
                      id="post-content"
                      value={values.content}
                      onChange={(val) => setFieldValue('content', val)}
                      error={Boolean(touched.content && errors.content)}
                    />
                    {touched.content && errors.content && (
                      <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                        {touched.content && errors.content}
                      </FormHelperText>
                    )}
                  </div>

                  <div>
                    <LabelStyle>Logo</LabelStyle>
                    <UploadSingleFile
                      maxSize={9145728}
                      accept="image/*"
                      file={values.logo}
                      onDrop={handleDrop}
                      error={Boolean(touched.cover && errors.cover)}
                    />
                    {touched.cover && errors.cover && (
                      <FormHelperText error sx={{ px: 2 }}>
                        {touched.cover && errors.cover}
                      </FormHelperText>
                    )}
                  </div>
                </Stack>
              </Card>
            </Grid>

            <Grid item xs={12} md={4}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <div>
                    <FormControlLabel
                      control={<Switch {...getFieldProps('activa')} checked={values.publish} />}
                      label="Activa"
                      labelPlacement="start"
                      sx={{ mb: 1, mx: 0, width: '100%', justifyContent: 'space-between' }}
                    />

                    <FormControlLabel
                      control={<Switch {...getFieldProps('comments')} checked={values.comments} />}
                      label="Habilitar commentarios"
                      labelPlacement="start"
                      sx={{ mx: 0, width: '100%', justifyContent: 'space-between' }}
                    />
                  </div>
                  <TextField fullWidth label="Visión" {...getFieldProps('vision')} />
                  <TextField fullWidth label="Misión" {...getFieldProps('mision')} />
                  <Autocomplete
                    multiple
                    freeSolo
                    value={values.valores}
                    onChange={(event, newValue) => {
                      setFieldValue('valores', newValue);
                    }}
                    options={VALORES.map((option) => option)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip {...getTagProps({ index })} key={option} size="small" label={option} />
                      ))
                    }
                    renderInput={(params) => <TextField {...params} label="Valores" />}
                  />
                </Stack>
              </Card>

              <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
                <LoadingButton fullWidth type="submit" variant="contained" size="large" loading={isSubmitting}>
                  {edit ? <>Actualizar</> : <>Agregar</>}
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
      {redirect ? (
        <Navigate to={PATH_DASHBOARD.entorno.root + '/empresa/' + paramCase(values.nombre)} replace />
      ) : (
        <></>
      )}
    </>
  );
}
