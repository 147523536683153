import React, { useState, useEffect } from 'react';
import {
  ListItem,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Box,
  Grid,
  FormControlLabel,
  Card,
  CardContent,
  RadioGroup,
  Radio,
  Stack,
  Button,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/styles';
// icon

// formik
import { Form, useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';
// firebase
import { firestore } from 'src/contexts/FirebaseContext';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

// -------------------------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

// -------------------------------------------------------------------------------------

AltaBoton.propTypes = {
  iddoc: PropTypes.string,
  edit: PropTypes.bool,
  listB: PropTypes.bool,
  btn: PropTypes.bool
};

export default function AltaBoton({ edit = false, iddoc, listB = false, btn = false }) {
  const [open, setOpen] = useState(false);
  const [uuidS, setUUIDS] = useState('');
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function getPs() {
      await firestore.collection('productos').onSnapshot((queryS) => {
        const dat = [];
        queryS.forEach((doc) => {
          dat.push({ id: doc.id, ...doc.data() });
        });
        setProducts(dat);
      });
    }
    getPs();
  }, []);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const uuid = uuidv4();
    setUUIDS(uuid);
  }, [iddoc]);

  const buttonTypes = [
    { value: 'iframe', name: 'Embebido' },
    { value: 'url', name: 'Enlace' },
    { value: 'newEmp', name: 'Nueva Empresa (componente)' },
    { value: 'planEst', name: 'Plan Estratégico (componente)' },
    { value: 'invGenerator', name: 'Agregar Invitado (componente)' },
    { value: 'pay', name: 'mercado pago' },
    { value: 'product', name: 'producto' }
  ];

  const productos = [{ value: 'empresap', name: 'empresap' }];

  const formik = useFormik({
    initialValues: {
      descripcion: '',
      buttonText: '',
      buttonType: 'url',
      buttonUrl: '',
      price: 10,
      quantity: 1,
      description: '',
      producto: 'empresap',
      productId: ''
    },
    validationSchema: yup.object().shape({
      buttonText: yup.string().required('Requerido'),
      price: yup.number().min(10).required('requerido'),
      quantity: yup.number().min(1).required('requerido'),
      description: yup.string()
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        setSubmitting(true);
        const data = values;
        console.log(data);
        if (edit) {
          await firestore
            .collection('buttons')
            .doc(iddoc)
            .update({ ...data });
        } else {
          await firestore
            .collection('buttons')
            .doc(uuidS)
            .set({ ...data })
            .then(() => {
              setUUIDS(uuidv4());
              resetForm();
            });
        }
        setSubmitting(false);
      } catch (err) {
        console.error(err);
        setSubmitting(false);
      }
    }
  });

  const { handleSubmit, getFieldProps, errors, touched, values, isSubmitting, setFieldValue } = formik;

  useEffect(() => {
    async function getCTA() {
      await firestore
        .collection('buttons')
        .doc(iddoc)
        .get()
        .then((doc) => {
          const { descripcion, buttonText, buttonUrl, buttonType, price, quantity, description, producto } = doc.data();
          setFieldValue('descripcion', descripcion || '');
          setFieldValue('buttonText', buttonText || '');
          setFieldValue('buttonType', buttonType || 'url');
          setFieldValue('buttonUrl', buttonUrl || '');
          setFieldValue('price', price || 10);
          setFieldValue('quantity', quantity || 1);
          setFieldValue('description', description || '');
          setFieldValue('producto', producto || 'empresap');
        });
    }
    if (edit) {
      getCTA();
    }
    // eslint-disable-next-line
  }, [edit, iddoc]);

  return (
    <>
      {btn && (
        <Button variant="contained" onClick={clickOpen}>
          {edit ? <>Editar</> : <>Alta de boton</>}
        </Button>
      )}
      {listB && (
        <ListItem button onClick={clickOpen} sx={{ color: '#506070' }}>
          <ListItemText>{edit ? <>Editar</> : <>Alta de boton</>}</ListItemText>
        </ListItem>
      )}
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <DialogContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" onSubmit={handleSubmit}>
              <Typography variant="h4">
                {edit ? <>Editar botton: {values.buttonText}</> : <>Alta de Boton</>}
              </Typography>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={8}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12}>
                            <TextField
                              fullWidth
                              label="texto del boton"
                              {...getFieldProps('buttonText')}
                              error={Boolean(touched.buttonText && errors.buttonText)}
                              helperText={touched.buttonText && errors.buttonText}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextField
                              fullWidth
                              label="descripcion (breve y suficiente para saber que hace)"
                              {...getFieldProps('descripcion')}
                              error={Boolean(touched.descripcion && errors.descripcion)}
                              helperText={touched.descripcion && errors.descripcion}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={12}>
                            <LabelStyle>Tipo de boton</LabelStyle>
                            <RadioGroup {...getFieldProps('buttonType')}>
                              <Stack spacing={1} direction="column">
                                {buttonTypes.map((mediab) => (
                                  <FormControlLabel
                                    key={mediab.name}
                                    value={mediab.value}
                                    control={<Radio />}
                                    label={mediab.name}
                                  />
                                ))}
                              </Stack>
                            </RadioGroup>
                          </Grid>
                          {values.buttonType === 'pay' && (
                            <>
                              <Grid item xs={12} md={12}>
                                <Typography>Detalles</Typography>
                                <TextField
                                  type="number"
                                  label="precio en MX"
                                  {...getFieldProps('price')}
                                  error={Boolean(touched.price && errors.price)}
                                  helperText={touched.price && errors.price}
                                />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <TextField
                                  type="number"
                                  label="cantidad"
                                  {...getFieldProps('quantity')}
                                  error={Boolean(touched.quantity && errors.quentity)}
                                  helperText={touched.quantity && errors.quantity}
                                />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <TextField
                                  label="descripcion"
                                  {...getFieldProps('description')}
                                  error={Boolean(touched.description && errors.description)}
                                  helperText={touched.description && errors.description}
                                />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <LabelStyle>Tipo de boton</LabelStyle>
                                <RadioGroup {...getFieldProps('producto')}>
                                  <Stack spacing={1} direction="column">
                                    {productos.map((p) => (
                                      <FormControlLabel
                                        key={p.name}
                                        value={p.value}
                                        control={<Radio />}
                                        label={p.name}
                                      />
                                    ))}
                                  </Stack>
                                </RadioGroup>
                              </Grid>
                            </>
                          )}
                          {values.buttonType !== 'pay' && (
                            <Grid item xs={12} md={12}>
                              <TextField
                                fullWidth
                                label="Url del botón"
                                {...getFieldProps('buttonUrl')}
                                error={Boolean(touched.buttonUrl && errors.buttonUrl)}
                                helperText={touched.buttonUrl && errors.buttonUrl}
                              />
                            </Grid>
                          )}
                          {values.buttonType === 'product' && (
                            <TextField select fullWidth {...getFieldProps('productId')}>
                              {products.map((p) => (
                                <MenuItem key={p.id} value={p.id}>
                                  {p.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
              <DialogActions>
                <LoadingButton variant="contained" loading={isSubmitting} type="submit">
                  Guardar
                </LoadingButton>
              </DialogActions>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Dialog>
    </>
  );
}
