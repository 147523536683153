import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, Grid, Typography } from '@mui/material';
import DeptoItem from './DeptoItem';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

PerfilesCard.propTypes = {
  idEmpresa: PropTypes.string
};

export default function PerfilesCard({ idEmpresa }) {
  const [departamentos, setDepartamentos] = useState([]);

  useEffect(() => {
    async function getDeptos() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('departamentos')
        .onSnapshot((queryS) => {
          const perfs = [];
          queryS.forEach((doc) => {
            perfs.push({ id: doc.id, ...doc.data() });
          });
          setDepartamentos(perfs);
        });
    }
    getDeptos();
  }, [idEmpresa]);
  return (
    <Card sx={{ margin: '3%' }}>
      <CardHeader title={<Typography variant="h4">Departamentos</Typography>} />
      <CardContent>
        <Grid container spacing={3}>
          {departamentos.map((depto) => (
            <DeptoItem key={depto.id} depto={depto} idEmpresa={idEmpresa} />
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
