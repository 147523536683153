import React, { useState, useEffect } from 'react';
import {
  Button,
  IconButton,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { firestore } from 'src/contexts/FirebaseContext';
import UserElement from 'src/components/_dashboard/general/UserElement';

AgregarInvitados.propTypes = {
  projectId: PropTypes.string.isRequired,
  idEmpresa: PropTypes.string.isRequired,
  showB: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.func
};

export default function AgregarInvitados({ projectId, idEmpresa, showB = false, open = false, setOpen }) {
  const [creatorid, setCreator] = useState('');
  const [empmembers, setEmpmembers] = useState([]);
  const [pmembers, setPmembers] = useState([]);

  useEffect(() => {
    async function getEmpMembers() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const { membersids } = doc.data();
            setEmpmembers(membersids);
          }
        });
      await firestore
        .collection('proyectosempresa')
        .doc(projectId)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const { membersids, creator } = doc.data();
            setPmembers(membersids);
            setCreator(creator);
          }
        });
    }
    getEmpMembers();
  }, [idEmpresa, projectId]);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  const agregar = async (u) => {
    await firestore
      .collection('proyectosempresa')
      .doc(projectId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const { membersids } = doc.data();
          const newMembersids = [...membersids, u];
          firestore.collection('proyectosempresa').doc(projectId).update({
            membersids: newMembersids
          });
        }
      });
  };

  const quitar = async (u) => {
    await firestore
      .collection('proyectosempresa')
      .doc(projectId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const { membersids } = doc.data();
          const newMembersids = membersids.filter((m) => m !== u);
          firestore.collection('proyectosempresa').doc(projectId).update({
            membersids: newMembersids
          });
        }
      });
  };

  return (
    <>
      {showB && (
        <Button variant="contained" onClick={clickOpen}>
          Agregar Integrantes
        </Button>
      )}
      <Dialog open={open} onClose={clickClose}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton onClick={clickClose}>
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box>
            <List>
              {empmembers
                .filter((u) => u !== creatorid)
                .map((mem) => (
                  <ListItem key={mem}>
                    <ListItemAvatar>
                      <UserElement userid={mem} />
                    </ListItemAvatar>
                    <ListItemText>
                      {pmembers.includes(mem) ? (
                        <Button variant="contained" onClick={() => quitar(mem)}>
                          quitar
                        </Button>
                      ) : (
                        <Button variant="contained" onClick={() => agregar(mem)}>
                          agregar
                        </Button>
                      )}
                    </ListItemText>
                  </ListItem>
                ))}
            </List>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
