import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import instagramFilled from '@iconify/icons-ant-design/instagram-filled';
import phoneFilled from '@iconify/icons-ant-design/phone-filled';
import whatsappFilled from '@iconify/icons-ant-design/whats-app';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Card, /* Grid, */ Avatar, Tooltip, /* Divider, */ Typography, IconButton, Button } from '@mui/material';
import UserCardNav from './UserCardNav';
// utils
// import { fShortenNumber } from '../../../../utils/formatNumber';
//
import SvgIconStyle from '../../../SvgIconStyle';
import AddPerson from '../../entorno/organizacion/personas/AddPerson';
import { firestore } from 'src/contexts/FirebaseContext';

// ----------------------------------------------------------------------

const CardMediaStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  paddingTop: 'calc(100% * 9 / 16)',
  '&:before': {
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
    backdropFilter: 'blur(3px)',
    WebkitBackdropFilter: 'blur(3px)', // Fix on Mobile
    borderTopLeftRadius: theme.shape.borderRadiusMd,
    borderTopRightRadius: theme.shape.borderRadiusMd,
    backgroundColor: alpha(theme.palette.primary.darker, 0.72)
  }
}));

const CoverImgStyle = styled('img')({
  top: 0,
  zIndex: 8,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

// ----------------------------------------------------------------------

/* function InfoItem(number) {
  return (
    <Grid item xs={4}>
      <Typography variant="caption" sx={{ mb: 0.5, color: 'text.secondary', display: 'block' }}>
        Follower
      </Typography>
      <Typography variant="subtitle1">{fShortenNumber(number)}</Typography>
    </Grid>
  );
} */

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
  nav: PropTypes.bool
};

export default function UserCard({ user, nav = false, ...other }) {
  const {
    displayName,
    name,
    cover,
    position,
    avatarUrl,
    PhotoURL,
    /*  follower, totalPost, following, */ nombre,
    avatar
  } = user;
  const [userS, setUserS] = useState({
    linkedinLink: '',
    twitterLink: '',
    instagramLink: '',
    facebookLink: '',
    cellphoneNum: '',
    whatsphoneNum: ''
  });
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    async function getUser() {
      await firestore
        .collection('users')
        .doc(user.id)
        .onSnapshot((doc) => {
          setUserS(doc.data());
        });
    }
    getUser();
  }, [user]);

  const SOCIALS = [
    {
      name: 'Linkedin',
      icon: <Icon icon={linkedinFill} color="#006097" />,
      href: user.linkedinLink || userS?.linkedinLink,
      text: user.linkedinLink || userS?.linkedinLink
    },
    {
      name: 'Twitter',
      icon: <Icon icon={twitterFill} color="#1C9CEA" />,
      href: user.twitterLink || userS?.twitterLink,
      text: user.twitterLink || userS?.twitterLink
    },
    {
      name: 'Instagram',
      icon: <Icon icon={instagramFilled} color="#D7336D" />,
      href: user.instagramLink || userS?.instagramLink,
      text: user.instagramLink || userS?.instagramLink
    },
    {
      name: 'Facebook',
      icon: <Icon icon={facebookFill} color="#1877F2" />,
      href: user.facebookLink || userS?.facebookLink,
      text: user.facebookLink || userS?.facebookLink
    },
    {
      name: 'Telefono',
      icon: <Icon icon={phoneFilled} color="#1877F2" />,
      href: 'tel:' + (user.cellphoneNum || userS?.cellphoneNum),
      text: user.cellphoneNum || userS?.cellphoneNum
    },
    {
      name: 'Whatsapp',
      icon: <Icon icon={whatsappFilled} color="#0DC142" />,
      href: 'https://wa.me/' + (user.whatsphoneNum || userS?.whatsphoneNum),
      text: user.whatsphoneNum || userS?.whatsphoneNum
    }
  ];

  return (
    <Card {...other}>
      <CardMediaStyle>
        <SvgIconStyle
          color="paper"
          src="/static/icons/shape-avatar.svg"
          sx={{
            width: 144,
            height: 62,
            zIndex: 10,
            bottom: -26,
            position: 'absolute'
          }}
        />
        <Avatar
          alt={name || nombre}
          src={avatarUrl || avatar || PhotoURL}
          sx={{
            width: 64,
            height: 64,
            zIndex: 11,
            position: 'absolute',
            transform: 'translateY(-50%)'
          }}
        />
        <CoverImgStyle alt="cover" src={cover || avatar} />
      </CardMediaStyle>
      <Typography variant="subtitle1" align="center" sx={{ mt: 6 }}>
        {displayName || name || nombre}
      </Typography>
      <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
        {position}
      </Typography>
      {userS?.editable && <AddPerson edit userId={user.id} iconB />}
      {nav && (
        <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
          <Button onClick={clickOpen}>Perfil</Button>
        </Typography>
      )}
      <Box sx={{ textAlign: 'center', mt: 2, mb: 2.5 }}>
        {SOCIALS.map((social) => {
          if (Boolean(social.text)) {
            return (
              <Tooltip key={social.name} title={social.name}>
                <IconButton LinkComponent="a" href={social.href} target="_blank">
                  {social.icon}
                </IconButton>
              </Tooltip>
            );
          }
          return <></>;
        })}
      </Box>
      {nav && <UserCardNav open={open} setOpen={setOpen} user={user} />}
      {/* <Divider />

      <Grid container sx={{ py: 3, textAlign: 'center' }}>
        {InfoItem(follower)}
        {InfoItem(following)}
        {InfoItem(totalPost)}
      </Grid> */}
    </Card>
  );
}
