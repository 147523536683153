import React, { useState } from 'react';
import { Button, Dialog, DialogContent, Box, ListItem, ListItemIcon, ListItemText } from '@mui/material';
//
import { Icon } from '@iconify/react';
import file from '@iconify/icons-eva/file-fill';
import fullscreenexit from '@iconify/icons-ant-design/fullscreen-exit';
import fullscreen from '@iconify/icons-ant-design/fullscreen';
import Seccion from './Seccion';
//
import NewDocsForm from './NewDocsForm';
import PropTypes from 'prop-types';

DocView.propTypes = {
  secciones: PropTypes.array,
  cardId: PropTypes.string,
  collection: PropTypes.string,
  listB: PropTypes.bool,
  disabled: PropTypes.bool
};

export default function DocView({
  secciones = [],
  cardId,
  collection = 'kobjsCards',
  listB = false,
  disabled = false
}) {
  const [open, setOpen] = useState(false);
  const [fullS, setFullS] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };
  return (
    <>
      {listB && (
        <ListItem button onClick={clickOpen} sx={{ margin: '2px', color: '#637381' }}>
          <ListItemIcon>
            <Icon icon={file} width={20} height={20} />
          </ListItemIcon>
          <ListItemText>Ver Documento</ListItemText>
        </ListItem>
      )}
      {!listB && (
        <Button onClick={clickOpen} sx={{ margin: '2px', color: '#637381' }}>
          <Icon icon={file} width={20} height={20} /> ver Documento
        </Button>
      )}

      <Dialog open={open} onClose={clickClose} fullScreen={fullS}>
        <DialogContent>
          <NewDocsForm cardId={cardId} edit collection={collection} disabled={disabled} />
          <Button variant="contained" onClick={() => setFullS(!fullS)}>
            <Icon icon={fullS ? fullscreenexit : fullscreen} width={25} height={25} />
          </Button>
          <Box>
            {secciones.map((sec) => (
              <Seccion key={sec.id} content={sec.content} type={sec.type} />
            ))}
            {/* <Markdown children={content} /> */}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
