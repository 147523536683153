import React, { useState } from 'react';
import { ListItem, ListItemText, ListItemIcon, Dialog, DialogContent } from '@mui/material';
import KanbanPerfil from 'src/pages/dashboard/KanbanPerfilHab';
// icon
import { Icon } from '@iconify/react';
import thunder from '@iconify/icons-ant-design/thunderbolt-fill';
//
import PropTypes from 'prop-types';

HabilidadesKanban.propTypes = {
  idEmpresa: PropTypes.string,
  idP: PropTypes.string
};

export default function HabilidadesKanban({ idEmpresa, idP }) {
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
        <ListItemIcon>
          <Icon icon={thunder} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Habilidades</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose}>
        <DialogContent>
          <KanbanPerfil idEmpresa={idEmpresa} idP={idP} />
        </DialogContent>
      </Dialog>
    </>
  );
}
