import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, Box, Typography } from '@mui/material';
import { fPercent } from 'src/utils/formatNumber';
import { firestore } from 'src/contexts/FirebaseContext';
//
import { merge, orderBy } from 'lodash';
import ReactApexChart from 'react-apexcharts';
//
import BaseOptionChart from 'src/components/charts/BaseOptionChart';
import PropTypes from 'prop-types';

const moment = require('moment');

const DAYSN = [0, 1, 2, 3, 4, 5, 6];

KRsChart.propTypes = {
  cardId: PropTypes.string
};

export default function KRsChart({ cardId }) {
  const [data, setData] = useState([]);
  const [CHART_DATA, setChartData] = useState([{ name: 'Desktops', data: [0] }]);
  const [LABELS, setLabels] = useState(['null']);

  useEffect(() => {
    async function getKRsH() {
      await firestore
        .collection('kobjsCards')
        .doc(cardId)
        .collection('krtime')
        .onSnapshot((queryS) => {
          const dat = [];
          queryS.forEach((doc) => {
            const { min, avance, max, date, krid, krtext } = doc.data();
            dat.push({ min, avance, max, date, krid, krtext });
          });
          setData(orderBy(dat, ['date'], ['desc']));
        });
    }
    getKRsH();
  }, [cardId]);

  useEffect(() => {
    const labels = [];
    const krsids = [];
    const krsnames = [];
    const salida = [];
    // ids de los krs
    data.forEach((d) => {
      const { krid, krtext } = d;
      if (!krsids.includes(krid)) {
        krsids.push(krid);
      }
      if (!Boolean(krsnames.find((k) => k.krid === krid))) {
        krsnames.push({ krid, krtext });
      }
    });
    // datos de los krs
    krsids.forEach((id) => {
      const krd = data.filter((k) => k.krid === id);
      const krname = krsnames.find((k) => k.krid === id).krtext;
      const Data = [];
      DAYSN.forEach((n) => {
        const dateCA = moment()
          .startOf('day')
          .subtract(n + 1, 'day')
          .toDate()
          .getTime();
        const dateC = moment().startOf('day').subtract(n, 'day').toDate().getTime();
        const dataf = krd.filter((k) => moment(k.date.toDate()).startOf('day').toDate().getTime() === dateC);
        const datafA = krd.filter((k) => moment(k.date.toDate()).startOf('day').toDate().getTime() === dateCA);
        if (dataf.length !== 0) {
          const { min, avance, max } = dataf[dataf.length - 1];
          const nummin = avance - min;
          const porcent = (nummin / max) * 100;
          Data.push(porcent);
        } else {
          const { min, avance, max } = datafA.length === 0 ? krd[krd.length - 1] : datafA[datafA.length - 1];
          const nmin = min || 0;
          const nmax = max || 100;
          const nummin = avance - nmin;
          const porcent = (nummin / nmax) * 100;
          Data.push(porcent);
        }
      });
      Data.reverse();
      salida.push({ name: krname, data: Data });
    });

    // labels
    DAYSN.forEach((n) => {
      labels.push(moment().startOf('day').subtract(n, 'day').format('DD dddd MM YYYY'));
    });
    labels.reverse();
    setChartData(salida);
    setLabels(labels);
  }, [data]);

  const chartOptions = merge(BaseOptionChart(), {
    xaxis: {
      categories: LABELS
    },
    yaxis: {
      labels: {
        formatter(y) {
          return `${fPercent(y)}`;
        }
      }
    },
    tooltip: { x: { show: false }, marker: { show: false } }
  });

  return (
    <Card>
      <CardHeader title={<Typography variant="h5">Historial Del Progreso del objetivo</Typography>} />
      <CardContent>
        <Box component="div" sx={{ padding: '5px', margin: '5px' }}>
          <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={320} width={1100} />
        </Box>
      </CardContent>
    </Card>
  );
}
