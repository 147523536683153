import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, Avatar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ProfileView from 'src/components/_dashboard/general/PerfilView';
import AltaDepartamento from '../AltaDepartamento';
import { getURLFile } from 'src/utils/storagef';
import PropTypes from 'prop-types';

// ---------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary
}));

const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'left'
});

// ---------------------------------------------------------

DeptoItem.propTypes = {
  depto: PropTypes.object,
  idEmpresa: PropTypes.string
};

export default function DeptoItem({ depto, idEmpresa }) {
  const { id, nombre, descripcion, perfiles, about } = depto;
  const [URLImg, setURLImg] = useState('');
  const ref = `empresas/${idEmpresa}/departamentos/${id}`;

  useEffect(() => {
    async function getImgUrl() {
      await getURLFile(ref).then((url) => {
        setURLImg(url);
      });
    }
    getImgUrl();
  }, [ref]);

  return (
    <Card>
      <CardHeader
        title={nombre}
        action={
          <>
            <AltaDepartamento edit iconB idEmpresa={idEmpresa} iddoc={id} />
          </>
        }
      />
      <CardContent>
        <RowStyle>
          <LabelStyle>Descripcion</LabelStyle>
          <Typography>{descripcion}</Typography>
        </RowStyle>
        <RowStyle>
          <LabelStyle>Sobre el departamento</LabelStyle>
          <Typography>{about}</Typography>
        </RowStyle>
        <RowStyle>
          <LabelStyle>Perfiles</LabelStyle>
          <Typography>
            {perfiles?.map((perf) => (
              <ProfileView key={perf} idEmpresa={idEmpresa} idP={perf} />
            ))}
          </Typography>
        </RowStyle>
        <RowStyle>
          <LabelStyle>Imagen</LabelStyle>
          <Typography>
            <Avatar src={URLImg} />
          </Typography>
        </RowStyle>
      </CardContent>
    </Card>
  );
}
