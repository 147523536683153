import React, { useState } from 'react';
import { ListItem, ListItemText, ListItemIcon, Dialog, DialogContent, Typography } from '@mui/material';
// icon
import { Icon } from '@iconify/react';
import evalI from '@iconify/icons-ant-design/file';
//
import PropTypes from 'prop-types';

EvaluacionesD.propTypes = {
  idEmpresa: PropTypes.string
};

export default function EvaluacionesD({ idEmpresa }) {
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickClose} sx={{ color: '#637381' }}>
        <ListItemIcon>
          <Icon icon={evalI} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Evaluaciones</ListItemText>
      </ListItem>
      <Dialog>
        <DialogContent>
          <Typography variant="h2">Proximamente...</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}
