import { createSlice } from '@reduxjs/toolkit';
// utils
import { firestore } from 'src/contexts/FirebaseContext';
import { paramCase } from 'change-case';

// ----------------------------------------------------------------------

async function getBlogs() {
  const blogs = [];
  await firestore
    .collection('blogs')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        blogs.push(doc.data());
      });
    });
  return blogs;
}

async function getRecentBlogs() {
  const blogs = [];
  await firestore
    .collection('blogs')
    .orderBy('createdAt', 'desc')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        blogs.push(doc.data());
      });
    });
  return blogs;
}

async function fetchBlog(title) {
  const blogs = [];
  await firestore
    .collection('blogs')
    .get()
    .then((queryS) => {
      queryS.forEach((doc) => {
        const blogD = doc.data();
        blogs.push(blogD);
      });
    });
  console.log(blogs);
  return blogs.find((blogD) => paramCase(blogD.title) === title);
}

// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: false,
  posts: [],
  post: null,
  recentPosts: [],
  hasMore: true,
  index: 0,
  step: 11
};

const slice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET POST INFINITE
    getPostsInitial(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    getMorePosts(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getPostSuccess(state, action) {
      state.isLoading = false;
      state.post = action.payload;
    },

    // GET RECENT POST
    getRecentPostsSuccess(state, action) {
      state.isLoading = false;
      state.recentPosts = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMorePosts } = slice.actions;

// ----------------------------------------------------------------------

export function getAllPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const blogs = await getBlogs();
      dispatch(slice.actions.getPostsSuccess(blogs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPostsInitial(index, step) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(step);
      const blogs = await getRecentBlogs();
      const blogsInitial = blogs;
      const results = blogsInitial.length;
      const maxLength = blogs.length;
      dispatch(slice.actions.getPostsInitial(blogsInitial));

      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPost(title) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const blog = await fetchBlog(paramCase(title));
      console.log(blog);
      dispatch(slice.actions.getPostSuccess(blog));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getRecentPosts(title) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const titles = title.replaceAll('-', ' ');
      const blogs = await getBlogs();
      dispatch(slice.actions.getRecentPostsSuccess(blogs.filter((blog) => blog.title.toLocaleLowerCase() !== titles)));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
