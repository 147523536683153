import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Typography,
  Box,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';
import MenuOrg from './MemuOrg';
import { CTAShow } from 'src/components/_dashboard/ctas';
// icon
import { Icon } from '@iconify/react';
import teamI from '@iconify/icons-ant-design/team-outline';
//
import usePlan, { getCTAState, isPremium } from 'src/hooks/usePlan';
import PropTypes from 'prop-types';

Organizacion.propTypes = {
  empresa: PropTypes.object
};

export default function Organizacion({ empresa }) {
  const { id, orgMod, expiresOrg } = empresa;
  const modulo = 'organizacion';
  const [open, setOpen] = useState(false);
  const { CTAState } = usePlan();
  const allowOrg = isPremium(orgMod, expiresOrg);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  const etapaCTA = getCTAState(modulo, CTAState);

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#506070' }}>
        <ListItemIcon>
          <Icon icon={teamI} width={25} height={25} />
        </ListItemIcon>
        <ListItemText>Organizacion</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <Box sx={{ width: '85vw' }} />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid xs={12} lg={4}>
              <MenuOrg idEmpresa={id} empresa={empresa} />
            </Grid>
            <Grid xs={12} lg={8}>
              <Card sx={{ margin: '3%' }}>
                <CardContent>
                  {allowOrg && <Typography variant="h4">Proximammente: Esquema organizacional</Typography>}
                  {!allowOrg && <CTAShow etapaCTA={etapaCTA} modulo={modulo} />}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
