import React, { useState } from 'react';
import { Button, Dialog, DialogContent, Box } from '@mui/material';
import ProductDetails from 'src/pages/dashboard/EcommerceProductDetails';
import PropTypes from 'prop-types';

ButtonProduct.propTypes = {
  productId: PropTypes.string,
  buttonText: PropTypes.string
};

export default function ButtonProduct({ productId, buttonText }) {
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={clickOpen} variant="contained">
        {buttonText}
      </Button>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <Box sx={{ width: '80vw' }} />
        <DialogContent>
          <ProductDetails idP={productId} />
        </DialogContent>
      </Dialog>
    </>
  );
}
