import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Card, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import NewSprintForm from '../NewSprintForm';
import Personas from './Personas';
import KanbanSprintD from './KanbanSprintD';
import { PATH_DASHBOARD } from 'src/routes/paths';
// icon
import { Icon } from '@iconify/react';
import arrow from '@iconify/icons-eva/diagonal-arrow-right-up-fill';
//
import PropTypes from 'prop-types';

SPMenu.propTypes = {
  info: PropTypes.object,
  idEmpresa: PropTypes.string
};

export default function SPMenu({ info, idEmpresa }) {
  const { id } = info;
  const [openE, setOpenE] = useState(false);
  return (
    <Card>
      <List>
        <ListItem
          component={NavLink}
          to={`${PATH_DASHBOARD.entorno.empresa}${idEmpresa}/sprints/${id}`}
          sx={{ color: '#637381' }}
        >
          <ListItemIcon>
            <Icon icon={arrow} width={23} height={23} />
          </ListItemIcon>
          <ListItemText>Sprint</ListItemText>
        </ListItem>
        <KanbanSprintD idEmpresa={idEmpresa} spid={id} />
        <NewSprintForm edit showB listB idEmpresa={idEmpresa} spid={id} open={openE} setOpen={setOpenE} />
        <Personas sprint={info} idEmpresa={idEmpresa} spid={id} />
      </List>
    </Card>
  );
}
