import React, { useState, useCallback } from 'react';
import { Icon } from '@iconify/react';
import { isString } from 'lodash';
import closeFill from '@iconify/icons-eva/close-fill';
import { useDropzone } from 'react-dropzone';
import roundAddPhotoAlternate from '@iconify/icons-ic/round-add-photo-alternate';
// material
import { Stack, Paper, Button, Tooltip, OutlinedInput, Box } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useDispatch } from 'src/redux/store';
import { getBoard } from 'src/redux/slices/kanbansprint';
//
import { MIconButton } from '../../@material-extend';
import { varFadeInRight } from '../../animate';
import MyAvatar from '../../MyAvatar';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { firestore, storage as st } from 'src/contexts/FirebaseContext';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  width: 64,
  height: 64,
  fontSize: 24,
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  border: `dashed 1px ${theme.palette.divider}`,
  '&:hover': { opacity: 0.72 }
}));

UploadFile.propTypes = {
  cardId: PropTypes.string,
  setComment: PropTypes.func,
  setMsgType: PropTypes.func
};

function UploadFile({ cardId, setComment, setMsgType }) {
  const [files, setFiles] = useState([]);

  const handleRemove = (file) => {
    const filteredItems = files.filter((_file) => _file !== file);
    setFiles(filteredItems);
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) => {
          const refF = st.ref().child(`${cardId}/coments/${file.name}`);
          refF.put(file).then(() => {
            refF.getDownloadURL().then((url) => {
              setComment(url);
              setMsgType('image');
            });
          });
          return Object.assign(file, {
            preview: URL.createObjectURL(file)
          });
        })
      );
    },
    [setFiles, cardId, setComment, setMsgType]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop
  });

  return (
    <>
      {files.map((file) => {
        const { name, preview } = file;
        const key = isString(file) ? file : name;

        return (
          <Box
            key={key}
            {...varFadeInRight}
            sx={{
              p: 0,
              m: 0.5,
              width: 64,
              height: 64,
              borderRadius: 1,
              overflow: 'hidden',
              position: 'relative'
            }}
          >
            <Paper
              variant="outlined"
              component="img"
              src={isString(file) ? file : preview}
              sx={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute' }}
            />
            <Box sx={{ top: 6, right: 6, position: 'absolute' }}>
              <MIconButton
                size="small"
                onClick={() => handleRemove(file)}
                sx={{
                  p: '2px',
                  color: 'common.white',
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                  '&:hover': {
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48)
                  }
                }}
              >
                <Icon icon={closeFill} />
              </MIconButton>
            </Box>
          </Box>
        );
      })}

      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 })
        }}
      >
        <input {...getInputProps()} />

        <Box component={Icon} icon={roundAddPhotoAlternate} sx={{ color: 'text.secondary' }} />
      </DropZoneStyle>
    </>
  );
}

KanbanTaskCommentInput.propTypes = {
  cardId: PropTypes.string.isRequired,
  idEmpresa: PropTypes.string.isRequired,
  spid: PropTypes.string.isRequired
};

export default function KanbanTaskCommentInput({ cardId, idEmpresa, spid }) {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');
  const [msgType, setMsgType] = useState('text');

  const handleComentar = async () => {
    const newComment = {
      id: user.id,
      avatar: user.photoURL,
      name: user.displayName,
      createdAt: new Date(),
      messageType: msgType,
      message: comment
    };
    setComment('');
    await firestore
      .collection('kobjsCards')
      .doc(cardId)
      .get()
      .then((doc) => {
        const { comments } = doc.data();
        const newComments = [...comments, newComment];
        firestore.collection('kobjsCards').doc(cardId).update({ comments: newComments });
      });
    dispatch(getBoard(idEmpresa, spid));
  };
  return (
    <Stack direction="row" spacing={2} sx={{ py: 3, px: 2.5 }}>
      <MyAvatar />

      <Paper variant="outlined" sx={{ p: 1, flexGrow: 1 }}>
        <OutlinedInput
          fullWidth
          multiline
          row={2}
          placeholder="Type a message"
          sx={{ '& fieldset': { display: 'none' } }}
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
            setMsgType('text');
          }}
        />

        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={0.5}>
            <Tooltip title="Add photo">
              <UploadFile cardId={cardId} setComment={setComment} setMsgType={setMsgType} />
            </Tooltip>
            {/* <Tooltip title="Attachment">
              <MIconButton size="small">
                <Icon icon={attach2Fill} width={20} height={20} />
              </MIconButton>
            </Tooltip> */}
          </Stack>

          <Button variant="contained" onClick={handleComentar}>
            Comentar
          </Button>
        </Stack>
      </Paper>
    </Stack>
  );
}
