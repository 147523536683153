import React from 'react';
import { Container, Box } from '@mui/material';
import Markdown from 'src/components/Markdown';
import PropTypes from 'prop-types';

Seccion.propTypes = {
  type: PropTypes.oneOf(['video', 'ifrmae', 'markdown']).isRequired,
  content: PropTypes.string.isRequired
};

export default function Seccion({ type, content }) {
  const components = {
    markdown: <Markdown children={content} />,
    video: (
      <video controls width="100%" height="auto">
        <source src={content} />
      </video>
    ),
    img: <Box component="img" src={content} />,
    iframe: <iframe title="frame" src={content} width="100%" height="500px" style={{ border: 'none' }} />
  };

  return <Container>{components[type]}</Container>;
}
