import { storage as st } from 'src/contexts/FirebaseContext';

export async function getURLFile(ref) {
  const fileRef = st.ref().child(ref);
  return new Promise((resolve) => {
    fileRef
      .getDownloadURL()
      .then((url) => {
        resolve(url);
      })
      .catch((err) => {
        console.error(err);
        resolve('');
      });
  });
}

export async function uploadFile(file, ref) {
  const fileRef = st.ref().child(ref);
  return new Promise((resolve, reject) => {
    fileRef
      .put(file)
      .then(() => {
        fileRef.getDownloadURL().then((url) => {
          resolve(url);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export async function deleteFile(ref) {
  const fileRef = st.ref().child(ref);
  return new Promise((resolve, reject) => {
    fileRef
      .delete()
      .then(() => {
        resolve('borrado');
      })
      .catch((err) => {
        reject(err);
      });
  });
}
