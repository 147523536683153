import React from 'react';
import { Card, List } from '@mui/material';
import { EvaluacionesD } from './nav';
import PropTypes from 'prop-types';

MenuRH.propTypes = {
  idEmpresa: PropTypes.string
};

export default function MenuRH({ idEmpresa }) {
  return (
    <Card>
      <List>
        <EvaluacionesD idEmpresa={idEmpresa} />
      </List>
    </Card>
  );
}
