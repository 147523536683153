import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// import shareFill from '@iconify/icons-eva/share-fill';
// import printerFill from '@iconify/icons-eva/printer-fill';
// import archiveFill from '@iconify/icons-eva/archive-fill';
// import downloadFill from '@iconify/icons-eva/download-fill';
// import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import eyefill from '@iconify/icons-eva/eye-fill';
// import linkout from '@iconify/icons-eva/external-link-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import settings from '@iconify/icons-eva/settings-fill';
// material
import { useTheme } from '@mui/material/styles';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
// material
import { MIconButton } from 'src/components/@material-extend';
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  Menu,
  MenuItem,
  Typography,
  Stack,
  Tab,
  Tabs
} from '@mui/material';
//
import Label from '../../Label';
import Scrollbar from '../../Scrollbar';
import { firestore } from 'src/contexts/FirebaseContext';
import useAuth from 'src/hooks/useAuth';
import { KanbanTaskDetails, KanbanControlPanelT } from 'src/components/_dashboard/kobjs';
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

MoreMenuButton.propTypes = {
  openD: PropTypes.func,
  openCP: PropTypes.func
};

function MoreMenuButton({ openD, openCP }) {
  const menuRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <>
        <MIconButton ref={menuRef} size="large" onClick={handleOpen}>
          <Icon icon={moreVerticalFill} width={20} height={20} />
        </MIconButton>
      </>

      <Menu
        open={open}
        anchorEl={menuRef.current}
        onClose={handleClose}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            openD();
            handleClose();
          }}
        >
          <Icon icon={eyefill} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Propiedades
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            openCP();
            handleClose();
          }}
        >
          <Icon icon={settings} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            panel de control
          </Typography>
        </MenuItem>
        {/* <MenuItem>
          <Icon icon={printerFill} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Print
          </Typography>
        </MenuItem>
        <MenuItem>
          <Icon icon={shareFill} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Share
          </Typography>
        </MenuItem>
        <MenuItem>
          <Icon icon={archiveFill} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Archive
          </Typography>
        </MenuItem>

        <Divider />
        <MenuItem sx={{ color: 'error.main' }}>
          <Icon icon={trash2Outline} width={20} height={20} />
          <Typography variant="body2" sx={{ ml: 2 }}>
            Delete
          </Typography>
        </MenuItem> */}
      </Menu>
    </>
  );
}

CardObj.propTypes = {
  row: PropTypes.object
};

function CardObj({ row }) {
  const theme = useTheme();
  const [openD, setOpenD] = useState(false);
  const [openCP, setOpenCP] = useState(false);

  return (
    <>
      <TableRow key={row.id}>
        <TableCell>
          <MoreMenuButton project={row} openD={() => setOpenD(true)} openCP={() => setOpenCP(true)} />
        </TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={(row.prioritize === 'medium' && 'warning') || (row.prioritize === 'hight' && 'error') || 'success'}
          >
            {row.prioritize}
          </Label>
        </TableCell>
        <TableCell>{row.completed ? <>Si</> : <>No</>}</TableCell>
      </TableRow>
      <KanbanTaskDetails
        card={row}
        cardId={row.id}
        isOpen={openD}
        onClose={() => setOpenD(false)}
        projectId={row.pid}
      />

      <KanbanControlPanelT card={row} cardId={row.id} projectId={row.pid} open={openCP} setOpen={setOpenCP} />
    </>
  );
}

// ----------------------------------------------------------------------
ObjetivosH.propTypes = {
  pid: PropTypes.string
};

export default function ObjetivosH({ pid }) {
  const [objs, setObjs] = useState([]);
  const [objsR, setObjsR] = useState([]);
  const [objsH, setObjsH] = useState([]);
  const [projectos, setProjectos] = useState([]);
  const [cat, setCat] = useState('fav');
  const { user } = useAuth();

  const OPTIONS = [
    { name: 'haciendo', value: 'haciendo' },
    { name: 'Favoritos', value: 'fav' },
    { name: 'Mis objetivos', value: 'resp' }
  ];

  const getTitle = () => {
    switch (cat) {
      case 'haciendo':
        return 'Objetivos que se estan haciendo';
      case 'fav':
        return 'Objetivos favoritos';
      case 'resp':
        return 'Objetivos donde eres responsable';
      default:
        return '';
    }
  };

  const getPrioNumber = (prioridad) => {
    switch (prioridad) {
      case 'undefined':
        return 4;
      case 'low':
        return 3;
      case 'medium':
        return 2;
      case 'hight':
        return 1;
      default:
        return 5;
    }
  };

  useEffect(() => {
    async function getObjs() {
      await firestore
        .collection('kobjsCards')
        .where('pid', '==', pid)
        .onSnapshot((queryS) => {
          const objss = [];
          queryS.forEach((doc) => {
            const { likes, prioritize } = doc.data();
            if (likes !== undefined) {
              if (likes.includes(user.id)) {
                objss.push({ id: doc.id, ...doc.data(), pnum: getPrioNumber(prioritize) });
              }
            }
          });
          setObjs(orderBy(objss, ['pnum'], ['asc']));
        });
      await firestore
        .collection('kobjsCards')
        .where('pid', '==', pid)
        .onSnapshot((queryS) => {
          const objss = [];
          queryS.forEach((doc) => {
            const { responsable, prioritize } = doc.data();
            if (responsable !== undefined) {
              if (responsable.find((f) => f.id === user.id)) {
                objss.push({ id: doc.id, ...doc.data(), pnum: getPrioNumber(prioritize) });
              }
            }
          });
          setObjsR(orderBy(objss, ['pnum'], ['asc']));
        });
      await firestore
        .collection('kobjsCards')
        .where('pid', '==', pid)
        .where('haciendo', '==', true)
        .onSnapshot((queryS) => {
          const objss = [];
          queryS.forEach((doc) => {
            const { prioritize } = doc.data();
            objss.push({ id: doc.id, ...doc.data(), pnum: getPrioNumber(prioritize) });
          });
          setObjsH(orderBy(objss, ['pnum'], ['asc']));
        });
      await firestore.collection('proyectosempresa').onSnapshot((queryS) => {
        const projs = [];
        queryS.forEach((doc) => {
          projs.push({ id: doc.id, ...doc.data() });
        });
        setProjectos(projs);
      });
    }
    getObjs();
  }, [user, pid]);

  const handleChangeTab = (event, newValue) => {
    setCat(newValue);
  };

  return (
    <Card>
      <Stack spacing={5} sx={{ marginLeft: '2%' }}>
        <Tabs value={cat} scrollButtons="auto" variant="scrollable" allowScrollButtonsMobile onChange={handleChangeTab}>
          {OPTIONS.map((opt) => (
            <Tab disableRipple key={opt.value} label={opt.name} value={opt.value} />
          ))}
        </Tabs>
      </Stack>
      <CardHeader title={getTitle()} sx={{ mb: 3 }} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 500 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Nombre</TableCell>
                <TableCell>Prioridad</TableCell>
                <TableCell>Completado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cat === 'fav' && objs.map((row) => <CardObj row={row} key={row.id} projectos={projectos} />)}
              {cat === 'resp' && objsR.map((row) => <CardObj row={row} key={row.id} projectos={projectos} />)}
              {cat === 'haciendo' && objsH.map((row) => <CardObj row={row} key={row.id} projectos={projectos} />)}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          to="/bob"
          size="small"
          color="inherit"
          component={RouterLink}
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          Asistente
        </Button>
      </Box>
    </Card>
  );
}
