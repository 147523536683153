import React, { useState, useEffect } from 'react';
import {
  Button,
  AppBar,
  Toolbar,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  FormHelperText,
  Typography,
  TextField,
  Tab,
  Tabs,
  Divider,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { Close, Add } from '@mui/icons-material';
import { QuillEditor } from 'src/components/editor';
import FileList from 'src/components/_dashboard/general/FileList';
import EditSeccion from './EditSeccion';
import { useSnackbar } from 'notistack';
// icons
import imagenI from '@iconify/icons-ant-design/file-image-fill';
import MarkdownI from '@iconify/icons-ant-design/file-text-fill';
import VideoI from '@iconify/icons-ant-design/video-camera';
import FrameI from '@iconify/icons-ant-design/global-outline';
import SaveI from '@iconify/icons-ant-design/save-filled';
import fullscreenexit from '@iconify/icons-ant-design/fullscreen-exit';
import fullscreen from '@iconify/icons-ant-design/fullscreen';
//
import { Icon } from '@iconify/react';
import addfile from '@iconify/icons-eva/plus-circle-fill';
import editfill from '@iconify/icons-eva/edit-fill';
import { v4 as uuidv4 } from 'uuid';
//
import { firestore } from 'src/contexts/FirebaseContext';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

NewDocsForm.propTypes = {
  collection: PropTypes.string,
  cardId: PropTypes.string,
  edit: PropTypes.bool,
  listB: PropTypes.bool,
  disabled: PropTypes.bool
};

export default function NewDocsForm({ collection = 'kobjsCards', cardId, edit, listB = false, disabled = false }) {
  const [open, setOpen] = useState(false);
  const [openD, setOpenD] = useState(false);
  const [cambiado, setCambiado] = useState(false);
  const [newSection, setNewSection] = useState(false);
  const [fullS, setFullS] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const tipos = [
    { value: 'video', name: 'Video', icon: VideoI },
    { value: 'img', name: 'Imagen', icon: imagenI },
    { value: 'iframe', name: 'Web', icon: FrameI },
    { value: 'markdown', name: 'Texto enriquecido', icon: MarkdownI }
  ];

  const clickOpen = () => {
    setOpen(true);
  };

  const clickCloseCon = () => {
    if (cambiado) {
      setOpenD(true);
    } else {
      setOpen(false);
    }
  };

  const clickClose = () => {
    setOpenD(false);
    setOpen(false);
  };

  const clickCloseD = () => {
    setOpenD(false);
  };

  const validation = Yup.object().shape({
    content: Yup.string().max(1048487),
    secciones: Yup.array(Yup.object().shape({ id: Yup.string().min(36), content: Yup.string().max(1048487) }))
      .min(1)
      .required('Agrega al menos una Seccion')
  });

  const formik = useFormik({
    initialValues: {
      type: 'markdown',
      content: '',
      secciones: []
    },
    validationSchema: validation,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (edit) {
          setCambiado(false);
          await firestore
            .collection(collection)
            .doc(cardId)
            .collection('docs')
            .doc('doc')
            .update({ ...values })
            .then(() => {
              setOpen(false);
            });
        } else {
          setCambiado(false);
          await firestore
            .collection(collection)
            .doc(cardId)
            .collection('docs')
            .doc('doc')
            .set({ ...values })
            .then(() => {
              setOpen(false);
            });
        }
        setSubmitting(false);
      } catch (err) {
        console.log(err);
        setSubmitting(false);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  useEffect(() => {
    async function getDoc() {
      await firestore
        .collection(collection)
        .doc(cardId)
        .collection('docs')
        .doc('doc')
        .get()
        .then((doc) => {
          const { content, secciones } = doc.data();
          setFieldValue('content', content);
          setFieldValue('secciones', secciones || []);
        });
    }
    if (edit) {
      getDoc();
    }
    // eslint-disable-next-line
  }, [cardId, edit]);

  const addSection = () => {
    const sectionsTemp = values.secciones;
    sectionsTemp.push({ type: values.type, content: values.content, id: uuidv4() });
    setFieldValue('secciones', sectionsTemp);
    setCambiado(true);
    setFieldValue('content', '');
  };

  const components = {
    markdown: (
      <>
        <LabelStyle>Texto enriquecido</LabelStyle>
        <QuillEditor
          id="post-content"
          value={values.content}
          onChange={(val) => {
            setCambiado(true);
            setFieldValue('content', val);
          }}
          error={Boolean(touched.content && errors.content)}
        />
      </>
    ),
    video: (
      <>
        <TextField label="url del video" fullWidth {...getFieldProps('content')} />
        <FileList
          direction="kobjs/files"
          idBucket={cardId}
          onSelect={(url) => {
            setFieldValue('content', url);
            enqueueSnackbar('Seleccionado', { variant: 'success' });
          }}
          fileType="video/mp4"
        />
      </>
    ),
    img: (
      <>
        <TextField label="url de la imagen" fullWidth {...getFieldProps('content')} />
        <FileList
          direction="kobjs/files"
          idBucket={cardId}
          onSelect={(url) => {
            setFieldValue('content', url);
            enqueueSnackbar('Seleccionado', { variant: 'success' });
          }}
          fileType="image/*"
        />
      </>
    ),
    iframe: (
      <>
        <TextField label="url del iframe" fullWidth {...getFieldProps('content')} />
      </>
    )
  };

  return (
    <>
      {listB && (
        <ListItem button onClick={clickOpen} sx={{ margin: '2px', color: '#637381' }} disabled={disabled}>
          <ListItemIcon>
            <Icon icon={edit ? editfill : addfile} width={20} height={20} />
          </ListItemIcon>
          <ListItemText>{edit ? 'Editar documento' : 'Agregar documento'}</ListItemText>
        </ListItem>
      )}
      {!listB && (
        <Button onClick={clickOpen} sx={{ margin: '2px', color: '#637381' }} disabled={disabled}>
          <Icon icon={edit ? editfill : addfile} width={20} height={20} />
          {edit ? 'Editar documento' : 'Agregar documento'}
        </Button>
      )}
      <Dialog open={open} onClose={clickCloseCon} maxWidth="lg" fullScreen={fullS}>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <AppBar position={fullS ? 'fixed' : 'relative'}>
              <Toolbar>
                <IconButton onClick={clickCloseCon}>
                  <Close />
                </IconButton>
                <LoadingButton type="submit" loading={isSubmitting} variant="contained">
                  Guardar
                </LoadingButton>
                <Button variant="contained" onClick={() => setFullS(!fullS)}>
                  <Icon icon={fullS ? fullscreenexit : fullscreen} width={25} height={25} />
                </Button>
              </Toolbar>
            </AppBar>
            <DialogContent sx={{ marginTop: '34px' }}>
              <Box component="div" sx={{ marginBottom: '2%' }}>
                {values.secciones.map((secc, i) => (
                  <>
                    <EditSeccion
                      key={i}
                      index={i}
                      formik={formik}
                      content={secc.content}
                      type={secc.type}
                      showB
                      idS={secc.id}
                      direction="kobjs/files"
                      idBucket={cardId}
                      setCambiado={setCambiado}
                    />
                    {values.secciones.length - i !== 1 && <Divider sx={{ marginTop: '2%', marginBottom: '2%' }} />}
                  </>
                ))}
              </Box>
              <Divider />
              <Box component="div" sx={{ marginTop: '2%' }}>
                {newSection && (
                  <>
                    <Box component="div" sx={{ display: 'flex', float: 'right' }}>
                      <Button onClick={() => setNewSection(false)} sx={{ margin: '1%', color: '#637381' }}>
                        <Close /> Cerrar
                      </Button>
                      <Button onClick={addSection} sx={{ margin: '1%', color: '#637381' }}>
                        <Icon icon={SaveI} width={32} height={32} />
                        Guardar
                      </Button>
                    </Box>
                    <Typography varinat="h3">Nueva Seccion</Typography>
                    <FormHelperText>{touched.secciones && errors.secciones}</FormHelperText>
                    {touched.content && errors.content && (
                      <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                        {touched.content && errors.content + ' you have' + values.content.length + ' characters'}
                      </FormHelperText>
                    )}

                    <Tabs value={values.type} onChange={(e, newVal) => setFieldValue('type', newVal)}>
                      {tipos.map((t) => (
                        <Tab
                          key={t.name}
                          value={t.value}
                          label={t.name}
                          icon={<Icon icon={t.icon} width={32} height={32} />}
                        />
                      ))}
                    </Tabs>
                    {components[values.type]}
                  </>
                )}
                {!newSection && (
                  <Button
                    onClick={() => setNewSection(true)}
                    sx={edit ? { margin: '1%' } : { margin: '1%', color: '#637381' }}
                  >
                    <Add /> Agregar sección
                  </Button>
                )}
              </Box>
            </DialogContent>
            <Dialog open={openD} onClose={clickCloseD}>
              <AppBar position="relative">
                <Toolbar>
                  <IconButton onClick={clickCloseD}>
                    <Close />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <DialogContent>
                <Box>
                  <Typography variant="h3">No se han guardado cambios</Typography>
                </Box>
                <DialogActions>
                  console.log(o);
                  <Button variant="contained" sx={{ margin: '2%' }} onClick={clickCloseD}>
                    Cancelar
                  </Button>
                  <Button variant="contained" sx={{ margin: '2%' }} onClick={clickClose}>
                    Salir sin Guardar
                  </Button>
                  <Button
                    disabled={disabled}
                    variant="contained"
                    sx={{ margin: '2%' }}
                    onClick={() => {
                      clickCloseD();
                      handleSubmit();
                    }}
                  >
                    Guardar y Salir
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>
          </Form>
        </FormikProvider>
      </Dialog>
    </>
  );
}
