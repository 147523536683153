import React from 'react';
import { Card, List } from '@mui/material';
import { DialogDeptos } from './departamentos';
import { DialogPersons } from './personas';
import { PermisosD } from './permisos';
import { CTANav } from 'src/components/_dashboard/ctas';
import { ChooseCTA } from 'src/components/_dashboard/asistentemain/other';
import PropTypes from 'prop-types';
import usePermissions, { tienePermiso } from 'src/hooks/usePermissions';
import { isPremium } from 'src/hooks/usePlan';
import useAuth from 'src/hooks/useAuth';

MenuOrg.propTypes = {
  idEmpresa: PropTypes.string,
  empresa: PropTypes.object
};

export default function MenuOrg({ idEmpresa, empresa }) {
  const { orgMod, expiresOrg } = empresa;
  const { user } = useAuth();
  const { permisos } = usePermissions();
  const { creator } = empresa;
  const allowOrg = isPremium(orgMod, expiresOrg);
  // permisos
  const accesPerm = tienePermiso('gestion permiso', user.id, permisos);
  const gestOrg = tienePermiso('gestionar organizacion', user.id, permisos);
  //
  return (
    <>
      <Card sx={{ margin: '3%' }}>
        <List>
          {(gestOrg || creator === user.id) && allowOrg && <DialogDeptos idEmpresa={idEmpresa} />}
          {(gestOrg || creator === user.id) && <DialogPersons idEmpresa={idEmpresa} empresa={empresa} />}
          {(accesPerm || creator === user.id) && <PermisosD idEmpresa={idEmpresa} empresa={empresa} />}
        </List>
      </Card>
      {(user.email === 'kintaroponce.uni@gmail.com' || user.email === 'ocompudoc@gmail.com') && (
        <Card sx={{ margin: '3%' }}>
          <List>
            <CTANav />
            <ChooseCTA modulo="organizacion" />
          </List>
        </Card>
      )}
    </>
  );
}
