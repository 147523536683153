import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  Stack,
  IconButton,
  Dialog,
  DialogContent,
  Button,
  ListItem,
  List
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AltaPerfil from '../AltaPerfil';
import UserAvatar from 'src/components/_dashboard/general/UserAvatar';
import UserElement from 'src/components/_dashboard/general/UserElement';
import ProfileView from 'src/components/_dashboard/general/PerfilView';
import ProfileItemV from 'src/components/_dashboard/general/PerfilItemV';
import { getURLFile } from 'src/utils/storagef';
// icon
import { Icon } from '@iconify/react';
import addI from '@iconify/icons-eva/plus-fill';
//
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary
}));

const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'left'
});

// ----------------------------------------------------------------

PerfilItem.propTypes = {
  perfil: PropTypes.object,
  idEmpresa: PropTypes.string
};

export default function PerfilItem({ perfil, idEmpresa }) {
  const { id, nombre, descripcion, miembros, about, jefep } = perfil;
  const jp = jefep || [];
  const [URLImg, setURLImg] = useState('');
  const ref = `empresas/${idEmpresa}/perfiles/${id}`;

  const [openD, setOpenD] = useState(false);
  const [openDJ, setOpenDJ] = useState(false);
  const [membersemp, setEmpMembers] = useState([]);

  const [perfiles, setPerfiles] = useState([]);

  useEffect(() => {
    async function getPerfiles() {
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('perfiles')
        .onSnapshot((queryS) => {
          const perfs = [];
          queryS.forEach((doc) => {
            if (doc.id !== id) {
              perfs.push(doc.id);
            }
          });
          setPerfiles(perfs);
        });
    }
    getPerfiles();
  }, [idEmpresa, id]);

  useEffect(() => {
    async function getMembers() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .onSnapshot((doc) => {
          const { membersids } = doc.data();
          setEmpMembers(membersids);
        });
    }
    getMembers();
  }, [idEmpresa]);

  const clickOpenDJ = () => {
    setOpenDJ(true);
  };

  const clickCloseDJ = () => {
    setOpenDJ(false);
  };

  const clickOpenD = () => {
    setOpenD(true);
  };

  const clickCloseD = () => {
    setOpenD(false);
  };

  useEffect(() => {
    async function getImgUrl() {
      await getURLFile(ref).then((url) => {
        setURLImg(url);
      });
    }
    getImgUrl();
  }, [ref]);

  const addChief = async (userid) => {
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(id)
      .update({ jefep: [...jp, userid] });
  };

  const removeChief = async (userid) => {
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(id)
      .update({ jefep: jp.filter((userids) => userids !== userid) });
  };

  const addMember = async (userid) => {
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(id)
      .update({ miembros: [...miembros, userid] });
  };

  const removeMember = async (userid) => {
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(id)
      .update({ miembros: miembros.filter((userids) => userids !== userid) });
  };

  return (
    <Card>
      <CardHeader
        title={nombre}
        action={
          <>
            <AltaPerfil idEmpresa={idEmpresa} iddoc={id} edit iconB />
          </>
        }
      />
      <CardContent>
        <RowStyle>
          <LabelStyle>Descripcion</LabelStyle>
          <Typography>{descripcion}</Typography>
        </RowStyle>
        <RowStyle>
          <LabelStyle>Sobre el Perfil</LabelStyle>
          <Typography>{about}</Typography>
        </RowStyle>
        <RowStyle>
          <LabelStyle>Jefe</LabelStyle>
          <Stack direction="row" flexWrap="wrap" alignItems="center">
            {jp?.map((miem) => (
              <ProfileView key={miem} idEmpresa={idEmpresa} idP={miem} removePerf={removeChief} />
            ))}
            <IconButton onClick={clickOpenDJ} disabled={jp.length !== 0}>
              <Icon icon={addI} width={23} height={23} />
            </IconButton>
          </Stack>
        </RowStyle>
        <RowStyle>
          <LabelStyle>Asignados</LabelStyle>
          <Stack direction="row" flexWrap="wrap" alignItems="center">
            {miembros.map((miem) => (
              <UserAvatar key={miem} userid={miem} removeUser={removeMember} />
            ))}
            <IconButton onClick={clickOpenD}>
              <Icon icon={addI} width={23} height={23} />
            </IconButton>
          </Stack>
        </RowStyle>
        <RowStyle>
          <LabelStyle>Imagen</LabelStyle>
          <Typography>
            <Avatar src={URLImg} />
          </Typography>
        </RowStyle>
        <Dialog open={openD} onClose={clickCloseD}>
          <DialogContent>
            <List>
              {membersemp.map((mam) => (
                <ListItem key={mam}>
                  <UserElement userid={mam} />
                  <Button
                    onClick={() => {
                      if (miembros.includes(mam)) {
                        removeMember(mam);
                      } else {
                        addMember(mam);
                      }
                    }}
                  >
                    {miembros.includes(mam) ? 'quitar' : 'agregar'}
                  </Button>
                </ListItem>
              ))}
            </List>
          </DialogContent>
        </Dialog>
        <Dialog open={openDJ} onClose={clickCloseDJ}>
          <DialogContent>
            <List>
              {perfiles.map((mam) => (
                <ListItem key={mam}>
                  <ProfileItemV idEmpresa={idEmpresa} idP={mam} />
                  <Button
                    onClick={() => {
                      if (jp.includes(mam)) {
                        removeChief(mam);
                      } else {
                        addChief(mam);
                      }
                    }}
                  >
                    {jp.includes(mam) ? 'quitar' : 'agregar'}
                  </Button>
                </ListItem>
              ))}
            </List>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
}
