import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material';
import { Close, Add } from '@mui/icons-material';
import { AltaProyecto } from 'src/components/_dashboard/entorno';
import PropTypes from 'prop-types';
import { firestore } from 'src/contexts/FirebaseContext';

AddProyecto.propTypes = {
  idEmpresa: PropTypes.string,
  cardId: PropTypes.string,
  proyectosA: PropTypes.array
};

export default function AddProyecto({ cardId, idEmpresa, proyectosA = [] }) {
  const [open, setOpen] = useState(false);
  const [openAP, setOpenAP] = useState(false);
  const [proyectos, setProyectos] = useState([]);

  useEffect(() => {
    async function getProyects() {
      await firestore
        .collection('proyectosempresa')
        .where('idEmpresa', '==', idEmpresa)
        .onSnapshot((queryS) => {
          const datas = [];
          queryS.forEach((doc) => {
            datas.push({ id: doc.id, ...doc.data() });
          });
          setProyectos(datas);
        });
    }
    getProyects();
  }, [idEmpresa]);

  const addProyect = async (idP) => {
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('kanbanCards')
      .doc(cardId)
      .update({ proyectosA: [...proyectosA, idP] });
  };

  const removeProyect = async (idP) => {
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('kanbanCards')
      .doc(cardId)
      .update({ proyectosA: proyectosA.filter((p) => p !== idP) });
  };

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={clickOpen} sx={{ margin: '2px', color: '#637381' }}>
        Alinear proyectos
      </Button>
      <Dialog open={open} onClose={clickClose}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton onClick={clickClose}>
              <Close />
            </IconButton>
            <AltaProyecto idEmpresa={idEmpresa} open={openAP} setOpen={setOpenAP} onCreateNew={addProyect} showB />
          </Toolbar>
        </AppBar>
        <DialogContent>
          <List>
            {proyectos.map((p) => (
              <ListItem key={p.id}>
                <ListItemText
                  primary={
                    <>
                      <Typography variant="subtitle2">{p.nombre}</Typography>
                      {proyectosA.includes(p.id) ? (
                        <>
                          <IconButton variant="contained" onClick={() => removeProyect(p.id)}>
                            <Close />
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton variant="contained" onClick={() => addProyect(p.id)}>
                            <Add />
                          </IconButton>
                        </>
                      )}
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}
