import React from 'react';
import { Dialog, DialogContent, Grid, Box } from '@mui/material';
import EquipoCard from './EquipoCard';
import MenuEquipo from './MenuEquipo';
import PropTypes from 'prop-types';

DialogEquipoItem.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  equipo: PropTypes.object,
  idEmpresa: PropTypes.string
};

export default function DialogEquipoItem({ open = false, setOpen, equipo, idEmpresa }) {
  const clickClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={clickClose} maxWidth="lg">
      <Box sx={{ width: '70vw' }} />
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <MenuEquipo equipo={equipo} idEmpresa={idEmpresa} />
          </Grid>
          <Grid item xs={12} lg={8}>
            <EquipoCard equipo={equipo} idEmpresa={idEmpresa} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
