import React, { useState, useEffect } from 'react';
import {
  ListItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogContent,
  Typography,
  IconButton,
  List,
  Button,
  Stack
} from '@mui/material';
import { styled } from '@mui/styles';
// icon
import { Icon } from '@iconify/react';
import persons from '@iconify/icons-ant-design/team-outline';
import UserAvatar from 'src/components/_dashboard/general/UserAvatar';
import UserElement from 'src/components/_dashboard/general/UserElement';
import ProfileView from 'src/components/_dashboard/general/PerfilView';
import addI from '@iconify/icons-eva/plus-fill';
import { firestore } from 'src/contexts/FirebaseContext';
//
import PropTypes from 'prop-types';

// -------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary
}));

const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'left'
});

// -------------------------------------------------------------

PersonasD.propTypes = {
  perfil: PropTypes.object,
  idEmpresa: PropTypes.string
};

export default function PersonasD({ perfil, idEmpresa }) {
  const { id, miembros, jefep } = perfil;
  const jp = jefep || [];
  const [open, setOpen] = useState(false);
  const [openD, setOpenD] = useState(false);
  const [openDJ, setOpenDJ] = useState(false);
  const [membersemp, setEmpMembers] = useState([]);

  const [perfiles, setPerfiles] = useState([]);

  useEffect(() => {
    async function getPerfiles() {
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('perfiles')
        .onSnapshot((queryS) => {
          const perfs = [];
          queryS.forEach((doc) => {
            if (doc.id !== id) {
              perfs.push(doc.id);
            }
          });
          setPerfiles(perfs);
        });
    }
    getPerfiles();
  }, [idEmpresa, id]);

  useEffect(() => {
    async function getMembers() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .onSnapshot((doc) => {
          const { membersids } = doc.data();
          setEmpMembers(membersids);
        });
    }
    getMembers();
  }, [idEmpresa]);

  const clickOpenDJ = () => {
    setOpenDJ(true);
  };

  const clickCloseDJ = () => {
    setOpenDJ(false);
  };

  const clickOpenD = () => {
    setOpenD(true);
  };

  const clickCloseD = () => {
    setOpenD(false);
  };

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  const addChief = async (userid) => {
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(id)
      .update({ jefep: [...jp, userid] });
  };

  const removeChief = async (userid) => {
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(id)
      .update({ jepep: jp.filter((userids) => userids !== userid) });
  };

  const addMember = async (userid) => {
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(id)
      .update({ miembros: [...miembros, userid] });
  };

  const removeMember = async (userid) => {
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(id)
      .update({ miembros: miembros.filter((userids) => userids !== userid) });
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
        <ListItemIcon>
          <Icon icon={persons} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Personas</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose}>
        <DialogContent>
          <RowStyle>
            <LabelStyle>Jefe</LabelStyle>
            <Stack direction="row" flexWrap="wrap" alignItems="center">
              {jp?.map((miem) => (
                <ProfileView key={miem} idEmpresa={idEmpresa} idP={miem} removePerf={removeChief} />
              ))}
              <IconButton onClick={clickOpenDJ} disabled={jp.length !== 0}>
                <Icon icon={addI} width={23} height={23} />
              </IconButton>
            </Stack>
          </RowStyle>
          <RowStyle>
            <LabelStyle>Asignados</LabelStyle>
            <Stack direction="row" flexWrap="wrap" alignItems="center">
              {miembros.map((miem) => (
                <UserAvatar key={miem} userid={miem} removeUser={removeMember} />
              ))}
              <IconButton onClick={clickOpenD}>
                <Icon icon={addI} width={23} height={23} />
              </IconButton>
            </Stack>
          </RowStyle>
          <Dialog open={openD} onClose={clickCloseD}>
            <DialogContent>
              <List>
                {membersemp.map((mam) => (
                  <ListItem key={mam}>
                    <UserElement userid={mam} />
                    <Button
                      onClick={() => {
                        if (miembros.includes(mam)) {
                          removeMember(mam);
                        } else {
                          addMember(mam);
                        }
                      }}
                    >
                      {miembros.includes(mam) ? 'quitar' : 'agregar'}
                    </Button>
                  </ListItem>
                ))}
              </List>
            </DialogContent>
          </Dialog>
          <Dialog open={openDJ} onClose={clickCloseDJ}>
            <DialogContent>
              <List>
                {perfiles.map((mam) => (
                  <ListItem key={mam}>
                    <ProfileView idEmpresa={idEmpresa} idP={mam} />
                    <Button
                      onClick={() => {
                        if (jp.includes(mam)) {
                          removeChief(mam);
                        } else {
                          addChief(mam);
                        }
                      }}
                    >
                      {jp.includes(mam) ? 'quitar' : 'agregar'}
                    </Button>
                  </ListItem>
                ))}
              </List>
            </DialogContent>
          </Dialog>
        </DialogContent>
      </Dialog>
    </>
  );
}
