import React, { useState } from 'react';
import { Card, List } from '@mui/material';
import { NewSprintForm } from 'src/components/_dashboard/sprints';
import PropTypes from 'prop-types';

SprintMenu.propTypes = {
  empid: PropTypes.string
};

export default function SprintMenu({ empid }) {
  const [openSF, setOpenSF] = useState(false);

  return (
    <Card>
      <List>
        <NewSprintForm idEmpresa={empid} open={openSF} setOpen={setOpenSF} showB listB />
      </List>
    </Card>
  );
}
