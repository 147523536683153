import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

DeptoView.propTypes = {
  idEmpresa: PropTypes.string,
  idD: PropTypes.string
};

export default function DeptoView({ idEmpresa, idD }) {
  const [nombre, setNombre] = useState('');

  useEffect(() => {
    async function getName() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('departamentos')
        .doc(idD)
        .onSnapshot((doc) => {
          setNombre(doc.data().nombre);
        });
    }
    getName();
  }, [idEmpresa, idD]);

  return <Box>{nombre}</Box>;
}
