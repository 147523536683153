import React, { useState, useRef } from 'react';
import { Icon } from '@iconify/react';
import attach2Fill from '@iconify/icons-eva/attach-2-fill';
import roundAddPhotoAlternate from '@iconify/icons-ic/round-add-photo-alternate';
import videoI from '@iconify/icons-ant-design/video-camera-add-outlined';
import embed from '@iconify/icons-ant-design/fund-filled';
// material
import { Box, Card, TextField, IconButton, Menu } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { firestore, storage as st } from 'src/contexts/FirebaseContext';
import { v1 as uuidv1 } from 'uuid';
import useAuth from 'src/hooks/useAuth';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

PostInput.propTypes = {
  collection: PropTypes.string,
  doc: PropTypes.string
};

export default function PostInput({ collection, doc }) {
  const { user } = useAuth();
  const [posting, setPosting] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');
  // menu
  const [openMenu, setOpenMenu] = useState(false);
  const [iframe, setIframe] = useState('');
  const menuRef = useRef(null);
  // file
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const fileInputRefimg = useRef(null);
  const fileInputRefvid = useRef(null);

  const handleAttach = () => {
    fileInputRef.current.click();
  };

  const handleAttachimg = () => {
    fileInputRefimg.current.click();
  };

  const handleAttachvid = () => {
    fileInputRefvid.current.click();
  };

  const uploadFile = async (idP) => {
    let link = '';
    if (file !== null) {
      const refF = st.ref().child('posts/' + collection + '/doc/' + doc + '/posts/' + idP + '/' + file.name);
      await refF.put(file);
      link = refF.getDownloadURL();
    }
    return link;
  };

  const handlePost = async () => {
    setPosting(true);
    const idc = uuidv1();
    const author = {
      id: user.id,
      name: user.displayName,
      avatarUrl: user.photoURL
    };
    await firestore
      .collection(collection)
      .doc(doc)
      .collection('posts')
      .doc(idc)
      .set({
        author,
        message,
        createdAt: new Date(),
        type,
        media: type.includes('iframe') ? iframe : await uploadFile(idc),
        comments: [],
        isLiked: false,
        personLikes: []
      });
    setPosting(false);
    setMessage('');
    setFile(null);
  };

  return (
    <Card sx={{ p: 3 }}>
      <TextField
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        multiline
        fullWidth
        rows={4}
        placeholder="comparte que estas pensando aqui..."
        sx={{
          '& fieldset': {
            borderWidth: `1px !important`,
            borderColor: (theme) => `${theme.palette.grey[500_32]} !important`
          }
        }}
      />

      <Box
        sx={{
          mt: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <IconButton size="small" onClick={handleAttach} sx={{ mr: 1 }}>
            <Icon icon={roundAddPhotoAlternate} width={24} height={24} />
          </IconButton>
          <IconButton size="small" onClick={handleAttachvid} sx={{ mr: 1 }}>
            <Icon icon={videoI} width={24} height={24} />
          </IconButton>
          <IconButton size="small" onClick={handleAttachimg}>
            <Icon icon={attach2Fill} width={24} height={24} />
          </IconButton>
          <IconButton onClick={() => setOpenMenu(true)} ref={menuRef}>
            <Icon icon={embed} width={24} height={24} />
          </IconButton>
          <Menu open={openMenu} onClose={() => setOpenMenu(false)} anchorEl={menuRef.current}>
            <TextField
              label="embebido"
              value={iframe}
              onChange={(e) => {
                const val = e.target.value;
                if (val === '') {
                  setType('');
                } else {
                  setType('iframe');
                }
                setIframe(val);
              }}
            />
          </Menu>
          {file !== null && (
            <Box component="img" sx={{ width: 'auto', height: '30px' }} src={URL.createObjectURL(file)} />
          )}
        </Box>
        <LoadingButton loading={posting} variant="contained" onClick={handlePost}>
          Publicar
        </LoadingButton>
      </Box>

      <input
        accept="image/*"
        ref={fileInputRef}
        onChange={(e) => {
          const filec = e.target.files[0];
          setType(filec.type);
          console.log(filec);
          setFile(filec);
        }}
        type="file"
        style={{ display: 'none' }}
      />
      <input
        accept="video/*"
        ref={fileInputRefvid}
        onChange={(e) => {
          const filec = e.target.files[0];
          setType(filec.type);
          console.log(filec);
          setFile(filec);
        }}
        type="file"
        style={{ display: 'none' }}
      />
      <input
        accept="*/*"
        ref={fileInputRefimg}
        onChange={(e) => {
          const filec = e.target.files[0];
          setType(filec.type);
          console.log(filec);
          setFile(filec);
        }}
        type="file"
        style={{ display: 'none' }}
      />
    </Card>
  );
}
