import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  Step,
  Stepper,
  Container,
  StepLabel,
  StepConnector,
  Dialog,
  DialogContent,
  Button,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';
// icons
import { Icon } from '@iconify/react';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
import planI from '@iconify/icons-eva/calendar-fill';
// components
import { firestore } from 'src/contexts/FirebaseContext';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';

// pasos
import { StepZero, StepOne, StepTwo, StepThree } from 'src/components/asistenteproyecto';

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool
};

function QontoStepIcon({ active, completed }) {
  return (
    <Box
      sx={{
        zIndex: 9,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: active ? 'primary.main' : 'text.disabled'
      }}
    >
      {completed ? (
        <Box component={Icon} icon={checkmarkFill} sx={{ zIndex: 1, width: 20, height: 20, color: 'primary.main' }} />
      ) : (
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor'
          }}
        />
      )}
    </Box>
  );
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  top: 10,
  left: 'calc(-50% + 20px)',
  right: 'calc(50% + 20px)',
  '& .MuiStepConnector-line': {
    borderTopWidth: 2,
    borderColor: theme.palette.divider
  },
  '&.Mui-active, &.Mui-completed': {
    '& .MuiStepConnector-line': {
      borderColor: theme.palette.primary.main
    }
  }
}));

// -----------------------------------------------

const STEPS = ['Introducción', 'Vision', 'Valores', 'Puntos de enfoque'];

AsistenteProyecto.propTypes = {
  proyectId: PropTypes.string,
  listB: PropTypes.bool
};

export default function AsistenteProyecto({ proyectId, listB }) {
  const [open, setOpen] = useState(false);
  const [idEmpresaS, setIdEmpresa] = useState('');
  const [activeStep, setActStep] = useState(0);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    async function getIdEmpresa() {
      await firestore
        .collection('proyectosempresa')
        .doc(proyectId)
        .onSnapshot((doc) => {
          const { idEmpresa } = doc.data();
          setIdEmpresa(idEmpresa);
        });
    }
    getIdEmpresa();
  }, [proyectId]);

  const next = () => {
    setActStep(activeStep + 1);
  };

  const back = () => {
    if (activeStep > 0) {
      setActStep(activeStep - 1);
    }
  };

  const validation = Yup.object().shape({
    vision: Yup.string().required('Vision es requerida')
  });

  const formik = useFormik({
    initialValues: {
      vision: '',
      valores: []
    },
    validationSchema: validation,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        console.log(values);
        await firestore.collection('proyectosempresa').doc(proyectId).update({
          vision: values.vision,
          valores: values.valores
        });
        if (activeStep === 2) {
          next();
        }
      } catch (err) {
        console.error(err);
        setSubmitting(false);
      }
      setSubmitting(false);
    }
  });

  const { handleSubmit, setFieldValue } = formik;

  useEffect(() => {
    async function getData() {
      await firestore
        .collection('proyectosempresa')
        .doc(proyectId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const { vision, valores } = doc.data();
            setFieldValue('vision', vision);
            setFieldValue('valores', valores);
          } else {
            console.log('No such document!');
          }
        });
    }
    getData();
    // eslint-disable-next-line
  }, [proyectId]);

  return (
    <>
      {listB && (
        <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
          <ListItemIcon>
            <Icon icon={planI} width={25} height={25} />
          </ListItemIcon>
          <ListItemText>Plan Estrategico</ListItemText>
        </ListItem>
      )}
      {!listB && (
        <Button onClick={clickOpen} sx={{ margin: '1%' }}>
          <Icon icon={planI} width={25} height={25} />
          Plan Estrategico
        </Button>
      )}

      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <DialogContent>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                  {STEPS.map((label) => (
                    <Step key={label}>
                      <StepLabel
                        StepIconComponent={QontoStepIcon}
                        sx={{
                          '& .MuiStepLabel-label': {
                            typography: 'subtitle2',
                            color: 'text.disabled'
                          }
                        }}
                      >
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid item xs={12}>
                <FormikProvider value={formik}>
                  <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    {activeStep === 0 && <StepZero next={next} />}
                    {activeStep === 1 && <StepOne formik={formik} back={back} next={next} />}
                    {activeStep === 2 && <StepTwo formik={formik} back={back} />}
                    {activeStep === 3 && <StepThree pid={proyectId} back={back} idEmpresa={idEmpresaS} />}
                  </Form>
                </FormikProvider>
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
      </Dialog>
    </>
  );
}
