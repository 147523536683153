import React from 'react';
import PropTypes from 'prop-types';

CTAVideo.propTypes = {
  src: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

export default function CTAVideo({ src, width, height }) {
  return <video controls="controls" src={src} width={width || '100%'} height={height || 'auto'} />;
}
