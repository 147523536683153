import React, { useState } from 'react';
import { ListItem, ListItemText, ListItemIcon, Dialog, DialogContent } from '@mui/material';
// icon
import { Icon } from '@iconify/react';
import home from '@iconify/icons-ant-design/home-outline';
//
import PermissionItem from './PermissionItem';
import PropTypes from 'prop-types';

EmpresaPermisos.propTypes = {
  idEmpresa: PropTypes.string,
  empresa: PropTypes.object
};

export default function EmpresaPermisos({ idEmpresa, empresa }) {
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
        <ListItemIcon>
          <Icon icon={home} width={23} height={23} />
        </ListItemIcon>
        <ListItemText>Permisos de la Empresa</ListItemText>
      </ListItem>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <DialogContent>
          <PermissionItem
            idEmpresa={idEmpresa}
            empresa={empresa}
            permiso="gestion permiso"
            altName="Gestión de Permisos"
          />
          <PermissionItem
            idEmpresa={idEmpresa}
            empresa={empresa}
            permiso="module manage"
            altName="Gestión de Modulos y plan"
          />
          <PermissionItem idEmpresa={idEmpresa} empresa={empresa} permiso="editar empresa" altName="Editar empresa" />
          <PermissionItem
            idEmpresa={idEmpresa}
            empresa={empresa}
            permiso="gestionar plan estrategico"
            altName="Gestionar plan estratégico"
          />
          <PermissionItem
            idEmpresa={idEmpresa}
            empresa={empresa}
            permiso="gestionar organizacion"
            altName="Gestionar organización"
          />
          <PermissionItem idEmpresa={idEmpresa} empresa={empresa} permiso="ver kanban" altName="Ver Kanban" />
          <PermissionItem idEmpresa={idEmpresa} empresa={empresa} permiso="editar kanban" altName="Editar Kanban" />
          <PermissionItem idEmpresa={idEmpresa} empresa={empresa} permiso="eliminar kanban" altName="Eliminar Kanban" />
        </DialogContent>
      </Dialog>
    </>
  );
}
