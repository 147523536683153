import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import empresasReducer from './slices/empresas';
import proyectosReducer from './slices/proyectos';
import userReducer from './slices/user';
import usercrmReducer from './slices/usercrm';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import sprintscalendarReducer from './slices/sprintscalendar';
import objscalendarReducer from './slices/objscalendar';
import kanbanReducer from './slices/kanban';
import kobjsReducer from './slices/kobjs';
import kanbansprintReducer from './slices/kanbansprint';
import kanbanempresaReducer from './slices/kanbanempresa';
import kanbanperfilReducer from './slices/kanbanperfil';
import kanbanperfilhabReducer from './slices/kanbanperfilhab';
import kanbanperfilcompReducer from './slices/kanbanperfilcomp';
import kanbanentregablesReducer from './slices/kanbanentregables';
import kanbanphReducer from './slices/kanbanph';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  kanbansprint: kanbansprintReducer,
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  empresas: empresasReducer,
  proyectos: proyectosReducer,
  kobjs: kobjsReducer,
  user: userReducer,
  usercrm: usercrmReducer,
  calendar: calendarReducer,
  sprintscalendar: sprintscalendarReducer,
  objscalendar: objscalendarReducer,
  kanban: kanbanReducer,
  kanbanempresa: kanbanempresaReducer,
  kanbanperfil: kanbanperfilReducer,
  kanbanperfilhab: kanbanperfilhabReducer,
  kanbanperfilcomp: kanbanperfilcompReducer,
  kanbanentregables: kanbanentregablesReducer,
  kanbanph: kanbanphReducer,
  product: persistReducer(productPersistConfig, productReducer)
});

export { rootPersistConfig, rootReducer };
