import React from 'react';
import { ListItem, ListItemText, Typography, Button, ListItemIcon } from '@mui/material';
import { Circle } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

SprintItem.propTypes = {
  idEmpresa: PropTypes.string,
  cardId: PropTypes.string,
  info: PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string,
    objetivos: PropTypes.array
  })
};

export default function SprintItem({ idEmpresa, cardId, info }) {
  const { id, nombre, objetivos } = info;
  const { enqueueSnackbar } = useSnackbar();

  const addObj = async () => {
    const newArr = objetivos;
    const isAdded = objetivos.includes(cardId);
    console.log(id, cardId, idEmpresa);
    if (isAdded) {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .doc(id)
        .update({ objetivos: newArr.filter((o) => o !== cardId) });
    } else {
      newArr.push(cardId);
      await firestore.collection('empresas').doc(idEmpresa).collection('sprints').doc(id).update({ objetivos: newArr });
    }

    if (isAdded) {
      enqueueSnackbar('Removido', { variant: 'success' });
    } else {
      enqueueSnackbar('Agregado', { variant: 'success' });
    }
  };

  return (
    <ListItem>
      <ListItemIcon>
        <Circle />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="body1" sx={{ margin: '3px', display: 'block' }}>
          {nombre}
        </Typography>
        <Button onClick={addObj} variant="contained">
          {objetivos.includes(cardId) ? <>Remover</> : <>Agregar</>}
        </Button>
      </ListItemText>
    </ListItem>
  );
}
