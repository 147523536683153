import FullCalendar from '@fullcalendar/react'; // => request placed at the top
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import interactionPlugin from '@fullcalendar/interaction';
import { useSnackbar } from 'notistack';
import { useState, useRef, useEffect } from 'react';
// material
import { useTheme } from '@mui/material/styles';
import { Card, Container, useMediaQuery } from '@mui/material';
import { SprintNav } from 'src/components/_dashboard/sprints';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import {
  // getEvents,
  getEventsByArray,
  openModal,
  closeModal,
  updateEvent,
  selectEvent,
  selectRange
} from '../../redux/slices/sprintscalendar';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
// import { DialogAnimate } from '../../components/animate';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { CalendarStyle, CalendarToolbar } from '../../components/_dashboard/sprintscalendar';
import { firestore } from 'src/contexts/FirebaseContext';
import useAuth from 'src/hooks/useAuth';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

const COLORS = {
  creado: '#1890FF',
  completado: '#00AB55',
  'en proceso': '#FFC107',
  detenido: '#FF4842',
  archivado: '#04297A'
};

// ----------------------------------------------------------------------

const selectedEventSelector = (state) => {
  const { events, selectedEventId } = state.sprintscalendar;
  if (selectedEventId) {
    return events.find((_event) => _event.id === selectedEventId);
  }
  return null;
};

SprintsCalendar.propTypes = {
  idEmpresa: PropTypes.string.isRequired
};

export default function SprintsCalendar({ idEmpresa }) {
  const { user } = useAuth();
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const calendarRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [date, setDate] = useState(new Date());
  const [view, setView] = useState(isMobile ? 'listWeek' : 'dayGridMonth');
  const selectedEvent = useSelector(selectedEventSelector);
  const { events, isOpenModal /* selectedRange */ } = useSelector((state) => state.sprintscalendar);

  useEffect(() => {
    async function getSprints() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .onSnapshot((queryS) => {
          const sprints = [];
          queryS.forEach((doc) => {
            const {
              fechaE,
              fechaF,
              nombre,
              status,
              objetivo,
              allDay,
              scrumMaster,
              productOwner,
              team,
              coach,
              involucrados,
              creator,
              publico
            } = doc.data();
            if (
              scrumMaster?.includes(user.id) ||
              productOwner?.includes(user.id) ||
              team?.includes(user.id) ||
              coach?.includes(user.id) ||
              involucrados?.includes(user.id) ||
              creator === user.id ||
              publico
            ) {
              sprints.push({
                id: doc.id,
                start: fechaE.toDate(),
                end: fechaF.toDate(),
                title: nombre,
                description: objetivo,
                allDay,
                textColor: COLORS[status],
                nombre,
                objetivo,
                fechaE,
                fechaF,
                ...doc.data()
              });
            }
          });
          dispatch(getEventsByArray(sprints));
        });
    }
    getSprints();
    // dispatch(getEvents());
  }, [dispatch, idEmpresa, user]);

  useEffect(() => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const newView = isMobile ? 'listWeek' : 'dayGridMonth';
      calendarApi.changeView(newView);
      setView(newView);
    }
  }, [isMobile]);

  const handleClickToday = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleChangeView = (newView) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const handleSelectRange = (arg) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.unselect();
    }
    dispatch(selectRange(arg.start, arg.end));
  };

  const handleSelectEvent = (arg) => {
    dispatch(selectEvent(arg.event.id));
  };

  const handleResizeEvent = async ({ event }) => {
    try {
      dispatch(
        updateEvent(event.id, {
          allDay: event.allDay,
          start: event.start,
          end: event.end
        })
      );
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .doc(event.id)
        .update({ fechaE: event.start, fechaF: event.end, allDay: event.allDay });
      enqueueSnackbar('Update event success', { variant: 'success' });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDropEvent = async ({ event }) => {
    try {
      dispatch(
        updateEvent(event.id, {
          allDay: event.allDay,
          start: event.start,
          end: event.end
        })
      );
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .doc(event.id)
        .update({ fechaE: event.start, fechaF: event.end, allDay: event.allDay });
      enqueueSnackbar('Update event success', {
        variant: 'success'
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenModal = () => {
    dispatch(openModal());
  };
  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <Container maxWidth={themeStretch ? false : 'xl'}>
      <HeaderBreadcrumbs heading="Calendario" links={[{ name: 'Calendario de Sprints' }]} />

      <Card>
        <CalendarStyle>
          <CalendarToolbar
            date={date}
            view={view}
            onNextDate={handleClickDateNext}
            onPrevDate={handleClickDatePrev}
            onToday={handleClickToday}
            onChangeView={handleChangeView}
          />
          <FullCalendar
            weekends
            editable
            droppable
            selectable
            events={events}
            ref={calendarRef}
            rerenderDelay={10}
            initialDate={date}
            initialView={view}
            dayMaxEventRows={3}
            eventDisplay="block"
            headerToolbar={false}
            allDayMaintainDuration
            eventResizableFromStart
            select={handleSelectRange}
            eventDrop={handleDropEvent}
            eventClick={handleSelectEvent}
            eventResize={handleResizeEvent}
            height={isMobile ? 'auto' : 720}
            plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
          />
        </CalendarStyle>
      </Card>

      {selectedEvent && (
        <SprintNav
          idEmpresa={idEmpresa}
          info={selectedEvent}
          open={isOpenModal}
          setOpen={() => {
            if (isOpenModal) {
              handleCloseModal();
            } else {
              handleOpenModal();
            }
          }}
        />
      )}
      {/* <DialogAnimate open={isOpenModal} onClose={handleCloseModal}>
        <DialogTitle>{selectedEvent ? 'Edit Event' : 'Add Event'}</DialogTitle>

        <CalendarForm event={selectedEvent} range={selectedRange} onCancel={handleCloseModal} />
      </DialogAnimate> */}
    </Container>
  );
}
