import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import attach2Fill from '@iconify/icons-eva/attach-2-fill';
import roundAddPhotoAlternate from '@iconify/icons-ic/round-add-photo-alternate';
// material
import { Box, Card, TextField, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { firestore, storage as st } from 'src/contexts/FirebaseContext';
import { v1 as uuidv1 } from 'uuid';
import { useDispatch } from 'src/redux/store';
import { getPosts } from 'src/redux/slices/user';
import useAuth from 'src/hooks/useAuth';
import { createNotification, generateNotification } from 'src/utils/notifications';

// ----------------------------------------------------------------------

export default function ProfilePostInput() {
  const { id } = useParams();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [posting, setPosting] = useState(false);
  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleAttach = () => {
    fileInputRef.current.click();
  };

  const uploadFile = async (idP) => {
    let link = '';
    if (file !== null) {
      const refF = st.ref().child('users/' + user.id + '/posts/' + idP + '/' + file.name);
      await refF.put(file);
      link = refF.getDownloadURL();
    }
    return link;
  };

  const handlePost = async () => {
    setPosting(true);
    const idc = uuidv1();
    const author = {
      id: user.id,
      name: user.displayName,
      avatarUrl: user.photoURL
    };
    await firestore
      .collection('users')
      .doc(id || user.id)
      .collection('posts')
      .doc(idc)
      .set({
        author,
        message,
        createdAt: new Date(),
        media: await uploadFile(idc),
        comments: [],
        isLiked: false,
        personLikes: []
      });
    dispatch(getPosts(id || user.id));
    setPosting(false);
    setMessage('');
    setFile(null);
    if (user.id !== id) {
      const noti = generateNotification(
        user.displayName + ' a publicado en tu muro',
        '',
        user.avatar,
        'avatar',
        id,
        '/dashboard/user/profile'
      );
      createNotification(noti);
    }
  };

  return (
    <Card sx={{ p: 3 }}>
      <TextField
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        multiline
        fullWidth
        rows={4}
        placeholder="comparte que estas pensando aqui..."
        sx={{
          '& fieldset': {
            borderWidth: `1px !important`,
            borderColor: (theme) => `${theme.palette.grey[500_32]} !important`
          }
        }}
      />

      <Box
        sx={{
          mt: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <IconButton size="small" onClick={handleAttach} sx={{ mr: 1 }}>
            <Icon icon={roundAddPhotoAlternate} width={24} height={24} />
          </IconButton>
          <IconButton size="small" onClick={handleAttach}>
            <Icon icon={attach2Fill} width={24} height={24} />
          </IconButton>
          {file !== null && (
            <Box component="img" sx={{ width: 'auto', height: '30px' }} src={URL.createObjectURL(file)} />
          )}
        </Box>
        <LoadingButton loading={posting} variant="contained" onClick={handlePost}>
          Publicar
        </LoadingButton>
      </Box>

      <input
        ref={fileInputRef}
        onChange={(e) => {
          console.log(e.target.files[0]);
          setFile(e.target.files[0]);
        }}
        type="file"
        style={{ display: 'none' }}
      />
    </Card>
  );
}
