import { createSlice } from '@reduxjs/toolkit';
// utils
import { firestore } from 'src/contexts/FirebaseContext';

// ----------------------------------------------------------------------

async function getBlogs() {
  const blogs = [];
  await firestore
    .collection('proyectosempresa')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        blogs.push(doc.data());
      });
    });
  return blogs;
}

async function getRecentBlogs() {
  const blogs = [];
  await firestore
    .collection('proyectosempresa')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        blogs.push(doc.data());
      });
    });
  return blogs;
}

async function fetchProject(id) {
  const projects = [];
  await firestore
    .collection('proyectosempresa')
    .get()
    .then((queryS) => {
      queryS.forEach((doc) => {
        const blogD = doc.data();
        projects.push({ id: doc.id, ...blogD });
      });
    });
  return projects.find((project) => project.id === id);
}

// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: false,
  posts: [],
  proyecto: null,
  recentPosts: [],
  hasMore: true,
  index: 0,
  step: 11
};

const slice = createSlice({
  name: 'proyectos',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET POST INFINITE
    getPostsInitial(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    getMorePosts(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getPostSuccess(state, action) {
      state.isLoading = false;
      state.proyecto = action.payload;
    },

    // GET RECENT POST
    getRecentPostsSuccess(state, action) {
      state.isLoading = false;
      state.recentPosts = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMorePosts } = slice.actions;

// ----------------------------------------------------------------------

export function getAllEmpresas() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const blogs = await getBlogs();
      dispatch(slice.actions.getPostsSuccess(blogs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEmpresasInitial(index, step) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(step);
      const blogs = await getRecentBlogs();
      const blogsInitial = blogs;
      const results = blogsInitial.length;
      const maxLength = blogs.length;
      dispatch(slice.actions.getPostsInitial(blogsInitial));

      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProyecto(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const project = await fetchProject(id);
      dispatch(slice.actions.getPostSuccess(project));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getRecentEmpresas(title) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const titles = title.replaceAll('-', ' ');
      const blogs = await getBlogs();
      dispatch(slice.actions.getRecentPostsSuccess(blogs.filter((blog) => blog.nombre.toLocaleLowerCase() !== titles)));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
