import React, { useRef, useState, useCallback, useEffect } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
// material
import { Box, Card, Typography, CardContent, IconButton, Grid } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { getURLFile, uploadFile } from 'src/utils/storagef';
import { CarouselControlsArrowsBasic2 } from '../../carousel';
import { varFadeIn, MotionInView } from '../../animate';
import ValueNavD from '../navs/ValueNavD';

// ----------------------------------------------------------------------

const casaIMG = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIMAAACDCAIAAAAh/z8xAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAF
QklEQVR4Xu3d309bdRjH8YNi1gGDsUFB3OISFzFzm0hcXEy88NKrcaHZEmcM0+xS419iYYEQD6wh
B8qPAQsLTrNFp4ZlRAKz2RpoSqggv9pTjrW0pZS2x4smyh4IrL++53Pi87p8nqZpeNPTHvqDonA0
JjEAL9ABMwiXQMElUHAJFFwCBZdAwSVQcAkUXAIFl0DBJVBwCRRcAgWXQMElUHAJFFwCBZdAwSVQ
cAkUXAIFl0DBJVBwCRRcAgWXQMElUHAJFFwCBZdAYe4SPnXdp67TqTkV04FJrPkC9oHRNTUgSVKt
tera5aYa63F6IVMpMuMnWfyq9s23SjT23y0vsVi+vv6ptfrYjkuZjPmOTn5Vs3X27MwgSVI0FrN1
9vhVbefQXExWIqAFbZ09kegmXUhSJLpp6+wJaEG6MAkzldCCIZus7JkhLRLdtMmKFgzRhRmYpoQW
DLXIykY4ShfP2ghHW8wZwxwlgqFwi6wEQ2G62EtGF8ZhghLpX/OMfrLpGAfegaCgl8j6aPOcRzMc
0CUi0c0WWVGzfTqkasGWfR/hoeCWSJ8iZJ0hTdWCu08+MIGWiMZirbIjL2dqflVrlR34MRBLxGJb
rbJj1a/SRbZW/Wqr7IjFtugCCVyJrfj2jZt9ecyQtupXb9zs24pv0wUMrBJb8e02e//Sqo8u8mFp
1ddm749vJ+gCA1CJ+Haizd6/sLRCF/mzsLTS3g0aA6VEIpFs7y5shjTvwnJ7d38ikaQLo0GUSCSS
Hcqgd2GZLgrDu7DcoQwmkym6MJTxJZLJVIcy6JlfpItC8swvyr1DUDEMLpFK6XLvkOAMaTMer9w7
lErpdGEQI0ukUnqXY2TG46ULUWY83i7HiK5DxDCshK7rXY6Rp+45uhDrqXvOPjCKEMOYErou2QdG
Dc+Q5nS57QOjhrcwoISuS8rwmNPlpgvjOF1uZXiMTsUyoIQyPDbldNGp0aacrr7bd+lUINEl+m7f
BcyQNjH9xMAYQkvcunNvYvoJnSKZmH5y6849OhVCXIkfHjwcn3xMp3jGJx/f/+URnRaeoBI+//r3
P43TKarvfvw1i1fOcySoxM+PJukImK5L47+JvvsKKjE7Z9iJdHZmPfN0VGCCSoi/s+dI1f6iowIT
VMJ04sJfZ8X9JMtLxcVNH35w/kx9eVkp3T23UDjidLmHxu7TBR7cEtevfvT6a6/SaYbKy0rff7ex
8mi53DNMd2BAj051NdbcM/zrbP3p6uOVdAoGtIS1Ks+f06qrtdIRGNASumT0H6mFAy3xP4T7iL3b
H3+uBP8++LzkaEX5qZN1dArPTCUePJz83TVLp7s0nH2j+fIlOoXHRycUXAIFl0DBJVBwCRRcAgWX
QMElUHAJFFwCBZdAwSVQcAkUXAIFl0DBJVBwCRRcAgVoiaKiIjrKTd6vMO8ElbAcOkRH+8r7x3Iz
vcKSw4fpqMAElThRh/7GL+LkKzV0VGCCSlxoOEdH+8r7wSTTK7z4dmY3OHeC3mVzsfHcxJTTu5jT
t9U0X7kkSSLePlN/+lTj+TN0WmCC7hOSJDVfaaqsOEKneKqrjn32sYjehLgSFUfKvvzi6oWGN+kC
RnHxi++989ZXn39SWmKhu8Iz4D+BbESiPn+ATp9VVlpSa62i0xys+QPhyAHfp3zi5RqLJbPneHlk
QAm2J3FHJ7Y/LoGCS6DgEii4BAougYJLoOASKLgECi6Bgkug4BIouAQKLoGCS6DgEii4BAougYJL
oOASKLgECi6Bgkug4BIouAQKLoGCS6DgEii4BAougYJLoPgHXa/p7unnBcUAAAAASUVORK5CYII=`;

// ----------------------------------------------------------------------

const CaptionStyle = styled(CardContent)(({ theme }) => ({
  bottom: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  backdropFilter: 'blur(3px)',
  WebkitBackdropFilter: 'blur(3px)', // Fix on Mobile
  justifyContent: 'space-between',
  color: theme.palette.common.white,
  backgroundColor: alpha(theme.palette.grey[900], 0.72),
  borderBottomLeftRadius: theme.shape.borderRadiusMd,
  borderBottomRightRadius: theme.shape.borderRadiusMd
}));

const GalleryImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});

ValueCard.propTypes = {
  info: PropTypes.shape({
    id: PropTypes.string,
    imageUrl: PropTypes.string,
    title: PropTypes.string,
    role: PropTypes.string
  }),
  idEmpresa: PropTypes.string,
  pid: PropTypes.string
};

function ValueCard({ info, idEmpresa, pid }) {
  const { imageUrl, title } = info;
  const [open, setOpen] = useState(false);
  const [urlI, setUrlI] = useState('');

  const menuRef = useRef(null);
  const inputRef = useRef(null);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickUpload = () => {
    inputRef.current.click();
  };

  const IMGRef = `empresas/${idEmpresa}/proyectos/${pid}/valores/${title}`;

  const setUrlICB = useCallback(async () => {
    try {
      const url = await getURLFile(IMGRef);
      setUrlI(url);
    } catch (err) {
      console.error(err);
    }
  }, [IMGRef]);

  useEffect(() => {
    setUrlICB();
  }, [setUrlICB]);

  return (
    <Card sx={{ pt: '100%', cursor: 'pointer', margin: '2%' }}>
      <GalleryImgStyle alt="gallery image" src={Boolean(urlI) ? urlI : imageUrl} />

      <CaptionStyle>
        <div>
          <Typography variant="subtitle1">{title}</Typography>
        </div>
        <IconButton color="inherit" ref={menuRef} onClick={clickOpen}>
          <Icon icon={moreVerticalFill} width={20} height={20} />
        </IconButton>
        <ValueNavD
          open={open}
          setOpen={setOpen}
          title={title}
          imgUrl={Boolean(urlI) ? urlI : imageUrl}
          clickUpload={clickUpload}
          idEmpresa={idEmpresa}
          pid={pid}
        />
        <input
          accept="image/*"
          ref={inputRef}
          type="file"
          onChange={(e) => {
            const file = e.target.files[0];
            console.log(file);
            uploadFile(file, IMGRef).then((url) => {
              setUrlI(url);
            });
          }}
          style={{ display: 'none' }}
        />
      </CaptionStyle>
    </Card>
  );
}

ProyectoTags.propTypes = {
  post: PropTypes.object.isRequired,
  sx: PropTypes.object,
  variant: PropTypes.string
};

export default function ProyectoTags({ post, variant = 'grid', sx }) {
  const { valores, idEmpresa, id } = post;
  const carouselRef = useRef();
  const theme = useTheme();

  const settings = {
    slidesToShow: valores.length >= 3 ? 3 : valores.length,
    centerMode: true,
    centerPadding: '0',
    rtl: Boolean(theme.direction === 'rtl'),
    responsive: [
      {
        breakpoint: 1279,
        settings: { slidesToShow: 3 }
      },
      {
        breakpoint: 959,
        settings: { slidesToShow: 3 }
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 3 }
      }
    ]
  };

  const handlePrevious = () => {
    carouselRef.current.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current.slickNext();
  };

  return (
    <Box sx={{ py: 1, height: 'auto', position: 'relative', ...sx }}>
      {variant === 'slide' && (
        <Box sx={{ height: '100%' }}>
          <Slider ref={carouselRef} {...settings} autoplay autoplaySpeed={2900} pauseOnHover>
            {valores.map((valor) => (
              <MotionInView key={valor} variants={varFadeIn}>
                <ValueCard info={{ imageUrl: casaIMG, title: valor }} idEmpresa={idEmpresa} pid={id} />
              </MotionInView>
            ))}
          </Slider>
          <CarouselControlsArrowsBasic2
            onNext={handleNext}
            onPrevious={handlePrevious}
            sx={{ transform: 'translateY(-14px)' }}
          />
        </Box>
      )}
      {variant === 'grid' && (
        <Grid container spacing={3}>
          {valores.map((valor) => (
            <Grid key={valor} item xs={12} md={4} lg={3}>
              <ValueCard info={{ imageUrl: casaIMG, title: valor }} idEmpresa={idEmpresa} pid={id} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
}
