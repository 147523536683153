import React from 'react';
import { Card, CardHeader } from '@mui/material';
import UserElement from 'src/components/_dashboard/general/UserElement';
import PropTypes from 'prop-types';

PermisosCard.propTypes = {
  empresa: PropTypes.object
};

export default function PermisosCard({ empresa }) {
  const { creator } = empresa;
  return (
    <Card>
      <CardHeader
        title={
          <>
            Creador de la empresa:
            <UserElement userid={creator} />
          </>
        }
      />
    </Card>
  );
}
