import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
// material
import {
  Stack,
  Avatar,
  Tooltip,
  Typography,
  CardHeader,
  MenuItem,
  List,
  ListItemAvatar,
  ListItemText,
  Dialog,
  DialogContent
} from '@mui/material';
import { styled } from '@mui/styles';
//
import KanbanUser from '../KanbanUser';
import plusFill from '@iconify/icons-eva/plus-fill';
import { MIconButton } from 'src/components/@material-extend';
import { firestore } from 'src/contexts/FirebaseContext';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useDispatch } from 'src/redux/store';
import { getBoard } from 'src/redux/slices/kanbanperfil';
import { createNotification, generateNotification } from 'src/utils/notifications';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
});

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary
}));

// ----------------------------------------------------------------------

Involucrados.propTypes = {
  card: PropTypes.object.isRequired,
  idEmpresa: PropTypes.string,
  idP: PropTypes.string,
  cardId: PropTypes.string
};

export default function Involucrados({ card, idEmpresa, idP, cardId }) {
  const { name } = card;
  const dispatch = useDispatch();
  const [apoyo, setApoyo] = useState(card.apoyo || []);
  const [responsable, setResponsable] = useState(card.responsable || []);
  const [lider, setLider] = useState(card.lider || []);
  const [soporte, setSoporte] = useState(card.soporte || []);
  const [consultado, setConsultado] = useState(card.consultado || []);
  const [informado, setInformado] = useState(card.informado || []);
  const [openMenua, setOpenMenua] = useState(false);
  const [openMenur, setOpenMenur] = useState(false);
  const [openMenul, setOpenMenul] = useState(false);
  const [openMenus, setOpenMenus] = useState(false);
  const [openMenuc, setOpenMenuc] = useState(false);
  const [openMenui, setOpenMenui] = useState(false);

  useEffect(() => {
    setApoyo(card.apoyo || []);
    setResponsable(card.responsable || []);
    setLider(card.lider || []);
    setSoporte(card.soporte || []);
    setConsultado(card.consultado || []);
    setInformado(card.informado || []);
  }, [card]);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function getUsers() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setUsers(doc.data().membersinfo);
          }
        });
    }
    getUsers();
  }, [idEmpresa]);

  const addApoyoa = async (user) => {
    const newArr = [...apoyo, user];
    setApoyo(newArr);
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(idP)
      .collection('kanbanCardscomp')
      .doc(cardId)
      .update({ apoyo: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa));
      });
    const noti = generateNotification(
      'se te ha asigando un objetivo en apoyo',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + idEmpresa
    );
    createNotification(noti);
  };

  const removeApoyoa = async (user) => {
    const newArr = [...apoyo.filter((as) => as.id !== user.id)];
    setApoyo(newArr);
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(idP)
      .collection('kanbanCards')
      .doc(cardId)
      .update({ apoyo: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, idP));
      });
    const noti = generateNotification(
      'se te han quitado en un objetivo en apoyo',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + idEmpresa
    );
    createNotification(noti);
  };

  const addResponsable = async (user) => {
    const newArr = [...responsable, user];
    setResponsable(newArr);
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(idP)
      .collection('kanbanCardscomp')
      .doc(cardId)
      .update({ responsable: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa));
      });
    const noti = generateNotification(
      'se te ha asigando un objetivo en responsable',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + idEmpresa
    );
    createNotification(noti);
  };

  const removeResponsable = async (user) => {
    const newArr = [...responsable.filter((as) => as.id !== user.id)];
    setResponsable(newArr);
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(idP)
      .collection('kanbanCardscomp')
      .doc(cardId)
      .update({ responsable: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, idP));
      });
    const noti = generateNotification(
      'se te han quitado en un objetivo en responsable',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + idEmpresa
    );
    createNotification(noti);
  };
  const addLider = async (user) => {
    const newArr = [...lider, user];
    setLider(newArr);
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(idP)
      .collection('kanbanCardscomp')
      .doc(cardId)
      .update({ lider: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, idP));
      });
    const noti = generateNotification(
      'se te ha asigando un objetivo en lider',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + idEmpresa
    );
    createNotification(noti);
  };

  const removeLider = async (user) => {
    const newArr = [...lider.filter((as) => as.id !== user.id)];
    setLider(newArr);
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(idP)
      .collection('kanbanCardscomp')
      .doc(cardId)
      .update({ lider: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, idP));
      });
    const noti = generateNotification(
      'se te han quitado en un objetivo en lider',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + idEmpresa
    );
    createNotification(noti);
  };
  const addSoporte = async (user) => {
    const newArr = [...soporte, user];
    setSoporte(newArr);
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(idP)
      .collection('kanbanCardscomp')
      .doc(cardId)
      .update({ soporte: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, idP));
      });
    const noti = generateNotification(
      'se te ha asigando un objetivo en soporte',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + idEmpresa
    );
    createNotification(noti);
  };

  const removeSoporte = async (user) => {
    const newArr = [...soporte.filter((as) => as.id !== user.id)];
    setSoporte(newArr);
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(idP)
      .collection('kanbanCardscomp')
      .doc(cardId)
      .update({ soporte: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, idP));
      });
    const noti = generateNotification(
      'se te han quitado en un objetivo en soporte',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + idEmpresa
    );
    createNotification(noti);
  };
  const addConsultado = async (user) => {
    const newArr = [...consultado, user];
    setConsultado(newArr);
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(idP)
      .collection('kanbanCardscomp')
      .doc(cardId)
      .update({ consultado: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, idP));
      });
    const noti = generateNotification(
      'se te ha asigando un objetivo en consultado',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + idEmpresa
    );
    createNotification(noti);
  };

  const removeConsultado = async (user) => {
    const newArr = [...consultado.filter((as) => as.id !== user.id)];
    setConsultado(newArr);
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(idP)
      .collection('kanbanCardscomp')
      .doc(cardId)
      .update({ consultado: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, idP));
      });
    const noti = generateNotification(
      'se te han quitado en un objetivo en consultado',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + idEmpresa
    );
    createNotification(noti);
  };

  const addInformado = async (user) => {
    const newArr = [...informado, user];
    setInformado(newArr);
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(idP)
      .collection('kanbanCardscomp')
      .doc(cardId)
      .update({ informado: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, idP));
      });
    const noti = generateNotification(
      'se te ha asigando un objetivo en informado',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + idEmpresa
    );
    createNotification(noti);
  };

  const removeInformado = async (user) => {
    const newArr = [...informado.filter((as) => as.id !== user.id)];
    setInformado(newArr);
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('perfiles')
      .doc(idP)
      .collection('kanbanCardscomp')
      .doc(cardId)
      .update({ informado: newArr })
      .then(() => {
        dispatch(getBoard(idEmpresa, idP));
      });
    const noti = generateNotification(
      'se te han quitado en un objetivo en informado',
      'objetivo: ' + name,
      user.avatar,
      'avatar',
      user.id,
      PATH_DASHBOARD.entorno.empresa + idEmpresa
    );
    createNotification(noti);
  };

  return (
    <>
      <CardHeader title="Involucrados" subheader="en el objetivo" />
      <Stack spacing={3} sx={{ p: 3 }}>
        <RowStyle>
          <LabelStyle sx={{ mt: 1.5 }}>Responsable</LabelStyle>
          <Stack direction="row" flexWrap="wrap" alignItems="center">
            {responsable.map((user) => (
              <KanbanUser key={user.id} user={user} removeUser={removeResponsable} />
            ))}
            <Tooltip title="Agregar responsable">
              <MIconButton
                disabled={responsable.length !== 0}
                sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                onClick={() => setOpenMenur(true)}
              >
                <Icon icon={plusFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
            <Dialog open={openMenur} onClose={() => setOpenMenur(false)}>
              <DialogContent>
                <List>
                  {users
                    .filter((u) => !Boolean(responsable.find((d) => d.id === u.id)))
                    .map((u) => {
                      return (
                        <MenuItem
                          key={'userr' + u.id}
                          onClick={() => {
                            addResponsable(u);
                            setOpenMenur(false);
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar src={u.avatar} />
                          </ListItemAvatar>
                          <ListItemText>{u.nombre}</ListItemText>
                        </MenuItem>
                      );
                    })}
                </List>
              </DialogContent>
            </Dialog>
          </Stack>
        </RowStyle>

        <RowStyle>
          <LabelStyle sx={{ mt: 1.5 }}>Apoyo</LabelStyle>
          <Stack direction="row" flexWrap="wrap" alignItems="center">
            {apoyo.map((user) => (
              <KanbanUser key={user.id} user={user} removeUser={removeApoyoa} />
            ))}
            <Tooltip title="Agregar apoyo">
              <MIconButton
                sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                onClick={() => setOpenMenua(true)}
              >
                <Icon icon={plusFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
            <Dialog open={openMenua} onClose={() => setOpenMenua(false)}>
              <DialogContent>
                <List>
                  {users
                    .filter((u) => !Boolean(apoyo.find((d) => d.id === u.id)))
                    .map((u) => {
                      return (
                        <MenuItem
                          key={'usera' + u.id}
                          onClick={() => {
                            addApoyoa(u);
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar src={u.avatar} />
                          </ListItemAvatar>
                          <ListItemText>{u.nombre}</ListItemText>
                        </MenuItem>
                      );
                    })}
                </List>
              </DialogContent>
            </Dialog>
          </Stack>
        </RowStyle>

        <RowStyle>
          <LabelStyle sx={{ mt: 1.5 }}>Lider</LabelStyle>
          <Stack direction="row" flexWrap="wrap" alignItems="center">
            {lider.map((user) => (
              <KanbanUser key={user.id} user={user} removeUser={removeLider} />
            ))}
            <Tooltip title="Agregar lider">
              <MIconButton
                sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                onClick={() => setOpenMenul(true)}
              >
                <Icon icon={plusFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
            <Dialog open={openMenul} onClose={() => setOpenMenul(false)}>
              <DialogContent>
                <List>
                  {users
                    .filter((u) => !Boolean(lider.find((d) => d.id === u.id)))
                    .map((u) => {
                      return (
                        <MenuItem
                          key={'userl' + u.id}
                          onClick={() => {
                            addLider(u);
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar src={u.avatar} />
                          </ListItemAvatar>
                          <ListItemText>{u.nombre}</ListItemText>
                        </MenuItem>
                      );
                    })}
                </List>
              </DialogContent>
            </Dialog>
          </Stack>
        </RowStyle>

        <RowStyle>
          <LabelStyle sx={{ mt: 1.5 }}>Soporte</LabelStyle>
          <Stack direction="row" flexWrap="wrap" alignItems="center">
            {soporte.map((user) => (
              <KanbanUser key={user.id} user={user} removeUser={removeSoporte} />
            ))}
            <Tooltip title="Agregar soporte">
              <MIconButton
                sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                onClick={() => setOpenMenus(true)}
              >
                <Icon icon={plusFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
            <Dialog open={openMenus} onClose={() => setOpenMenus(false)}>
              <DialogContent>
                <List>
                  {users
                    .filter((u) => !Boolean(soporte.find((d) => d.id === u.id)))
                    .map((u) => {
                      return (
                        <MenuItem
                          key={'users' + u.id}
                          onClick={() => {
                            addSoporte(u);
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar src={u.avatar} />
                          </ListItemAvatar>
                          <ListItemText>{u.nombre}</ListItemText>
                        </MenuItem>
                      );
                    })}
                </List>
              </DialogContent>
            </Dialog>
          </Stack>
        </RowStyle>

        <RowStyle>
          <LabelStyle sx={{ mt: 1.5 }}>Consultado</LabelStyle>
          <Stack direction="row" flexWrap="wrap" alignItems="center">
            {consultado.map((user) => (
              <KanbanUser key={user.id} user={user} removeUser={removeConsultado} />
            ))}
            <Tooltip title="Agregar consultado">
              <MIconButton
                sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                onClick={() => setOpenMenuc(true)}
              >
                <Icon icon={plusFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
            <Dialog open={openMenuc} onClose={() => setOpenMenuc(false)}>
              <DialogContent>
                <List>
                  {users
                    .filter((u) => !Boolean(consultado.find((d) => d.id === u.id)))
                    .map((u) => {
                      return (
                        <MenuItem
                          key={'userc' + u.id}
                          onClick={() => {
                            addConsultado(u);
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar src={u.avatar} />
                          </ListItemAvatar>
                          <ListItemText>{u.nombre}</ListItemText>
                        </MenuItem>
                      );
                    })}
                </List>
              </DialogContent>
            </Dialog>
          </Stack>
        </RowStyle>

        <RowStyle direction="row">
          <LabelStyle sx={{ mt: 1.5 }}>Informado</LabelStyle>
          <Stack direction="row" flexWrap="wrap" alignItems="center">
            {informado.map((user) => (
              <KanbanUser key={user.id} user={user} removeUser={removeInformado} />
            ))}
            <Tooltip title="Agregar informado">
              <MIconButton
                sx={{ p: 1, ml: 0.5, border: (theme) => `dashed 1px ${theme.palette.divider}` }}
                onClick={() => setOpenMenui(true)}
              >
                <Icon icon={plusFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
            <Dialog open={openMenui} onClose={() => setOpenMenui(false)}>
              <DialogContent>
                <List>
                  {users
                    .filter((u) => !Boolean(informado.find((d) => d.id === u.id)))
                    .map((u) => {
                      return (
                        <MenuItem
                          key={'useri' + u.id}
                          onClick={() => {
                            addInformado(u);
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar src={u.avatar} />
                          </ListItemAvatar>
                          <ListItemText>{u.nombre}</ListItemText>
                        </MenuItem>
                      );
                    })}
                </List>
              </DialogContent>
            </Dialog>
          </Stack>
        </RowStyle>
      </Stack>
    </>
  );
}
