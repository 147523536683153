import { firestore } from 'src/contexts/FirebaseContext';

export async function captureEvent(nameEvent, userid) {
  await firestore.collection('events').add({ name: nameEvent, fecha: new Date(), userid, place: null });
}

export async function captureEventC(nameEvent, place, userid, idEmpresa, idP) {
  await firestore
    .collection('events')
    .add({ name: nameEvent, fecha: new Date(), userid, place, idEmpresa: idEmpresa || null, idP: idP || null });
}
