import React, { useState } from 'react';
import { TableRow, TableCell, TextField, MenuItem, IconButton } from '@mui/material';
import SprintNav from './SprintNav';
//
import { Icon } from '@iconify/react';
import moreFill from '@iconify/icons-eva/more-horizontal-fill';
//
import { fDate, parseDate } from 'src/utils/formatTime';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

SprintCard.propTypes = {
  sprint: PropTypes.shape({
    id: PropTypes.string,
    nombre: PropTypes.string,
    objetivo: PropTypes.string,
    fechaE: PropTypes.object,
    fechaF: PropTypes.object,
    status: PropTypes.string,
    objetivos: PropTypes.array,
    likes: PropTypes.array
  }),
  idEmpresa: PropTypes.string
};

export default function SprintCard({ sprint, idEmpresa }) {
  const { id, nombre, objetivo, fechaE, fechaF, status } = sprint;

  const OPTIONS = ['creado', 'en proceso', 'completado', 'detenido', 'archivado'];

  const setStatus = async (newStatus) => {
    await firestore.collection('empresas').doc(idEmpresa).collection('sprints').doc(id).update({ status: newStatus });
  };

  const [openM, setOpenM] = useState(false);

  const clickOpenM = () => {
    setOpenM(true);
  };

  return (
    <TableRow>
      <TableCell>
        <IconButton onClick={clickOpenM}>
          <Icon icon={moreFill} width={23} height={23} />
        </IconButton>
        <SprintNav idEmpresa={idEmpresa} open={openM} setOpen={setOpenM} info={sprint} />
      </TableCell>
      <TableCell>{nombre}</TableCell>
      <TableCell>{objetivo}</TableCell>
      <TableCell>
        <TextField
          select
          value={status}
          onChange={(e) => {
            const val = e.target.value;
            if (val === 'archivado') {
              if (window.confirm('¿desea archivar este sprint?')) {
                setStatus(val);
              }
            } else {
              setStatus(val);
            }
          }}
        >
          {OPTIONS.map((opt) => (
            <MenuItem value={opt} key={opt}>
              {opt}
            </MenuItem>
          ))}
        </TextField>
      </TableCell>
      <TableCell>{fDate(parseDate(fechaE))}</TableCell>
      <TableCell>{fDate(parseDate(fechaF))}</TableCell>
    </TableRow>
  );
}
