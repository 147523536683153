import { createSlice } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
// utils
import axios from '../../utils/axios';
import { firestore } from 'src/contexts/FirebaseContext';

// funciones auxiliares
/*
function deleteObj(idObj, idEmpresa, spid) {
  firestore
    .collection('empresas')
    .doc(idEmpresa)
    .collection('sprints')
    .doc(spid)
    .update({ objetivos: objetivos.filter((oo) => oo !== o) });
  firestore
    .collection('empresas')
    .doc(idEmpresa)
    .collection('sprints')
    .doc(spid)
    .collection('kanbanColunms')
    .get()
    .then((queryS) => {
      queryS.forEach((doccc) => {
        const { cardIds } = doccc.data();
        const newArr = cardIds.filter((d) => d !== o);
        firestore
          .collection('empresas')
          .doc(idEmpresa)
          .collection('sprints')
          .doc(spid)
          .collection('kanbanColunms')
          .doc(doccc.id)
          .update({ cardIds: newArr });
      });
    });
} */

// Firestore conecction

async function getBoardFirestore(idEmpresa, spid) {
  const cards = [];
  const columns = [];
  const columnorder = [];
  const cardsIds = [];
  await firestore
    .collection('empresas')
    .doc(idEmpresa)
    .collection('sprints')
    .doc(spid)
    .get()
    .then((doc) => {
      const { objetivos } = doc.data();
      let objs = objetivos;

      // obtener objetivos
      objetivos.forEach(async (o) => {
        cardsIds.push(o);
        // obj info
        await firestore
          .collection('kobjsCards')
          .doc(o)
          .get()
          .then((docc) => {
            if (docc.exists) {
              const {
                pid,
                assignee,
                attachments,
                name,
                prioritize,
                due,
                completed,
                comments,
                description,
                apoyo,
                responsable,
                lider,
                soporte,
                informado,
                consultado,
                haciendo,
                krs,
                likes,
                proyectosA
              } = docc.data();
              cards.push({
                pid,
                id: docc.id,
                assignee,
                attachments,
                name,
                prioritize,
                due,
                completed,
                comments,
                description,
                apoyo,
                responsable,
                lider,
                soporte,
                informado,
                consultado,
                haciendo,
                krs,
                likes,
                proyectosA
              });
            } else {
              objs = objs.filter((oo) => oo !== o);
              firestore
                .collection('empresas')
                .doc(idEmpresa)
                .collection('sprints')
                .doc(spid)
                .update({ objetivos: objs });
              firestore
                .collection('empresas')
                .doc(idEmpresa)
                .collection('sprints')
                .doc(spid)
                .collection('kanbanColunms')
                .get()
                .then((queryS) => {
                  queryS.forEach((doccc) => {
                    const { cardIds } = doccc.data();
                    const newArr = cardIds.filter((d) => d !== o);
                    firestore
                      .collection('empresas')
                      .doc(idEmpresa)
                      .collection('sprints')
                      .doc(spid)
                      .collection('kanbanColunms')
                      .doc(doccc.id)
                      .update({ cardIds: newArr });
                  });
                });
            }
          });
      });
    });
  await firestore
    .collection('empresas')
    .doc(idEmpresa)
    .collection('sprints')
    .doc(spid)
    .collection('kanbanColunms')
    .get()
    .then((queryS) => {
      queryS.forEach((doc) => {
        columns.push({ id: doc.id, ...doc.data() });
      });
    });
  cardsIds.forEach((card) => {
    let cin = 0;
    columns.forEach((col) => {
      if (!col.cardIds.includes(card)) {
        cin += 1;
      }
    });
    if (cin !== columns.length - 1) {
      const cIndex = columns.findIndex((col) => col.name === 'Programado');
      const newArr = columns[cIndex].cardIds;
      newArr.push(card);
      columns[cIndex].cardIds = newArr;
    }
  });
  await firestore
    .collection('empresas')
    .doc(idEmpresa)
    .collection('sprints')
    .doc(spid)
    .collection('kanbanColumnOrder')
    .doc('order')
    .get()
    .then((doc) => {
      const { columnOrder } = doc.data();
      columnOrder.forEach((column) => {
        columnorder.push(column);
      });
    });
  const Board = {
    cards,
    columns,
    columnOrder: columnorder
  };
  console.log(Board);
  return Board;
}

// async function updateColumnFirestore() {}

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: false,
  board: {
    cardsA: [],
    cards: {},
    columns: {},
    columnOrder: []
  }
};

const slice = createSlice({
  name: 'kanbansprint',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BOARD
    getBoardSuccess(state, action) {
      state.isLoading = false;
      const board = action.payload;
      const cardsA = board.cards;
      const cards = objFromArray(board.cards);
      const columns = objFromArray(board.columns);
      const { columnOrder } = board;
      state.board = {
        cardsA,
        cards,
        columns,
        columnOrder
      };
    },

    // CREATE NEW COLUMN
    createColumnSuccess(state, action) {
      const { newColumn, idEmpresa, spid } = action.payload;
      const id = uuidv4();
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .doc(spid)
        .collection('kanbanColunms')
        .doc(id)
        .set({ id, ...newColumn });
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .doc(spid)
        .collection('kanbanColumnOrder')
        .doc('order')
        .get()
        .then((doc) => {
          const { columnOrder } = doc.data();
          const newcolumnOrder = [...columnOrder, id];
          firestore
            .collection('empresas')
            .doc(idEmpresa)
            .collection('sprints')
            .doc(spid)
            .collection('kanbanColumnOrder')
            .doc('order')
            .update({ columnOrder: newcolumnOrder });
        });
      state.isLoading = false;
      state.board.columns = {
        ...state.board.columns,
        [id]: { id, ...newColumn }
      };
      state.board.columnOrder.push(id);
    },

    persistCard(state, action) {
      const { columns } = action.payload;
      state.board.columns = columns;
    },

    persistColumn(state, action) {
      const { newColumnOrder, idEmpresa, spid } = action.payload;
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .doc(spid)
        .collection('kanbanColumnOrder')
        .doc('order')
        .update({ columnOrder: newColumnOrder });
      state.board.columnOrder = newColumnOrder;
    },

    addTask(state, action) {
      const { task, idEmpresa, spid } = action.payload;
      const { card, columnId } = task;
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .doc(spid)
        .collection('kanbanCards')
        .doc(card.id)
        .set(card);
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .doc(spid)
        .collection('kanbanColunms')
        .doc(columnId)
        .get()
        .then((doc) => {
          const { cardIds } = doc.data();
          firestore
            .collection('empresas')
            .doc(idEmpresa)
            .collection('sprints')
            .doc(spid)
            .collection('kanbanColunms')
            .doc(columnId)
            .update({ cardIds: [card.id, ...cardIds] });
        });
      state.board.cards[card.id] = card;
      state.board.columns[columnId].cardIds.push(card.id);
    },

    deleteTask(state, action) {
      const { task, idEmpresa, spid } = action.payload;
      const { cardId, columnId } = task;
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .doc(spid)
        .collection('kanbanColunms')
        .doc(columnId)
        .get()
        .then((doc) => {
          const { cardIds } = doc.data();
          firestore
            .collection('empresas')
            .doc(idEmpresa)
            .collection('sprints')
            .doc(spid)
            .collection('kanbanColunms')
            .doc(columnId)
            .update({ cardIds: cardIds.filter((id) => id !== cardId) });
        });
      /* firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .doc(spid)
        .collection('kanbanCards')
        .doc(cardId)
        .delete(); */
      state.board.columns[columnId].cardIds = state.board.columns[columnId].cardIds.filter((id) => id !== cardId);
      state.board.cards = omit(state.board.cards, [cardId]);
    },

    // UPDATE COLUMN
    updateColumnSuccess(state, action) {
      const { column, idEmpresa, spid } = action.payload;
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .doc(spid)
        .collection('kanbanColunms')
        .doc(column.id)
        .update({ name: column.name });
      state.isLoading = false;
      state.board.columns[column.id] = column;
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      const { columnId, idEmpresa, spid } = action.payload;
      const deletedColumn = state.board.columns[columnId];
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .doc(spid)
        .collection('kanbanColumnOrder')
        .doc('order')
        .get()
        .then((doc) => {
          const { columnOrder } = doc.data();
          const newcolumnOrder = columnOrder.filter((id) => id !== columnId);
          firestore
            .collection('empresas')
            .doc(idEmpresa)
            .collection('sprints')
            .doc(spid)
            .collection('kanbanColumnOrder')
            .doc('order')
            .update({ columnOrder: newcolumnOrder });
        });
      /* firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .doc(spid)
        .collection('kanbanColunms')
        .doc(columnId)
        .get()
        .then((doc) => {
          const { cardIds } = doc.data();
          cardIds.forEach((id) => {
            firestore
              .collection('empresas')
              .doc(idEmpresa)
              .collection('sprints')
              .doc(spid)
              .collection('kanbanCards')
              .doc(id)
              .delete();
          });
        }); */
      firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .doc(spid)
        .collection('kanbanColunms')
        .doc(columnId)
        .delete();
      state.isLoading = false;
      state.board.columns = omit(state.board.columns, [columnId]);
      state.board.cards = omit(state.board.cards, [...deletedColumn.cardIds]);
      state.board.columnOrder = state.board.columnOrder.filter((c) => c !== columnId);
    }
  }
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getBoard(idEmpresa, spid) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await getBoardFirestore(idEmpresa, spid);
      console.log(resp);
      dispatch(slice.actions.getBoardSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createColumn(newColumn, idEmpresa, spid) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const NC = { cardIds: [], ...newColumn };
      dispatch(slice.actions.createColumnSuccess({ newColumn: NC, idEmpresa, spid }));
      getBoard(idEmpresa, spid);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateColumn(columnId, idEmpresa, spid, updateColumn) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('updatecollumn');
      const response = await axios.post('/api/kanban/columns/update', {
        columnId,
        updateColumn
      });
      dispatch(slice.actions.updateColumnSuccess({ column: response.data.column, idEmpresa, spid }));
      getBoard(idEmpresa, spid);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteColumn(columnId, idEmpresa, spid) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .doc(spid)
        .collection('kanbanColunms')
        .doc(columnId)
        .delete();
      dispatch(slice.actions.deleteColumnSuccess({ columnId, idEmpresa, spid }));
      getBoard(idEmpresa, spid);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function persistColumn(newColumnOrder, idEmpresa, spid) {
  return (dispatch) => {
    dispatch(slice.actions.persistColumn({ newColumnOrder, idEmpresa, spid }));
    getBoard(idEmpresa, spid);
  };
}

// ----------------------------------------------------------------------

export function persistCard(columns) {
  return (dispatch) => {
    dispatch(slice.actions.persistCard(columns));
    getBoard();
  };
}

// ----------------------------------------------------------------------

export function addTask(task, idEmpresa, spid) {
  return (dispatch) => {
    dispatch(slice.actions.addTask({ task, idEmpresa, spid }));
    getBoard(idEmpresa, spid);
  };
}

// ----------------------------------------------------------------------

export function deleteTask(taskId, idEmpresa, spid) {
  return (dispatch) => {
    dispatch(slice.actions.deleteTask({ taskId, idEmpresa, spid }));
    getBoard(idEmpresa, spid);
  };
}
