import { firestore } from 'src/contexts/FirebaseContext';

const geolocation = require('geolocation');

export async function captureEvent(collection, iddoc, userid, type, card) {
  const docref = firestore.collection(collection).doc(iddoc).collection('events');
  geolocation.getCurrentPosition((err, pos) => {
    if (err) {
      console.error(err);
      docref.add({ fecha: new Date(), userid, type, card });
    } else {
      docref.add({ fecha: new Date(), userid, type, card, pos });
    }
  });
}
