import React, { useState, useEffect } from 'react';
import { Button, ListItem, ListItemIcon, ListItemText, Dialog, DialogContent, Grid, Box } from '@mui/material';
import AltaProyecto from './AltaProyecto';
import { firestore } from 'src/contexts/FirebaseContext';
// icon
import { Icon } from '@iconify/react';
import proyectI from '@iconify/icons-ant-design/file-text-filled';
//
import useAuth from 'src/hooks/useAuth';
import { ProyectItem } from 'src/components/_dashboard/general-app/ProyectosFav';
import PropTypes from 'prop-types';

ListaProyectos.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  idEmpresa: PropTypes.string,
  showB: PropTypes.bool,
  listB: PropTypes.bool
};

export default function ListaProyectos({ open = false, setOpen, idEmpresa, showB, listB }) {
  const { user } = useAuth();
  const [openAP, setOpenAP] = useState(false);
  const [proyectos, setProyectos] = useState([]);

  useEffect(() => {
    async function getProyects() {
      await firestore
        .collection('proyectosempresa')
        .where('idEmpresa', '==', idEmpresa)
        .onSnapshot((queryS) => {
          const p = [];
          queryS.forEach((doc) => {
            const { creator, membersids } = doc.data();
            if (creator === user.id || membersids.includes(user.id)) {
              p.push({ id: doc.id, ...doc.data() });
            }
          });
          setProyectos(p);
        });
    }
    getProyects();
  }, [idEmpresa, user]);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      {showB && listB && (
        <ListItem button onClick={clickOpen} sx={{ color: '#506070' }}>
          <ListItemIcon>
            <Icon icon={proyectI} width={25} height={25} />
          </ListItemIcon>
          <ListItemText>Proyectos</ListItemText>
        </ListItem>
      )}
      {showB && !listB && <Button onClick={clickOpen}>Proyectos</Button>}
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <Box sx={{ width: '60vw' }} />
        <DialogContent sx={{ maxWidth: '60vw' }}>
          <Box>
            <AltaProyecto open={openAP} setOpen={setOpenAP} idEmpresa={idEmpresa} showB />
          </Box>
          <Grid container spacing={3}>
            {proyectos.map((p) => (
              <Grid item xs={12} key={p.id}>
                <ProyectItem proyecto={p} allowInteraction />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
