import { useState } from 'react';
import { Icon } from '@iconify/react';
import androidFilled from '@iconify/icons-ant-design/android-filled';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, Dialog, DialogContent, Stack, Box } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import { useAnalytics } from 'src/pages/dashboard/GeneralAnalyticsUser';
import { EmpresaItem } from 'src/components/_dashboard/general-app/EmpresasFav';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

export default function AnalyticsWeeklySales() {
  const { user } = useAuth();
  const { emps } = useAnalytics();
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
    console.log('open');
  };

  const clickClose = () => {
    setOpen(false);
  };
  return (
    <>
      <RootStyle onClick={clickOpen}>
        <IconWrapperStyle>
          <Icon icon={androidFilled} width={24} height={24} />
        </IconWrapperStyle>
        <Typography variant="h3">{fShortenNumber(emps.length)}</Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          Empresas Creadas
        </Typography>
      </RootStyle>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <Box sx={{ width: '60vw' }} />
        <DialogContent>
          <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
            {emps.map((p) => (
              <EmpresaItem
                key={p.id}
                empresa={p}
                allowInteraction={p.creator === user.id || p.membersids.includes(user.id)}
              />
            ))}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
