import React from 'react';
import { Card } from '@mui/material';
import ValueHero from './ValueHero';
import PropTypes from 'prop-types';

ValueCard.propTypes = {
  title: PropTypes.string,
  imgUrl: PropTypes.string
};

export default function ValueCard({ title, imgUrl }) {
  return (
    <Card>
      <ValueHero nameE={title} img={imgUrl} />
    </Card>
  );
}
