import React, { useState, useEffect } from 'react';
import { Card, CardContent, Box, Button, Typography } from '@mui/material';
// logo
import Logo from 'src/assets/logo';
//
import { firestore } from 'src/contexts/FirebaseContext';
import useAuth from 'src/hooks/useAuth';
import PropTypes from 'prop-types';

SolicitarEspacio.propTypes = {
  module: PropTypes.string,
  title: PropTypes.any,
  descripcion: PropTypes.any
};

export default function SolicitarEspacio({ module, title = '', descripcion = '' }) {
  const { user } = useAuth();
  const [solicitado, setSolicitado] = useState(false);

  useEffect(() => {
    async function detectS() {
      await firestore
        .collection('solicitudes')
        .doc(`${user.id}-${module}`)
        .onSnapshot((doc) => {
          setSolicitado(doc.exists);
        });
    }
    detectS();
  }, [module, user]);

  return (
    <Card>
      <CardContent>
        <Box>
          <Logo sx={{ width: 150, height: 'auto' }} />
        </Box>
        <Typography variant="h3" sx={{ textAlign: 'center' }}>
          {title}
        </Typography>
        <Box sx={{ textAlign: 'center' }}>{descripcion}</Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <Button
            variant="contained"
            disabled={solicitado}
            onClick={() => {
              firestore
                .collection('solicitudes')
                .doc(`${user.id}-${module}`)
                .set({ userid: user.id, module, solicitado: new Date() });
            }}
          >
            {solicitado ? <>Solicitado</> : <>Solicitar Acceso</>}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
