import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

CTAImg.propTypes = {
  src: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};

export default function CTAImg({ src, width, height }) {
  return <Box component="img" src={src} sx={{ width: width || '100%', height: height || 'auto' }} />;
}
