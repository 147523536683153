import React, { useState } from 'react';
import { Button, Typography, TextField, Box, Tab, Tabs, FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
// icons
import { Icon } from '@iconify/react';
import imagenI from '@iconify/icons-ant-design/file-image-fill';
import MarkdownI from '@iconify/icons-ant-design/file-text-fill';
import VideoI from '@iconify/icons-ant-design/video-camera';
import FrameI from '@iconify/icons-ant-design/global-outline';
import EditI from '@iconify/icons-ant-design/edit-filled';
import ExitEditI from '@iconify/icons-ant-design/carry-out-filled';
import DeleteI from '@iconify/icons-ant-design/delete-filled';
//
import { QuillEditor } from 'src/components/editor';
import { useSnackbar } from 'notistack';
import FileList from 'src/components/_dashboard/general/FileList';
import Markdown from 'src/components/Markdown';
import PropTypes from 'prop-types';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

EditSeccion.propTypes = {
  formik: PropTypes.object,
  content: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  index: PropTypes.number,
  showB: PropTypes.bool,
  idS: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  idBucket: PropTypes.string.isRequired,
  setCambiado: PropTypes.func.isRequired
};

export default function EditSeccion({
  formik,
  content,
  type,
  index,
  showB = false,
  idS,
  direction,
  idBucket,
  setCambiado
}) {
  const [edit, setEdit] = useState(false);
  const { errors, values, touched, setFieldValue } = formik;
  const { enqueueSnackbar } = useSnackbar();

  const tipos = [
    { value: 'video', name: 'video', icon: VideoI },
    { value: 'img', name: 'imagen', icon: imagenI },
    { value: 'iframe', name: 'Web', icon: FrameI },
    { value: 'markdown', name: 'texto enriquecido', icon: MarkdownI }
  ];

  const components = {
    markdown: <Markdown children={content} />,
    video: (
      <video controls width="100%" height="auto">
        <source src={content} />
      </video>
    ),
    img: <Box component="img" src={content} />,
    iframe: <iframe title="frame" src={content} width="100%" height="500px" style={{ border: 'none' }} />
  };

  const componentsEdit = {
    markdown: (
      <>
        <LabelStyle>Texto enriquecido</LabelStyle>
        <QuillEditor
          id="post-content"
          value={values.secciones[index].content}
          onChange={(val) => {
            const newS = values.secciones;
            newS[index].content = val;
            setFieldValue('secciones', newS);
            setCambiado(true);
          }}
          error={Boolean(touched.content && errors.content)}
        />
      </>
    ),
    video: (
      <>
        <TextField
          label="url del video"
          value={values.secciones[index].content}
          onChange={(e) => {
            const val = e.target.value;
            const newS = values.secciones;
            newS[index].content = val;
            setFieldValue('secciones', newS);
            setCambiado(true);
          }}
        />
        <FileList
          direction={direction}
          idBucket={idBucket}
          onSelect={(url) => {
            const newS = values.secciones;
            newS[index].content = url;
            setFieldValue('secciones', newS);
            enqueueSnackbar('Seleccionado', { variant: 'success' });
            setCambiado(true);
          }}
          fileType="video/mp4"
        />
      </>
    ),
    img: (
      <>
        <TextField
          label="url de la imagen"
          value={values.secciones[index].content}
          onChange={(e) => {
            const val = e.target.value;
            const newS = values.secciones;
            newS[index].content = val;
            setFieldValue('secciones', newS);
            setCambiado(true);
          }}
        />
        <FileList
          direction={direction}
          idBucket={idBucket}
          onSelect={(url) => {
            const newS = values.secciones;
            newS[index].content = url;
            setFieldValue('secciones', newS);
            enqueueSnackbar('Seleccionado', { variant: 'success' });
            setCambiado(true);
          }}
          fileType="image/*"
        />
      </>
    ),
    iframe: (
      <>
        <TextField
          label="url del iframe"
          value={values.secciones[index].content}
          onChange={(e) => {
            const val = e.target.value;
            const newS = values.secciones;
            newS[index].content = val;
            setFieldValue('secciones', newS);
            setCambiado(true);
          }}
        />
      </>
    )
  };

  return (
    <>
      {showB && (
        <>
          <Button onClick={() => setEdit(!edit)} sx={{ margin: '1%', color: '#637381' }}>
            {edit ? (
              <>
                <Icon icon={ExitEditI} width={28} height={28} /> Salir de la edición
              </>
            ) : (
              <>
                <Icon icon={EditI} width={28} height={28} /> Editar
              </>
            )}
          </Button>
          <Button
            onClick={() => {
              if (window.confirm('desea eliminar esta seccion?')) {
                const newS = values.secciones;
                setFieldValue(
                  'secciones',
                  newS.filter((d) => d.id !== idS)
                );
                setCambiado(true);
              }
            }}
            sx={{ margin: '1%', color: '#637381' }}
          >
            <Icon icon={DeleteI} width={28} height={28} /> Borrar
          </Button>
        </>
      )}
      <FormHelperText>{touched.secciones && errors.secciones}</FormHelperText>
      {!edit && components[type]}
      {edit && (
        <>
          <Tabs value={values.type} onChange={(e, newVal) => setFieldValue('type', newVal)}>
            {tipos.map((t) => (
              <Tab key={t.name} value={t.value} label={t.name} icon={<Icon icon={t.icon} width={32} height={32} />} />
            ))}
          </Tabs>
          {componentsEdit[type]}
        </>
      )}
    </>
  );
}
