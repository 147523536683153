import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Avatar, Box } from '@mui/material';
import UserCard from 'src/components/_dashboard/user/cards/UserCard';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

ProfileInfoD.propTypes = {
  userid: PropTypes.string.isRequired
};

export default function ProfileInfoD({ userid }) {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    async function getUD() {
      await firestore
        .collection('users')
        .doc(userid)
        .onSnapshot((doc) => {
          const { photoURL, displayName } = doc.data();
          setUser({ id: doc.id, avatar: photoURL, name: displayName, ...doc.data() });
        });
    }
    getUD();
  }, [userid]);

  // metodos
  const clickOpen = () => {
    setOpen(true);
  };
  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Avatar onClick={clickOpen} alt={user.name} src={user.avatar} sx={{ m: 0.5, width: 36, height: 36 }} />
      <Dialog open={open} onClose={clickClose} maxWidth={620}>
        <Box sx={{ width: '600px' }} />
        <DialogContent>
          <UserCard user={user} />
        </DialogContent>
      </Dialog>
    </>
  );
}
