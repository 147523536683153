import React from 'react';
import { Card, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
// icon
import { Icon } from '@iconify/react';
import image from '@iconify/icons-ant-design/file-image-fill';
import Channel from 'src/components/_dashboard/social/Channel';
//
import PropTypes from 'prop-types';

ValueNav.propTypes = {
  clickUpload: PropTypes.func,
  idEmpresa: PropTypes.string,
  pid: PropTypes.string
};

export default function ValueNav({ clickUpload, idEmpresa, pid }) {
  return (
    <Card>
      <List>
        <ListItem
          button
          onClick={() => {
            clickUpload();
          }}
        >
          <ListItemIcon>
            <Icon icon={image} width={23} height={23} />
          </ListItemIcon>
          <ListItemText>Agrega imagen</ListItemText>
        </ListItem>
        <Channel collection="valores" doc={idEmpresa + pid} />
      </List>
    </Card>
  );
}
