import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { NuevaEmpresaForm } from 'src/components/_dashboard/entorno';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

EditEmpresa.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  idEmpresa: PropTypes.string
};

export default function EditEmpresa({ open = false, setOpen, idEmpresa }) {
  const clickClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={clickClose} maxWidth="lg">
      <DialogContent>
        <NuevaEmpresaForm edit idPost={idEmpresa} />
      </DialogContent>
    </Dialog>
  );
}
