import React, { useState, useEffect } from 'react';
import { ListItem, ListItemAvatar, ListItemText, Avatar, Tooltip } from '@mui/material';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

UserElement.propTypes = {
  userid: PropTypes.string.isRequired,
  variant: PropTypes.string
};

export default function UserElement({ userid, variant = 'list' }) {
  const [name, setName] = useState('');
  const [img, setImg] = useState('');

  useEffect(() => {
    async function getUser() {
      await firestore
        .collection('users')
        .doc(userid)
        .onSnapshot((doc) => {
          if (doc.exists) {
            const { displayName, photoURL } = doc.data();
            setName(displayName);
            setImg(photoURL);
          }
        });
    }
    getUser();
  }, [userid]);

  if (variant === 'icon') {
    return (
      <>
        <Tooltip title={name}>
          <Avatar src={img} />
        </Tooltip>
      </>
    );
  }

  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar src={img} />
        </ListItemAvatar>
        <ListItemText>{name}</ListItemText>
      </ListItem>
    </>
  );
}
