import React, { useState } from 'react';
import { Button, Dialog, DialogContent, Box } from '@mui/material';
import CTAIframe from './CTAIframe';
import PropTypes from 'prop-types';

CTAButtonIframe.propTypes = {
  buttonText: PropTypes.string,
  src: PropTypes.string,
  onClick: PropTypes.func
};

export default function CTAButtonIframe({ buttonText, src, onClick }) {
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <Button variant="contained" onClick={clickOpen}>
        {buttonText}
      </Button>
      <Dialog open={open} onClose={clickClose} maxWidth="lg">
        <Box sx={{ width: '60vw' }} />
        <DialogContent>
          <CTAIframe src={src} height="350" />
        </DialogContent>
      </Dialog>
    </>
  );
}
