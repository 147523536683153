import React, { useState, useEffect } from 'react';
import { Typography, IconButton, Dialog, DialogContent, List, ListItem, Button } from '@mui/material';
import { styled } from '@mui/styles';
import UserAvatar from 'src/components/_dashboard/general/UserAvatar';
import UserElement from 'src/components/_dashboard/general/UserElement';
// icon
import { Icon } from '@iconify/react';
import addP from '@iconify/icons-ant-design/plus';
//
import PropTypes from 'prop-types';
import { firestore } from 'src/contexts/FirebaseContext';

// -------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary
}));

const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'left'
});

// -------------------------------------------------------------------

PermissionItem.propTypes = {
  idEmpresa: PropTypes.string,
  permiso: PropTypes.string,
  empresa: PropTypes.object,
  altName: PropTypes.string
};

export default function PermissionItem({ idEmpresa, permiso, empresa, altName }) {
  const { membersids } = empresa;
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    async function getUsrs() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('permisos')
        .doc(permiso)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setUsers(doc.data().users);
          }
        });
    }
    getUsrs();
  }, [idEmpresa, permiso]);

  const addPerson = async (userid) => {
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('permisos')
      .doc(permiso)
      .set({ users: [...users, userid] });
  };

  const removePerson = async (userid) => {
    await firestore
      .collection('empresas')
      .doc(idEmpresa)
      .collection('permisos')
      .doc(permiso)
      .set({ users: users.filter((u) => u !== userid) });
  };

  return (
    <>
      <RowStyle>
        <LabelStyle>{Boolean(altName) ? altName : permiso}</LabelStyle>
        {users.map((usrid) => (
          <UserAvatar key={usrid} userid={usrid} removeUser={removePerson} />
        ))}
        <IconButton onClick={clickOpen}>
          <Icon icon={addP} width={23} height={23} />
        </IconButton>
      </RowStyle>
      <Dialog open={open} onClose={clickClose}>
        <DialogContent>
          <List>
            {membersids.map((usrid) => (
              <ListItem key={usrid}>
                <UserElement userid={usrid} />
                <Button
                  onClick={() => {
                    if (users.includes(usrid)) {
                      removePerson(usrid);
                    } else {
                      addPerson(usrid);
                    }
                  }}
                >
                  {users.includes(usrid) ? 'quitar' : 'agregar'}
                </Button>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}
