import { firestore } from 'src/contexts/FirebaseContext';
import { v1 as uuidv1 } from 'uuid';

export function generateNotification(title, description, avatar, type, userid, link) {
  return { title, description, avatar, type, userid, createdAt: new Date(), isUnRead: true, link };
}

export async function createNotification(notification) {
  await firestore.collection('notifications').doc(uuidv1()).set(notification);
}

export async function markAsReadNotification(idNotification) {
  await firestore.collection('notifications').doc(idNotification).update({ isUnRead: false });
}

export async function markAsReadNotifications(userid) {
  await firestore
    .collection('notifications')
    .where('userid', '==', userid)
    .get()
    .then((queryS) => {
      queryS.forEach((doc) => {
        firestore.collection('notifications').doc(doc.id).update({ isUnRead: false });
      });
    });
}
