import React from 'react';
import { Dialog, DialogContent, Grid, Box } from '@mui/material';
import ProyectCard from './ProyectCard';
import PNavigator from './PNavigator';
import PropTypes from 'prop-types';

ProyectDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  proyecto: PropTypes.object
};

export default function ProyectDialog({ open = false, setOpen, proyecto }) {
  const clickClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={clickClose} maxWidth="lg">
      <Box sx={{ width: '80vw' }} />
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <PNavigator proyecto={proyecto} />
          </Grid>
          <Grid item xs={12} lg={9}>
            <ProyectCard proyecto={proyecto} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
