import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, List, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import SprintItem from './SprintItem';
import { firestore } from 'src/contexts/FirebaseContext';
import PropTypes from 'prop-types';

SprintList.propTypes = {
  idEmpresa: PropTypes.string,
  cardId: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  showB: PropTypes.bool
};

export default function SprintList({ idEmpresa, cardId, open = false, setOpen, showB = false }) {
  const [sprints, setSprints] = useState([]);

  useEffect(() => {
    async function getSprints() {
      await firestore
        .collection('empresas')
        .doc(idEmpresa)
        .collection('sprints')
        .where('status', '==', 'en proceso')
        .onSnapshot((queryS) => {
          const datas = [];
          queryS.forEach((doc) => {
            datas.push({ id: doc.id, ...doc.data() });
          });
          setSprints(datas);
        });
    }
    getSprints();
  }, [idEmpresa]);

  const clickOpen = () => {
    setOpen(true);
  };

  const clickClose = () => {
    setOpen(false);
  };

  return (
    <>
      {showB && (
        <Button onClick={clickOpen} variant="contained">
          Sprints
        </Button>
      )}
      <Dialog open={open} onClose={clickClose}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton onClick={clickClose}>
              <Close />
            </IconButton>
            <Typography>Sprints</Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <List>
            {sprints.map((sp) => (
              <SprintItem idEmpresa={idEmpresa} cardId={cardId} info={sp} key={sp.id} />
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}
