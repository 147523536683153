import React, { useState } from 'react';
import {
  TextField,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  AppBar,
  Toolbar,
  IconButton,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Button
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CardIframe } from 'src/components/_dashboard/other';
import { Close } from '@mui/icons-material';
// icon
import { Icon } from '@iconify/react';
import plus from '@iconify/icons-eva/plus-fill';
//
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { firestore } from 'src/contexts/FirebaseContext';
import { v1 as uuidv1 } from 'uuid';
import useAuth from 'src/hooks/useAuth';
import PropTypes from 'prop-types';
import track from 'src/utils/analytics';
import usePlan, { addTagCTAState, isAuthorized } from 'src/hooks/usePlan';
import { captureEvent } from 'src/utils/events';

const moment = require('moment');

NewEmpresaForm.propTypes = {
  idEmpresa: PropTypes.string.isRequired,
  btn: PropTypes.bool,
  listB: PropTypes.bool,
  btnText: PropTypes.string,
  onCreate: PropTypes.func,
  premium: PropTypes.bool
};

export default function NewEmpresaForm({ idEmpresa, btn = false, listB = false, btnText, onCreate, premium = false }) {
  const [openD, setOpenD] = useState(false);
  const { user } = useAuth();
  const { plan, CTAState } = usePlan();
  const autorizado = isAuthorized('empresa', plan);

  const clickOpen = () => {
    setOpenD(true);
    track.event('alta de empresa');
  };

  const clickClose = () => {
    setOpenD(false);
  };

  const clickCloseT = () => {
    setOpenD(false);
    addTagCTAState('empresa', CTAState, user.id, 'notNewEmp');
    captureEvent('notNewEmp', user.id);
  };

  const validation = Yup.object().shape({
    nombre: Yup.string().required('nombre es requerido'),
    description: Yup.string().required('Descripcion es requerida')
  });

  const formik = useFormik({
    initialValues: {
      nombre: '',
      description: '',
      content: '',
      logo: null,
      tags: [],
      activa: true,
      comments: true,
      mision: '',
      vision: '',
      valores: [],
      premium: autorizado && premium,
      expiresP: autorizado && premium ? moment().add(1, 'year').toDate() : null,
      orgMod: false,
      expiresOrg: null
    },
    validationSchema: validation,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const newid = uuidv1();
      try {
        const { nombre, description, content, tags, activa, mision, vision, valores, logo } = values;
        await firestore
          .collection('empresas')
          .doc(idEmpresa)
          .get()
          .then((doc) => {
            if (doc.exists) {
              firestore.collection('empresas').doc(idEmpresa).update({
                nombre,
                description,
                body: content,
                tags,
                activa,
                mision,
                vision,
                valores,
                logo
              });
            } else {
              firestore
                .collection('empresas')
                .doc(newid)
                .set({
                  id: newid,
                  creator: user.id,
                  nombre,
                  description,
                  body: content,
                  tags,
                  activa,
                  mision,
                  vision,
                  valores,
                  logo,
                  createdAt: new Date(),
                  likes: [],
                  membersids: [user.id],
                  membersinfo: [
                    {
                      id: user.id,
                      nombre: user.displayName,
                      avatar: user.photoURL
                    }
                  ]
                });
              if (onCreate) {
                onCreate();
                addTagCTAState('empresa', CTAState, user.id, 'newEmp');
              }
            }
          });
        resetForm();
        clickClose();
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <>
      {listB && (
        <ListItem button onClick={clickOpen} sx={{ color: '#637381' }}>
          <ListItemIcon>
            <Icon icon={plus} width={23} height={23} />
          </ListItemIcon>
          <ListItemText>Nueva Empresa</ListItemText>
        </ListItem>
      )}
      {btn && (
        <Button variant="contained" onClick={clickOpen}>
          {Boolean(btnText) ? btnText : <>Nueva Empresa</>}
        </Button>
      )}
      <Dialog open={openD} onClose={clickCloseT}>
        <AppBar position="relative">
          <Toolbar>
            <IconButton onClick={clickCloseT}>
              <Close />
            </IconButton>
            <Typography variant="subtitle1">Nueva Empresa</Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Nombre de la empresa"
                    {...getFieldProps('nombre')}
                    error={Boolean(touched.nombre && errors.nombre)}
                    helperText={touched.nombre && errors.nombre}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    minRows={3}
                    maxRows={5}
                    label="Descripcion"
                    {...getFieldProps('description')}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>
              </Grid>
              <DialogActions>
                <LoadingButton loading={isSubmitting} type="submit" variant="contained">
                  Guardar
                </LoadingButton>
              </DialogActions>
              <Box>
                <CardIframe src="https://slides.com/alekzen/bunkaprimerospasos/fullscreen" />
              </Box>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Dialog>
    </>
  );
}
